import { styled } from '@mui/material';

import { TextField } from '../TextField';

export const ColorTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    overflow: 'hidden',
  },

  '& .MuiInputAdornment-root, & .MuiInputBase-root': {
    paddingLeft: 0,
  },

  '& input[type="color"]': {
    padding: 0,
    width: 72,
    height: 47,

    WebkitAppearance: 'none',
    border: 'none',
    background: 'transparent',
    marginRight: 16,
    cursor: 'pointer',

    '&::-webkit-color-swatch-wrapper': {
      padding: 0,
    },

    '&::-webkit-color-swatch': {
      border: 'none',
    },
  },
});
