import React, { FC } from 'react';

import { Typography } from '@mui/material';
import classNames from 'classnames';

import { useChipStateStyles } from './ChipState.styles';

export type ChipStateValue = 'upcoming' | 'ongoing' | 'past' | 'actionRequired' | 'cancelled';

// TODO replace useChipStateStyles with styled component
const chipStateVariants: Record<ChipStateValue, { color: string; label: string }> = {
  ongoing: {
    color: 'customGreen.main',
    label: 'Ongoing',
  },
  upcoming: {
    color: 'primary.main',
    label: 'Upcoming',
  },
  past: {
    color: 'custom.lightGrey',
    label: 'Completed',
  },
  cancelled: {
    color: 'error.main',
    label: 'Cancelled',
  },
  actionRequired: {
    color: 'legacy.texasRose',
    label: 'Action required',
  },
};

interface ChipStateProps {
  state: ChipStateValue;
  label?: string;
}

export const ChipState: FC<ChipStateProps> = ({ state, label }) => {
  const classes = useChipStateStyles();

  return (
    <Typography
      variant="bodySmallBold"
      // @ts-expect-error ChipStateValue does not index ClassNameMap
      className={classNames(classes.container, classes[state])}
      data-testid="ChipState"
      component="div"
    >
      {label ?? chipStateVariants[state].label ?? 'Unknown'}
    </Typography>
  );
};
