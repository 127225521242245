import React, { FC, ReactNode } from 'react';

import { Box, Stack, StackProps } from '@mui/material';

export const CenteredWithSidebar: FC<
  {
    sidebar?: ReactNode;
    sidebarWidth: number;
  } & StackProps
> = ({ sidebar, sidebarWidth, maxWidth, children, ...props }) => (
  <Stack direction="row" justifyContent="center" overflow="auto" px={4} py={3} width="100%" {...props}>
    {sidebar ?? <Box width={sidebarWidth} flexShrink={0} mr={4} />}
    <Box component="main" width="100%" sx={({ breakpoints }) => ({ maxWidth: maxWidth ?? breakpoints.values.desktop })}>
      {children}
    </Box>
    <Box
      flexGrow={1}
      flexShrink={0}
      sx={{
        maxWidth: sidebarWidth + 32,
      }}
    />
  </Stack>
);
