import type { Components, Theme } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { typography, palette, spacing } }) => ({
      ...typography.body2,
      fontSize: '14px',
      padding: spacing(1, 3),
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      borderBottomColor: palette.custom.lightGrey,
    }),
    head: ({ theme: { spacing } }) => ({
      padding: spacing(2, 3),
    }),
    stickyHeader: ({ theme: { palette } }) => ({
      backgroundColor: palette.grey[50],
    }),
  },
};
