import { alpha, type Components, type Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      borderRadius: 4,
      '& input + fieldset legend': {
        fontSize: '12px',
      },
      '& > fieldset > legend': {
        fontSize: '12px',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.mode === 'light' ? palette.divider : palette.custom.darkGrey,
      },
      '&.MuiInputBase-readOnly': {
        backgroundColor: palette.divider,
      },
    }),
    input: ({ theme: { palette } }) => ({
      '&:disabled': {
        backgroundColor: alpha(palette.custom.lightGrey, 0.5),
        borderRadius: 4,
        WebkitTextFillColor: palette.custom.darkGrey,
        '& + .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.mode === 'light' ? palette.divider : palette.custom.darkGrey,
          borderRadius: 4,
        },
      },
    }),
  },
};
