import React, { FC } from 'react';

import { SnackBarAlertData } from 'store/features/alerts';

import { DEFAULT_AUTO_HIDE_DURATION, useSnackbarAutoHide } from '../../hooks/useSnackbarAutoHide';
import { Snackbar } from './Snackbar';

type Props = {
  alertData: SnackBarAlertData;
};

export const SnackbarContainer: FC<Props> = ({ alertData }) => {
  const { severity, message } = alertData;
  const timeout =
    (typeof message === 'object' ? message.timeout : DEFAULT_AUTO_HIDE_DURATION) ?? DEFAULT_AUTO_HIDE_DURATION;
  const isInfiniteMessage = timeout === 0;

  const { hide } = useSnackbarAutoHide(alertData, isInfiniteMessage ? null : timeout);

  const handleClose = timeout > DEFAULT_AUTO_HIDE_DURATION || isInfiniteMessage ? hide : undefined;

  return (
    <Snackbar severity={severity} onClose={handleClose}>
      {typeof message === 'string' ? message : message.message}
    </Snackbar>
  );
};
