import React, { ComponentProps, FC, ReactNode, memo } from 'react';

import { AppBar, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { LogoContainer } from 'common/components';
import { useBreakpoints, useElementSize } from 'common/hooks';
import { CloseIcon } from 'icons';
import { useAppSelector } from 'store';
import { selectIsHeaderDisplayed } from 'store/features/app';

import { NavigationBarDivider, SectionWrapper, TitleWrapper } from './NavigationBar.styled';
import {
  CurrentUserContainer,
  GoToHelpCenter,
  MobileMenuButton,
  NavigationBarToolbar,
  TabletMenuButton,
} from './components';

type Props = {
  variant?: 'default';
  title?: ReactNode;
  withSidebarToggle?: boolean;
  disableUserSection?: boolean;
  disableLogoInteraction?: boolean;
  onClose?(): void;
  ToolbarProps?: Omit<ComponentProps<typeof NavigationBarToolbar>, 'title' | 'children'>;
};

/**
 * @module **Natter Design System**
 *
 * **Natter navigation**
 *
 * A horizontal navigation component for Natter products.
 * @link https://www.figma.com/design/En2gOztv0xR9rIjYFtdut5/Design-system?node-id=343-2420
 *
 * @example
 * ```tsx
 * <NavigationBar />
 *
 *
 * <NavigationBar
 *   title="Untitled Event"
 *   ToolbarProps={{
 *     onExit: handleExit,
 *     action: <Button>Button</Button>,
 *     extraButtons: (
 *       <IconButton>
 *         <VisibilityOutlined />
 *       </IconButton>
 *     ),
 *   }}
 * />
 * ```
 **/
export const NavigationBar: FC<Props> = memo(
  ({
    title,
    onClose,
    withSidebarToggle = false,
    disableUserSection = false,
    disableLogoInteraction = false,
    ToolbarProps,
  }) => {
    const { isMobile, isTablet } = useBreakpoints();

    // TODO N2-2439 remove this from Redux as it was used in old FullPageContainer only
    const displayHeader = useAppSelector(selectIsHeaderDisplayed);
    const displayTabletMenuButton = withSidebarToggle && isTablet;
    const displayUserSection = !disableUserSection && !onClose;

    const [ref, size] = useElementSize();

    if (!displayHeader) return null;

    return (
      <>
        <AppBar component="header" position="fixed" color="inherit" ref={ref}>
          <Toolbar>
            <SectionWrapper>
              {displayTabletMenuButton && <TabletMenuButton />}
              <Stack direction="row" gap={1} alignItems="center">
                <LogoContainer disableInteraction={disableLogoInteraction} />

                {title && !ToolbarProps && (
                  <>
                    <NavigationBarDivider sx={{ mr: 1 }} />
                    <TitleWrapper data-testid="NavigationBar-title">
                      <Typography variant="natter-text-sm" noWrap>
                        {title}
                      </Typography>
                    </TitleWrapper>
                  </>
                )}
              </Stack>
            </SectionWrapper>

            {displayUserSection && (
              <Stack direction="row" justifyContent="end" alignItems="center" gap={1}>
                <GoToHelpCenter />
                {isMobile ? (
                  <MobileMenuButton />
                ) : (
                  <>
                    <NavigationBarDivider sx={{ ml: 0.25, mr: 1 }} />
                    <CurrentUserContainer />
                  </>
                )}
              </Stack>
            )}

            {onClose && (
              <IconButton aria-label="Close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>

          {ToolbarProps && <NavigationBarToolbar title={title} {...ToolbarProps} />}
        </AppBar>
        {/**
         * This is needed so we don't have anything behind the fixed position app bar
         * https://mui.com/material-ui/react-app-bar/#fixed-placement
         * */}
        <Toolbar sx={{ height: size.height, flexShrink: 0 }} disableGutters />
      </>
    );
  }
);
