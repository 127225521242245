import React, { FC, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';

import { Box, Stack } from '@mui/material';
import { enumToOptionItems } from 'common/components/Inputs';
import { TimeUnit } from 'domain/Common';

import { Select } from '../Select';
import { TextFieldWithLabel } from '../TextFieldWithLabel';

const timePeriodOptions = enumToOptionItems(TimeUnit);

const getAmountMaxBasedOnUnit = (unit: TimeUnit) => {
  switch (unit) {
    case TimeUnit.Weeks:
      return 51;
    case TimeUnit.Months:
      return 12;
    default:
      return 365;
  }
};

export const RepeatDate: FC<{
  amountName: string;
  unitName: string;
  label: ReactNode;
  disabled?: boolean;
  unitDisabled?: boolean;
}> = ({ amountName, unitName, label, disabled, unitDisabled }) => {
  const unit = useWatch({ name: unitName });
  const max = getAmountMaxBasedOnUnit(unit);

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} gap={1}>
      <Box width={{ xs: 1, md: 200 }} minWidth={214} flexShrink={0}>
        <TextFieldWithLabel
          disabled={disabled}
          name={amountName}
          label={label}
          type="number"
          InputProps={{
            inputProps: { min: 1, max, 'data-testid': 'RepeatDate-input-amount' },
          }}
          fullWidth
        />
      </Box>

      <Select
        name={unitName}
        options={timePeriodOptions}
        disabled={disabled || unitDisabled}
        data-testid="RepeatDate-input-unit"
      />
    </Stack>
  );
};
