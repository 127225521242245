import { type Components, type Theme, alpha } from '@mui/material';

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  styleOverrides: {
    root: ({ theme: { breakpoints, spacing, palette, typography } }) => ({
      flex: 1,
      padding: spacing(1, 0),
      borderColor: palette.primary.main,
      color: palette.grey[700],

      ...typography['natter-text-md'],
      fontWeight: 500,

      '&:hover': {
        backgroundColor: alpha(palette.primary.main, 0.15),
      },
      '&.Mui-selected': {
        touchAction: 'none',
        pointerEvents: 'none',
        backgroundColor: palette.primary.main,
        color: palette.getContrastText(palette.primary.main),
        '&:hover': {
          // required for proper UX on mobile
          backgroundColor: palette.primary.main,
        },
      },

      [breakpoints.down('sm')]: {
        ...typography['natter-text-sm'],
      },
    }),
  },
};
