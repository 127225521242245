import React from 'react';

import { Components, Theme } from '@mui/material';
import { StarIcon } from 'icons';

import { orange } from '../palette';

export const MuiRating: Components<Theme>['MuiRating'] = {
  defaultProps: {
    icon: <StarIcon />,
    emptyIcon: <StarIcon />,
  },
  styleOverrides: {
    root: {
      color: orange[400],
    },
    sizeLarge: ({ theme: { spacing } }) => ({
      fontSize: spacing(6),
      gap: spacing(2),
      transform: `translateX(${spacing(1)})`,
    }),
    iconEmpty: ({ theme: { palette } }) => ({
      color: palette.grey[300],
    }),
  },
};
