import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { pinInputSchema } from './schema';

type Props = {
  onSubmit(pin: string): void;
  initialValue?: string;
  length?: number;
};

export const usePinInputForm = ({ onSubmit, initialValue = '', length = 6 }: Props) => {
  const methods = useForm({
    defaultValues: {
      pin: Array.from({ length }).map((_, index) => ({ value: initialValue[index] ?? '' })),
    },
    resolver: yupResolver(pinInputSchema),
    mode: 'onChange',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit(values.pin.map(({ value }) => value).join(''));
  });

  return { methods, handleSubmit };
};
