/**
 * Converts a value to a boolean. Needed e.g. to parse env variables
 *
 * @param {string | number | boolean | undefined | null} value - The value to be converted.
 * @returns {boolean} - The boolean representation of the input value.
 *
 * @example
 * // Returns true
 * toBoolean(true);
 *
 * @example
 * // Returns false
 * toBoolean(0);
 *
 * @example
 * // Returns true
 * toBoolean('true');
 *
 * @example
 * // Returns false
 * toBoolean('false');
 *
 * @example
 * // Returns false
 * toBoolean(undefined);
 */
export const toBoolean = (value: string | number | boolean | undefined | null): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'number') {
    return Boolean(value);
  }

  return value === 'true';
};
