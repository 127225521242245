import React, { FC } from 'react';

import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { Logo } from 'common/layout/components/Logo';

export const PublicPage: FC<{
  communityLogo?: string;
}> = ({ communityLogo, children }) => (
  <>
    <AppBar component="header" position="relative" color="inherit">
      <Toolbar>
        <Logo logoImageLink={communityLogo} />
      </Toolbar>
    </AppBar>
    <Box height={1} sx={{ overflow: 'auto' }}>
      <Container sx={({ spacing }) => ({ height: `calc(100% - ${spacing(2)})` })}>{children}</Container>
    </Box>
  </>
);
