import { createApi } from '@reduxjs/toolkit/query/react';
import { ERROR_MESSAGES } from 'common/constants';
import { assert } from 'common/utils';
import { serializeDateFields } from 'common/utils/time';
import { BasicPaginationParams, PaginatedApiParamsV3, PaginatedApiPayloadV2 } from 'domain/Common';
import { EventQuestionAssignmentType, EventStatus } from 'domain/event';
import { pickBy } from 'lodash';
import { flow, prop } from 'lodash/fp';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import {
  AnswerStatistics,
  CreateInsightsReportParams,
  GetReportSourcesParams,
  InsightsReportData,
  TagCategoryDemographics,
} from '../insightsReport';
import {
  mapAnswerStatisticsList,
  mapEventsListToEventReportSources,
  mapEventsReportDataToInsightsReportData,
  mapEventsReportListToInsightsReportList,
} from './mappers';
import { EventReportData, EventReportSource } from './types';

const noReportIdError = 'Report ID required';
const apiV2 = getApiBaseUrl('v2');
const apiV3 = getApiBaseUrl('v3');

export const eventsReportApi = createApi({
  reducerPath: 'eventsReportApi',
  tagTypes: ['REPORTS', 'SURVEY_REPORTS', 'REPORT_DEMOGRAPHICS', 'REPORT_ANSWERS', 'REPORT_SOURCES'],
  baseQuery: axiosBaseQuery({ baseURL: '/' }),
  endpoints: (builder) => ({
    getEventsReportsList: builder.query<
      PaginatedApiPayloadV2<InsightsReportData>,
      PaginatedApiParamsV3 & { communityId: number | null }
    >({
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'REPORTS' as const, id: id.toString() })),
              { type: 'REPORTS' as const, id: 'LIST' },
            ]
          : ['REPORTS'],
      query: ({ communityId, ...params }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return {
          url: `${apiV2}/event/report/reports/${communityId}`,
          params,
        };
      },
      transformResponse: mapEventsReportListToInsightsReportList,
    }),
    getEventsReportById: builder.query<InsightsReportData, { reportId: number | undefined }>({
      providesTags: (result) => (result?.id ? [{ type: 'REPORTS', id: result.id }] : []),
      query: ({ reportId }) => {
        assert(reportId, noReportIdError);
        return `${apiV2}/event/report/${reportId}`;
      },
      transformResponse: flow(serializeDateFields(), mapEventsReportDataToInsightsReportData),
    }),
    getIsAnyEventReport: builder.query<boolean, { communityId: number }>({
      query: ({ communityId }) => ({
        url: `${apiV2}/event/report/reports/${communityId}`,
        page: 1,
        size: 1,
      }),
      transformResponse: (data: PaginatedApiPayloadV2<EventReportData>) => data.totalCount > 0,
    }),
    getEventsReportSources: builder.query<PaginatedApiPayloadV2<EventReportSource>, GetReportSourcesParams>({
      providesTags: ['REPORT_SOURCES'],
      query: ({ page, pageSize, communityId }) => ({
        url: `${apiV2}/event`,
        params: {
          page,
          size: pageSize,
          states: [EventStatus.Finished].join(','),
          communityId,
        },
      }),
      transformResponse: mapEventsListToEventReportSources,
    }),
    getSimilarEventsReportSources: builder.query<
      PaginatedApiPayloadV2<EventReportSource>,
      BasicPaginationParams & { id: number }
    >({
      query: ({ id, page, pageSize }) => ({
        url: `${apiV2}/event/${id}/similar-events`,
        params: { page, size: pageSize },
      }),
      transformResponse: mapEventsListToEventReportSources,
      forceRefetch: () => true,
    }),
    createEventsReport: builder.mutation<void, CreateInsightsReportParams>({
      query: ({ ids, selectAll }) => ({
        url: `${apiV2}/event/report`,
        method: 'POST',
        data: {
          allEvents: selectAll,
          eventIds: ids,
        },
      }),
    }),
    retryEventsReportGeneration: builder.mutation<void, InsightsReportData>({
      invalidatesTags: (_result, _error, { id }) => [{ type: 'REPORTS', id }],
      query: ({ associatedItems, name }) => ({
        url: `${apiV2}/event/report`,
        method: 'POST',
        data: { eventIds: associatedItems.map(prop('id')), name },
      }),
    }),
    updateEventsReport: builder.mutation<void, Pick<InsightsReportData, 'id' | 'name'>>({
      query: ({ id, name }) => ({
        url: `${apiV3}/report/${id}`,
        method: 'PUT',
        data: { name },
      }),
    }),
    deleteEventsReport: builder.mutation<void, Pick<InsightsReportData, 'id' | 'name' | 'status'>>({
      query: ({ id }) => ({
        url: `${apiV3}/report/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'REPORTS', id: 'LIST' }],
    }),
    getEventsReportKeyMetrics: builder.query<Record<string, number>, { reportId: number }>({
      providesTags: (_result, _error, args) => (args.reportId ? [{ type: 'REPORTS', id: args.reportId }] : []),
      query: ({ reportId }) => {
        assert(reportId, noReportIdError);
        return `${apiV2}/event/report/${reportId}/event-metrics`;
      },
    }),
    getEventsReportDemographics: builder.query<TagCategoryDemographics[], { reportId: number }>({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_DEMOGRAPHICS', id: reportId }] : [],
      query: ({ reportId }) => `${apiV2}/event/report/${reportId}/event-demographics`,
    }),
    getEventsReportAnswers: builder.query<
      AnswerStatistics[],
      { reportId: number; assignmentType: EventQuestionAssignmentType; communityTagIds?: Record<number, number[]> }
    >({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_ANSWERS', id: reportId }] : [],
      query: ({ reportId, communityTagIds, assignmentType }) => {
        const filteredCommunityTagIds = pickBy(communityTagIds, (value) => value.length);
        const communityTagIdsParam = Object.keys(filteredCommunityTagIds).length
          ? JSON.stringify(filteredCommunityTagIds)
          : undefined;
        return {
          url: `${apiV2}/event/report/${reportId}/answers`,
          params: {
            eventQuestionAssignmentType: assignmentType,
            communityTagIds: communityTagIdsParam,
          },
        };
      },
      transformResponse: mapAnswerStatisticsList,
    }),
    exportEventsReport: builder.mutation<void, { reportId: number }>({
      query: ({ reportId }) => ({
        url: `${apiV3}/report/${reportId}/export`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetEventsReportsListQuery,
  useRetryEventsReportGenerationMutation,
  useGetEventsReportByIdQuery,
  useGetEventsReportKeyMetricsQuery,
  useGetEventsReportDemographicsQuery,
  useGetEventsReportAnswersQuery,
  useGetIsAnyEventReportQuery,
  useCreateEventsReportMutation,
  useUpdateEventsReportMutation,
  useDeleteEventsReportMutation,
  useLazyGetEventsReportSourcesQuery,
  useLazyGetSimilarEventsReportSourcesQuery,
  useExportEventsReportMutation,
} = eventsReportApi;
