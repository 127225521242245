import type { Components, Theme } from '@mui/material';

export const MuiCard: Components<Theme>['MuiCard'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      border: `1px solid ${palette.custom.lightGrey}`,
      borderRadius: 16,
    }),
  },
};
