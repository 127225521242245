import { Nullable } from 'domain/Common';
import type {
  Answer,
  AnswerBase,
  QuestionAssignmentAnswer,
  QuestionAssignmentKey,
  QuestionAssignmentType,
} from 'domain/question';

export type QuestionsWSParams<T = Record<string, unknown>> = T & QuestionAssignmentKey;
export type AssignAnswerToUserIdsRequest = Pick<AnswerBase, 'answerId' | 'questionId' | 'assignmentId'> & {
  userIds: number[];
};
export type AnonymiseAnswerRequest = Pick<AnswerBase, 'answerId' | 'questionId' | 'assignmentId'>;
export type UpdateAnswerRequest = Answer;
export type DeleteAnswerRequest = Pick<AnswerBase, 'answerId' | 'questionId' | 'assignmentId'>;

export type QuestionAssignmentWSPayload = {
  type: QuestionAssignmentType;
  questionId: number;
  assignmentId: string;
  enabledUntil: number;
  title: string;
  answers: { [key: string]: QuestionAssignmentAnswer };
  questionAssignmentKey: QuestionAssignmentKey;
};

export type QuestionAssignmentState = {
  type: QuestionAssignmentType;
  questionId: number;
  assignmentId: string;
  enabledUntil: number;
  title: Nullable<string>;
  questionAssignmentKey: Nullable<QuestionAssignmentKey>;
  activeAnswerId: string | undefined;
};
export type EnabledUntilPayload = QuestionAssignmentAnswer;
export type DisabledPayload = QuestionAssignmentKey;
export type AnswerAssignedToUserIdsPayload = QuestionAssignmentAnswer;
export type AnswerAnonymizedPayload = Answer['answerId'];
export type AnswerCreatedPayload = QuestionAssignmentAnswer;
export type AnswerDeletedPayload = Answer['answerId'];
export type AnswerUpdatedPayload = {
  answer: QuestionAssignmentAnswer;
  updatedByUserId: number;
};

export const RoomQuestionAnswerAuthor = {
  Me: 'ME',
  Shared: 'SHARED',
  Anonymous: 'ANONYMOUS',
  Participant: 'PARTICIPANT',
  Unknown: 'UNKNOWN',
} as const;

export type RoomQuestionAnswerAuthorType = (typeof RoomQuestionAnswerAuthor)[keyof typeof RoomQuestionAnswerAuthor];

export const RoomQuestionAnswerAuthorLabel = {
  [RoomQuestionAnswerAuthor.Anonymous]: 'Anonymized',
  [RoomQuestionAnswerAuthor.Me]: 'You',
  [RoomQuestionAnswerAuthor.Shared]: 'Shared',
  [RoomQuestionAnswerAuthor.Participant]: 'Participant',
  [RoomQuestionAnswerAuthor.Unknown]: 'Unknown',
} as const;
