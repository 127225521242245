import type { Components, Theme } from '@mui/material';

export const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  defaultProps: {
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      '&:before': {
        display: 'none',
      },
    },
    rounded: {
      '&:last-child': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
};
