import { useCallback, useState } from 'react';

import imageCompression from 'browser-image-compression';

export type Image = {
  name: string;
  type: string;
  data: string;
};

interface Props {
  onImageLoaded(image: Image): void;
  onError(error: unknown): void;
  onCompressionProgress?(progress: number): void;
}

export const useImageCompression = ({ onImageLoaded, onError, onCompressionProgress }: Props) => {
  const [progress, setProgress] = useState(0);

  const handleProgress = useCallback(
    (compressionProgress) => {
      setProgress(compressionProgress);
      onCompressionProgress?.(compressionProgress);
    },
    [onCompressionProgress]
  );

  const compressImage = useCallback(
    async (file: File) => {
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.5,
          useWebWorker: true,
          onProgress: handleProgress,
        });

        const reader = new FileReader();
        reader.onloadend = (): void => {
          if (!reader.result) {
            return;
          }
          handleProgress(0);
          onImageLoaded({
            name: compressedFile.name,
            type: compressedFile.type,
            /**
             * reader.result is a string if we use `reader.readAsDataURL`
             * so no issue casting this for typesafety
             * https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result#value
             */
            data: reader.result as string,
          });
        };

        reader.readAsDataURL(compressedFile);
      } catch (error: unknown) {
        onError(error);
      }
    },
    [onError, onImageLoaded, handleProgress]
  );

  return {
    compressImage,
    progress,
  };
};
