const AppEnvironment = {
  Production: 'prod',
  Development: 'dev',
} as const;

export const isProduction = () => window._env_.REACT_APP_ENVIRONMENT === AppEnvironment.Production;

export const isDevelopment = () => window._env_.REACT_APP_ENVIRONMENT === AppEnvironment.Development;

// Assuming that any other value for REACT_APP_ENVIRONMENT represents the local environment
export const isLocal = () => !isProduction() && !isDevelopment();
