import { Box, Stack, lighten, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  isPreview?: boolean;
  isDragging?: boolean;
  indicatorPosition?: 'start' | 'end';
};

export const DraggableItemWrapper = styled(Stack, {
  shouldForwardProp: omitForwardedProps<Props>('isDragging', 'isPreview', 'indicatorPosition'),
})<Props>(({ theme: { palette, spacing, shadows, shape }, isPreview, isDragging, indicatorPosition }) => {
  const getBorderColor = () => {
    if (isPreview) return palette.primary.main;
    if (isDragging) return lighten(palette.primary.main, 0.95);
    return palette.grey[400];
  };

  return {
    position: 'relative',

    border: `1px solid`,
    borderColor: getBorderColor(),
    borderRadius: shape.borderRadius,
    padding: spacing(1),
    ...(indicatorPosition === 'start' ? { paddingLeft: 0 } : { paddingRight: 0 }),

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing(1),

    wordBreak: 'break-word',
    background: isDragging ? lighten(palette.primary.main, 0.95) : 'white',
    boxShadow: isPreview ? shadows[6] : undefined,

    '& .MuiSvgIcon-root': {
      color: palette.grey[400],
      flexBasis: 44,
    },
  };
});

export const DragIconWrapper = styled(Box)<{ indicatorPosition: Props['indicatorPosition'] }>(
  ({ indicatorPosition }) => ({
    order: indicatorPosition === 'start' ? -1 : undefined,
    cursor: 'grab',
  })
);
