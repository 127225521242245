import { DEFAULT_PRIMARY_COLOR } from 'common/theme/palette';
import { array, boolean, number, object, string } from 'yup';

import { tagsCategoriesSchema } from '../CommunityTags/schema';

export const basicCommunityWizardSchema = object({
  id: number(),
  name: string()
    .trim()
    .min(3, 'Name too short (min 3 characters)')
    .max(255, 'Name too long (max 255 characters)')
    .required(),
  color: object({ hex: string().required().default(DEFAULT_PRIMARY_COLOR) }),
  guests: array()
    .of(object({ email: string().required() }))
    .default([]),
  calendarPermissionsRequired: boolean(),
  logo: object(),
}).concat(tagsCategoriesSchema);
