import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { isApiResponse } from 'common/utils';
import type { RootState } from 'store';
import { addAppConfirmationDialog } from 'store/features/alerts';
import { handleQueryError } from 'store/utils';

import { getSurveySuccessEffect } from './effects';
import { surveyApi } from './surveyApi';

export const surveyApiLister = createListenerMiddleware<RootState>();

surveyApiLister.startListening({
  matcher: isAnyOf(
    surveyApi.endpoints.getSurveysList.matchRejected,
    surveyApi.endpoints.createSurvey.matchRejected,
    surveyApi.endpoints.deleteSurvey.matchRejected,
    surveyApi.endpoints.publishSurvey.matchRejected,
    surveyApi.endpoints.updateSurvey.matchRejected,
    surveyApi.endpoints.unpublishSurvey.matchRejected,
    surveyApi.endpoints.createSurveyQuestions.matchRejected,
    surveyApi.endpoints.createSurveyUsers.matchRejected,
    surveyApi.endpoints.createSurveyAnswers.matchRejected
  ),
  effect: ({ type, payload }, { dispatch }) => {
    const response = isApiResponse(payload) ? payload : undefined;
    handleQueryError(payload, dispatch, type, response?.data || 'Unable to call Survey API');
  },
});

surveyApiLister.startListening({
  matcher: surveyApi.endpoints.getSurvey.matchFulfilled,
  effect: getSurveySuccessEffect,
});

surveyApiLister.startListening({
  matcher: surveyApi.endpoints.publishSurvey.matchFulfilled,
  effect: (_, { dispatch }) => {
    dispatch(
      // TODO Display copy link to share the survey
      addAppConfirmationDialog({
        title: 'Survey successfully published',
        description: 'Your Survey was successfully published and shared with your participants.',
        confirmLabel: 'Got it',
        showWarningIcon: false,
        centered: true,
      })
    );
  },
});

surveyApiLister.startListening({
  matcher: surveyApi.endpoints.unpublishSurvey.matchFulfilled,
  effect: (_, { dispatch }) => {
    dispatch(
      addAppConfirmationDialog({
        title: 'Survey is now unpublished',
        description: 'You can publish it again until the cut off due date.',
        confirmLabel: 'Got it',
        showWarningIcon: false,
        centered: true,
      })
    );
  },
});

surveyApiLister.startListening({
  matcher: surveyApi.endpoints.deleteSurvey.matchFulfilled,
  effect: (_, { dispatch }) => {
    dispatch(
      addAppConfirmationDialog({
        title: 'Survey successfully deleted',
        description: 'This Survey was successfully deleted and removed from your Surveys list.',
        confirmLabel: 'Got it',
        showWarningIcon: false,
        centered: true,
      })
    );
  },
});
