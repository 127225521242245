import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

export const DragProvider: FC = ({ children }) => (
  <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} context={window}>
    {children}
  </DndProvider>
);
