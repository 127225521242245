export const MeetingWSChannels = {
  public: '/meeting/:eventId',
  private: '/user/meeting/:eventId',
} as const;

export const MeetingWSMessages = {
  EventStarted: 'event_started',
  GuestExitedMeeting: 'guest_exited_meeting',
  GuestHandUp: 'guest_hand_up',
  GuestHandDown: 'guest_hand_down',
  GuestHandLoweredBy: 'guest_hand_lowered_by',
  BigMeetingModeEnabled: 'big_meeting_mode_enabled',
  AudienceCountUpdated: 'audience_count_updated',
  EventMetricsUpdated: 'event_metrics_updated',
  BmmGuestHandUp: 'bmm_guest_hand_up',
  BmmGuestHandDown: 'bmm_guest_hand_down',
  BmmGuestHandUpList: 'bmm_guest_hand_up_list',
  UserMuted: 'guest_muted',
} as const;

export type MeetingWSMessage = (typeof MeetingWSMessages)[keyof typeof MeetingWSMessages];

export const MeetingWSPath = '/meeting/v2/:eventId' as const;

export const MeetingWSActions = {
  RaiseHand: `${MeetingWSPath}/raise_hand`,
  LowerHand: `${MeetingWSPath}/lower_hand`,
  LowerOtherUserHand: `${MeetingWSPath}/lower_hand/:userId`,
  Join: `${MeetingWSPath}/join`,
  JoinNattering: `${MeetingWSPath}/join_nattering`,
  SkipNattering: `${MeetingWSPath}/skip_nattering`,
  MuteUser: `${MeetingWSPath}/mute_guest`,
} as const;

export type MeetingWSAction = (typeof MeetingWSActions)[keyof typeof MeetingWSActions];
