import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { CommunityMetrics } from 'domain/Community';
import axiosBaseQuery from '../_axiosBaseQuery';

export const coDashboardApi = createApi({
  reducerPath: 'coDashboardApi',
  tagTypes: ['ALL_COMMUNITY_MEMBERS', 'COMMUNITY_MEMBER'],
  baseQuery: retry(axiosBaseQuery(), { maxRetries: 3 }),
  endpoints: (builder) => ({
    getCoDashboardData: builder.query<CommunityMetrics, { communityId: number | undefined }>({
      query: ({ communityId }) => {
        if (!communityId) {
          throw new Error('CommunityId required');
        }

        return `/community/${communityId}/dashboard`;
      },
    }),
  }),
});

export const { useGetCoDashboardDataQuery } = coDashboardApi;
