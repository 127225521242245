import { ErrorInfo, createContext } from 'react';

interface ErrorBoundaryContextState {
  triggerError(args: { message?: string; error?: Error; errorInfo?: ErrorInfo }): void;
}

const ErrorBoundaryContext = createContext<ErrorBoundaryContextState>({
  triggerError: ({ error }) => console.error(error),
});

export { ErrorBoundaryContext };
