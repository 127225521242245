import { createAction } from '@reduxjs/toolkit';

export const eventBroadcastActions = {
  agora: {
    createAudioTrack: {
      failed: createAction<{ error: unknown }>('[Event Broadcast Agora] Create Audio Track Failed'),
    },
    publishAudioTrack: {
      failed: createAction<{ error: unknown }>('[Event Broadcast Agora] Publish Audio Track Failed'),
    },
    removeAudioTrack: {
      failed: createAction<{ error: unknown }>('[Event Broadcast Agora] Remove Audio Track Failed'),
    },
    createVideoTrack: {
      failed: createAction<{ error: unknown }>('[Event Broadcast Agora] Create Video Track Failed'),
    },
    publishVideoTrack: {
      failed: createAction<{ error: unknown }>('[Event Broadcast Agora] Publish Video Track Failed'),
    },
    removeVideoTrack: {
      failed: createAction<{ error: unknown }>('[Event Broadcast Agora] Remove Video Track Failed'),
    },
    video: {
      track: {
        stateChanged: {
          failed: createAction<{ error: unknown }>('[Event Broadcast Agora] Video Track State Changed Failed'),
        },
      },
    },
  },
};
