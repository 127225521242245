import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

type Defined<T> = {
  [K in keyof T]-?: Exclude<T[K], undefined>;
};

export function omitUndefined<T extends object>(params: T): Defined<T> {
  return omitBy(params, isUndefined) as Defined<T>;
}
