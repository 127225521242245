import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { isApiResponse } from 'common/utils';
import type { RootState } from 'store';
import { handleQueryError } from 'store/utils';

import { attachmentsApi } from './attachmentsApi';

export const attachmentsApiListener = createListenerMiddleware<RootState>();

attachmentsApiListener.startListening({
  matcher: isAnyOf(
    attachmentsApi.endpoints.uploadAttachment.matchRejected,
    attachmentsApi.endpoints.deleteAttachment.matchRejected
  ),
  effect: ({ type, payload }, { dispatch }) => {
    const response = isApiResponse(payload) ? payload : undefined;
    handleQueryError(payload, dispatch, type, response?.data || 'Unexpected error with attachments');
  },
});
