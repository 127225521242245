/**
 * Extract variables from a string path.
 *
 * @param {string} path - The input path string containing variables prefixed with ':'. For example, '/meeting/:eventId/join'.
 * @returns {string[]} An array of variable names extracted from the path. For the example input, the result is ['eventId'].
 *
 * @example
 * const pathVariables = extractPathVariables('/meeting/:eventId/join');
 * console.log(pathVariables);
 * // Output: ['eventId']
 */
export const extractPathVariables = (path: string): string[] => {
  const regex = /:([^/?]+)(\?)?/g;
  const matches = path.match(regex);

  if (matches) {
    // Use Set to remove duplicates
    return Array.from(new Set(matches.map((match) => match.slice(1))));
  }

  return [];
};
