import React, { FC, ReactElement } from 'react';

import { IconButton, IconButtonProps } from '@mui/material';

interface ButtonIconProps extends IconButtonProps {
  icon: ReactElement;
  fullWidth?: boolean;
  contrastColor?: boolean;
  tooltip?: string;
}

export const ButtonIcon: FC<ButtonIconProps> = ({
  icon,
  color = 'primary',
  size = 'small',
  hidden,
  fullWidth,
  sx,
  ...props
}) => {
  if (hidden) {
    return null;
  }

  return (
    <IconButton
      size={size}
      color={color}
      hidden={hidden}
      {...props}
      sx={(theme) => ({
        width: fullWidth ? '100%' : undefined,
        ...(typeof sx === 'function' ? (sx as Function)(theme) : sx),
      })}
    >
      {icon}
    </IconButton>
  );
};
