import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useFilterByTagsModalStyles = makeStyles(
  (theme) => ({
    filled: {
      background: lighten(theme.palette.primary.main, 0.9),
      border: `1px solid ${theme.palette.primary.main}`,
    },
    outlined: {
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.custom.lightGrey}`,
    },
    tagWrapper: {
      maxWidth: '100%',
    },
    tag: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      borderRadius: 4,
    },
    filterPopover: {
      width: 520,
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    filter: {
      margin: 10,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(4),
    },
    clearAll: {
      fontSize: 14,
      color: theme.palette.common.black,
      cursor: 'pointer',
    },
    clearButton: {
      color: theme.palette.common.black,
      width: '150px',
      height: '44px',
    },
  }),
  { name: 'filterByTagsModal' }
);
