import { RefObject, useEffect } from 'react';

type Params = {
  ref: RefObject<HTMLElement>;
  block?: ScrollLogicalPosition;
  skip?: boolean;
};

export const useScrollIntoView = ({ ref, skip = false, block }: Params) => {
  useEffect(() => {
    if (skip || !ref.current) return;

    ref.current.scrollIntoView({
      block,
      behavior: 'instant',
    });
  }, [ref, skip, block]);
};
