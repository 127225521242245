import React, { FC } from 'react';

import { SearchIcon } from 'icons';

import { TextField, TextFieldProps } from '../TextField';

export type SearchInputProps = {
  label?: string;
  onChange(value: string): void;
} & Pick<TextFieldProps, 'size' | 'value' | 'fullWidth'>;

export const SearchInput: FC<SearchInputProps> = ({
  value,
  fullWidth = true,
  onChange,
  label = 'Search',
  size = 'small',
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ target }) =>
    onChange(target.value);

  return (
    <TextField
      value={value}
      fullWidth={fullWidth}
      placeholder={label}
      prefix={<SearchIcon sx={{ height: 20, width: 20, marginLeft: -1 }} />}
      size={size}
      onChange={handleChange}
    />
  );
};
