import { useMemo } from 'react';

import { orderBy } from 'lodash';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import { BasicChartData } from '../types';
import { getColor } from '../utils';

type Props = {
  data: BasicChartData;
  dataKeys: string[];
};

export const usePieData = ({ data, dataKeys }: Props) => {
  const chartData = useMemo(() => orderBy(data, dataKeys, 'desc'), [data, dataKeys]);
  const legendData = useMemo(
    () =>
      chartData.map<Payload>(({ name }, index) => ({
        value: name,
        type: 'square',
        color: getColor(index),
      })),
    [chartData]
  );

  return {
    chartData,
    legendData,
  };
};
