import { createSelector } from '@reduxjs/toolkit';
import { getFirstName } from 'common/utils';
import { EventRoomAttendee } from 'domain/event';
import type { RootState } from 'store';
import { selectUserAccount } from 'store/apis/user';

import { answersAdapter } from './answersSlice';
import { RoomQuestionAnswerAuthor, RoomQuestionAnswerAuthorLabel } from './types';

const answersSelectors = answersAdapter.getSelectors<RootState>((state) => state.question.answers);

const selectQuestionsRoot = (state: RootState) => state.question;

export const selectQuestionTitle = createSelector(selectQuestionsRoot, (state) => state.questionAssignment?.title);
export const selectQuestionActiveAnswerId = (state: RootState) => state.question.questionAssignment.activeAnswerId;
export const selectQuestionAssignmentKey = createSelector(
  selectQuestionsRoot,
  (state) => state.questionAssignment?.questionAssignmentKey
);
export const selectAnswers = (state: RootState) => answersSelectors.selectAll(state);
export const selectAnswersIds = (state: RootState) => answersSelectors.selectIds(state);
export const selectAnswer = answersSelectors.selectById;

export const selectAnswerUpdatedByUserId = createSelector(selectAnswer, (answer) => answer?.updatedByUserId);

export const selectAnswerIsUpdatedByCurrentUser = createSelector(
  selectAnswerUpdatedByUserId,
  selectUserAccount,
  (updatedByUserId, user) => updatedByUserId === user?.id
);

export const selectAnswerAssignedToUserIds = createSelector(selectAnswer, (answer) => answer?.assignedToUserIds);

export const selectAnswerText = createSelector(selectAnswer, (answer) => answer?.text);

export const selectOwnedEmptyAnswerId = createSelector(
  selectAnswers,
  selectUserAccount,
  (answers, user) =>
    // TODO N2-1084 Trim as a workaround for non empty answers in Question Service
    answers.find(
      ({ text, assignedToUserIds }) => user?.id && assignedToUserIds.includes(user.id) && !text.trim().length
    )?.uuid
);

export const selectAnswerAuthor = createSelector(selectAnswer, selectUserAccount, (answer, user) => {
  if (!answer) return RoomQuestionAnswerAuthor.Unknown;

  if (answer.isAnonymous) return RoomQuestionAnswerAuthor.Anonymous;
  if (answer.assignedToUserIds.length > 1) return RoomQuestionAnswerAuthor.Shared;
  if (answer.assignedToUserIds.every((id) => id === user?.id)) return RoomQuestionAnswerAuthor.Me;
  return RoomQuestionAnswerAuthor.Participant;
});

export const selectIsSharedAnswer = createSelector(
  selectAnswerAuthor,
  (author) => author === RoomQuestionAnswerAuthor.Shared
);

export const selectAnswerAuthorLabel = createSelector(
  selectAnswer,
  selectAnswerAuthor,
  (state: RootState, answerId: string, attendees: EventRoomAttendee[]) => attendees,
  (answer, author, attendees) => {
    if (author !== RoomQuestionAnswerAuthor.Participant) {
      return RoomQuestionAnswerAuthorLabel[author];
    }

    const attendee = attendees.find(({ id }) => answer?.assignedToUserIds.includes(id));

    if (attendee) {
      return `${getFirstName(attendee)}'s perspective`;
    }
  }
);

export const selectAnswerEditedByLabel = createSelector(
  selectAnswer,
  selectUserAccount,
  (state: RootState, answerId: string, attendees: EventRoomAttendee[]) => attendees,
  (answer, user, attendees) => {
    if (!answer?.updatedByUserId) return;

    const updatedBy = attendees.find(({ id }) => id === answer.updatedByUserId && id !== user?.id);
    if (updatedBy) return `${getFirstName(updatedBy)} is editing...`;
  }
);

export const selectAnswerCanEdit = createSelector(
  (state: RootState, answerId?: string) => (answerId ? selectAnswer(state, answerId) : undefined),
  selectUserAccount,
  selectAnswerIsUpdatedByCurrentUser,
  (answer, user, isUpdatedByCurrentUser) => {
    if (!user?.id || !answer) return false;
    return (!!isUpdatedByCurrentUser && !answer.assignedToUserIds.length) || answer.assignedToUserIds.includes(user.id);
  }
);

export const selectAnswerPlaceholder = createSelector(
  selectAnswerAuthor,
  selectAnswerAuthorLabel,
  selectAnswerCanEdit,
  (state: RootState, answerId: string, attendees: EventRoomAttendee[], placeholder: string) => placeholder,
  (author, authorLabel, canEdit, placeholder) => {
    if (canEdit) return placeholder;
    return `You can't edit ${author !== 'ANONYMOUS' ? authorLabel : 'that answer'}`;
  }
);
