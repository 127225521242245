import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Stack, Typography } from '@mui/material';
import { IconButton } from 'common/components';
import { constantToLabel } from 'common/utils/constantToLabel';
import { CommunityGuest } from 'domain/Community';
import { DeleteIcon } from 'icons';

import { GuestItemContainer } from './GuestItem.styled';

export type GuestItemProps = {
  fieldName: string;
  user: CommunityGuest;
  index: number;
};

export const GuestItem: FC<GuestItemProps> = ({ user, fieldName, index }) => {
  const { getValues, setValue } = useFormContext();

  const handleRemoveGuestIconClicked = useCallback(() => {
    const guests: unknown[] = getValues(fieldName);
    guests.splice(index, 1);
    setValue(fieldName, guests);
  }, [fieldName, getValues, index, setValue]);

  return (
    <GuestItemContainer>
      <Stack direction="row" justifyContent="space-between" flex={1} gap={2} data-testid={`GuestItem-${user.email}`}>
        <Typography variant="bodyBold">{user.email}</Typography>
        {user.communityRole && <Typography>{constantToLabel(user.communityRole)}</Typography>}
      </Stack>
      <IconButton variant="text" color="error" onClick={handleRemoveGuestIconClicked}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </GuestItemContainer>
  );
};
