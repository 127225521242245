import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Chip, Grid, Typography } from '@mui/material';
import type { Tag, TagsCategory } from 'domain/Common';

import { useTagsSelectStyles } from './TagsSelect.styles';

const getIsTagSelected = (currentTag: Tag, clickedTag: Tag, category: TagsCategory): boolean | undefined => {
  if (currentTag.id === clickedTag.id) {
    return !clickedTag.isSelected;
  }
  if (category.isSingleSelection) {
    return false;
  }
  return currentTag.isSelected;
};

export const TagsSelectInput: FC<{
  category: TagsCategory;
  name: string;
  unselectSingleEnabled?: boolean;
  disableEdit?: boolean;
}> = ({ category, name, unselectSingleEnabled = false, disableEdit = false }) => {
  const methods = useFormContext();
  const classes = useTagsSelectStyles();
  const tags: Tag[] = useMemo(() => methods.watch(name) || [], [methods, name]);
  const { error } = methods.getFieldState(name, methods.formState);

  useEffect(() => {
    methods.register(name);
  }, [methods, name]);

  const handleOnClick = useCallback(
    (tag: Tag) => {
      // single-selection tag can't be unselected
      if (tag.isSelected && category.isSingleSelection && !unselectSingleEnabled) {
        return;
      }
      const updatedTags = tags.map((t) => ({
        ...t,
        isSelected: getIsTagSelected(t, tag, category),
      }));
      methods.setValue(name, updatedTags);
      methods.clearErrors(name);
    },
    [methods, name, tags, category, unselectSingleEnabled]
  );

  return (
    <Box>
      <Typography variant="body1" fontWeight="bold" mb={1.5} color={error ? 'error' : undefined}>
        {category.name}
      </Typography>
      <Grid container spacing={1.5}>
        {tags?.map((tag) => (
          <Grid item key={tag.id} className={classes.tagWrapper} title={tag.name}>
            <Chip
              data-testid={`TagsSelectInput-${tag.name}`}
              label={tag.name}
              variant={tag.isSelected || disableEdit ? 'filled' : 'outlined'}
              onClick={disableEdit ? undefined : (): void => handleOnClick(tag)}
            />
          </Grid>
        ))}
      </Grid>
      {!!error && (
        <Typography variant="caption" color="error" mt={1}>
          {error.message}
        </Typography>
      )}
    </Box>
  );
};
