import { AnyAction, Dispatch, ListenerEffect, ThunkDispatch } from '@reduxjs/toolkit';
import { generateDynamicPath } from 'common/utils';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { RootState } from 'store';

import { selectActiveEventId } from '../selectors';

type CombinedDispatch = Dispatch<AnyAction> & ThunkDispatch<RootState, unknown, AnyAction>;

type Effect = ListenerEffect<AnyAction, RootState, CombinedDispatch>;

export const goToEventRepeatEffect: Effect = async (_, { dispatch, getState }) => {
  const id = selectActiveEventId(getState());

  dispatch(push(generateDynamicPath(AppRoutes.EventWizard, { id }, { repeat: true })));
};
