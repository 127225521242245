import { ApiExceptionMessage, uuid } from 'common/utils';

import { alertsSlice } from './alertsSlice';
import { AlertBaseData, AlertSeverity, AlertType, AppAlertMessage, DialogAlertData } from './types';

export const { addAppMessage, removeAppMessage, removeAlertsMatchingContext } = alertsSlice.actions;

const addSnackbarMessage =
  (severity: AlertSeverity, config: Pick<AlertBaseData, 'context'>) =>
  (message: string | (AppAlertMessage & Partial<Pick<AlertBaseData, 'id'>>)) =>
    addAppMessage({
      id: (typeof message === 'object' && message.id) || uuid(),
      message,
      type: AlertType.SnackBar,
      severity,
      context: config.context,
    });

export const addAppSuccessMessage = addSnackbarMessage(AlertSeverity.Success, { context: 'APP' });
export const addAppWarningMessage = addSnackbarMessage(AlertSeverity.Warning, { context: 'APP' });
export const addAppErrorMessage = addSnackbarMessage(AlertSeverity.Error, { context: 'APP' });
export const addAppInfoMessage = addSnackbarMessage(AlertSeverity.Info, { context: 'APP' });

export const addBroadcastInfoMessage = addSnackbarMessage(AlertSeverity.Info, { context: 'BROADCAST' });
export const addNatteringInfoMessage = addSnackbarMessage(AlertSeverity.Info, { context: 'NATTERING' });

export const addAppBackendErrorMessage = (message: string | AppAlertMessage | ApiExceptionMessage) =>
  addAppMessage({ id: uuid(), message, type: AlertType.BackendError, context: 'APP' });

type AppAlertMessageOptions = Omit<DialogAlertData['options'], 'title'> & { title: string };
export const addAppAlertDialog = (options: AppAlertMessageOptions) =>
  addAppMessage({
    id: uuid(),
    message: options.title,
    type: AlertType.Dialog,
    options,
    context: 'APP',
  });

export const addAppConfirmationDialog = (options: AppAlertMessageOptions) =>
  addAppAlertDialog({ ...options, variant: 'confirmation' });
