import React, { FC } from 'react';
import { useFormState } from 'react-hook-form';

import { Grid, Stack } from '@mui/material';
import { type FormStepProps } from 'common/components';
import { Button } from 'common/components/_legacy/Button';
import { type TagsCategory as TagsCategoryType } from 'domain/Common';
import { CommunityFormValues } from 'domain/Community';

import { Section, TagsCategoryList } from './components';

type CommunityTagsProps = FormStepProps & {
  isEditMode: boolean;
  isSubmitting: boolean;
  isLastStep: boolean;
  optionalCategory: TagsCategoryType | undefined;
};

export const CommunityTags: FC<CommunityTagsProps> = ({
  isEditMode,
  isSubmitting,
  isLastStep,
  optionalCategory,
  nextStep,
}) => {
  const formState = useFormState<CommunityFormValues>();

  return (
    <Section
      title="Add Community Tags"
      description="Add tags that match the profile, interests or expertise of your Community Members"
      mt={-3}
    >
      <Grid container>
        <Grid item lg={6} xs={12}>
          <TagsCategoryList optionalCategory={optionalCategory} />
        </Grid>
      </Grid>
      <Stack direction="row-reverse" mt={6}>
        {isEditMode ? (
          <Button
            data-testid="CommunityWizard-submit"
            variant="contained"
            type="submit"
            isLoading={formState.isSubmitting || isSubmitting}
          >
            Save Changes
          </Button>
        ) : (
          <>
            {isLastStep ? (
              <Button variant="contained" type="submit" data-testid="CommunityWizard-submit" isLoading={isSubmitting}>
                Create Community
              </Button>
            ) : (
              <Button variant="contained" data-testid="CommunityWizard-submit" onClick={nextStep}>
                Next Step
              </Button>
            )}
          </>
        )}
      </Stack>
    </Section>
  );
};
