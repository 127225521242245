import { isChrome, isDesktop, isEdge, isFirefox, isIOS, isSafari } from 'react-device-detect';

import { MediaDevice } from 'domain/event';
import { filter, flow, map, pick } from 'lodash/fp';

const SYSTEM_DEFAULT_SPEAKER: MediaDevice = {
  deviceId: 'default',
  kind: 'audiooutput',
  label: 'System default speaker device',
  groupId: '',
};

const mapDevicesForStore = map<MediaDeviceInfo | MediaDevice, MediaDevice>(
  pick(['deviceId', 'kind', 'label', 'groupId'])
);

export const mapDevicesForOurApp = (originalDevices: MediaDeviceInfo[]) => {
  const devices = flow(filter<MediaDeviceInfo>('deviceId'), mapDevicesForStore)(originalDevices);

  if (isDesktop && (isChrome || isEdge)) {
    /**
     * if we're on Chrome or Edge
     * find the default device that does not have the `default` device id
     * remove the devices with the `default` id
     * move the default device to the beginning of the array
     *
     * we do not use the `default` device id because if device changes, React will not trigger a change, a rerender
     * because the device changed however device id stayed `default`
     */
    const defaultAudioInputDeviceLabel = devices.find(
      ({ deviceId, kind }) => kind === 'audioinput' && deviceId === 'default'
    )?.label;
    const defaultAudioOutputDeviceLabel = devices.find(
      ({ deviceId, kind }) => kind === 'audiooutput' && deviceId === 'default'
    )?.label;

    const duplicatedDefaultAudioInputDevice = devices.find(
      ({ kind, label }) =>
        kind === 'audioinput' && label !== defaultAudioInputDeviceLabel && defaultAudioInputDeviceLabel?.includes(label)
    );
    const duplicatedDefaultAudioOutputDevice = devices.find(
      ({ kind, label }) =>
        kind === 'audiooutput' &&
        label !== defaultAudioOutputDeviceLabel &&
        defaultAudioOutputDeviceLabel?.includes(label)
    );

    const filteredDevices = devices.filter((device) => {
      if (device.kind === 'audioinput' && defaultAudioInputDeviceLabel?.includes(device.label)) {
        return false;
      }

      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (device.kind === 'audiooutput' && defaultAudioOutputDeviceLabel?.includes(device.label)) {
        return false;
      }

      return true;
    });

    if (duplicatedDefaultAudioInputDevice) {
      filteredDevices.unshift(duplicatedDefaultAudioInputDevice);
    }

    if (duplicatedDefaultAudioOutputDevice) {
      filteredDevices.unshift(duplicatedDefaultAudioOutputDevice);
    }

    return filteredDevices;
  }

  if (isFirefox) {
    const firstSpeaker = devices.find((device) => device.kind === 'audiooutput');
    const firefoxDevices = devices.filter((device) => device.kind !== 'audiooutput');

    firefoxDevices.push({
      ...SYSTEM_DEFAULT_SPEAKER,
      deviceId: firstSpeaker?.deviceId || SYSTEM_DEFAULT_SPEAKER.deviceId,
    });
    return firefoxDevices;
  }

  // Below is to fix Safari/ios experience: https://natterco.atlassian.net/browse/N2-821
  if (isIOS || isSafari) {
    devices.push(SYSTEM_DEFAULT_SPEAKER);
  }

  return devices;
};
