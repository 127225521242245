import { OptionItem } from '../Select';

/**
 * Generates an array of number options for a select input.
 *
 * Note: The value of each option is a string to follow MUI value rules https://mui.com/material-ui/api/select/#props
 *
 * @param count - The number of options to generate.
 * @param shouldDisable - An array of string values that should be disabled.
 * @returns An array of OptionItem objects representing the number options.
 */
export const generateNumberOptions = (count: number, shouldDisable: string[] = []): OptionItem<string>[] =>
  Array.from({ length: count }).map((_, i) => {
    const value = (++i).toString();
    return {
      value,
      label: value,
      disabled: shouldDisable.includes(value),
    };
  });
