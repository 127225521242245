import { Chip, styled } from '@mui/material';

export const MemberTagItem = styled(Chip, { name: 'MemberTagItem' })(
  ({ theme: { spacing, palette, typography }, variant }) => ({
    ...typography['natter-text-md'],
    fontWeight: 500,

    height: 'initial',
    borderRadius: spacing(0.5),

    '& .MuiChip-label': {
      padding: spacing(1, 2),
    },

    ...(variant === 'filled' && {
      border: '1px solid',
      borderColor: palette.primary.main,
      color: palette.primary.main,
      backgroundColor: palette.extendedPrimary[25],

      '&:hover': {
        backgroundColor: palette.extendedPrimary[50],
      },
    }),
  })
);
