import { createListenerMiddleware } from '@reduxjs/toolkit';
import { mapToMeetingGuestFromApi } from 'mappers/member';
import type { RootState } from 'store';
import { userApi } from 'store/apis/user';

import { broadcastGuestDemoted_WS } from '../event';
import { searchBroadcastParticipants_WS } from './actions';
import { resetParticipantSearch, setParticipantsSearchIsLoading } from './participantsSearchSlice';

export const participantsSearchListener = createListenerMiddleware<RootState>();

participantsSearchListener.startListening({
  actionCreator: searchBroadcastParticipants_WS.pending,
  effect: (_args, { dispatch }) => {
    dispatch(setParticipantsSearchIsLoading(true));
  },
});

participantsSearchListener.startListening({
  actionCreator: broadcastGuestDemoted_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();
    if (state.event.isActiveEventOwned) return;

    const speaker = mapToMeetingGuestFromApi(payload);
    const userAccountId = userApi.endpoints.getAccount.select()(state).data?.id;
    const isUser = userAccountId === speaker.id;
    if (isUser) {
      dispatch(resetParticipantSearch());
    }
  },
});
