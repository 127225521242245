import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEqual, xorWith } from 'lodash';
import { InsightsReportType } from 'pages/InsightsReport/types';
import { ReportSource } from 'store/apis/insightsReport';

import { insightsReportsWizardActions } from './insightsReportsWizard.actions';

type InitialState = {
  selectedSurveyType: InsightsReportType;
  isAllSelected: boolean;
  step: 'sources' | 'similar-sources';
  selectedReportSource: ReportSource | undefined;
  selectedSimilarReportSourcesIds: number[];
};

const initialState: InitialState = {
  selectedSurveyType: 'Event',
  isAllSelected: false,
  step: 'sources',
  selectedReportSource: undefined,
  selectedSimilarReportSourcesIds: [],
};

export const insightsReportsWizardSlice = createSlice({
  name: 'insightsReportsWizard',
  initialState,
  reducers: {
    resetInsightsReportsWizardState: () => initialState,
    setInsightsReportsWizardStep: (state, { payload }: PayloadAction<InitialState['step']>) => {
      state.step = payload;
      state.isAllSelected = false;
    },
    setSelectedInsightsReportType: (state, { payload }: PayloadAction<InitialState['selectedSurveyType']>) => {
      state.selectedSurveyType = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(insightsReportsWizardActions.reportSourcesTable.reportSourceSelected, (state, { payload }) => {
        state.selectedReportSource = payload;
        state.selectedSimilarReportSourcesIds = payload ? [payload.id] : [];
      })
      .addCase(
        insightsReportsWizardActions.similarReportSourcesTable.selectAllCheckboxToggled,
        (state, { payload: { isChecked, similarReportSources } }) => {
          state.isAllSelected = isChecked;
          if (!isChecked) {
            state.selectedSimilarReportSourcesIds = [];
          } else {
            state.selectedSimilarReportSourcesIds = [
              state.selectedReportSource!.id,
              ...similarReportSources.map((source) => source.id),
            ];
          }
        }
      )
      .addCase(insightsReportsWizardActions.similarReportSourcesTable.reportSourceSelected, (state, { payload }) => {
        const updatedSimilarSources = xorWith(state.selectedSimilarReportSourcesIds, [payload.id], isEqual);

        if (state.selectedSimilarReportSourcesIds.length > updatedSimilarSources.length) {
          state.isAllSelected = false;
        }

        state.selectedSimilarReportSourcesIds = updatedSimilarSources;
      })
      .addCase(insightsReportsWizardActions.actionButton.nextButton.clicked, (state) => {
        state.step = 'similar-sources';
        state.isAllSelected = false;
      })
      .addCase(
        insightsReportsWizardActions.similarReportSourcesTable.similarReportSourcesFetched,
        (state, { payload }) => {
          const updatedSelectedSources = [state.selectedReportSource!.id];
          if (state.isAllSelected) {
            updatedSelectedSources.push(...payload.map((source) => source.id));
          }
          state.selectedSimilarReportSourcesIds = updatedSelectedSources;
        }
      ),
});
