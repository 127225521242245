import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChatMessageBasic, ChatMessageStatus } from 'domain/Chat';
import differenceBy from 'lodash/differenceBy';

import { ChatState } from './types';
import { excludeMessages, mapMessagesWithStatus } from './utils';

const initialState: ChatState = {
  messages: [],
  pendingMessages: [],
};

const CHAT_HISTORY_LIMIT = 1000;

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetChatState: () => initialState,
    excludeFromMessages: (
      state,
      action: PayloadAction<{ messages: ChatMessageBasic[]; status: ChatMessageStatus }>
    ) => {
      state.messages = excludeMessages(state.messages, action.payload.messages, action.payload.status);
    },
    excludeFromPendingMessages: (state, action: PayloadAction<{ messages: ChatMessageBasic[] }>) => {
      state.pendingMessages = differenceBy(state.pendingMessages, action.payload.messages, 'id');
    },
    addPendingMessage: (state, action: PayloadAction<{ message: ChatMessageBasic }>) => {
      state.pendingMessages = [...state.pendingMessages, action.payload.message];
    },
    addRejectedMessages: (
      state,
      action: PayloadAction<{ messages: ChatMessageBasic[]; accountId: number | undefined }>
    ) => {
      state.messages = state.messages.map((message) => {
        const isPending = message.status === ChatMessageStatus.Pending;
        const isOwn = message.sender.id === action.payload.accountId;
        const isIdMatch = action.payload.messages.some(({ id }) => id === message.id);

        if (isPending && isOwn && isIdMatch) {
          return { ...message, status: ChatMessageStatus.Rejected };
        }
        return message;
      });
    },
    addMessages: (state, action: PayloadAction<{ messages: ChatMessageBasic[]; status: ChatMessageStatus }>) => {
      const newMessages = mapMessagesWithStatus({
        messages: action.payload.messages,
        status: action.payload.status,
      });
      state.messages = [...state.messages, ...newMessages].slice(-CHAT_HISTORY_LIMIT);
    },
    markAsReadMessage: (state, action: PayloadAction<{ id: number | string }>) => {
      state.messages = state.messages.map((message) => {
        if (message.id === action.payload.id) {
          return { ...message, isNew: false };
        }
        return message;
      });
    },
  },
});

export const {
  resetChatState,
  excludeFromMessages,
  excludeFromPendingMessages,
  addPendingMessage,
  addRejectedMessages,
  addMessages,
  markAsReadMessage,
} = chatSlice.actions;
