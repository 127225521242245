import type { IVirtualBackgroundProcessor } from 'agora-extension-virtual-background';
import AgoraRTC from 'agora-rtc-react';
import { logger } from 'common/services';

export const initVideoProcessor = async ({
  onBlurNotSupported,
  onSuccess,
}: {
  onBlurNotSupported: () => void;
  onSuccess: (virtualBackgroundProcessor: IVirtualBackgroundProcessor) => void;
}) => {
  try {
    const { default: VirtualBackgroundExtension } = await import('agora-extension-virtual-background');
    const virtualBackgroundExtension = new VirtualBackgroundExtension();
    if (!virtualBackgroundExtension.checkCompatibility()) {
      // blur not supported
      onBlurNotSupported();
      return;
    }

    AgoraRTC.registerExtensions([virtualBackgroundExtension]);
    const processor = virtualBackgroundExtension.createProcessor();
    await processor.init('/assets/agora/blurred-background');

    // supports blur
    onSuccess(processor);
  } catch (error) {
    logger.error('Error while trying to initialize video processor', { data: { error } });
  }
};
