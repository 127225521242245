import type { Components, Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: () => ({ wordBreak: 'break-word' }),
  },
};
