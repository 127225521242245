import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { Switch, SwitchProps } from '../Switch';
import { Wrapper } from './SwitchBox.styled';

export const SwitchBox: FC<SwitchProps> = ({ label, name, id = name, sx, ...props }) => (
  <Wrapper sx={sx}>
    <Typography variant="natter-text-sm" component="label" htmlFor={id}>
      {label}
    </Typography>

    <Switch size="small" name={name} id={id} {...props} />
  </Wrapper>
);
