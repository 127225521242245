import { isAndroid } from 'react-device-detect';

import { MediaDevice } from 'domain/event';
import { filter, flow, sortBy } from 'lodash/fp';

export const findVideoInputDevices = (devices: MediaDevice[]) => {
  const videoDevices = flow(filter<MediaDevice>(['kind', 'videoinput']), sortBy<MediaDevice>('label'))(devices);

  const filteredDevices: MediaDevice[] = [];
  if (isAndroid) {
    // fix for multiple-camera issue on Android -> https://natterco.atlassian.net/browse/N2-634
    const frontCamera = videoDevices.find(({ label }) => label.includes('front'));
    if (frontCamera) {
      filteredDevices.push(frontCamera);
    }
    const backCamera = videoDevices.find(({ label }) => label.includes('back'));
    if (backCamera) {
      filteredDevices.push(backCamera);
    }
  }
  return filteredDevices.length ? filteredDevices : videoDevices;
};
