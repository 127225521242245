import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { ConstructionIcon } from 'icons';

import { usePageInProgressStyles } from './PageInProgress.styles';

type Props = {
  title?: string;
};

export const PageInProgress: FC<Props> = ({ title = 'Page under construction' }) => {
  const classes = usePageInProgressStyles();
  const description = 'This page will be implemented in the future';

  return (
    <div className={classes.container}>
      <ConstructionIcon fontSize="large" color="primary" />
      <Typography variant="h4">{title}</Typography>
      <Typography variant="h6">{description}</Typography>
    </div>
  );
};
