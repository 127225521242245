export type UploadDropzoneType = 'image' | 'video' | 'csv';

export const ALLOWED_EXTENSIONS: Record<UploadDropzoneType, string> = {
  image: '.webp, .jpeg, .apng, .avif, .gif, .png, .svg, .jpg, .jfif, .pjpeg, .pjp',
  video: '.mp4, .mov, .webm',
  csv: '.csv',
};

export const TYPE_PLACEHOLDER_MAP: Record<UploadDropzoneType, string> = {
  image: 'an image',
  video: 'a video',
  csv: 'a CSV file',
};
