import { PaletteMode, type Theme, createTheme, darken, getLuminance, lighten } from '@mui/material';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { DEFAULT_PRIMARY_COLOR, createPalette } from './palette';
import { shadows } from './shadows';
import { shape } from './shape';
import { spacing } from './spacing';
import { typography } from './typography';

export const theme = (color = DEFAULT_PRIMARY_COLOR, mode: PaletteMode = 'light') =>
  createTheme({
    breakpoints,
    components,
    palette: { ...createPalette(color, mode === 'dark'), mode },
    shadows,
    shape,
    spacing,
    typography,
  });

export const contrast = (color: string): string =>
  getLuminance(color) > 0.5 ? darken(color, 0.6) : lighten(color, 0.9);

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, no-unused-vars
  interface DefaultTheme extends Theme {}
}
