import React, { FC, useMemo } from 'react';

import { useTheme } from '@mui/material';
import { PieLabelRenderProps } from 'recharts';

import { CHART_LABEL_FONT_SIZE, CHART_LABEL_FONT_WEIGHT } from '../constants';
import { initTemporaryLabelElement } from './BarChartLabel';

type Props = PieLabelRenderProps & {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  midAngle?: number;
  radiusDistance?: number;
};

const RADIAN = Math.PI / 180;

export const PieChartLabel: FC<Props> = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  percent,
  midAngle = 0,
  radiusDistance = 0.5,
  fill,
  endAngle = 0,
  startAngle = 0,
}) => {
  const { palette, typography } = useTheme();
  const formattedValue = `${(percent * 100).toFixed(0)}%`;
  const radius = innerRadius + (outerRadius - innerRadius) * radiusDistance;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const chordLength = getChordLength(radius, endAngle - startAngle);

  const fits = useMemo(() => {
    const element = initTemporaryLabelElement(typography.fontFamily, formattedValue);
    const { clientWidth } = element;
    document.body.removeChild(element);
    return clientWidth < chordLength;
  }, [typography, formattedValue, chordLength]);

  if (!fits) return null;

  return (
    <text
      x={x}
      y={y}
      fill={palette.getContrastText(fill)}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={CHART_LABEL_FONT_SIZE}
      fontWeight={CHART_LABEL_FONT_WEIGHT}
      style={{ pointerEvents: 'none' }}
    >
      {formattedValue}
    </text>
  );
};

const getChordLength = (radius: number, angle: number) => 2 * radius * Math.sin(degreesToRadians(angle) / 2);
const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180);
