import React, { FC } from 'react';

export const ErrorStateIcon: FC = () => (
  <svg width="134" height="134" viewBox="0 0 134 134" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M67.0007 100.333C68.8895 100.333 70.4729 99.6941 71.7507 98.4164C73.0284 97.1386 73.6673 95.5552 73.6673 93.6663C73.6673 91.7775 73.0284 90.1941 71.7507 88.9164C70.4729 87.6386 68.8895 86.9997 67.0007 86.9997C65.1118 86.9997 63.5284 87.6386 62.2507 88.9164C60.9729 90.1941 60.334 91.7775 60.334 93.6663C60.334 95.5552 60.9729 97.1386 62.2507 98.4164C63.5284 99.6941 65.1118 100.333 67.0007 100.333ZM67.0007 73.6663C68.8895 73.6663 70.4729 73.0275 71.7507 71.7497C73.0284 70.4719 73.6673 68.8886 73.6673 66.9997V40.333C73.6673 38.4441 73.0284 36.8608 71.7507 35.583C70.4729 34.3052 68.8895 33.6663 67.0007 33.6663C65.1118 33.6663 63.5284 34.3052 62.2507 35.583C60.9729 36.8608 60.334 38.4441 60.334 40.333V66.9997C60.334 68.8886 60.9729 70.4719 62.2507 71.7497C63.5284 73.0275 65.1118 73.6663 67.0007 73.6663ZM67.0007 133.666C57.7784 133.666 49.1118 131.916 41.0007 128.416C32.8895 124.916 25.834 120.166 19.834 114.166C13.834 108.166 9.08399 101.111 5.58398 92.9997C2.08398 84.8886 0.333984 76.2219 0.333984 66.9997C0.333984 57.7775 2.08398 49.1108 5.58398 40.9997C9.08399 32.8886 13.834 25.833 19.834 19.833C25.834 13.833 32.8895 9.08301 41.0007 5.58301C49.1118 2.08301 57.7784 0.333008 67.0007 0.333008C76.2229 0.333008 84.8895 2.08301 93.0007 5.58301C101.112 9.08301 108.167 13.833 114.167 19.833C120.167 25.833 124.917 32.8886 128.417 40.9997C131.917 49.1108 133.667 57.7775 133.667 66.9997C133.667 76.2219 131.917 84.8886 128.417 92.9997C124.917 101.111 120.167 108.166 114.167 114.166C108.167 120.166 101.112 124.916 93.0007 128.416C84.8895 131.916 76.2229 133.666 67.0007 133.666Z"
      fill="#F04438"
    />
  </svg>
);
