import { formatBytes } from 'common/utils';

import { STATUS_LABELS, STATUS_PROGRESS_LABELS } from '../constants';
import type { Status as StatusType } from '../types';

type CalculateStatusArgs = {
  progress?: number;
  size?: number;
  status: StatusType;
};

export const calculateStatus = ({ progress, size, status }: CalculateStatusArgs) => {
  const isIdle = status === 'idle';
  const isUploaded = status === 'uploaded';
  const content: string[] = [];

  if (size) {
    content.push(formatBytes(size));
  }

  if (!isIdle && !isUploaded) {
    content.push(progress ? `${progress}% ${STATUS_PROGRESS_LABELS[status]}` : STATUS_LABELS[status]);
  }

  return content.join(' — ');
};
