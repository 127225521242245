import React, { FC, cloneElement } from 'react';

import { Box } from '@mui/material';

export type StepBodyProps = {
  step: number;
  index: number;
  setStep(step: number): void;
  nextStep(): void;
  previousStep(): void;
  children: JSX.Element;
};

export const StepBody: FC<StepBodyProps> = (props) => {
  const { children, step, index, setStep, nextStep, previousStep, ...other } = props;

  if (step !== index) return null;

  return (
    <div hidden={step !== index} {...other}>
      <Box pt={4} pb={6}>
        {cloneElement(children, {
          currentFormStep: step,
          setStep,
          nextStep,
          previousStep,
        })}
      </Box>
    </div>
  );
};
