import React, { FC } from 'react';

import { Box } from '@mui/material';

export type TabPanelProps = {
  index: number;
  value: number;
  withPadding?: boolean;
  keepMounted?: boolean;
  absolutePosition?: boolean;
};

export const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  withPadding,
  index,
  keepMounted = false,
  absolutePosition = false,
}) => {
  const isVisible = value === index;

  return (
    <Box
      role="tabpanel"
      hidden={!(isVisible || absolutePosition)}
      id={`tabpanel-${index}`}
      height="calc(100% - 72px)"
      width={1}
      aria-labelledby={`tab-${index}`}
      sx={{
        visibility: isVisible ? 'visible' : 'hidden',
        ...(absolutePosition ? { position: 'absolute', top: 72, zIndex: 1 } : {}),
      }}
    >
      {(isVisible || keepMounted || absolutePosition) && (
        <Box height={1} sx={{ paddingTop: withPadding ? 4 : undefined, paddingBottom: withPadding ? 6 : undefined }}>
          {children}
        </Box>
      )}
    </Box>
  );
};
