import { toTimestamp } from 'common/utils';
import { differenceInMinutes } from 'date-fns';
import { EventRoom, EventRoomApiPayload } from 'domain/event';
import { mapToEventRoomAttendeeFromApi } from 'mappers/member';

export const mapEventRoomFromApi = (room: EventRoomApiPayload): EventRoom => {
  const { agoraToken, startTime: apiStartTime, endTime: apiEndTime, id, attendees = [] } = room;
  const startTime = toTimestamp(apiStartTime);
  const endTime = toTimestamp(apiEndTime);
  const duration = differenceInMinutes(endTime, startTime, { roundingMethod: 'round' });

  return {
    id,
    accessToken: agoraToken,
    attendees: attendees.map(mapToEventRoomAttendeeFromApi),
    duration,
    endTime,
    startTime,
    orderNumber: -1,
    roomQuestionAssignments: room.roomQuestionAssignments ?? [],
  };
};
