import { InferType, number, object, string } from 'yup';

export const attachmentSchema = object({
  id: string().required(),
  fileName: string().required(),
  contentType: string().required(),
  contentSize: number().required(),
  url: string().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
});

export type Attachment = InferType<typeof attachmentSchema>;

export const isAttachment = (attachment: unknown): attachment is Attachment => attachmentSchema.isValidSync(attachment);
