import React, { type FC, Suspense } from 'react';
import { Switch } from 'react-router';

import { SentryRoute, SuspenseFallback } from 'common/components';
import { lazyWithRetry } from 'common/utils';
import { CommunityCreationSuccessModal } from 'community/CommunityCreator/components';
import { ReconnectingModal } from 'layout/Dashboard/ReconnectingModal';
import JoinOrganization from 'pages/MasterAdmin/JoinOrganization';
import { AppRoutes } from 'router';

import { withUserAccount, withUserLoggedIn } from './hocs';

const MasterAdmin = lazyWithRetry(() => import('pages/MasterAdmin'), 'MasterAdmin');
const Dashboard = lazyWithRetry(() => import('layout/Dashboard'), 'Dashboard');
const RegisterToCommunity = lazyWithRetry(() => import('pages/RegisterToCommunity'), 'RegisterToCommunity');

const App: FC = () => (
  <>
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        <SentryRoute path={AppRoutes.JoinOrganization} component={JoinOrganization} />
        <SentryRoute path={`${AppRoutes.Organization}/:orgSlug?`} component={MasterAdmin} />
        <SentryRoute path={AppRoutes.RegisterToCommunity} component={RegisterToCommunity} />
        {/* TODO: There should be separate route for registered user. It should start with e.g. /community/:id? */}
        <SentryRoute path="/" component={Dashboard} />
      </Switch>
    </Suspense>
    <ReconnectingModal />
    <CommunityCreationSuccessModal />
  </>
);

export default withUserLoggedIn(withUserAccount(App));
