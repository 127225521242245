import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactionsState } from './types';

const initialState: ReactionsState = {
  reactions: {},
};

export const reactionsSlice = createSlice({
  name: 'reactions',
  initialState,
  reducers: {
    resetReactionsState: () => initialState,
    setReactionsForUser: (
      state,
      { payload: { reaction, count, userId } }: PayloadAction<{ reaction: string; count: number; userId: number }>
    ) => {
      state.reactions[userId] = { [reaction]: count };
    },
  },
});

export const { resetReactionsState, setReactionsForUser } = reactionsSlice.actions;
