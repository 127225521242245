import React, { FC } from 'react';

import { VideocamIcon, VideocamOffIcon } from 'icons';

import { ActionButton } from './ActionButton';
import type { ActionButtonProps } from './ActionButton/ActionButton';

type ToggleCameraProps = Pick<ActionButtonProps, 'onClick' | 'isActive' | 'isDisabled' | 'isLoading' | 'classes'>;

export const ToggleCameraButton: FC<ToggleCameraProps> = (props) => (
  <ActionButton tooltip={props.isActive ? 'Turn off camera' : 'Turn on camera'} {...props}>
    {props.isActive ? <VideocamIcon /> : <VideocamOffIcon />}
  </ActionButton>
);
