import { createApi } from '@reduxjs/toolkit/query/react';
import type { ImageFile } from 'common/components/ImageDropzoneWithPreview/types';
import { getImageFormData } from 'common/utils';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import { Attachment } from './types';

export const attachmentsApi = createApi({
  reducerPath: 'attachmentsApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    uploadAttachment: builder.mutation<Attachment, ImageFile>({
      query: (payload) => ({
        url: '/attachments',
        method: 'POST',
        data: getImageFormData(payload, 'file'),
      }),
    }),
    deleteAttachment: builder.mutation<void, Pick<Attachment, 'id'>>({
      query: ({ id }) => ({
        url: `/attachments/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useUploadAttachmentMutation, useDeleteAttachmentMutation } = attachmentsApi;
