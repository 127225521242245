import { MouseEvent, useCallback, useState } from 'react';

import { ApiRequestParams, SortOrder } from 'domain/Common';

export const useApiRequestParams = <
  SortByType = string,
  T extends ApiRequestParams<SortByType> = ApiRequestParams<SortByType>,
>({
  initialSortBy,
  initialSortOrder = 'asc',
  initialPageSize = 25,
  initialQueryParams = {
    pageSize: initialPageSize,
    page: 1,
    sortBy: (initialSortBy ?? '') as SortByType,
    sortOrder: initialSortOrder,
    searchPhrase: '',
  } as T,
}: {
  initialSortBy?: SortByType;
  initialSortOrder?: SortOrder;
  initialPageSize?: number;
  initialQueryParams?: T;
} = {}) => {
  const [queryParams, setQueryParams] = useState<T>(initialQueryParams);

  const handleSearchPhraseChange = useCallback((value: string) => {
    setQueryParams((currentQueryParams) => ({
      ...currentQueryParams,
      searchPhrase: value,
      page: 1,
    }));
  }, []);

  const handleOrderChange = useCallback((value: SortOrder) => {
    setQueryParams((currentQueryParams) => ({
      ...currentQueryParams,
      sortOrder: value,
      page: 1,
    }));
  }, []);

  const handleOrderByChange = useCallback((value: SortByType) => {
    setQueryParams((currentQueryParams) => ({
      ...currentQueryParams,
      sortBy: value,
      page: 1,
    }));
  }, []);

  const handleCurrentPageChange = useCallback((value: number) => {
    setQueryParams((currentQueryParams) => ({
      ...currentQueryParams,
      page: value,
    }));
  }, []);

  const handleNextPage = useCallback(() => {
    setQueryParams((currentQueryParams) => ({
      ...currentQueryParams,
      page: currentQueryParams.page + 1,
    }));
  }, []);

  const handlePageSizeChange = useCallback((value: number) => {
    setQueryParams((currentQueryParams) => ({
      ...currentQueryParams,
      pageSize: value,
      page: 1,
    }));
  }, []);

  const handleSortChange = useCallback(
    (event: MouseEvent<unknown>, newOrderBy: SortByType) => {
      if (newOrderBy === queryParams.sortBy) {
        handleOrderChange(queryParams.sortOrder === 'asc' ? 'desc' : 'asc');
      }
      handleOrderByChange(newOrderBy);
    },
    [queryParams.sortBy, queryParams.sortOrder, handleOrderByChange, handleOrderChange]
  );

  return {
    queryParams,
    setQueryParams,
    handleSearchPhraseChange,
    handleOrderChange,
    handleOrderByChange,
    handleCurrentPageChange,
    handlePageSizeChange,
    handleSortChange,
    handleNextPage,
  };
};
