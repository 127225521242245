import { type Components, type Theme } from '@mui/material';

import { textEllipsis } from '../typography';

export const MuiListItemText: Components<Theme>['MuiListItemText'] = {
  styleOverrides: {
    primary: ({ theme: { typography } }) => ({
      ...typography['natter-text-sm'],
      ...textEllipsis,
    }),
  },
};
