import { createReducer } from '@reduxjs/toolkit';

import { agoraActions } from './agora.actions';
import { AgoraState, NetworkQualityState } from './types';

const initialState: AgoraState = {
  networkQuality: {
    downlinkNetworkQuality: NetworkQualityState.Unknown,
    uplinkNetworkQuality: NetworkQualityState.Unknown,
  },
};

export const agoraReducer = createReducer(initialState, (builder) => {
  builder.addCase(agoraActions.networkQuality.changed, (state, action) => {
    state.networkQuality = action.payload;
  });
});
