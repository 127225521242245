import { OrganizationMember, OrganizationMemberApiPayload } from 'domain/Organization';

export const mapOrganizationMemberFromApi = (member: OrganizationMemberApiPayload): OrganizationMember => ({
  avatarLink: member.avatarLink,
  email: member.email,
  firstName: member.firstName,
  lastName: member.lastName,
  name: `${member.firstName} ${member.lastName}`,
  userId: member.userId,
  role: member.role,
  communityIds: member.communityIds ?? [],
});
