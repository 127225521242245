import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

interface Size {
  width: number;
  height: number;
}

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [
  MutableRefObject<T | null>,
  Size,
  MutableRefObject<Size | undefined>,
] => {
  const target = useRef<T | null>(null);
  const sizeRef = useRef<Size>();
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (target.current) setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setSize({ width, height });
  });

  useEffect(() => {
    sizeRef.current = size;
  }, [size]);

  return [target, size, sizeRef];
};
