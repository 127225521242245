import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory({
    getUserConfirmation: () => {
      /* Empty callback to block the default browser prompt */
    },
  }),
});
