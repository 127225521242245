import React, { FC } from 'react';

import { MenuList, Paper, Popper } from '@mui/material';
import { MenuItemType } from 'common/components/Menu';
import { MenuItem } from 'common/components/Menu/components';

export const TimePickerSelect: FC<{
  anchorEl: HTMLElement | null;
  options: MenuItemType[];
  activeValueIndex: number | undefined;
  onClose(): void;
}> = ({ anchorEl, options, activeValueIndex, onClose }) => (
  <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement="bottom-start" disablePortal={false}>
    <Paper
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 210,
      }}
    >
      <MenuList data-testid="TimePickerSelect-menu" variant="selectedMenu">
        {options.map((item, index) => (
          <MenuItem key={index} isActive={index === activeValueIndex} item={item} onClose={onClose} autoScroll />
        ))}
      </MenuList>
    </Paper>
  </Popper>
);
