import { createAction } from '@reduxjs/toolkit';
import { ReportSource } from 'store/apis/insightsReport';

import { insightsReportsWizardSlice } from './insightsReportsWizard.slice';
import { backButtonClickedAction, exitWizardAction } from './insightsReportsWizardActions.effects';

export const { setInsightsReportsWizardStep, setSelectedInsightsReportType, resetInsightsReportsWizardState } =
  insightsReportsWizardSlice.actions;

export const insightsReportsWizardActions = {
  exit: exitWizardAction,
  actionButton: {
    backButton: {
      clicked: backButtonClickedAction,
    },
    nextButton: {
      clicked: createAction('[Insights Reports Wizard ActionButton] next button clicked'),
    },
  },
  reportSourcesTable: {
    reportSourceSelected: createAction<ReportSource | undefined>(
      '[Insights Reports Wizard ReportSourcesTable] report source selected'
    ),
  },
  similarReportSourcesTable: {
    selectAllCheckboxToggled: createAction<{ isChecked: boolean; similarReportSources: ReportSource[] }>(
      '[Insights Reports Wizard SimilarReportSourcesTable] select all checkbox toggled'
    ),
    reportSourceSelected: createAction<ReportSource>(
      '[Insights Reports Wizard ReportSourcesTable] similar report sources selected'
    ),
    similarReportSourcesFetched: createAction<ReportSource[]>(
      '[Insights Reports Wizard ReportSourcesTable] similar report sources fetched'
    ),
  },
};
