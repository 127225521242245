import { type Components, type Theme } from '@mui/material';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  defaultProps: {},
  styleOverrides: ({ palette }) => ({
    ':root': {
      colorScheme: palette.mode,
    },
    '@global': {
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        boxSizing: 'border-box',
      },
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
      body: {
        margin: 0,
      },
    },
    'input, textarea, button, select, a': { WebkitTapHighlightColor: 'transparent' },
  }),
};
