import React, { FC, ReactNode, useEffect } from 'react';
import { FieldError, useController } from 'react-hook-form';

import { Slider, SliderProps, Stack, Typography } from '@mui/material';

import { SliderInputHint } from './SliderInputHint';

export type SliderInputProps = Pick<SliderProps, 'min' | 'max' | 'step' | 'marks' | 'valueLabelFormat'> & {
  name: string;
  label?: string;
  valueLabelFormat?: string | ((value: number, index: number) => ReactNode);
  onErrorChange?(error: FieldError | undefined): void;
};

export const SliderInput: FC<SliderInputProps> = ({
  name,
  label,
  marks,
  step,
  min = 0,
  max = 100,
  valueLabelFormat,
  onErrorChange,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name });
  const showHint = value === null;

  useEffect(() => {
    onErrorChange?.(error);
  }, [error, onErrorChange]);

  return (
    <Stack position="relative">
      {!!label && (
        <Typography id={`SliderInputLabel-${name}`} gutterBottom>
          {label}
        </Typography>
      )}
      <Slider
        data-testId="SliderInput-slider"
        aria-labelledby={`SliderInputLabel-${name}`}
        sx={{
          width: '100%',
          '& .MuiSlider-thumb': {
            visibility: showHint ? 'hidden' : 'visible',
          },
        }}
        min={min}
        max={max}
        step={step}
        marks={marks}
        // it's possible that the form value would be undefined and component will become an uncontrolled one, providing 0 instead fixes the issue
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
      />
      <SliderInputHint showHint={showHint} />
    </Stack>
  );
};
