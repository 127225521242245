import React, { FC, useMemo } from 'react';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT, legendWrapperStyle } from '../../constants';
import { useAutoDataKeys, useChartAxis, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateChartMargins, calculateTicks, createTickFormatter, getColor } from '../../utils';

export const LineChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys: customDataKeys,
  height = CHART_MIN_HEIGHT,
  TooltipContent,
  xDomain = [0, 'dataMax'],
  tickCount,
  hideCartesianGrid,
  legendFormatter,
}) => {
  const { getChartClass } = useChartStyles();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const { dataKeys } = useAutoDataKeys(data, customDataKeys);
  const { ticks, isLastTickEqualToMaxValue } = useMemo(() => calculateTicks({ data, dataKeys }), [data, dataKeys]);
  const { activeKey, handleMouseEnter } = useTooltip();

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <RechartsLineChart
        barCategoryGap={1}
        barGap={2}
        data={data}
        margin={calculateChartMargins({ labelNames, labelValues })}
        layout="horizontal"
        className={getChartClass('vertical', isLastTickEqualToMaxValue)}
      >
        {!hideCartesianGrid && <CartesianGrid vertical={false} />}
        <XAxis
          dataKey="name"
          axisLine={axisLineStyles}
          tick={tickStyles}
          tickMargin={8}
          interval={0}
          label={renderAxisXLabel(labelNames)}
          type="number"
          domain={xDomain}
          tickCount={tickCount}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          interval={0}
          domain={[0, 'dataMax']}
          ticks={ticks}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          tickFormatter={createTickFormatter()}
          width={20}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && (
          <Legend verticalAlign="top" align="right" formatter={legendFormatter} wrapperStyle={legendWrapperStyle} />
        )}
        {dataKeys.map((key, index) => {
          const color = getColor(index);
          return (
            <Line
              key={key}
              dataKey={key}
              fill={color}
              stroke={color}
              strokeWidth={3}
              onMouseEnter={handleMouseEnter(key)}
              isAnimationActive={false}
              dot={false}
              type="monotone"
            />
          );
        })}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};
