import React, { FC } from 'react';

import { Stack } from '@mui/material';
import { Button } from 'common/components';
import { useConfirmationModal } from 'common/hooks';
import { DeleteIcon } from 'icons';

type Props = {
  categoryName: string;
  onRemoveCategory(): void;
};

export const RemoveTagCategory: FC<Props> = ({ categoryName, onRemoveCategory }) => {
  const [openCallback, RemoveConfirmationModal, closeCallback] = useConfirmationModal(
    {
      title: 'Remove Category',
      description: `Are you sure you want to remove Community Tag Category: "${categoryName}"?`,
      maxWidth: 'xs',
      severity: 'danger',
      cancelLabel: 'No, cancel',
      confirmLabel: 'Yes, remove',
    },
    () => {
      onRemoveCategory();
      closeCallback();
    }
  );

  return (
    <Stack direction="row" justifyContent="end">
      <Button startIcon={<DeleteIcon />} size="small" variant="text" onClick={openCallback}>
        Remove Category
      </Button>
      {RemoveConfirmationModal}
    </Stack>
  );
};
