import React, { FC, Fragment, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { Tabs } from 'common/components';

import { StepBody } from './components/StepBody';

export type WizardProps = {
  children: (JSX.Element | false)[];
  stepLabels: string[];
  onStepChange?(currentStep: number, newStep: number): Promise<number | void>;
  variant?: 'scrollable' | 'standard' | 'fullWidth';
  className?: string;
  step?: number;
};

export const Wizard: FC<WizardProps> = ({
  children,
  stepLabels,
  onStepChange,
  variant = 'scrollable',
  className,
  step = 0,
}) => {
  const [value, setValue] = useState(step);
  const handleChange = useCallback(
    async (event: SyntheticEvent | undefined, newValue: number) => {
      const nextValue = onStepChange ? await onStepChange(value, newValue) : newValue;
      if (nextValue !== undefined) {
        setValue(nextValue);
      }
    },
    [value, onStepChange]
  );
  const setStep = useCallback((selectedStep: number) => handleChange(undefined, selectedStep), [handleChange]);
  const nextStep = useCallback(() => setStep(value + 1), [setStep, value]);
  const previousStep = useCallback(() => setStep(value - 1), [setStep, value]);

  useEffect(() => {
    setValue(step);
  }, [step]);

  return (
    <>
      <Tabs variant={variant} value={value} onChange={handleChange} className={className} tabs={stepLabels} />
      {children
        ?.filter((c) => c)
        .map((child, index) => (
          <Fragment key={`${index}-stepBody`}>
            {child ? (
              <StepBody
                data-testid={`${index}-step-body`}
                step={value}
                index={index}
                setStep={setStep}
                nextStep={nextStep}
                previousStep={previousStep}
              >
                {child}
              </StepBody>
            ) : null}
          </Fragment>
        ))}
    </>
  );
};
