import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import { StyledTextField } from './PinInputField.styled';

type Props = {
  index: number;
  disabled: boolean;
  isError: boolean;
  onIndexChange(newIndex: number): void;
  onChange(): void;
  onPaste(event: React.ClipboardEvent<HTMLDivElement>): void;
  onBackspace(): void;
};

export const PinInputField: FC<Props> = ({
  index,
  disabled,
  isError,
  onChange,
  onPaste,
  onBackspace,
  onIndexChange,
}) => {
  const name = `pin.${index}.value`;
  const { field, fieldState } = useController({ name });

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.ctrlKey || event.metaKey) {
      return;
    }
    if (event.key === 'Backspace') {
      if (field.value) {
        return field.onChange({ target: { value: '' } });
      }
      return onBackspace();
    }
    if (event.key === 'ArrowLeft') {
      return onIndexChange(index - 1);
    }
    if (event.key === 'ArrowRight') {
      return onIndexChange(index + 1);
    }
    if (!event.key.match(/^[a-zA-Z0-9]{1}$/)) {
      return;
    }
    field.onChange({ target: { value: event.key } });
    onChange();
  };

  return (
    <StyledTextField
      autoFocus={index === 0}
      value={field.value}
      inputProps={{ maxLength: 1 }}
      onKeyDown={handleKeyDown}
      onPaste={onPaste}
      error={!!fieldState.error || isError}
      disabled={disabled}
    />
  );
};
