import React, { FC, ReactNode, forwardRef } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { motion } from 'framer-motion';

import { BackdropLoader, Loader } from './components';

const MotionDiv: FC = ({ children }) => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ width: 'inherit', height: 'inherit' }}>
    {children}
  </motion.div>
);

type LoadableProps = {
  isLoading: boolean;
  transitionDelay?: boolean;
  backdrop?: boolean;
  sx?: SxProps<Theme>;
  animate?: boolean;
  size?: string | number;
  children?: ReactNode;
};

export const Loadable = forwardRef<HTMLElement, LoadableProps>(
  ({ isLoading, children, transitionDelay = true, backdrop = false, sx, animate = false, size }, ref) => {
    if (backdrop) {
      return (
        <BackdropLoader ref={ref} sx={sx} isLoading={isLoading} isTransitionDelay={transitionDelay} size={size}>
          {children}
        </BackdropLoader>
      );
    }
    if (isLoading) {
      return <Loader ref={ref} sx={sx} isTransitionDelay={transitionDelay} size={size} />;
    }
    if (animate) {
      return <MotionDiv>{children}</MotionDiv>;
    }
    return <>{children}</>;
  }
);
