import { createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { handleQueryError } from 'store/utils';

import { recurringNattersMeetingApi } from './recurringNattersMeetingApi';

export const recurringNattersMeetingApiListener = createListenerMiddleware<RootState>();

recurringNattersMeetingApiListener.startListening({
  matcher: recurringNattersMeetingApi.endpoints.cancelMeeting.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, "Unable to fetch Events Report's data");
  },
});
