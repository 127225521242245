import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import { red } from 'common/theme/palette';
import { ChevronRightIcon } from 'icons';
import { NestedMenuItem } from 'mui-nested-menu';

import { MenuItemType, isMenuItemWithComponent, isMenuItemWithText, isNestedMenuItemWithText } from '../../types';
import { MenuItem as StyledMenuItem } from './MenuItem.styled';

export type MenuItemProps = {
  isActive?: boolean;
  item: MenuItemType;
  parentMenuOpen?: boolean;
  onClose(): void;
  autoScroll?: boolean;
};

export const MenuItem: FC<MenuItemProps> = ({
  isActive,
  item,
  parentMenuOpen = false,
  onClose,
  autoScroll = false,
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const isNested = isNestedMenuItemWithText(item);
  const direction = (isNested && item.direction) || 'right';
  const label = isMenuItemWithComponent(item) ? undefined : item.text;
  const color = useMemo(() => {
    if (!isMenuItemWithText(item)) return undefined;
    return (item.color ?? item.severity === 'danger') ? red[600] : undefined;
  }, [item]);

  const handleClick = useCallback(() => {
    item.onClick?.();
    onClose();
  }, [item, onClose]);

  useEffect(() => {
    if (!(autoScroll && isActive)) return;

    ref.current?.scrollIntoView({
      block: 'nearest',
    });
  }, [isActive, autoScroll]);

  return (
    <>
      {item.withDivider && <Divider variant="middle" component="li" sx={{ mx: 1.5 }} />}

      {isNested ? (
        <NestedMenuItem
          renderLabel={() => <ListItemText>{item.text}</ListItemText>}
          leftIcon={item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          rightIcon={<ChevronRightIcon fontSize="small" />}
          parentMenuOpen={parentMenuOpen}
          sx={({ spacing }) => ({
            padding: spacing(1, 1.5),
            '& .MuiBox-root': {
              alignItems: 'center',
              gap: 1,
            },
          })}
          MenuProps={{
            sx: { marginLeft: 0.05 },
            anchorOrigin: {
              vertical: 'center',
              horizontal: direction ?? 'right',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: direction === 'right' ? 'left' : 'right',
            },
          }}
          aria-label={item.text}
        >
          {item.nestedItems.map((nestedItem, nestedIndex) => (
            <MenuItem key={nestedIndex} item={nestedItem} onClose={onClose} parentMenuOpen={parentMenuOpen} />
          ))}
        </NestedMenuItem>
      ) : (
        <StyledMenuItem
          isActive={isActive}
          selected={isActive}
          onClick={handleClick}
          disabled={item.disabled}
          aria-label={label}
          ref={ref}
        >
          {isMenuItemWithComponent(item) ? (
            item.component
          ) : (
            <>
              {item.icon && <ListItemIcon sx={{ color }}>{item.icon}</ListItemIcon>}
              <ListItemText sx={{ color }}>{item.text}</ListItemText>
            </>
          )}
        </StyledMenuItem>
      )}
    </>
  );
};
