import { DEFAULT_TIME_FORMAT } from 'common/components/Inputs/TimePicker/constants';
import { ERROR_MESSAGES } from 'common/constants';
import { format, toZonedTime } from 'date-fns-tz';

export const formatTimeEST = (date: string | number | Date, timeFormat: string = DEFAULT_TIME_FORMAT) => {
  try {
    return `${format(toZonedTime(date, 'America/New_York'), timeFormat)} (EST)`;
  } catch {
    return ERROR_MESSAGES.invalidTime;
  }
};

export const formatTimeGMT = (date: string | number | Date, timeFormat: string = DEFAULT_TIME_FORMAT) => {
  try {
    return `${format(toZonedTime(date, 'Etc/GMT'), timeFormat)} (GMT)`;
  } catch {
    return ERROR_MESSAGES.invalidTime;
  }
};

export const formatTimeCET = (date: string | number | Date, timeFormat: string = DEFAULT_TIME_FORMAT) => {
  try {
    return `${format(toZonedTime(date, 'Europe/Paris'), timeFormat)} (CET)`;
  } catch {
    return ERROR_MESSAGES.invalidTime;
  }
};
