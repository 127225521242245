import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';
import { TagsCategory } from 'domain/Common';

import { InputWrapper, type InputWrapperProps } from '../InputWrapper';
import { TagsSelectInput } from './TagsSelectInput';

export const TagsSelect: FC<
  InputWrapperProps & {
    unselectSingleEnabled?: boolean;
    disableEdit?: boolean;
  }
> = ({ unselectSingleEnabled, name, hint, label, disableEdit = false, ...props }) => {
  const methods = useFormContext();
  const { fields: categories } = useFieldArray({ name, control: methods.control });

  return (
    <InputWrapper label={label} hint={hint} name={name} {...props} autoWidth>
      <Stack gap={3}>
        {/* @ts-expect-error TODO: add schema to useForm */}
        {categories.map((category: TagsCategory, index) => (
          <TagsSelectInput
            name={`${name}.${index}.tags`}
            key={category.id}
            category={category}
            unselectSingleEnabled={unselectSingleEnabled}
            disableEdit={disableEdit}
          />
        ))}
      </Stack>
    </InputWrapper>
  );
};
