import { type Components, type Theme } from '@mui/material';

import { SortIcon } from '../icons';

export const MuiTableSortLabel: Components<Theme>['MuiTableSortLabel'] = {
  defaultProps: {
    IconComponent: SortIcon,
  },
  styleOverrides: {
    root: ({ theme: { typography } }) => ({
      '& > .MuiTypography': {
        ...typography['natter-text-xs'],
        fontWeight: 600,
      },
    }),
    icon: ({ theme: { palette } }) => ({
      transition: 'none',

      '& path': {
        fill: palette.grey[400],
      },
    }),
    active: ({ theme: { palette } }) => ({
      // Take only first as the direction is set by rotating the icon
      '& path:first-of-type': {
        fill: palette.grey[600],
      },
    }),
  },
};
