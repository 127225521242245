import { StepItem, StepState } from '../types';

const defaultOptions: StepState = {
  isCompleted: false,
  isDisabled: false,
  isInProgress: false,
};

/**
 * Creates a step item for the progress tracker.
 *
 * @param title - The title of the step.
 * @param description - The description of the step.
 * @param options - The options for the step (optional).
 * @returns The created step item.
 *
 * @example
 * ```ts
 * createStep('Create Survey', 'Add Questions to your Survey')
 * ```
 */
export const createStep = (
  title: StepItem['title'],
  description: StepItem['description'],
  options?: Partial<StepState>
): StepItem => ({
  title,
  description,
  ...defaultOptions,
  ...options,
});
