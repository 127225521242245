import { makeStyles } from '@mui/styles';

export const useFullPageContainerStyles = makeStyles(
  (theme) => ({
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
    },
    contentWrapper: {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
    },
    centered: {
      width: '100%',
      height: '100%',
      maxWidth: 468,
      margin: '0 auto',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
  }),
  { name: 'fullPageContainer' }
);
