/* eslint-disable @typescript-eslint/no-empty-interface */

/* eslint-disable no-unused-vars */
import { ElementType } from 'react';

import type { TypographyProps } from '@mui/material';
import type { TypographyOptions, Variant } from '@mui/material/styles/createTypography';
import { CSSProperties } from '@mui/styles';
import type {
  TypographyPropsVariantOverrides as OldTypographyPropsVariantOverrides,
  TypographyVariants as OldTypographyVariants,
  TypographyVariantsOptions as OldTypographyVariantsOptions,
} from 'theme/typography';

/**
 * @example
 * Use of custom typography variants in components
 * ```tsx
 *<Typography variant="natter-display-2xl">
 *  Display 2xl
 *</Typography>
 * ```
 * or in *.styled.ts
 * ```ts
 *styled(Box)(({ theme: { typography }}) => ({
 *  ...typography['natter-text-xl'],
 *}))
 * ```
 */
export const natterTypographyVariants = {
  /** **Display 2xl**: fontSize: **72px** | lineHeight: **90px** */
  'natter-display-2xl': { fontSize: '4.5rem', lineHeight: '5.625rem', tracking: '-2%' },
  /** **Display xl**: fontSize: **60px** | lineHeight: **72px** */
  'natter-display-xl': { fontSize: '3.75rem', lineHeight: '4.5rem', tracking: '-2%' },
  /** **Display lg**: fontSize: **48px** | lineHeight: **60px***/
  'natter-display-lg': { fontSize: '3rem', lineHeight: '3.75rem', tracking: '-2%' },
  /** **Display md**: fontSize: **36px** | lineHeight: **44px** */
  'natter-display-md': { fontSize: '2.25rem', lineHeight: '2.75rem', tracking: '-2%' },
  /** **Display sm**: fontSize: **30px** | lineHeight: **38px** */
  'natter-display-sm': { fontSize: '1.875rem', lineHeight: '2.375rem' },
  /** **Display xs**: fontSize: **24px** | lineHeight: **32px** */
  'natter-display-xs': { fontSize: '1.5rem', lineHeight: '2rem' },
  /** **Text xl**: fontSize: **20px** | lineHeight: **30px** */
  'natter-text-xl': { fontSize: '1.25rem', lineHeight: '1.875rem' },
  /** **Text lg**: fontSize: **18px** | lineHeight: **28px** */
  'natter-text-lg': { fontSize: '1.125rem', lineHeight: '1.75rem' },
  /** **Text md**: fontSize: **16px** | lineHeight: **24px** */
  'natter-text-md': { fontSize: '1rem', lineHeight: '1.5rem' },
  /** **Text sm**: fontSize: **14px** | lineHeight: **20px** */
  'natter-text-sm': { fontSize: '0.875rem', lineHeight: '1.25rem' },
  /** **Text xs**: fontSize: **12px** | lineHeight: **18px** */
  'natter-text-xs': { fontSize: '0.75rem', lineHeight: '1.125rem' },
  inherit: { fontSize: 'inherit', lineHeight: 'inherit' },
} as const;

export const typography: TypographyOptions = {
  fontFamily: 'Inter',
  htmlFontSize: 16,
  fontSize: 14,

  allVariants: {
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    margin: 0,
  },

  button: {
    ...natterTypographyVariants['natter-text-sm'],
    fontWeight: 500,
  },

  ...natterTypographyVariants,
};

export type TypographyWithComponent = TypographyProps & { component?: ElementType };
export type NatterTypographyVariant = keyof typeof natterTypographyVariants;

type NatterTypographyVariants = typeof natterTypographyVariants;
type DisabledDefaultVariants = Record<Variant, true>;
type EnabledVariants = Record<NatterTypographyVariant, true> & OldTypographyPropsVariantOverrides;

declare module '@mui/material/styles' {
  interface TypographyVariants extends NatterTypographyVariants, OldTypographyVariants {}
  interface TypographyVariantsOptions extends Partial<NatterTypographyVariants & OldTypographyVariantsOptions> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends EnabledVariants, DisabledDefaultVariants {}
}

export const textEllipsis: CSSProperties = {
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
