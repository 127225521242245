import { useCallback, useState } from 'react';

import { blobToData } from 'common/utils';
import { saveAs } from 'file-saver';
import { InterLight, InterMedium, InterRegular } from 'fonts/Inter';
import html2canvas from 'html2canvas';
import { useAppDispatch } from 'store';
import { addAppErrorMessage } from 'store/features/alerts';

const fontFamilyName = 'Inter';
const lightFontUrl = InterLight;
const regularFontUrl = InterRegular;
const mediumFontUrl = InterMedium;

const fontsDefinitions = [
  {
    familyName: fontFamilyName,
    fontWeight: 300,
    url: lightFontUrl,
  },
  {
    familyName: fontFamilyName,
    fontWeight: 500,
    url: regularFontUrl,
  },
  {
    familyName: fontFamilyName,
    fontWeight: 700,
    url: mediumFontUrl,
  },
];

const getFontFace = async (familyName: string, fontWeight: number, url: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const fontData = await blobToData(blob);

    return `@font-face {
          font-family: "${familyName}";
          font-style: normal;
          font-weight: ${fontWeight};
          font-display: swap;
          src: url("${fontData}");
      }`;
  } catch (err) {
    console.error('Error getting font face', err);
    return '';
  }
};

const loadFonts = async () =>
  Promise.all(
    fontsDefinitions.map((fontDefinition) =>
      getFontFace(fontDefinition.familyName, fontDefinition.fontWeight, fontDefinition.url)
    )
  );

const appendStyle = (css: string, target: Element) => {
  const styleEl = document.createElement('style');
  styleEl.appendChild(document.createTextNode(css));
  target.appendChild(styleEl);
};

const appendFonts = async (target: Element) => {
  const fonts = await loadFonts();
  fonts.forEach((font) => {
    appendStyle(font, target);
  });
};

type ExportAsPngProps = {
  elementId: string;
  fileName: string;
  onClone?(document: Document, element: HTMLElement): void;
};

export const useExportAsPNG = ({ elementId, fileName, onClone }: ExportAsPngProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const exportAsPNG = useCallback(async () => {
    setIsLoading(true);
    try {
      const element = document.getElementById(elementId);
      if (!element) return;

      const canvas = await html2canvas(element, {
        logging: true,
        onclone: async (document, clone) => {
          const defs = clone.querySelector('svg > defs');
          if (defs) {
            await appendFonts(defs);
          }

          onClone?.(document, clone);
        },
      });

      const data = canvas.toDataURL('image/png', 1.0);
      saveAs(data, `${fileName}.png`);
    } catch {
      dispatch(addAppErrorMessage(`Could not save '${fileName}' file.`));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, elementId, fileName, onClone]);

  return { exportAsPNG, isLoading };
};
