import React, { FC, ReactElement } from 'react';

import { Box, Stack } from '@mui/material';
import { EmptyState } from 'common/components';

import { BaseGuardContainer } from './BaseGuard.styled';

export type BaseGuardProps = {
  title: string;
  description: string;
  shouldDisplay: boolean;
  buttonLabel?: string;
  onClick?(): void;
  href?: string;
  icon?: ReactElement;
};

export const BaseGuard: FC<BaseGuardProps> = ({
  title,
  icon,
  description,
  shouldDisplay,
  buttonLabel,
  onClick,
  href,
  children,
}) => {
  if (shouldDisplay) {
    return <>{children}</>;
  }

  return (
    <BaseGuardContainer>
      <EmptyState
        title={title}
        description={description}
        icon={
          icon && (
            <Box color="primary.main">
              <Stack width={100} height={100} fontSize={100}>
                {icon}
              </Stack>
            </Box>
          )
        }
        buttonLabel={buttonLabel}
        onClick={onClick}
        href={href}
      />
    </BaseGuardContainer>
  );
};
