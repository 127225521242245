import { MeetingWSPath } from './meeting';

export const RoomWSMessages = {
  RoomExit: 'room_exit',
  RoomJoin: 'room_join',
  RoomOpened: 'room_opened',
  GuestJoinedRoom: 'guest_joined_room',
  RoomsCountUpdated: 'rooms_count_updated',
  RoomSupportRequested: 'room_support_requested',
  RoomSupportRequestedList: 'room_support_requested_list',
  RoomSupportCancelled: 'room_support_cancelled',
  RoomSupportJoined: 'room_support_joined',
  RoomSupportLeft: 'room_support_left',
  UpdateRoomAttendee: 'update_room_attendee',
  GuestDisconnected: 'guest_disconnected',
  UserDisconnectedList: 'user_disconnected_list',
  RoomClosed: 'room_closed',
  NatteringStarted: 'nattering_started',
  BroadcastCountdown: 'broadcast_countdown',
  TopicResponseUpdated: 'topic_response_updated',
  SetTopicResponse: 'set_topic_response',
  RoomSearchSubscribed: 'meeting_room_search_subscribed',
  TranscriptionStarted: 'transcription_started',
  TranscriptionStopped: 'transcription_stopped',
} as const;

export type RoomWSMessage = (typeof RoomWSMessages)[keyof typeof RoomWSMessages];

const RoomWSPath = `${MeetingWSPath}/room/:roomId` as const;

export const RoomWSActions = {
  RequestSupport: `${RoomWSPath}/request_support`,
  CancelSupport: `${RoomWSPath}/cancel_support`,
  LeaveAsSupport: `${RoomWSPath}/leave_as_support`,
  JoinAsSupport: `${RoomWSPath}/join_as_support`,
  RoomConnectionError: `${RoomWSPath}/room_connection_error`,
  RoomFeedback: `${RoomWSPath}/room_feedback`,
  UpdateTopicResponse: `${RoomWSPath}/update_topic_response`,
  SearchRoom: `${RoomWSPath}/search`,
  StartTranscription: `${RoomWSPath}/start_transcription`,
  StopTranscription: `${RoomWSPath}/stop_transcription`,
} as const;

export type RoomWSAction = (typeof RoomWSActions)[keyof typeof RoomWSActions];
