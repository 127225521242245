import React, { FC, MouseEventHandler, ReactNode } from 'react';

import { withPopover } from 'common/hocs/withPopover';

import { IconButton, IconButtonProps } from '../IconButton';

type Props = {
  Icon: ReactNode;
  onClick: MouseEventHandler<HTMLElement>;
} & IconButtonProps;

const Button: FC<Props> = ({ Icon, onClick, ...props }) => (
  <IconButton {...props} onClick={onClick}>
    {Icon}
  </IconButton>
);

export const IconButtonWithPopover = withPopover(Button);
