import { Divider, styled } from '@mui/material';

export const SectionWrapper = styled('div')({
  flex: '1 1 33%',
  display: 'flex',
  alignItems: 'center',
  '& > a': { width: '100%' },
});

export const TitleWrapper = styled('div', { name: 'TitleWrapper' })({
  display: 'grid',
  overflow: 'hidden',
});

export const NavigationBarDivider = styled(Divider)(({ theme: { palette } }) => ({
  borderColor: palette.grey[400],
}));

NavigationBarDivider.defaultProps = {
  orientation: 'vertical',
  variant: 'middle',
  flexItem: true,
  'aria-hidden': true,
};
