import React, { ChangeEvent, FC, SyntheticEvent } from 'react';

import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

import { TabsWrapper } from './Tabs.styled';

type Props = {
  tabs: string[];
  className?: string;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  value?: unknown;
  onChange?: (event: SyntheticEvent | ChangeEvent, newValue: number) => Promise<void> | void;
  centered?: boolean;
};

export const Tabs: FC<Props> = ({ tabs, variant, value, onChange, centered }) => (
  <TabsWrapper>
    <MuiTabs centered={centered} variant={variant} value={value} onChange={onChange}>
      {tabs.map((stepLabel) => (
        <MuiTab key={stepLabel} label={stepLabel} data-testid={`step-tab ${stepLabel}`} />
      ))}
    </MuiTabs>
  </TabsWrapper>
);
