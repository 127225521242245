import type { Components, Theme } from '@mui/material';

import { MuiAccordion } from './MuiAccordion';
import { MuiAccordionDetails } from './MuiAccordionDetails';
import { MuiAccordionSummary } from './MuiAccordionSummary';
import { MuiAppBar } from './MuiAppBar';
import { MuiAutocomplete } from './MuiAutocomplete';
import { MuiButton } from './MuiButton';
import { MuiCard } from './MuiCard';
import { MuiCardContent } from './MuiCardContent';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiChip } from './MuiChip';
import { MuiContainer } from './MuiContainer';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiDialog } from './MuiDialog';
import { MuiDialogActions } from './MuiDialogActions';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiDialogContentText } from './MuiDialogContentText';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiDivider } from './MuiDivider';
import { MuiIconButton } from './MuiIconButton';
import { MuiInputBase } from './MuiInputBase';
import { MuiInputLabel } from './MuiInputLabel';
import { MuiLink } from './MuiLink';
import { MuiMenu } from './MuiMenu';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiPaginationItem } from './MuiPaginationItem';
import { MuiPaper } from './MuiPaper';
import { MuiSelect } from './MuiSelect';
import { MuiSvgIcon } from './MuiSvgIcon';
import { MuiTab } from './MuiTab';
import { MuiTableCell } from './MuiTableCell';
import { MuiTableContainer } from './MuiTableContainer';
import { MuiTableHead } from './MuiTableHead';
import { MuiTableRow } from './MuiTableRow';
import { MuiTabs } from './MuiTabs';
import { MuiTextField } from './MuiTextField';
import { MuiToggleButton } from './MuiToggleButton';
import { MuiToolbar } from './MuiToolbar';
import { MuiTooltip } from './MuiTooltip';
import { MuiTypography } from './MuiTypography';

export const components: Components<Theme> = {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAppBar,
  MuiAutocomplete,
  MuiButton,
  MuiCard,
  MuiCardContent,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiContainer,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiDivider,
  MuiIconButton,
  MuiInputBase,
  MuiInputLabel,
  MuiLink,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaginationItem,
  MuiPaper,
  MuiSelect,
  MuiTab,
  MuiTableCell,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
  MuiTabs,
  MuiTextField,
  MuiToolbar,
  MuiTooltip,
  MuiTypography,
  MuiSvgIcon,
  MuiToggleButton,
};
