import React, { type FC, type SVGProps } from 'react';

import { useTheme } from '@mui/material';

export const NoMembersIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  const theme = useTheme();

  return (
    <svg width="1em" height="1em" viewBox="0 0 102 102" fill="none" {...props}>
      <circle cx={51} cy={49} r={49} fill={theme.palette.primary.main} fillOpacity={0.1} />
      <g filter="url(#prefix__filter0_d_7345_17876)">
        <circle cx={51} cy={49} r={43} fill="#fff" />
      </g>
      <path
        d="M42 38a9 9 0 1018.001 0 9 9 0 00-18 0zM37 69v-6.667A9.333 9.333 0 0146.335 53h9.333a9.333 9.333 0 019.334 9.333V69M41 47.072A7.072 7.072 0 1136 35M35.667 53.857h-2.334A7.333 7.333 0 0026 61.191v2.666M70 43v12m-6-6h12-12z"
        stroke={theme.palette.primary.main}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="prefix__filter0_d_7345_17876"
          x={0}
          y={0}
          width={102}
          height={102}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.392157 0 0 0 0 0.235294 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7345_17876" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_7345_17876" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
