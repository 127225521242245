import React, { FC, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, Switch } from 'common/components/ReactHookForm';
import { useBreakpoints } from 'common/hooks';
import { useDevicesSelect } from 'devices/hooks';
import { MediaDevice } from 'domain/event';
import { VideocamIcon, VideocamOffIcon } from 'icons';

import { DevicesSection } from './DevicesSection';

type Props = {
  devices: MediaDevice[];
  isAccessGranted: boolean;
  fieldName?: string;
  isEnabledFieldName?: string;
  onChange?(): void;
  onCameraEnabledChange?(value: boolean): void;
};

export const SelectCamera: FC<Props> = ({
  fieldName = 'cameraId',
  isEnabledFieldName = 'isCameraEnabled',
  devices,
  isAccessGranted,
  onChange,
  onCameraEnabledChange,
}) => {
  const { t } = useTranslation('devices', { keyPrefix: 'selectCamera' });
  const { isSmallScreen } = useBreakpoints();
  const { options, label, isDisabled } = useDevicesSelect({
    name: fieldName,
    label: t('label'),
    devices,
    isAccessGranted,
  });
  const isCameraEnabled: boolean = useWatch({ name: isEnabledFieldName });

  const handleChangeEffect = useCallback(
    (value) => {
      onCameraEnabledChange?.(value);
      onChange?.();
    },
    [onCameraEnabledChange, onChange]
  );

  return (
    <DevicesSection
      Icon={isCameraEnabled ? <VideocamIcon fontSize="small" /> : <VideocamOffIcon fontSize="small" />}
      title={t('label')}
      Action={
        <Switch size="small" name={isEnabledFieldName} disabled={isDisabled} onChangeEffect={handleChangeEffect} />
      }
    >
      <Select
        startAdornment={isSmallScreen && <VideocamIcon fontSize="small" />}
        size="small"
        label={label}
        disabled={isDisabled}
        name={fieldName}
        options={options}
        data-testid="MediaSettings-Camera"
        shouldShowError={false}
        onChange={onChange}
      />
    </DevicesSection>
  );
};
