import React, { FC } from 'react';

import { ButtonProps } from 'common/components/Buttons/Button';
import { CloseIcon } from 'icons';

import { IconButton } from '../IconButton';

type ClearButtonProps = Pick<ButtonProps, 'onClick'>;

export const ClearButton: FC<ClearButtonProps> = (props) => (
  <IconButton
    color="secondary"
    variant="text"
    sx={({ palette, spacing }) => ({
      color: palette.grey[500],
      backgroundColor: 'transparent',
      marginRight: spacing(-1.5),
      '&:active': {
        borderColor: 'transparent',
      },
    })}
    {...props}
  >
    <CloseIcon />
  </IconButton>
);
