import { FormControlLabel, FormControlLabelProps, RadioGroup, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

export type StyledRadioGroupProps = Pick<FormControlLabelProps, 'checked'> & {
  variant?: 'default' | 'outlined';
};

export const StyledRadioGroup = styled(RadioGroup, {
  shouldForwardProp: omitForwardedProps<StyledRadioGroupProps>('variant'),
})<StyledRadioGroupProps>(({ theme: { spacing }, variant }) => ({
  ...(variant === 'outlined' && {
    gap: spacing(2),

    display: 'grid',
    gridTemplateColumns: '1fr',
  }),
}));

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: omitForwardedProps<StyledRadioGroupProps>('variant', 'checked'),
})<StyledRadioGroupProps>(({ theme: { spacing, shape, palette }, checked, variant }) => ({
  ...(variant === 'outlined' && {
    '&.MuiFormControlLabel-root': {
      margin: 0,
    },

    padding: spacing(1, 2, 1, 1),
    fontWeight: 500,

    borderRadius: shape.borderRadius,
    border: '1px solid',
    borderColor: palette.divider,

    ...(checked && {
      backgroundColor: palette.extendedPrimary[25],
      borderColor: palette.primary.main,
    }),
  }),
}));
