import { MenuItem as MuiMenuItem, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  isActive?: boolean;
};

export const MenuItem = styled(MuiMenuItem, {
  shouldForwardProp: omitForwardedProps<Props>('isActive'),
})<Props>(({ theme: { palette }, isActive }) => ({
  '&&': {
    ...(isActive && {
      backgroundColor: palette.extendedPrimary[50],
      color: palette.primary.main,
      borderColor: palette.primary.main,

      '&:hover': {
        backgroundColor: palette.extendedPrimary[50],
      },
    }),
  },
}));
