import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { eventApi } from 'store/apis/event';

import {
  deleteEventSuccessEffect,
  getConnectionsFailedEffect,
  refreshEventsList,
  registerForEventFailedEffect,
  registerForEventSuccessEffect,
  startEventFailedEffect,
  startEventSuccessEffect,
  stopEventFailedEffect,
} from './effects';

export const activeEventListener = createListenerMiddleware<RootState>();

activeEventListener.startListening({
  matcher: eventApi.endpoints.deleteEvent.matchFulfilled,
  effect: deleteEventSuccessEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.startEvent.matchRejected,
  effect: startEventFailedEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.startEvent.matchFulfilled,
  effect: startEventSuccessEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.stopEvent.matchRejected,
  effect: stopEventFailedEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.getConnections.matchRejected,
  effect: getConnectionsFailedEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.registerForEvent.matchFulfilled,
  effect: registerForEventSuccessEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.registerForEvent.matchRejected,
  effect: registerForEventFailedEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.createEvent.matchFulfilled,
  effect: refreshEventsList,
});
