import React, { ComponentType, FC, ReactElement, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

/**
 * Higher-order component that prevents navigation to a page if it is entered without state.
 *
 * @example
 * ```tsx
 * <SentryRoute path={AppRoutes.SurveyDetails} component={InnerRoute(() => AppRoutes.SurveysList)(SurveyDetailsPage)} />
 * ```
 */
export const withInnerRoute =
  (getRedirectFn: <Params extends { [K in keyof Params]?: string }>(args: { params: Params }) => string) =>
  <Props extends object>(WrappedComponent: ComponentType<Props>): FC<Props> =>
  ({ ...props }): ReactElement | null => {
    const { state: locationState } = useLocation();
    const [state] = useState(locationState);
    const params = useParams();

    if (state) {
      return <WrappedComponent {...(props as Props)} />;
    }

    return <Redirect to={getRedirectFn({ params })} />;
  };
