import type { NetworkQuality } from 'agora-rtc-react';

export type AgoraState = {
  networkQuality: NetworkQuality;
};

export enum NetworkQualityState {
  Unknown,
  Excellent,
  Good,
  SlightlyImpared,
  Poor,
  BarelyUsable,
  Disconnected,
}
