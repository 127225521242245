import { FormControlLabel, styled } from '@mui/material';

import { SwitchProps } from '../Switch/Switch';

export const StyledFormControlLabel = styled(FormControlLabel)<{ size?: SwitchProps['size'] }>(
  ({ theme: { spacing, typography }, size }) => ({
    gap: spacing(1),
    margin: 0,

    '.MuiFormControlLabel-label': {
      fontSize: size === 'small' ? typography.body2.fontSize : typography.body1.fontSize,
      padding: 0,
    },
  })
);
