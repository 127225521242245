import React, { FC } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';

import { useCommunitySuccessStyles } from './communitySuccess.styles';

const svgVariants = {
  hidden: {},
  visible: {},
};

const pathVariants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      delay: 1,
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const tickVariants = {
  hidden: {
    y: -200,
  },
  visible: {
    y: 0,
    transition: {
      delay: 2,
    },
  },
};

export const CommunitySuccessIcon: FC = () => {
  const classes = useCommunitySuccessStyles();

  return (
    <motion.svg
      viewBox="0 0 123 93"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svgIcon}
      variants={svgVariants}
    >
      <motion.path
        d="M54.3322 44.4442C62.1869 44.4442 68.5543 38.0768 68.5543 30.2221C68.5543 22.3675 62.1869 16 54.3322 16C46.4776 16 40.1101 22.3675 40.1101 30.2221C40.1101 38.0768 46.4776 44.4442 54.3322 44.4442Z"
        className={classNames(classes.strokeColor)}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={pathVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M32.9998 79.9992V72.8881C32.9998 69.1162 34.4982 65.4987 37.1653 62.8316C39.8325 60.1644 43.4499 58.666 47.2219 58.666H61.444C65.2159 58.666 68.8334 60.1644 71.5005 62.8316C74.1677 65.4987 75.6661 69.1162 75.6661 72.8881V79.9992"
        className={classNames(classes.strokeColor)}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={pathVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M73.8892 16.4619C76.9484 17.2452 79.6599 19.0244 81.5963 21.519C83.5326 24.0136 84.5836 27.0817 84.5836 30.2396C84.5836 33.3975 83.5326 36.4656 81.5963 38.9602C79.6599 41.4548 76.9484 43.234 73.8892 44.0172"
        className={classNames(classes.strokeColor)}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={pathVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M91.6661 80V72.889C91.648 69.75 90.592 66.7052 88.6626 64.2291C86.7332 61.7531 84.0389 59.9849 80.9995 59.2002"
        className={classNames(classes.strokeColor)}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={pathVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M24.9999 73.3327C33.8364 73.3327 40.9997 66.1694 40.9997 57.3329C40.9997 48.4964 33.8364 41.333 24.9999 41.333C16.1634 41.333 9 48.4964 9 57.3329C9 66.1694 16.1634 73.3327 24.9999 73.3327Z"
        className={classNames(classes.strokeColor, classes.shadowing)}
        variants={tickVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M24.9999 73.3327C33.8364 73.3327 40.9997 66.1694 40.9997 57.3329C40.9997 48.4964 33.8364 41.333 24.9999 41.333C16.1634 41.333 9 48.4964 9 57.3329C9 66.1694 16.1634 73.3327 24.9999 73.3327Z"
        className={classNames(classes.strokeColor)}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={tickVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M19.6665 57.3329L23.222 60.8884L30.3331 53.7773"
        className={classNames(classes.strokeColor)}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={tickVariants}
        initial="hidden"
        animate="visible"
      />
    </motion.svg>
  );
};
