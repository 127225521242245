import { MiddlewareAPI } from '@reduxjs/toolkit';
import { QuestionsWSAction, QuestionsWSActions } from 'common/constants';
import { extractPathVariables, generateDynamicPath } from 'common/utils';
import omit from 'lodash/omit';
import type { AppDispatch, RootState } from 'store';
import { SocketSendParams } from 'store/features/socket';
import { websocketSend } from 'store/features/socket/actions';

/**
 * Factory function for creating Redux action creators for **Questions** WebSocket actions.
 * @TODO Make it generic to handle other WS endpoints
 *
 * @template T - The type of data that will be sent with the WebSocket action.
 *
 * @param {QuestionsWSAction} action - The path for the WebSocket action, which can contain variables indicated by a colon (e.g., '/meeting/:eventId/join').
 *
 * @param {T} data - The data to be sent with the WebSocket action.
 *
 * @example
 * ```ts
 * //before
 *
 * export const meetingJoin =
 * ({ eventId, pin }: { eventId: number; pin: string }) =>
 * (dispatch: AppDispatch) => {
 *   dispatch(
 *     websocketSend({
 *       path: `/meeting/${eventId}/join`,
 *       data: { pin: pin || undefined },
 *     })
 *   );
 * };
 *
 * // after
 * export const meetingJoin = createWebSocketAction<MeetingWSParams<MeetingJoinPayload>>('/meeting/:eventId/join');
 *
 * // usage in component or hook
 * const dispatch = useAppDispatch();
 * dispatch(meetingJoin({ eventId, pin: eventPinValue }));
 * ```
 *  */
export const createQuestionsWSAction =
  <T extends Record<string, unknown>>(action: QuestionsWSAction, callback?: (dispatch: AppDispatch, data: T) => void) =>
  (data: T = {} as T, config?: Partial<SocketSendParams>) =>
  (dispatch: AppDispatch, getState: MiddlewareAPI<AppDispatch, RootState>['getState']): void => {
    if (!Object.values(QuestionsWSActions).includes(action))
      throw Error(`Action '${action}' is not supported by Questions WS`);

    const { question } = getState();
    const questionAssignmentKey = question.questionAssignment?.questionAssignmentKey ?? {
      questionId: data.questionId,
      assignmentId: data.assignmentId,
    };
    if (!questionAssignmentKey) return;

    const payload = omit(data, extractPathVariables(action));
    dispatch(
      websocketSend({
        path: generateDynamicPath(action, { ...questionAssignmentKey, ...data }),
        data: Object.keys(payload).length ? payload : undefined,
        plainBody: Object.keys(payload).length ? config?.plainBody ?? true : undefined,
      })
    );
    if (typeof callback === 'function') {
      callback(dispatch, data);
    }
  };
