import { MediaDevice } from 'domain/event';

export const getInitialDeviceId = (mediaDevices: MediaDevice[], kind: MediaDeviceKind) => {
  const storedDefaultDeviceId = localStorage.getItem(getDeviceLocalStorageKey(kind, 'default'));
  const currentDefaultDeviceId = mediaDevices.find((device) => device.kind === kind)?.deviceId || '';
  const systemDefaultsDidNotChange = storedDefaultDeviceId === currentDefaultDeviceId;

  const storedSelectedDeviceId = localStorage.getItem(getDeviceLocalStorageKey(kind, 'selected'));
  const storedSelectedDevice = mediaDevices.find((device) => device.deviceId === storedSelectedDeviceId);

  localStorage.setItem(getDeviceLocalStorageKey(kind, 'default'), currentDefaultDeviceId);

  if (systemDefaultsDidNotChange && storedSelectedDevice) {
    return storedSelectedDevice.deviceId;
  }

  return currentDefaultDeviceId;
};

export const getDeviceLocalStorageKey = (kind: MediaDeviceKind, type: 'default' | 'selected') =>
  `${kind}-device-${type}`;
