import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

interface Props {
  container: RefObject<HTMLDivElement>;
  onDrop: (e: DragEvent) => void;
}

export const useDropContainer = ({ container, onDrop }: Props) => {
  const [dragging, setDragging] = useState(false);

  const handleDragOver = useCallback((e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      onDrop(e);
    },
    [onDrop]
  );

  const handleDragEnter = useCallback((e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  useLayoutEffect(() => {
    const currentContainer = container.current;
    if (!currentContainer) return;

    currentContainer.addEventListener('dragover', handleDragOver);
    currentContainer.addEventListener('drop', handleDrop);
    currentContainer.addEventListener('dragenter', handleDragEnter);
    currentContainer.addEventListener('dragleave', handleDragLeave);

    return () => {
      currentContainer.removeEventListener('dragover', handleDragOver);
      currentContainer.removeEventListener('drop', handleDrop);
      currentContainer.removeEventListener('dragenter', handleDragEnter);
      currentContainer.removeEventListener('dragleave', handleDragLeave);
    };
  }, [container, handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  return {
    dragging,
  };
};
