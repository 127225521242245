export enum ImageType {
  EventImage = 'EVENT_IMAGE',
  MainSponsorLogo = 'MAIN_SPONSOR_LOGO',
  OtherSponsorLogo = 'OTHER_SPONSOR_LOGO',
}

export type EventImageParams = {
  imageType: ImageType;
  sponsorURL?: string;
  image: Blob;
};

export type EventImageApiResponse = {
  id: number;
  imageName: string;
  imageLink: string;
  eventRelatedImageType: ImageType;
  occurrenceOrder: number;
  sponsorUrl?: string;
};

export type EventImage = Pick<EventImageApiResponse, 'id'> & {
  name: string;
  src: string;
};

export type EventSponsor = Pick<EventImageApiResponse, 'id'> & {
  image: {
    name: string;
    src: string;
  };
  url?: string;
};
