import { IconButton, Stack, darken, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils/omitForwardedProps';

import { isColorWhiteListed } from '../Button/utils/isColorWhiteListed';
import { IconButtonVariant, IconButtonVariantColor } from './types';
import {
  mapActiveBackgroundForTextVariant,
  mapActiveColorForTextVariant,
  mapColorForOutlinedVariant,
  mapColorForTextVariant,
  mapFocusColorForOutlinedVariant,
  mapFocusColorForTextVariant,
  mapHoverBackgroundForTextVariant,
  mapHoverColorForOutlinedVariant,
  mapHoverColorForTextVariant,
} from './utils';

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: omitForwardedProps('isLoading', 'variant', 'iconColor'),
})<{
  isLoading?: boolean;
  variant?: IconButtonVariant;
  iconColor?: IconButtonVariantColor;
}>(({ isLoading, variant, theme: { palette }, iconColor }) => ({
  ...(iconColor &&
    isColorWhiteListed(iconColor) &&
    isLoading && {
      '&:disabled': {
        backgroundColor: palette[iconColor].main,
        color: palette[iconColor].contrastText,
      },
    }),
  ...(variant === 'text' && {
    backgroundColor: 'transparent',
    color: mapColorForTextVariant(palette, iconColor),

    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.grey[300],
    },
    '&:hover': {
      backgroundColor: mapHoverBackgroundForTextVariant(palette, iconColor),
      color: mapHoverColorForTextVariant(palette, iconColor),
    },
    '&:focus': {
      backgroundColor: 'transparent',
      color: mapFocusColorForTextVariant(palette, iconColor),
    },
    '&:active, &.active': {
      color: mapActiveColorForTextVariant(palette, iconColor),
      backgroundColor: mapActiveBackgroundForTextVariant(palette, iconColor),
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    color: mapColorForTextVariant(palette, iconColor),
    border: '1px solid',
    borderColor: mapColorForOutlinedVariant(palette, iconColor),

    '&:disabled': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: mapHoverColorForOutlinedVariant(palette, iconColor),
    },
    '&:focus': {
      backgroundColor: mapFocusColorForOutlinedVariant(palette, iconColor),
    },
    '&:active, &.active': {
      backgroundColor: mapHoverColorForOutlinedVariant(palette, iconColor),
      borderColor: mapHoverColorForOutlinedVariant(palette, iconColor),
    },
  }),
  ...(variant === 'plain' && {
    backgroundColor: 'transparent',
    color: palette.text.secondary,

    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.grey[300],
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: darken(palette.text.secondary, 0.3),
    },
    '&:focus': {
      backgroundColor: palette.extendedPrimary[25],
    },
    '&:active, &.active': {
      backgroundColor: 'transparent',
    },
  }),
  ...(variant === 'contained' &&
    iconColor === 'secondary' && {
      backgroundColor: palette.grey[100],
      color: palette.grey[700],

      '&:hover': {
        backgroundColor: palette.grey[200],
      },

      '&:active, &.active': {
        backgroundColor: palette.extendedPrimary[50],
        color: palette.primary.main,
      },
    }),
}));

export const LoadingIndicatorContainer = styled(Stack)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});
