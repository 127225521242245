import { type Components, type Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme: { palette, typography, spacing } }) => ({
      backgroundColor: palette.grey[900],
      color: palette.grey[200],
      ...typography['natter-text-xs'],
      fontWeight: 400,
      margin: spacing(0),
      maxWidth: 300,

      '& .MuiTypography-root': {
        color: palette.grey[200],
      },

      '.MuiTooltip-popper[data-popper-placement*="bottom"] > &': {
        marginTop: 0,
      },
    }),
    tooltipArrow: ({ theme: { palette } }) => ({
      '& .MuiTooltip-arrow': {
        color: palette.grey[900],
      },
    }),
  },
};
