import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoMediaAccessModal } from 'devices/components';
import { checkAudioAccess } from 'devices/utils';
import { useAppDispatch } from 'store';

export type WithAudioAccessInfo = { audioAccess: boolean | undefined };

export const withAudioAccess =
  <P extends { onCancel: () => void }>(
    WrappedComponent: ComponentType<P & WithAudioAccessInfo>
  ): FC<Omit<P, 'audioAccess'>> =>
  (props) => {
    const { t } = useTranslation('devices');
    const dispatch = useAppDispatch();
    const [hasAudioAccess, setHasAudioAccess] = useState<boolean>();
    const [isCheckingMediaAccess, setIsCheckingMediaAccess] = useState(hasAudioAccess === undefined);

    useEffect(() => {
      if (hasAudioAccess !== undefined) return;

      (async () => {
        setIsCheckingMediaAccess(true);
        const hasAccess = await checkAudioAccess();
        setHasAudioAccess(hasAccess);
        setIsCheckingMediaAccess(false);
      })();
    }, [hasAudioAccess, dispatch]);

    return (
      <>
        <WrappedComponent
          isCheckingMediaAccess={isCheckingMediaAccess}
          audioAccess={hasAudioAccess}
          {...(props as P)}
        />
        <NoMediaAccessModal display={isCheckingMediaAccess} title={t('grantAccessHint')} />
      </>
    );
  };
