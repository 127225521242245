import { Stack, styled } from '@mui/material';

export const VideoAvatarContainer = styled(Stack)(({ theme: { spacing } }) => ({
  borderRadius: '50%',
  height: spacing(5),
  overflow: 'hidden',
  width: spacing(5),
}));

export const VideoAvatar = styled('video')({
  height: '100%',
});
