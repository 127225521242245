import { AnyAction, Dispatch, ListenerEffect, ThunkDispatch } from '@reduxjs/toolkit';
import { uuid } from 'common/utils';
import { ClientRole } from 'domain/Broadcast';
import type { RootState } from 'store';
import { selectActiveEventDetails } from 'store/apis/event';
import { selectUserAccountId } from 'store/apis/user';
import { addBroadcastInfoMessage, removeAppMessage, selectAlertById } from 'store/features/alerts';

import { selectBroadcast, selectParticipantsWithRaisedHands, selectRaisedHandAlertText } from '../selectors';

type CombinedDispatch = Dispatch<AnyAction> & ThunkDispatch<RootState, unknown, AnyAction>;

type Effect = ListenerEffect<AnyAction, RootState, CombinedDispatch>;

export const HAND_RAISED_NOTIFICATION_ID = uuid();

export const displayHandRaisedAlert: Effect = async (_, { dispatch, getState }) => {
  const state = getState();
  const broadcast = selectBroadcast(state);
  const eventDetails = selectActiveEventDetails(state);
  const accountId = selectUserAccountId(state);

  if (!(broadcast && eventDetails && accountId)) return;

  const participantsWithRaisedHands = selectParticipantsWithRaisedHands(state);
  const { role } = broadcast;
  const { isOwned } = eventDetails;
  const isSpeaker = role === ClientRole.Host;
  const shouldSeeHandRaisedAlert = isSpeaker || isOwned;

  if (!shouldSeeHandRaisedAlert) return;

  const otherHandsRaised = participantsWithRaisedHands.filter((id) => id !== accountId);

  if (otherHandsRaised.length === 0) return;

  const message = selectRaisedHandAlertText(getState());
  dispatch(
    addBroadcastInfoMessage({
      message,
      id: HAND_RAISED_NOTIFICATION_ID,
      timeout: 0,
    })
  );
};

export const updateHandRaisedAlert: Effect = async (_, { dispatch, getState }) => {
  const state = getState();

  const isHandRaisedNotificationDisplayed = selectAlertById(HAND_RAISED_NOTIFICATION_ID)(state);
  if (!isHandRaisedNotificationDisplayed) return;

  const accountId = selectUserAccountId(state);
  if (!accountId) return;

  const participantsWithRaisedHands = selectParticipantsWithRaisedHands(state);
  const otherHandsRaised = participantsWithRaisedHands.filter((id) => id !== accountId);

  if (otherHandsRaised.length === 0) {
    dispatch(removeAppMessage(HAND_RAISED_NOTIFICATION_ID));
  } else {
    const message = selectRaisedHandAlertText(getState());
    dispatch(
      addBroadcastInfoMessage({
        message,
        id: HAND_RAISED_NOTIFICATION_ID,
        timeout: 0,
      })
    );
  }
};

export const removeHandRaisedAlert: Effect = async (_, { dispatch }) => {
  dispatch(removeAppMessage(HAND_RAISED_NOTIFICATION_ID));
};
