import React, { FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from 'store';
import { removeAppMessage, selectLastBackendErrorAlert } from 'store/features/alerts';

import { ApiErrorAlert } from './ApiErrorAlert';
import { Wrapper } from './ApiErrorAlert.styled';

export const ApiErrorAlertContainer: FC = () => {
  const dispatch = useAppDispatch();
  const lastBackendAlert = useAppSelector(selectLastBackendErrorAlert);

  const handleClose = () => {
    if (!lastBackendAlert) return null;

    dispatch(removeAppMessage(lastBackendAlert.id));
  };

  return (
    <Wrapper>
      <AnimatePresence initial={false}>
        {lastBackendAlert && (
          <motion.div initial={{ y: '-100%' }} animate={{ y: 0, transition: { bounce: false } }} exit={{ y: '-100%' }}>
            <ApiErrorAlert message={lastBackendAlert.message} onClose={handleClose} />
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
