import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, Stack } from '@mui/material';
import { type FormStepProps } from 'common/components';
import { Button } from 'common/components/_legacy/Button';
import { ImageInput, InputWrapper, SwitchInput, TextInput } from 'common/components/_legacy/Form';

import { CommunityBasicsContainer } from './CommunityBasics.styled';

type CommunityBasicsProps = FormStepProps & {
  isEditMode: boolean;
  isLoading: boolean;
};

export const CommunityBasics: FC<CommunityBasicsProps> = ({ isEditMode, isLoading, nextStep }) => {
  const { formState } = useFormContext();

  return (
    <CommunityBasicsContainer>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <TextInput
            name="name"
            label="Community Name"
            placeholder="Enter Community name"
            hint="Input a name for your Community that reflects this group of users"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputWrapper
            name="calendarPermissionsRequired"
            label="Calendar Permissions"
            hint="Request calendar access permissions from users during registration to power Peer to Peer Insights"
            autoWidth
          >
            <SwitchInput
              name="calendarPermissionsRequired"
              label="Request calendar access from users during account setup"
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageInput
            accept=".png,.jpg,.jpeg"
            recommendedFormat="PNG"
            name="logo"
            label="Community Logo"
            hint="Upload a logo for your Community"
          />
        </Grid>
        <Grid item xs={12}>
          <Stack flexDirection="row-reverse">
            {isEditMode ? (
              <Button
                data-testid="CommunityWizard-submit"
                variant="contained"
                type="submit"
                isLoading={formState.isSubmitting || isLoading}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                data-testid="CommunityWizard-submit"
                variant="contained"
                onClick={() => {
                  nextStep?.();
                }}
                isLoading={isLoading}
              >
                Next Step
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </CommunityBasicsContainer>
  );
};
