import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { RootState } from 'store';
import { addAppAlertDialog, addAppErrorMessage, addAppInfoMessage, addAppSuccessMessage } from 'store/features/alerts';
import { handleQueryError } from 'store/utils';

import {
  InsightsReportStatus,
  createInsightsReportsRejectedEffect,
  createInsightsReportsSucceededEffect,
  insightsReportApi,
} from '../insightsReport';
import { eventsReportApi } from './eventsReportApi';

// TODO extract effects in next iteration
export const eventsReportApiLister = createListenerMiddleware<RootState>();

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.getEventsReportKeyMetrics.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, "Unable to fetch Events Report's key metrics");
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.retryEventsReportGeneration.matchFulfilled,
  effect: (_result, { dispatch }) => {
    dispatch(
      addAppAlertDialog({
        title: i18n.t('insightReport:wizard.retryEventsReportGeneration.title'),
        description: i18n.t('insightReport:wizard.retryEventsReportGeneration.description'),
        // TODO "Remove CTA and add a close icon? Jacopo Tortora d'Amato" - so soon will be removed
        confirmLabel: 'Got it',
      })
    );
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.retryEventsReportGeneration.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(
      error,
      dispatch,
      type,
      'Report creation unsuccessful. Please retry, or contact support@natter.co if the issue persists.'
    );
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.updateEventsReport.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    const { id, name } = meta.arg.originalArgs;
    const updateReportName = eventsReportApi.util.updateQueryData('getEventsReportById', { reportId: id }, (data) => {
      data.name = name;
    });
    dispatch(updateReportName);
    dispatch(addAppSuccessMessage(`${name} successfully updated`));
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.updateEventsReport.matchRejected,
  effect: ({ error, type, meta }, { dispatch }) => {
    const { name } = meta.arg.originalArgs;
    handleQueryError(error, dispatch, type, `Unable to update Report "${name}"`);
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.deleteEventsReport.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    const { name, status } = meta.arg.originalArgs;

    if (status === InsightsReportStatus.Error) {
      dispatch(addAppInfoMessage(`${name} successfully deleted`));
    } else {
      dispatch(
        addAppAlertDialog({
          title: 'Report Successfully Deleted',
          description: `Your Report ${name} was successfully deleted from the Reports List.`,
          confirmLabel: 'Ok',
        })
      );
    }

    dispatch(push(AppRoutes.InsightsReports));
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.deleteEventsReport.matchRejected,
  effect: ({ error, type, meta }, { dispatch }) => {
    const { name } = meta.arg.originalArgs;
    handleQueryError(error, dispatch, type, `Unable to delete Report "${name}"`);
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.createEventsReport.matchFulfilled,
  effect: createInsightsReportsSucceededEffect,
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.createEventsReport.matchRejected,
  effect: createInsightsReportsRejectedEffect,
});

eventsReportApiLister.startListening({
  matcher: isAnyOf(
    insightsReportApi.endpoints.renameThemes.matchFulfilled,
    insightsReportApi.endpoints.mergeThemes.matchFulfilled,
    insightsReportApi.endpoints.unmergeThemes.matchFulfilled,
    insightsReportApi.endpoints.updateThemesVisibility.matchFulfilled
  ),
  effect: ({ meta }, { dispatch }) => {
    dispatch(eventsReportApi.util.invalidateTags([{ type: 'REPORT_ANSWERS', id: meta.arg.originalArgs.reportId }]));
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.exportEventsReport.matchFulfilled,
  effect: (_, { dispatch }) => {
    dispatch(addAppInfoMessage('You will be notified via email once the export is complete'));
  },
});

eventsReportApiLister.startListening({
  matcher: eventsReportApi.endpoints.exportEventsReport.matchRejected,
  effect: (_, { dispatch }) => {
    dispatch(
      addAppErrorMessage(
        'There was a failure during the export process. Please try again, or contact support@natter.co if the issue persists.'
      )
    );
  },
});
