/**
 * Integrating websocket with Redux, inspired by this article: https://www.taniarascia.com/websockets-in-redux/
 */
import { Action, Middleware } from '@reduxjs/toolkit';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';
import type { RootState } from 'store';

import { addAppSuccessMessage } from '../alerts';
import * as actions from './actions';
import { Socket, SocketDisconnected, SocketEvents, SocketMessage, SocketPayload } from './socket';

const isThrottledMessage = ({ path, payload }: SocketMessage) =>
  (path.includes('ws_messages') || path.includes('throttled_messages')) && payload instanceof Array;

const getMessages = ({ path, payload }: SocketMessage) =>
  flow(
    groupBy<SocketPayload>('action' as keyof SocketPayload),
    mapValues<SocketPayload[], Omit<SocketPayload, 'action'>[]>((value) =>
      value.map((v) => {
        if (path.includes('ws_messages')) {
          return JSON.parse(v.params);
        }
        return v.params;
      })
    )
  )(payload);

export const socketMiddleware: (socket: Socket) => Middleware<{}, RootState> = (socket) => (params) => {
  const { dispatch } = params;

  socket.on(SocketEvents.connected, () => dispatch(actions.websocketConnected()));

  socket.on(SocketEvents.disconnected, ({ closedGracefully }: SocketDisconnected) => {
    if (closedGracefully) {
      dispatch(actions.websocketDisconnected());
      return;
    }
    dispatch(actions.websocketReconnecting());
  });

  socket.on(SocketEvents.reconnected, () => {
    dispatch(actions.websocketConnected());
    dispatch(addAppSuccessMessage('Reconnected to server'));
    dispatch(actions.websocketCleanupAfterReconnect());
  });

  socket.on(SocketEvents.channelSubscribed, (channelName: string) => {
    dispatch(actions.addConnectedRoom(channelName));
  });

  socket.on(SocketEvents.channelUnsubscribed, (channelName: string) => {
    dispatch(actions.removeConnectedRoom(channelName));
  });

  const handleWS = ({ path, payload }: SocketMessage) => {
    const messages = getMessages({ path, payload });

    Object.entries(messages).forEach(([key, value]) => {
      const action = `${path.split(':')[0]}:${key}`;

      value.forEach((v) => {
        dispatch({
          type: action,
          payload: v,
        });
      });
    });
  };

  socket.on(SocketEvents.newMessage, ({ path, payload }: SocketMessage) => {
    if (isThrottledMessage({ path, payload })) {
      handleWS({ path, payload });
    } else {
      dispatch({
        type: path,
        payload,
      });
    }
  });

  return (next) => async (action: Action) => {
    if (actions.websocketSubscribe.match(action)) {
      socket.subscribe(action.payload);
    } else if (actions.websocketUnsubscribe.match(action)) {
      socket.unsubscribe(action.payload);
    } else if (actions.websocketSend.match(action)) {
      socket.send(action.payload.path, action.payload.data, action.payload.plainBody);
    }

    return next(action);
  };
};
