import React, { ReactNode, createContext, useContext } from 'react';

import { assert } from 'common/utils';
import { keycloakService } from 'modules/keycloak/services';

export enum KeycloakRole {
  Admin = 'ADMIN',
}

interface KeycloakProviderProps {
  children: ReactNode;
}

const KeycloakContext = createContext<typeof keycloakService | undefined>(undefined);

const useKeycloakContext = () => {
  const ctx = useContext(KeycloakContext);
  assert(ctx, 'useKeycloakContext must be used inside KeycloakProvider');
  return ctx;
};

const KeycloakProvider = ({ children }: KeycloakProviderProps) => (
  <KeycloakContext.Provider value={keycloakService}>{children}</KeycloakContext.Provider>
);

export { KeycloakProvider, useKeycloakContext };
