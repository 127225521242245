import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { AvatarImage } from './AvatarImage';
import { useUserAvatarStyles } from './UserAvatar.styles';

export interface UserAvatarProps {
  data: {
    avatarLink?: string | null;
    name?: string;
    displayName?: string;
    email?: string;
  };
  displayAsCommunityAdmin?: boolean;
}

export const UserAvatar: FC<UserAvatarProps> = ({ data, displayAsCommunityAdmin }) => {
  const classes = useUserAvatarStyles();
  const name = (data.displayName || data.name) ?? 'Unknown';
  const avatarName = data.name || data.displayName || data.email || '';

  return (
    <div className={classes.container}>
      <AvatarImage
        avatarLink={data.avatarLink ?? undefined}
        avatarName={avatarName}
        isCommunityAdmin={displayAsCommunityAdmin}
      />
      <div className={classes.userNameWrapper}>
        <Typography variant="body2" className={classes.userName} title={data.displayName || data.name}>
          {name}
        </Typography>
      </div>
    </div>
  );
};
