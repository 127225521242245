import React, { FC, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { Tag, TagsCategory } from 'domain/Common';

import { MemberTagCategory, MemberTagCategoryProps } from './MemberTagsCategory';
import { getIsTagSelected } from './utils';

type MemberTagCategoryContainerProps = Pick<MemberTagCategoryProps, 'title' | 'required'> & {
  name: string;
  isSingleSelection: boolean;
};

export const MemberTagCategoryContainer: FC<MemberTagCategoryContainerProps> = ({
  name,
  isSingleSelection,
  ...props
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<Record<string, TagsCategory['tags']>>({ name });

  const handleTagSelect = useCallback(
    (selectedTag: Tag) => {
      const updatedTags = value.map((tag) => ({
        ...tag,
        isSelected: getIsTagSelected(tag, selectedTag, isSingleSelection),
      }));

      onChange(updatedTags);
    },
    [isSingleSelection, onChange, value]
  );

  return <MemberTagCategory tags={value} onTagSelect={handleTagSelect} error={error?.message} {...props} />;
};
