export const QuestionWSChannels = {
  public: '/question/:questionId/assignment/:assignmentId',
  private: '/user/question/:questionId/assignment/:assignmentId',
} as const;

export const QuestionsWSMessages = {
  CreateAnswer: 'create_answer',
  AssignToUserIds: 'assign_to_user_ids',
  AnonymiseAnswer: 'anonymise_answer',
  UpdateAnswer: 'update_answer',
  DeleteAnswer: 'delete_answer',
  QuestionAssignment: 'question_assignment',
  EnabledUntil: 'enabled_until',
  Disabled: 'disabled',
  AnswerAssignedToUserIds: 'answer_assigned_to_user_ids',
  AnswerAnonymised: 'answer_anonymised',
  AnswerCreated: 'answer_created',
  AnswerDeleted: 'answer_deleted',
  AnswerUpdated: 'answer_updated',
} as const;

export type QuestionsWSMessage = (typeof QuestionsWSMessages)[keyof typeof QuestionsWSMessages];

const QuestionsWSPath = '/question' as const;
const QuestionsAnswerWSPath = `${QuestionsWSPath}/answer` as const;

export const QuestionsWSActions = {
  AnswerCreate: `${QuestionsAnswerWSPath}/create`,
  AnswerAssignToUserIds: `${QuestionsAnswerWSPath}/assign_to_user_ids`,
  AnswerAnonymise: `${QuestionsAnswerWSPath}/anonymise`,
  AnswerUpdate: `${QuestionsAnswerWSPath}/update`,
  AnswerDelete: `${QuestionsAnswerWSPath}/delete`,
} as const;

export type QuestionsWSAction = (typeof QuestionsWSActions)[keyof typeof QuestionsWSActions];
