/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

import { blue, green, grey, orange, purple, red } from 'common/theme/palette';

import { Badge } from '../Badge';

export const variants = ['light', 'contained'] as const;

export type StatusIndicatorVariant = (typeof variants)[number];

export const colors = {
  /** grey */
  default: { color: grey[600], backgroundColor: grey[200], contained: grey[600] },
  /** blue */
  inprogress: { color: blue[700], backgroundColor: blue[50], contained: blue[700] },
  /** orange */
  moved: { color: orange[800], backgroundColor: orange[50], contained: orange[300] },
  /** purple */
  new: { color: purple[800], backgroundColor: purple[50], contained: purple[800] },
  /** red */
  removed: { color: red[700], backgroundColor: red[50], contained: red[700] },
  /** green */
  success: { color: green[800], backgroundColor: green[50], contained: green[600] },
} as const;

export type StatusIndicatorColor = keyof typeof colors;

type Props<Value extends string | number = string> =
  | {
      variant?: StatusIndicatorVariant;
      color: StatusIndicatorColor;
      value?: never;
      colorMapping?: never;
    }
  | {
      variant?: StatusIndicatorVariant;
      color?: never;
      value: Value;
      colorMapping: Record<Value, StatusIndicatorColor>;
    };

/**
 * @module **Natter Design System**
 *
 * **Status indicator**
 *
 * This is a visual indicator to highlight an item’s status
 * @link https://www.figma.com/file/En2gOztv0xR9rIjYFtdut5/Design-system?type=design&node-id=255-5548&mode=design&t=qe04rhbExnjUOphv-4
 *
 * @example
 * ```tsx
 * <StatusIndicator color="inprogress">In progress</StatusIndicator>
 *
 * <StatusIndicator value="ACTIVE" colorMapping={{ ACTIVE: 'success', DRAFT: 'moved', UNPUBLISHED: 'moved' }} />
 * ```
 *  */
export const StatusIndicator: FC<Props> = ({ children, color: initialColor, variant = 'light', value, ...props }) => {
  const isContained = variant === 'contained';
  const { color, backgroundColor, contained } =
    initialColor && Object.keys(colors).includes(initialColor)
      ? colors[initialColor]
      : props.colorMapping && value && colors[props.colorMapping[value]]
        ? colors[props.colorMapping[value]]
        : colors.default;

  return (
    <Badge
      backgroundColor={isContained ? contained : backgroundColor}
      color={isContained ? undefined : color}
      data-testid="StatusIndicator"
    >
      {children}
    </Badge>
  );
};
