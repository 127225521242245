import { type Components, type Theme } from '@mui/material';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      '&:last-of-type .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '&.MuiTableRow-hover:hover': {
        backgroundColor: palette.grey[100],
      },
    }),
  },
};
