import { add, isBefore, set } from 'date-fns';
import { TimeUnit } from 'domain/Common';

type Props = {
  dueBy: Date;
  dueTime: Date;
  reminderIntervalAmount: number;
  reminderIntervalTimeUnit: TimeUnit;
};

export const validateReminderInterval = (props: Props) => {
  const { dueBy, dueTime, reminderIntervalAmount, reminderIntervalTimeUnit } = props;
  const now = new Date();
  const dueDate = set(dueBy, {
    hours: dueTime.getHours(),
    minutes: dueTime.getMinutes(),
    seconds: dueTime.getSeconds(),
  });

  const firstReminderDate = add(now, {
    [reminderIntervalTimeUnit.toLowerCase()]: reminderIntervalAmount,
  });

  return isBefore(firstReminderDate, dueDate);
};
