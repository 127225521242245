import { type PaletteOptions as MuiPaletteOptions, lighten } from '@mui/material';
import { grey } from 'common/theme/palette';

export const DEFAULT_PRIMARY_COLOR = '#1570EF';

export const NatterColors = {
  blue: '#0052FF',
  purple: '#BA64FF',
  green: '#38E489',
  orange: '#FF9900',
  red: '#FD4041',
  grey: '#999999',
  greyLight: '#F6F6F6',
};

export const avatarColors = {
  communityAdmin: '#EEBC1D',
};

export const userRolesColors = {
  communityMember: '#F6F6F6',
  communityAdmin: '#612DBD33',
  custom: '#4390E133',
};

export const socialMediaColors = {
  facebook: '#3b5998',
  twitter: '#21a1f3',
  instagram: '#000000',
  linkedin: '#0d66c2',
};

export const customColors = {
  grey: '#3D3D3D',
  greyLight: NatterColors.greyLight,
  darkGrey: '#595959',
  darkGreySolid: '#A9A9A9',
  darkestGrey: '#111111',
  lightGrey: '#EDEDED',
  blue: NatterColors.blue,
  silver: '#B6B6B6',
  disabled: '#A5A5A5',
  greySolid: '#808080',
};

export const createPalette = (color = DEFAULT_PRIMARY_COLOR, isDarkMode = false): MuiPaletteOptions => ({
  primary: {
    main: color,
    light: lighten(color, 0.8),
  },
  extendedPrimary: {},
  secondary: {
    main: '#e74357',
  },
  error: {
    main: NatterColors.red,
    dark: NatterColors.red,
  },
  background: {
    default: isDarkMode ? '#000' : '#fff',
  },
  customRed: {
    main: NatterColors.red,
    light: 'rgba(253, 64, 65, 0.1)',
  },
  customGreen: {
    main: '#00A091',
    light: 'rgba(56, 228, 137, 0.1)',
  },
  divider: isDarkMode ? '#3D3D3D' : '#EDEDED',
  custom: {
    ...customColors,
    cardBackground: isDarkMode ? '#121212' : NatterColors.greyLight,
  },
  customGray: {
    main: '#474747',
    light: 'rgba(17, 17, 17, 0.4)',
  },
  legacy: {
    chetwodeBlue: '#8884D8',
    texasRose: '#FFC14C',
    lightgray: '#d3d3d3',
    offBlack: '#303030',
    night: '#232323',
    alto: '#D9D9D9',
    redAlpha: 'rgba(255,0,0,0.5)',
    greenSolid: '#008000',
    redSolid: '#FF0000',
  },
  contrastThreshold: 2.38,
  grey,
});

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    customGreen: Palette['primary'];
    customRed: Palette['primary'];
    custom: {
      grey: string;
      greyLight: string;
      darkGrey: string;
      darkestGrey: string;
      lightGrey: string;
      blue: string;
      silver: string;
      disabled: string;
      greySolid: string;
      darkGreySolid: string;
      cardBackground: string;
    };
    legacy: Record<string, string>;
  }
  interface PaletteOptions {
    customRed: PaletteOptions['primary'];
    customGreen: PaletteOptions['primary'];
    custom: {
      grey: string;
      greyLight: string;
      darkGrey: string;
      darkestGrey: string;
      lightGrey: string;
      blue: string;
      silver: string;
      disabled: string;
      greySolid: string;
      darkGreySolid: string;
      cardBackground: string;
    };
    legacy: Record<string, string>;
  }
  interface Palette {
    customGray: Palette['primary'];
  }
  interface PaletteOptions {
    customGray: PaletteOptions['primary'];
  }
}
