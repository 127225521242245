import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Stack, Typography, alpha, styled } from '@mui/material';

interface InputContainerProps {
  selected: boolean;
  dragging: boolean;
  withError: boolean;
}

export const InputContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'dragging' && prop !== 'withError',
})<InputContainerProps>(({ theme: { spacing, palette }, selected, dragging, withError }) => ({
  minHeight: 200,
  gap: spacing(2),
  alignItems: 'center',
  padding: spacing(4),
  borderRadius: spacing(0.5),
  border: '1px dashed',
  borderColor: alpha(palette.primary.main, 0.3),
  position: 'relative',
  transition: 'background-color 200ms',
  backgroundColor: alpha(palette.primary.main, 0.05),
  justifyContent: 'space-between',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: alpha(palette.primary.main, 0.1),
  },
  ...(selected && {
    minHeight: 'initial',
    padding: spacing(2),
    border: '1px solid',
    borderColor: palette.custom.lightGrey,
    backgroundColor: 'initial',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '&:hover': {
      backgroundColor: 'initial',
    },
  }),
  ...(dragging && {
    backgroundColor: alpha(palette.primary.main, 0.1),
  }),
  ...(withError && {
    borderColor: alpha(palette.error.main, 0.3),
    backgroundColor: alpha(palette.error.main, 0.1),
    '&:hover': {
      backgroundColor: alpha(palette.error.main, 0.1),
    },
  }),
}));

export const FileInput = styled('input')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  opacity: 0,
  cursor: 'pointer',
});

export const SelectedImage = styled(LazyLoadImage)(({ theme: { spacing, palette } }) => ({
  pointerEvents: 'none',
  position: 'relative',
  height: 88,
  width: 88,
  border: '1px solid',
  borderColor: palette.custom.lightGrey,
  borderRadius: spacing(0.5),
  objectFit: 'contain',
}));

export const SelectedImageName = styled(Typography)({
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
