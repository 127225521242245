import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import {
  newChatApprovedMessages_WS,
  newChatMessages_WS,
  newChatPendingMessages_WS,
  newChatRejectedMessages_WS,
} from './actions';
import {
  newApprovedMessagesEffect,
  newMessagesEffect,
  newPendingMessageEffect,
  newRejectedMessagesEffect,
} from './effects';

export const chatListener = createListenerMiddleware<RootState>();

chatListener.startListening({
  actionCreator: newChatMessages_WS,
  effect: newMessagesEffect,
});

chatListener.startListening({
  actionCreator: newChatPendingMessages_WS,
  effect: newPendingMessageEffect,
});

chatListener.startListening({
  actionCreator: newChatApprovedMessages_WS,
  effect: newApprovedMessagesEffect,
});

chatListener.startListening({
  actionCreator: newChatRejectedMessages_WS,
  effect: newRejectedMessagesEffect,
});
