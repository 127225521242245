import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { isDefined } from 'common/utils';

import type { AlertContext, AlertData } from './types';

export const alertsAdapter = createEntityAdapter<AlertData>({
  selectId: (alert) => alert.id,
});

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertsAdapter.getInitialState(),
  reducers: {
    addAppMessage: (state, action: PayloadAction<AlertData>) => {
      const { message, id } = action.payload;
      const existingAlert =
        alertsAdapter.getSelectors().selectById(state, id) ??
        Object.values(state.entities).find((alert) => alert?.message === message);
      if (existingAlert) {
        alertsAdapter.removeOne(state, existingAlert.id);
      }
      alertsAdapter.addOne(state, action.payload);
    },
    removeAppMessage: alertsAdapter.removeOne,
    removeAlertsMatchingContext: (state, action: PayloadAction<AlertContext>) => {
      const alertsToRemove = Object.values(state.entities)
        .filter((alert) => alert?.context === action.payload)
        .map((alert) => alert?.id)
        .filter(isDefined);
      alertsAdapter.removeMany(state, alertsToRemove);
    },
  },
});
