import { createAction } from '@reduxjs/toolkit';

import { EventWizardFormSerializedValues } from '../validation/schema';

export const eventWizardActions = {
  initialized: createAction<{ id?: number; isRepeat?: boolean }>('[Event Wizard] initialized'),
  exitButton: {
    clicked: createAction('[Event Wizard] exit button clicked'),
  },
  backButton: {
    clicked: createAction('[Event Wizard] back button clicked'),
  },
  nextButton: {
    clicked: createAction('[Event Wizard] next button clicked'),
  },
  saveChangesButton: {
    clicked: createAction('[Event Wizard] save button clicked'),
  },
  cancelButton: {
    clicked: createAction('[Event Wizard] cancel button clicked'),
  },
  eventCreated: createAction('[Event Wizard] event created'),
  progressTracker: {
    stepClicked: createAction<number>('[Event Wizard Progress Tracker] step clicked'),
  },
  form: {
    validity: {
      changed: createAction<boolean>('[Event Wizard Form] validity changed'),
    },
  },
  details: {
    videoDropzone: {
      fileSelected: createAction('[Event Wizard Details Video Dropzone] file selected'),
      uploadCanceled: createAction('[Event Wizard Details Video Dropzone] upload canceled'),
      uploadCompleted: createAction('[Event Wizard Details Video Dropzone] upload completed'),
      uploadFailed: createAction<string>('[Event Wizard Details Video Dropzone] upload failed'),
    },
  },
  summary: {
    eventDetails: {
      editButton: {
        clicked: createAction('[Event Wizard Summary Event Details] edit button clicked'),
      },
    },
    entrySurvey: {
      editButton: {
        clicked: createAction('[Event Wizard Summary Entry Survey] edit button clicked'),
      },
    },
    nattersSetup: {
      editButton: {
        clicked: createAction('[Event Wizard Summary Natters Setup] edit button clicked'),
      },
    },
    exitSurvey: {
      editButton: {
        clicked: createAction('[Event Wizard Summary Exit Survey] edit button clicked'),
      },
    },
    additionalSettings: {
      changed: createAction('[Event Wizard Summary Additional Settings] changed'),
    },
    createEventButton: {
      clicked: createAction<EventWizardFormSerializedValues>('[Event Wizard Summary] create event button clicked'),
    },
    updateEventButton: {
      clicked: createAction<EventWizardFormSerializedValues>('[Event Wizard Summary] update event button clicked'),
    },
  },
  eventCreationModal: {
    closed: createAction('[Event Wizard Event Creation Modal] closed'),
  },
  eventLimitReachedModal: {
    confirmedButton: {
      clicked: createAction('[Event Wizard Event Limit Reached Modal] confirmed button clicked'),
    },
  },
};
