import { PaginatedApiPayload } from 'domain/Common';
import { isArray, mapValues, snakeCase } from 'lodash';

/**
 * Transforms a paginated API payload to ensure backwards compatibility.
 * [NAT-4275](https://natterco.atlassian.net/browse/NAT-4275)
 *
 * @template T - The type of data in the payload.
 * @param {PaginatedApiPayload<T>} response - The paginated API response to transform.
 * @returns {PaginatedApiPayload<T>} The transformed paginated API response.
 */
export const transformPaginatedApiPayload = <T = unknown>(response: PaginatedApiPayload<T>): PaginatedApiPayload<T> => {
  if (!response) {
    return response;
  }

  const transformedResponse: PaginatedApiPayload<T> = { ...response };

  if (response.content) {
    transformedResponse.data = response.content;
  }

  return transformedResponse;
};

type WithData = {
  data: unknown;
};

type WithValidationErrors = {
  validationErrors: { [key: string]: string[] };
};

type WithErrors = {
  errors: { message: string; errorId: string; stackTrace: string; metadata: unknown }[];
};

type ValidationError = {
  data: {
    validationErrors: { [key: string]: string[] };
  };
};

export const hasValidationErrors = (obj: unknown): obj is WithValidationErrors =>
  (obj as WithValidationErrors)?.validationErrors !== undefined;

export const hasErrors = (obj: unknown): obj is WithErrors => (obj as WithErrors)?.errors !== undefined;

export const hasData = (obj: unknown): obj is WithData => (obj as WithData)?.data !== undefined;

export const isValidationError = (obj: unknown): obj is ValidationError =>
  hasData(obj) && hasValidationErrors(obj.data);

/** The API requires arrays to be joined by comma, and has new pagination request so this mapper converts old request params into new one
 *
 * @example
 * ```
 * // old
 * {
 *   sortOrder: 'asc' | 'desc',
 *   sortBy: 'displayName',
 * }
 * // new
 * {
 *   sortDir: 'ASC' | 'DESC',
 *   sortParam: 'DISPLAY_NAME',
 *   tagIds: 123,456
 * }
 * ```
 */
export const mapToApiParams = <P extends Record<string, unknown>>(params: P) =>
  mapValues(params, (value, key) => {
    if (key === 'sortParam' && typeof value === 'string') return snakeCase(value).toUpperCase();
    if (key === 'sortDir' && typeof value === 'string') return value.toUpperCase();
    if (isArray(value)) return value.join(',');
    return value;
  });
