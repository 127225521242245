import React, { FC, FocusEventHandler } from 'react';
import { useController } from 'react-hook-form';

import { InlineEditorInputProps, InlineEditorInput as InlineEditorInputRaw } from 'common/components/Inputs';

type Props = {
  name: string;
} & InlineEditorInputProps;

export const InlineEditorInput: FC<Props> = ({ name, defaultValue, onBlur, ...props }) => {
  const {
    field: { value, onChange, ref, onBlur: formOnBlur, disabled },
    fieldState: { error },
  } = useController({ name, defaultValue });

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    formOnBlur();
    onBlur?.(event);
  };

  return (
    <InlineEditorInputRaw
      error={!!error}
      onChange={onChange}
      value={value}
      disabled={disabled}
      inputRef={ref}
      {...props}
      onBlur={handleBlur}
    />
  );
};
