import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import type { TagsCategory as TagsCategoryType } from 'domain/Common';

import { TagsCategory } from './TagsCategory';

interface TagsCategoryPreviewProps {
  item: TagsCategoryType;
}

export const TagsCategoryPreview: FC<TagsCategoryPreviewProps> = ({ item }) => {
  const methods = useForm({
    defaultValues: {
      item,
    },
  });

  return (
    <FormProvider {...methods}>
      <TagsCategory name="item" shadow />
    </FormProvider>
  );
};
