import type { Components, Theme } from '@mui/material';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 4,
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    }),
  },
};
