import React, { PropsWithChildren, ReactNode } from 'react';
import { useController } from 'react-hook-form';

import { SelectProps } from '@mui/material';
import { OptionItem, Select as SelectInput } from 'common/components/Inputs/Select';

type SelectInputProps<T> = Pick<
  SelectProps<T>,
  'label' | 'placeholder' | 'required' | 'defaultValue' | 'displayEmpty' | 'disabled' | 'size' | 'startAdornment'
> & {
  name: string;
  options: OptionItem<T>[];
  shouldShowError?: boolean;
  'data-testid'?: string;
  onChange?(value: T, child: ReactNode): void;
};

export const Select = <T,>({
  name,
  required,
  defaultValue,
  onChange: onChangeEffect,
  ...props
}: PropsWithChildren<SelectInputProps<T>>) => {
  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({ name, defaultValue, rules: { required } });

  return (
    <SelectInput
      {...props}
      name={name}
      onChange={(e, child) => {
        onChange(e, child);
        onChangeEffect?.(e.target.value as T, child);
      }}
      value={value}
      error={error}
      selectRef={ref}
    />
  );
};
