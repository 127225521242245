import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type WithCallback = {
  callback(): void;
};

type WithParentUrl = {
  parentUrl: string;
};

type Props = WithCallback | WithParentUrl;

export const useNavigation = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { callback } = props as Partial<WithCallback>;
  const { parentUrl } = props as Partial<WithParentUrl>;

  const navigateBack = useCallback(() => {
    if (location.key) {
      // location.key means user routed in-app
      return history.goBack();
    }
    if (callback) {
      return callback();
    }
    if (parentUrl) {
      history.push(parentUrl);
    }
  }, [location, history, callback, parentUrl]);

  return {
    navigateBack,
  };
};
