import React, { forwardRef } from 'react';

import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';

export type SwitchProps = Pick<
  MuiSwitchProps,
  'aria-label' | 'checked' | 'defaultChecked' | 'disabled' | 'id' | 'name' | 'size' | 'onChange' | 'value' | 'sx'
>;

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => <MuiSwitch ref={ref} {...props} />);
