import { useEffect, useRef } from 'react';

export const useUnmountingRef = () => {
  const isUnmountingRef = useRef(false);

  useEffect(
    () => () => {
      isUnmountingRef.current = true;
    },
    []
  );

  return isUnmountingRef;
};
