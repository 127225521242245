import React, { PropsWithChildren, ReactNode, RefObject, forwardRef, memo, useEffect, useRef } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { Box, Grid, SxProps, Theme } from '@mui/material';
import classNames from 'classnames';

import { useSortableItemStyles } from './SortableItem.styles';
import { useDragAndDrop, useSortableItem } from './hooks';

interface SortableItemProps {
  droppableType: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  canDrag?: boolean;
  className?: string;
  contentClassName?: string;
  sx?: SxProps<Theme> | undefined;
  isGridItem?: boolean;
  itemData?: object;
  collectItemData?: (index: number) => object;
  customPreview?: boolean;
  onDrop?(endIndex: number): void;
  onDragStart?(): void;
  children: ReactNode | ((props: { handlerRef: RefObject<HTMLDivElement>; isDragging: boolean }) => ReactNode);
}

export const SortableItem = memo(
  forwardRef<unknown, PropsWithChildren<SortableItemProps>>(
    (
      {
        droppableType,
        index,
        moveItem,
        canDrag = true,
        className,
        contentClassName,
        sx,
        children,
        isGridItem = false,
        itemData,
        collectItemData,
        customPreview,
        onDrop,
        onDragStart,
      },
      forwardedRef
    ) => {
      const classes = useSortableItemStyles();
      const ref = useRef<HTMLDivElement>(null);
      const startIndexRef = useRef<number>();
      const handlerRef = useRef<HTMLDivElement>(null);

      const { isDragging, handlerId, preview } = useDragAndDrop({
        index,
        canDrag,
        itemData: collectItemData ? collectItemData(index) : itemData,
        previewRef: ref,
        handlerRef,
        droppableType,
        customPreview,
        moveItem,
      });

      useSortableItem({ onDrop, onDragStart, isDragging, startIndexRef, index });

      useEffect(() => {
        if (customPreview) {
          preview(getEmptyImage(), { captureDraggingState: true });
        }
      }, [customPreview, preview]);

      const renderChildren = () => (typeof children === 'function' ? children({ handlerRef, isDragging }) : children);

      return (
        <Box
          component={isGridItem ? Grid : Box}
          item={isGridItem || undefined}
          className={classNames(isDragging && classes.dragged, className)}
          ref={ref}
          data-handler-id={handlerId}
          data-testid={`SortableItem-${index}`}
          sx={sx}
        >
          <Box ref={forwardedRef} className={contentClassName}>
            {renderChildren()}
          </Box>
        </Box>
      );
    }
  )
);
