import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getItemsCountLabel } from 'common/utils';
import type { RootState } from 'store';
import { addAppSuccessMessage } from 'store/features/alerts';
import { handleQueryError } from 'store/utils';

import { insightsReportApi } from './insightsReportApi';

// TODO extract effects in next iteration
export const insightsReportApiLister = createListenerMiddleware<RootState>();

insightsReportApiLister.startListening({
  matcher: insightsReportApi.endpoints.mergeThemes.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    dispatch(addAppSuccessMessage(`${getItemsCountLabel('Theme', meta.arg.originalArgs.themeIds.length)} merged`));
  },
});

insightsReportApiLister.startListening({
  matcher: insightsReportApi.endpoints.unmergeThemes.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    dispatch(addAppSuccessMessage(`${getItemsCountLabel('Theme', meta.arg.originalArgs.themeIds.length)} unmerged`));
  },
});

insightsReportApiLister.startListening({
  matcher: insightsReportApi.endpoints.renameThemes.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    const renamedThemes = Object.keys(meta.arg.originalArgs.themeIdToNewName);
    dispatch(addAppSuccessMessage(`${getItemsCountLabel('Theme', renamedThemes.length)} renamed`));
  },
});

insightsReportApiLister.startListening({
  matcher: insightsReportApi.endpoints.updateThemesVisibility.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    const themesCount = Object.values(meta.arg.originalArgs.themeIdsToHideStatus).length;
    const hiddenThemesCount = Object.values(meta.arg.originalArgs.themeIdsToHideStatus).filter(
      (isHidden) => isHidden
    ).length;
    const message =
      hiddenThemesCount === 0
        ? 'All Themes are now visible'
        : `${hiddenThemesCount} out of ${themesCount} Themes ${hiddenThemesCount === 1 ? 'is' : 'are'} now hidden`;
    dispatch(addAppSuccessMessage(message));
  },
});

insightsReportApiLister.startListening({
  matcher: insightsReportApi.endpoints.validateReportPin.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, 'Unable to validate PIN');
  },
});
