import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { orange } from 'common/theme/palette';
import { isCommunityAdmin } from 'common/utils';
import { CommunityMember } from 'domain/Community';

import { Badge } from '../Badge';
import { UserAvatar } from '../UserAvatar';
import { MemberTagsTooltip } from './components';

type Props = {
  member: Partial<CommunityMember>;
};

export const MemberAvatar: FC<Props> = ({ member }) => (
  <MemberTagsTooltip member={member}>
    <Stack direction="row" gap={1} alignItems="center">
      <UserAvatar avatarLink={member.avatarLink} name={member.displayName || 'Unknown'} dimensions={24} />
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {member.displayName || 'Unknown'}
      </Typography>
      {isCommunityAdmin(member) && <Badge backgroundColor={orange[300]}>ADMIN</Badge>}
    </Stack>
  </MemberTagsTooltip>
);
