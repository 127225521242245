import { initReactI18next } from 'react-i18next';

import { isProduction } from 'common/utils';
import i18n from 'i18next';
import { defaultNS, ns, resources } from 'locales/en';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: !isProduction(),
  defaultNS,
  resources,
  ns,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
