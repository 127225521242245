import React, { FC } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { Alert, AlertVariant } from 'common/components';
import { Switch } from 'common/components/ReactHookForm';
import { DevicesDefaultFormValues } from 'devices/types';

import { DevicesSection } from '../DevicesSection';

type Props = {
  disabled?: boolean;
  onChange?(): void;
};

export const BlurBackgroundSwitch: FC<Props> = ({ disabled, onChange }) => {
  const { t } = useTranslation('devices', { keyPrefix: 'videoBlur' });
  const isBlurEnabled = useWatch<DevicesDefaultFormValues, 'isBlurEnabled'>({ name: 'isBlurEnabled' });
  const isCameraEnabled = useWatch<DevicesDefaultFormValues, 'isCameraEnabled'>({ name: 'isCameraEnabled' });
  const showBlurWarning = isIOS && isBlurEnabled;
  const showBroadcastBlurWarning = isSafari && !showBlurWarning && isBlurEnabled;

  return (
    <DevicesSection
      title={
        <Stack gap={1} width="100%">
          <Switch
            sx={{
              justifyContent: 'space-between',
              '& .MuiFormControlLabel-label': {
                padding: 0,
              },
            }}
            labelPlacement="start"
            size="small"
            label={t('label')}
            data-testid="MediaSettings-BlurCheckbox"
            name="isBlurEnabled"
            disabled={disabled || !isCameraEnabled}
            onChangeEffect={onChange}
          />
          {showBlurWarning && (
            <Alert sx={{ marginTop: 1 }} variant={AlertVariant.Warning}>
              <b>{t('negativeImpactWarningTitle')}</b>
              <ul style={{ marginTop: 4, marginBottom: 0 }}>
                {t('negativeImpactWarningDetails', { returnObjects: true }).map((hint, index) => (
                  <li key={index}>{hint}</li>
                ))}
              </ul>
            </Alert>
          )}
          {showBroadcastBlurWarning && (
            <Alert sx={{ marginTop: 1 }} variant={AlertVariant.Warning}>
              {t('notSupportedWarning')}
            </Alert>
          )}
        </Stack>
      }
    />
  );
};
