import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

export const useNavigationBlocker = ({
  when,
}: {
  when: boolean;
}): {
  showPrompt: boolean;
  handleCancel(): void;
  handleConfirm(): void;
} => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const unblockRef = useRef<() => void>();

  const handleCancel = useCallback(() => {
    setShowPrompt(false);
  }, []);

  useEffect(() => {
    // @ts-expect-error fix: history.block argument of type TransitionPromptHook should return type string | false | void
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current?.();
    };
  }, [history, when]);

  const handleConfirm = useCallback(() => {
    if (unblockRef.current) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }, [currentPath, history]);

  return {
    showPrompt,
    handleCancel,
    handleConfirm,
  };
};
