import type { Components, Theme } from '@mui/material';

export const MuiTableContainer: Components<Theme>['MuiTableContainer'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderRadius: '10px 10px 0 0',
    },
  },
};
