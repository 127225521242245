import React, { FC, useMemo } from 'react';

import {
  Bar,
  CartesianGrid,
  LabelList,
  Legend,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT, legendWrapperStyle } from '../../constants';
import { useChartAxis, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateChartMargins, calculateTicks, createTickFormatter, getColor } from '../../utils';

export const BarChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
  legendFormatter,
  isPercentageValue,
}) => {
  const { getChartClass } = useChartStyles();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const { ticks, isLastTickEqualToMaxValue } = useMemo(() => calculateTicks({ data, dataKeys }), [data, dataKeys]);
  const { activeKey, handleMouseEnter } = useTooltip();

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <RechartsBarChart
        barCategoryGap={1}
        barGap={2}
        data={data}
        margin={calculateChartMargins({ labelNames, labelValues })}
        layout="horizontal"
        className={getChartClass('vertical', isLastTickEqualToMaxValue)}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} axis="X" />}
          tickMargin={16}
          interval={0}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          interval={0}
          domain={[0, 'dataMax']}
          allowDataOverflow
          ticks={ticks}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          tickFormatter={createTickFormatter(isPercentageValue)}
          width={40}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && (
          <Legend verticalAlign="top" align="right" formatter={legendFormatter} wrapperStyle={legendWrapperStyle} />
        )}
        {dataKeys.map((key, index) => {
          const color = getColor(index);
          return (
            <Bar key={key} dataKey={key} fill={color} onMouseEnter={handleMouseEnter(key)}>
              <LabelList dataKey={key} fill={color} content={BarChartLabel} />
            </Bar>
          );
        })}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};
