import type { Components, Theme } from '@mui/material';

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      padding: spacing(3, 2, 5, 2),
      gap: spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    }),
  },
};
