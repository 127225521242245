import i18n from 'i18n';
import { InferType, boolean, object, string } from 'yup';

export const defaultDevicesSchema = object({
  microphoneId: string().required(i18n.t('devices:defaultDevicesSchema.microphoneError')).default(''),
  isMicrophoneEnabled: boolean().required().default(true),
  cameraId: string().required(i18n.t('devices:defaultDevicesSchema.cameraError')).default(''),
  isCameraEnabled: boolean().required().default(true),
  isBlurEnabled: boolean().required().default(false),
  speakerId: string().required(i18n.t('devices:defaultDevicesSchema.speakerError')).default(''),
});

export type DevicesDefaultFormValues = InferType<typeof defaultDevicesSchema>;
