import { createApi } from '@reduxjs/toolkit/query/react';
import { type ImageFile } from 'common/components/_legacy/Form';
import { assert, getImageFormData } from 'common/utils';
import { Account } from 'domain/Account';
import { isEmpty } from 'lodash';
import { mapAccountFromApi } from 'mappers/account';
import { getApiBaseUrl } from 'modules/api/utils';
import type { RootState } from 'store';

import axiosBaseQuery from '../_axiosBaseQuery';
import { selectUserAccount } from './selectors';
import { UpdateProfilePayload } from './types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v2') }),
  tagTypes: ['ACCOUNT'],
  endpoints: (builder) => ({
    getAccount: builder.query<Account, void>({
      keepUnusedDataFor: 600,
      providesTags: ['ACCOUNT'],
      query: () => ({
        url: `/account/me`,
        params: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      }),
      transformResponse: mapAccountFromApi,
    }),
    updateAccount: builder.mutation<Account, Pick<Account, 'firstName' | 'lastName'>>({
      query: (data) => ({
        url: `/account/me`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['ACCOUNT'],
    }),
    updateAccountAvatar: builder.mutation<void, { avatar: ImageFile; userId: number }>({
      query: ({ avatar, userId }) => ({
        url: `/account/${userId}/avatar`,
        method: 'PUT',
        data: getImageFormData(avatar, 'avatar'),
      }),
      invalidatesTags: ['ACCOUNT'],
    }),
    deleteAccountAvatar: builder.mutation<void, { userId: number }>({
      query: ({ userId }) => ({
        url: `/account/${userId}/avatar`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ACCOUNT'],
    }),
    updateProfile: builder.mutation<void, UpdateProfilePayload>({
      queryFn: async (data, { dispatch, getState }) => {
        const state = getState() as RootState;
        const account = selectUserAccount(state);
        assert(account);

        await dispatch(userApi.endpoints.updateAccount.initiate(data)).unwrap();

        if ('name' in data.avatarLink) {
          await dispatch(
            userApi.endpoints.updateAccountAvatar.initiate({
              userId: account.id,
              avatar: data.avatarLink as ImageFile,
            })
          ).unwrap();
        } else if (account.avatarLink && isEmpty(data.avatarLink)) {
          await dispatch(userApi.endpoints.deleteAccountAvatar.initiate({ userId: account.id })).unwrap();
        }

        return { data: undefined };
      },
    }),
  }),
});

export const {
  useGetAccountQuery,
  useUpdateAccountMutation,
  useUpdateAccountAvatarMutation,
  useDeleteAccountAvatarMutation,
  useUpdateProfileMutation,
} = userApi;
