import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { STEPS } from 'pages/EventWizard/constants';
import { eventApi } from 'store/apis/event/eventApi';

import { INITIAL_STEPS, INITIAL_STEPS_FOR_REPEAT } from './constants';
import { eventWizardActions } from './eventWizard.actions';
import { EventWizardState } from './types';

export const initialState: EventWizardState = {
  isLoading: false,
  form: {
    mode: 'create',
    isDirty: false,
  },
  progressTracker: {
    activeStep: STEPS.EventDetails,
    steps: INITIAL_STEPS,
  },
};

export const eventWizardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(eventWizardActions.initialized, (_, { payload: { id, isRepeat } }) => {
      const isNewEvent = !id || isRepeat;

      return {
        ...initialState,
        eventId: isNewEvent ? undefined : id,
        form: {
          ...initialState.form,
          mode: isNewEvent ? 'create' : 'edit',
        },
        progressTracker: {
          steps: isRepeat ? INITIAL_STEPS_FOR_REPEAT : INITIAL_STEPS,
          activeStep: isNewEvent ? STEPS.EventDetails : STEPS.Summary,
        },
      };
    })
    .addCase(eventWizardActions.backButton.clicked, (state) => {
      if (state.progressTracker.activeStep <= 0) {
        return state;
      }

      state.progressTracker.activeStep--;
    })
    .addCase(eventWizardActions.nextButton.clicked, (state) => {
      if (state.progressTracker.activeStep >= state.progressTracker.steps.length - 1) {
        return state;
      }

      state.progressTracker.steps[state.progressTracker.activeStep].isCompleted = true;
      state.progressTracker.steps[state.progressTracker.activeStep].isInProgress = false;

      state.progressTracker.steps[state.progressTracker.activeStep + 1].isDisabled = false;
      state.progressTracker.steps[state.progressTracker.activeStep + 1].isInProgress = true;

      for (let i = state.progressTracker.activeStep + 2; i < state.progressTracker.steps.length; i++) {
        state.progressTracker.steps[i].isDisabled = !state.progressTracker.steps[i].isCompleted;
      }
      if (state.progressTracker.steps[state.progressTracker.steps.length - 2].isCompleted) {
        state.progressTracker.steps[state.progressTracker.steps.length - 1].isDisabled = false;
      }

      state.progressTracker.activeStep++;
    })
    .addCase(eventWizardActions.progressTracker.stepClicked, (state, action) => {
      state.progressTracker.activeStep = action.payload;
    })
    .addCase(eventWizardActions.summary.eventDetails.editButton.clicked, (state) => {
      state.form.mode = 'edit';
      state.progressTracker.activeStep = STEPS.EventDetails;
    })
    .addCase(eventWizardActions.summary.entrySurvey.editButton.clicked, (state) => {
      state.form.mode = 'edit';
      state.progressTracker.activeStep = STEPS.EntrySurvey;
    })
    .addCase(eventWizardActions.summary.nattersSetup.editButton.clicked, (state) => {
      state.form.mode = 'edit';
      state.progressTracker.activeStep = STEPS.NattersSetup;
    })
    .addCase(eventWizardActions.summary.exitSurvey.editButton.clicked, (state) => {
      state.form.mode = 'edit';
      state.progressTracker.activeStep = STEPS.ExitSurvey;
    })
    .addCase(eventWizardActions.summary.additionalSettings.changed, (state) => {
      state.form.isDirty = true;
    })
    .addCase(eventWizardActions.eventCreated, (state) => ({
      ...initialState,
      createdEvent: state.createdEvent,
    }))
    .addCase(eventWizardActions.eventCreationModal.closed, (state) => {
      state.createdEvent = undefined;
    })
    .addCase(eventWizardActions.saveChangesButton.clicked, (state) => {
      if (!state.eventId) {
        state.form.mode = 'create';
      }
      state.form.isDirty = true;
      state.progressTracker.activeStep = STEPS.Summary;
    })
    .addCase(eventWizardActions.cancelButton.clicked, (state) => {
      if (!state.eventId) {
        state.form.mode = 'create';
      }
      state.progressTracker.activeStep = STEPS.Summary;
    })
    .addCase(eventWizardActions.form.validity.changed, (state, { payload: isFormValid }) => {
      if (isFormValid) {
        state.progressTracker.steps = state.progressTracker.steps.map((step, i) => ({
          ...step,
          isDisabled: i === 0 ? false : !step.isCompleted && !step.isInProgress,
        }));
        if (state.progressTracker.steps[state.progressTracker.steps.length - 2].isCompleted) {
          state.progressTracker.steps[state.progressTracker.steps.length - 1].isDisabled = false;
        }
      } else {
        state.progressTracker.steps = state.progressTracker.steps.map((step) => ({
          ...step,
          isDisabled: true,
        }));
      }
    })
    .addMatcher(
      isAnyOf(
        eventWizardActions.details.videoDropzone.uploadCanceled,
        eventWizardActions.details.videoDropzone.uploadCompleted,
        eventWizardActions.details.videoDropzone.uploadFailed,
        eventApi.endpoints.createEvent.matchRejected,
        eventApi.endpoints.postEventImage.matchRejected,
        eventApi.endpoints.createEventQuestions.matchRejected
      ),
      (state) => {
        state.progressTracker.steps = state.progressTracker.steps.map((step, i) => ({
          ...step,
          isDisabled: i === 0 ? false : !step.isCompleted,
        }));
        if (state.progressTracker.steps[state.progressTracker.steps.length - 2].isCompleted) {
          state.progressTracker.steps[state.progressTracker.steps.length - 1].isDisabled = false;
        }
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        eventApi.endpoints.createEvent.matchPending,
        eventWizardActions.details.videoDropzone.fileSelected,
        eventApi.endpoints.updateEvent.matchPending,
        eventApi.endpoints.postEventImage.matchPending,
        eventApi.endpoints.createEventQuestions.matchPending
      ),
      (state) => {
        state.progressTracker.steps = state.progressTracker.steps.map((step) => ({
          ...step,
          isDisabled: true,
        }));
        state.isLoading = true;
      }
    )
    .addMatcher(eventApi.endpoints.createEvent.matchFulfilled, (state, action) => {
      state.createdEvent = action.payload;
    })
    .addMatcher(
      isAnyOf(
        eventWizardActions.exitButton.clicked,
        eventApi.endpoints.updateEvent.matchFulfilled,
        eventWizardActions.eventLimitReachedModal.confirmedButton.clicked
      ),
      () => initialState
    );
});
