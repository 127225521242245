import { Drawer, styled } from '@mui/material';

export const DRAWER_WIDTH = 248;

// Note: zIndex.appBar - 1 to handle sidebar under Navigation Bar
export const MobileDrawer = styled(Drawer)(({ theme: { zIndex } }) => ({
  zIndex: zIndex.appBar - 1,
}));

export const DesktopDrawer = styled(Drawer)(({ theme: { zIndex }, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': { width: DRAWER_WIDTH },
  zIndex: open ? zIndex.appBar - 1 : undefined,
}));
