import { makeStyles } from '@mui/styles';

export const usePageInProgressStyles = makeStyles(
  (theme) => ({
    container: {
      width: '100%',
      height: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
    icon: {
      color: theme.palette.primary.main,
    },
  }),
  { name: 'pageInProgress' }
);
