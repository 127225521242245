import { isValid, secondsToMilliseconds, toDate } from 'date-fns';
import { DateISOString } from 'domain/Common';
import { isArray, isObject } from 'lodash';

type ObjectLike = Record<string, unknown>;

const DEFAULT_DATE_FIELDS = ['createdAt', 'updatedAt', 'date'] as const;

/**
 * Serializes a date field value to an ISO string format.
 *
 * - If the value is already a valid Date object, it is converted to an ISO string.
 * - If the value is a number representing seconds since the Unix epoch, it is converted to a Date object and then to an ISO string.
 * - If the value is a string representing a valid date, it is converted to a Date object and then to an ISO string.
 * - If the value is not a Date, number, or valid date string, it is returned as is.
 *
 * @example ```ts
 * serializeDateField(new Date('2022-01-01')); // out 2022-01-01T00:00:00.000Z
 * serializeDateField('2022-01-01'); // out 2022-01-01T00:00:00.000Z
 * serializeDateField(1704067200); // out 2024-01-01T00:00:00.000Z
 * serializeDateField('John Doe'); // out John Doe
 * ```
 */
export const serializeDateField = <T = unknown>(value: T): DateISOString | T => {
  if (value instanceof Date && isValid(value)) {
    return value.toISOString();
  }

  if (typeof value === 'number' && isValid(secondsToMilliseconds(value))) {
    return toDate(secondsToMilliseconds(value)).toISOString();
  }

  if (typeof value === 'string' && isValid(new Date(value))) {
    return new Date(value).toISOString();
  }

  return value;
};

/**
 * Serializes specified date fields in an object to DateISOString e.g. `2024-04-16T08:42:50.181Z`.
 *
 * By default serialize `createdAt` and `updatedAt`
 *
 * @example ```ts
 * serializeDateFields('dueBy')({ dueBy: new Date('2022-01-01') }) // out { dueBy: '2022-01-01T00:00:00.000Z' }
 * serializeDateFields()({ createdAt: '2022-01-01' }) // out { createdAt: '2022-01-01T00:00:00.000Z' }
 * serializeDateFields()({ id: 1, name: 'John Doe' }) // out { id: 1, name: 'John Doe' }
 * ```
 */
export const serializeDateFields =
  <T extends ObjectLike, K extends keyof T = keyof T>(...keys: K[]) =>
  (obj: T): T =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      if ([...DEFAULT_DATE_FIELDS, ...keys].includes(key as K)) {
        return Object.assign(acc, { [key]: serializeDateField(value) });
      }

      if (isArray(value)) {
        return Object.assign(acc, { [key]: value.map(serializeDateFields()) });
      }

      if (isObject(value) && Object.keys(value).length) {
        return Object.assign(acc, { [key]: serializeDateFields()(value as ObjectLike) });
      }

      return Object.assign(acc, { [key]: value });
    }, {} as T);
