import { useEffect } from 'react';

import { useTimeout } from 'common/hooks';
import { useAppDispatch } from 'store';
import { SnackBarAlertData, removeAppMessage } from 'store/features/alerts';

export const DEFAULT_AUTO_HIDE_DURATION = 5_000;

export const useSnackbarAutoHide = (
  alertData: SnackBarAlertData,
  duration: number | null = DEFAULT_AUTO_HIDE_DURATION
) => {
  const dispatch = useAppDispatch();

  const hide = () => {
    dispatch(removeAppMessage(alertData.id));
  };

  const { reset } = useTimeout(hide, duration);

  useEffect(() => {
    reset();
  }, [reset, alertData]);

  return { hide };
};
