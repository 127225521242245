import { useEffect } from 'react';
import type { FieldPath, UseFormSetValue } from 'react-hook-form';

import { type DevicesDefaultFormValues, defaultDevicesSchema } from 'devices/constants';
import { useAppSelector } from 'store';
import {
  selectActiveAudioInputDevice,
  selectActiveAudioOutputDevice,
  selectActiveVideoDevice,
  selectIsAudioEnabled,
  selectIsBlurEnabled,
  selectIsVideoEnabled,
  selectMediaDevicesLoaded,
} from 'store/features/user';

type Props = {
  setValue: UseFormSetValue<DevicesDefaultFormValues>;
  watchFields?: FieldPath<DevicesDefaultFormValues>[];
};

export const useDevicesFormAutoUpdate = ({
  setValue,
  watchFields = Object.keys(defaultDevicesSchema.fields) as FieldPath<DevicesDefaultFormValues>[],
}: Props) => {
  const isReady = useAppSelector(selectMediaDevicesLoaded);
  const activeAudioInputDevice = useAppSelector(selectActiveAudioInputDevice);
  const activeAudioOutputDevice = useAppSelector(selectActiveAudioOutputDevice);
  const activeVideoDevice = useAppSelector(selectActiveVideoDevice);
  const isAudioEnabled = useAppSelector(selectIsAudioEnabled);
  const isVideoEnabled = useAppSelector(selectIsVideoEnabled);
  const isBlurEnabled = useAppSelector(selectIsBlurEnabled);

  useEffect(() => {
    if (!isReady || !watchFields.includes('microphoneId')) return;

    setValue('microphoneId', activeAudioInputDevice?.deviceId ?? '', { shouldValidate: true });
  }, [setValue, activeAudioInputDevice, isReady, watchFields]);

  useEffect(() => {
    if (!isReady || !watchFields.includes('isMicrophoneEnabled')) return;

    setValue('isMicrophoneEnabled', isAudioEnabled);
  }, [setValue, isAudioEnabled, isReady, watchFields]);

  useEffect(() => {
    if (!isReady || !watchFields.includes('speakerId')) return;

    setValue('speakerId', activeAudioOutputDevice?.deviceId ?? '', { shouldValidate: true });
  }, [setValue, activeAudioOutputDevice, isReady, watchFields]);

  useEffect(() => {
    if (!isReady || !watchFields.includes('cameraId')) return;

    setValue('cameraId', activeVideoDevice?.deviceId ?? '', { shouldValidate: true });
  }, [setValue, activeVideoDevice, isReady, watchFields]);

  useEffect(() => {
    if (!isReady || !watchFields.includes('isCameraEnabled')) return;

    setValue('isCameraEnabled', isVideoEnabled);
  }, [setValue, isVideoEnabled, isReady, watchFields]);

  useEffect(() => {
    if (!isReady || !watchFields.includes('isBlurEnabled')) return;

    setValue('isBlurEnabled', isBlurEnabled ?? false);
  }, [setValue, isBlurEnabled, isReady, watchFields]);
};
