import { Stack, SvgIconTypeMap, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { blue, green, grey, orange, red } from 'common/theme/palette';
import { omitForwardedProps } from 'common/utils';
import { CheckFilledIcon, ErrorFilledIcon, InfoFilledIcon, WarningFilledIcon } from 'icons';

import { AlertVariant, AlertVariantType } from './types';

export const iconsMap: Record<AlertVariantType, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
  [AlertVariant.Success]: CheckFilledIcon,
  [AlertVariant.Error]: ErrorFilledIcon,
  [AlertVariant.Warning]: WarningFilledIcon,
  [AlertVariant.Info]: InfoFilledIcon,
  [AlertVariant.InfoGray]: InfoFilledIcon,
  [AlertVariant.Transparent]: InfoFilledIcon,
};

type Props = {
  variant?: AlertVariantType;
};

export const AlertWrapper = styled(Stack, {
  shouldForwardProp: omitForwardedProps<Props>('variant'),
})<Props>(({ theme: { spacing, typography }, variant }) => ({
  flexDirection: 'row',
  gap: spacing(2),
  padding: spacing(2),
  border: 1,
  ...typography['natter-text-sm'],

  ...(variant === AlertVariant.Info && {
    backgroundColor: blue[50],

    '.MuiSvgIcon-root': {
      color: blue[600],
    },
  }),
  ...(variant === AlertVariant.Error && {
    backgroundColor: red[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: red[600],
    },
  }),
  ...(variant === AlertVariant.Warning && {
    backgroundColor: orange[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: orange[600],
    },
  }),
  ...(variant === AlertVariant.InfoGray && {
    backgroundColor: grey[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: grey[800],
    },
  }),
  ...(variant === AlertVariant.Success && {
    backgroundColor: green[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: green[600],
    },
  }),
  ...(variant === AlertVariant.Transparent && {
    backgroundColor: 'white',
    color: 'inherit',
    borderColor: grey[200],
    border: '1px solid',

    '& .MuiSvgIcon-root': {
      color: grey[800],
    },
  }),
}));
