import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme: { palette, typography } }) => ({
  '& .MuiInputBase-root': {
    width: 48,
    height: 48,
    backgroundColor: palette.common.white,

    '& input': {
      ...typography['natter-display-xs'],
      textAlign: 'center',
    },
  },
}));
