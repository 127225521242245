import React, { ComponentType } from 'react';
import { useDragLayer } from 'react-dnd';

import { Box } from '@mui/material';
import { getItemStyles } from 'common/components/DragAndDrop/getDraggableItemStyles';

type Props<T> = {
  droppableType: string;
  Preview: ComponentType<{ item: T; isPreview?: boolean }>;
};

export const CustomDragLayer = <T,>({ Preview, droppableType }: Props<T>): JSX.Element | null => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div style={getItemStyles(initialOffset, currentOffset, item.width)}>
        {itemType === droppableType ? <Preview item={item} isPreview /> : null}
      </div>
    </Box>
  );
};
