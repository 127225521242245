/* istanbul ignore file */
import TagManager from 'react-gtm-module';

export const initGtm = () => {
  const tagManagerId = window._env_.TAG_MANAGER_ID;
  const tagManagerAuth = window._env_.TAG_MANAGER_AUTH;
  const tagManagerPreview = window._env_.TAG_MANAGER_PREVIEW;

  const tagManagerArgs = {
    gtmId: tagManagerId,
    auth: tagManagerAuth,
    preview: tagManagerPreview,
  };

  if (Object.values(tagManagerArgs).every(Boolean)) {
    TagManager.initialize(tagManagerArgs);
  }
};
