import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { Autocomplete, Avatar, Chip, TextField } from '@mui/material';
import { CsvDropzone } from 'common/components/CsvDropzone';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';
import { isArray } from 'lodash';
import { concat, flow, prop, uniqBy } from 'lodash/fp';

import { UserEmailFormValues } from './schema';
import { getInvalidEmailError } from './utils';

const FIELD_ID = 'email' as const;
type Person = Record<string, unknown> & { [FIELD_ID]: string };
const PERSONS_TEMPLATE = [FIELD_ID, 'john.doe@example.com', 'simple@example.com'].join('\n');

type Props = {
  name: string;
  noOptionsText?: string;
  templateFileName?: string;
};

export const EmailInput: FC<Props> = ({
  name,
  noOptionsText = 'No users found',
  templateFileName = 'Users list - template.csv',
}) => {
  const [options, setOptions] = useState<Person[]>([]);
  const {
    field: { value: users, onChange },
    fieldState: { error },
  } = useController<{ [key: string]: UserEmailFormValues }>({ name, defaultValue: [] });
  const invalidEmailError = getInvalidEmailError(error);
  const errorMessage = error?.message ?? invalidEmailError?.message;
  const handleFileUploaded = useCallback(
    (data: Person[]) => {
      setOptions(flow(concat(data), uniqBy(FIELD_ID))(users));
    },
    [users]
  );

  useEffect(() => {
    if (!options.length) return;

    onChange(options);
  }, [onChange, options]);

  const handleChange = (event: SyntheticEvent<Element, Event>, newValues: Array<string | Person>) => {
    const newValue = newValues.flatMap((item) => {
      if (typeof item === 'string') {
        return item.split(',').map((value) => ({ [FIELD_ID]: value.trim() }));
      }

      return [item];
    });

    onChange(uniqBy(FIELD_ID, newValue));
  };

  return (
    <>
      <Autocomplete
        value={users}
        onChange={handleChange}
        limitTags={10}
        multiple
        options={options}
        disableCloseOnSelect
        getOptionLabel={prop(FIELD_ID)}
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error && <FormFieldHint error>{errorMessage}</FormFieldHint>}
            placeholder="Enter email address"
            sx={{
              '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                minWidth: 130,
              },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option[FIELD_ID] === value[FIELD_ID]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              avatar={<Avatar alt={option[FIELD_ID]} />}
              data-testid="EmailInput-chip"
              label={option[FIELD_ID]}
              size="small"
              color={isArray(error) && error[index] ? 'error' : undefined}
              {...getTagProps({ index })}
            />
          ))
        }
      />

      <CsvDropzone<Person>
        onFileLoaded={handleFileUploaded}
        template={PERSONS_TEMPLATE}
        templateFileName={templateFileName}
        requiredFields={[FIELD_ID]}
      />
    </>
  );
};
