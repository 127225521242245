import { InferType, array, object, string } from 'yup';

export const pinInputSchema = object({
  pin: array(
    object({
      value: string().required(),
    })
  ).required(),
});

export type PinInputFormValues = InferType<typeof pinInputSchema>;
