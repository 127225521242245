import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { CircularProgress, ButtonProps as MaterialButtonProps } from '@mui/material';

import { mapButtonSizeToLoaderSize } from './utils/mapButtonSizeToLoaderSize';

export type ButtonProps = Pick<
  MaterialButtonProps,
  | 'children'
  | 'disabled'
  | 'fullWidth'
  | 'href'
  | 'size'
  | 'startIcon'
  | 'endIcon'
  | 'sx'
  | 'type'
  | 'variant'
  | 'tabIndex'
  | 'onClick'
  | 'ref'
  | 'form'
  | 'disableRipple'
> & {
  color?: 'primary' | 'secondary' | 'info' | 'error';
  externalHref?: string;
  isLoading?: boolean;
};

// This is necessary because otherwise we get a console error from MUI as it uses ref
const LinkComponent = React.forwardRef<HTMLAnchorElement, LinkProps>(({ to, ...props }, ref) => (
  <Link innerRef={ref} to={to} {...props} />
));

export const Button: FC<ButtonProps> = ({
  children,
  externalHref,
  href,
  isLoading = false,
  size = 'medium',
  variant = 'contained',
  ...props
}) => (
  // @ts-expect-error props are incompatible but MUI passes them down
  <LoadingButton
    href={externalHref}
    loadingIndicator={<CircularProgress color="inherit" size={mapButtonSizeToLoaderSize(size)} />}
    LinkComponent={href ? LinkComponent : undefined}
    loading={isLoading}
    size={size}
    target={externalHref ? '_blank' : undefined}
    to={href}
    variant={variant}
    {...props}
  >
    {/**
     * This is required because MUI renders the children even when loading
     * and this way the width of the button does not match the designs
     */}
    {!isLoading ? children : null}
  </LoadingButton>
);
