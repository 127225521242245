import { createAction } from '@reduxjs/toolkit';

export const communityActions = {
  communityAccount: {
    memberSetupRequired: createAction('[Community Account] member setup required'),
  },
  community: {
    registeredToCommunity: createAction<{ communityId: number }>('[Community] registered to community'),
  },
};
