import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { mapEventRoomFromApi } from 'mappers/event';

import { meetingRoomSearchSubscribed_WS } from './actions';
import { GetRoomsParams, RoomsSearchState } from './types';

export const roomsSearchInitialState: RoomsSearchState = {
  params: {
    searchPhrase: '',
    page: 1,
    pageSize: 9,
  },
  isLoading: false,
  results: {
    data: [],
    totalCount: 0,
    searchPhrase: '',
  },
};

export const roomsSearchSlice = createSlice({
  name: 'roomsSearch',
  initialState: roomsSearchInitialState,
  reducers: {
    setRoomsSearchParams: (state, { payload }: PayloadAction<Partial<GetRoomsParams>>) => {
      state.params = {
        ...state.params,
        ...payload,
      };
    },
    setRoomsSearchIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(meetingRoomSearchSubscribed_WS, (state, { payload }) => {
      state.results.searchPhrase = payload.searchPhrase;
      state.results.data = payload.rooms.data.map((d) => mapEventRoomFromApi(d));
      state.results.totalCount = payload.rooms.totalCount;
      state.isLoading = false;
    }),
});

export const { setRoomsSearchParams, setRoomsSearchIsLoading } = roomsSearchSlice.actions;
