export const TaskStepperStatuses = {
  Completed: 'COMPLETED',
  InProgress: 'IN_PROGRESS',
  Incomplete: 'INCOMPLETE',
  NotStarted: 'NOT_STARTED',
} as const;

export type TaskStepperStatus = (typeof TaskStepperStatuses)[keyof typeof TaskStepperStatuses];

export type Step = {
  title: string;
  description?: string;
  status: TaskStepperStatus;
};
