import { Stack, styled } from '@mui/material';

export const DevicesSectionRoot = styled(Stack)(({ theme: { palette, shape } }) => ({
  border: '1px solid',
  borderColor: palette.grey[200],
  borderRadius: shape.borderRadius,
}));

export const DevicesSectionSummary = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DevicesSectionTitleWrapper = styled(Stack)(({ theme: { palette, spacing } }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  gap: spacing(1),
  padding: spacing(2),
  backgroundColor: palette.grey[25],
}));

export const DevicesSectionDetails = styled(Stack)(({ theme: { spacing, palette } }) => ({
  padding: spacing(2),
  gap: spacing(2),
  borderTop: '1px solid',
  borderColor: palette.grey[200],
}));
