import { ChatMessage, ChatMessageBasic, ChatMessageStatus } from 'domain/Chat';
import differenceWith from 'lodash/differenceWith';

export const excludeMessages = (excludeFrom: ChatMessage[], toExclude: ChatMessageBasic[], status: ChatMessageStatus) =>
  differenceWith(excludeFrom, toExclude, (a, b) => a.id === b.id && a.status === status);

export const mapMessagesWithStatus = ({
  messages,
  status,
}: {
  messages: ChatMessageBasic[];
  status: ChatMessageStatus;
}) =>
  messages.map(
    (messageItem): ChatMessage => ({
      ...messageItem,
      timestamp: 'timestamp' in messageItem ? messageItem.timestamp : Date.now(),
      isNew: true,
      status,
    })
  );
