import { makeStyles } from '@mui/styles';

export const useTagsSelectStyles = makeStyles(
  () => ({
    tagWrapper: {
      maxWidth: '100%',
    },
  }),
  { name: 'tagsSelect' }
);
