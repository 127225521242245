import React, { FC } from 'react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorBox } from 'common/components';
import { WithMediaAccessInfo } from 'devices';

type Props = Pick<WithMediaAccessInfo, 'mediaAccess'> & {
  fields: string[];
  devices?: ('camera' | 'microphone')[];
};

export const DevicesErrorBox: FC<Props> = ({ mediaAccess, fields, devices = ['camera', 'microphone'] }) => {
  const { t } = useTranslation('devices', { keyPrefix: 'errorBox' });
  const { errors } = useFormState();

  const errorsArray = Object.entries(errors)
    .filter(([key]) => fields.includes(key))
    .map(([, value]) => value);

  const title = (() => {
    if (!mediaAccess) {
      return t('noMediaAccess', { devices });
    }
    if (errorsArray.length === 1) {
      return errorsArray[0]?.message;
    }
    if (errorsArray.length > 1) {
      return t('multipleErrors');
    }
    return '';
  })();

  return <ErrorBox error={title} />;
};
