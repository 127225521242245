import React, { type ComponentType, type FC, type ReactElement, useCallback, useEffect } from 'react';

import { useAppDispatch } from 'store';
import { userActions } from 'store/features/user';

export const withDevices =
  <P extends { isCheckingMediaAccess: boolean }>(
    WrappedComponent: ComponentType<P & { isCheckingMediaAccess: boolean }>
  ): FC<Partial<P>> =>
  ({ isCheckingMediaAccess, ...props }): ReactElement => {
    const dispatch = useAppDispatch();

    const checkDevices = useCallback(() => {
      dispatch(userActions.navigator.mediaDevices.devicesChanged());
    }, [dispatch]);

    useEffect(() => {
      if (isCheckingMediaAccess) {
        return;
      }

      checkDevices();

      navigator.mediaDevices.addEventListener('devicechange', checkDevices);

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', checkDevices);
      };
    }, [checkDevices, isCheckingMediaAccess]);

    return <WrappedComponent {...(props as P)} isCheckingMediaAccess={isCheckingMediaAccess} />;
  };
