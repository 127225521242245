import React, { FC } from 'react';

import { SxProps, Typography } from '@mui/material';

import { ActionsWrapper, Wrapper } from './CameraTest.styled';
import { type UseCameraProps, useCameraTest } from './hooks';

type Props = UseCameraProps & { isCameraEnabled: boolean; sx?: SxProps };

export const CameraTest: FC<Props> = ({ videoDeviceId, isCameraEnabled, blurLevel, children, sx }) => {
  const { videoElementRef } = useCameraTest({ blurLevel, videoDeviceId, isCameraEnabled });

  return (
    <Wrapper data-testid="CameraTest" aria-label="Camera preview" aria-hidden="true" sx={sx}>
      {!isCameraEnabled && <Typography variant="natter-text-xl">Camera is Off</Typography>}

      <video
        ref={videoElementRef}
        style={{ display: isCameraEnabled ? 'block' : 'none' }}
        data-testid="CameraTest-video"
      />

      <ActionsWrapper>{children}</ActionsWrapper>
    </Wrapper>
  );
};
