import { Avatar, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

import { LogoProps } from '../Logo';
import CommunityLogoBackground from './CommunityLogoBackground.svg';

export const CommunityLogoAvatar = styled(Avatar, {
  name: 'CommunityLogoAvatar',
  shouldForwardProp: omitForwardedProps('brandingColor'),
})<Pick<LogoProps, 'size'> & { brandingColor?: string }>(({ theme: { palette }, brandingColor, size }) => ({
  fontWeight: 600,
  color: palette.common.white,
  width: 56,
  height: 56,
  background: `${brandingColor} url(${CommunityLogoBackground}) no-repeat center`,
  backgroundSize: 'cover',

  ...(size === 'small' && {
    width: 24,
    height: 24,
  }),
}));
