import type { Choice } from './Choice';
import { CreateAnswerVariants } from './Variants';

/** @deprecated after shifting to api v2 replace this type with QuestionVariants (maybe rename to QuestionType) to be aligned with BE */
export enum AnswerTypes {
  SingleChoice = 'SINGLE_CHOICE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  Text = 'OPEN',
  Ranking = 'RANKING',
  NPS = 'NPS',
  Slider = 'SLIDER',
}

export type AnswerBase = {
  isObligatory: boolean;
  answerId: string;
  questionId: number;
  assignmentId: string;
  type: CreateAnswerVariants;
};

export type TextAnswer = AnswerBase & {
  text: string | undefined;
};

export type MultipleChoiceSingleSelectAnswer = AnswerBase & {
  choiceId: Choice['id'] | undefined | null;
};

export type MultipleChoiceMultipleSelectAnswer = AnswerBase & {
  choices: (Choice & { value: boolean })[];
};

export type RankingAnswer = AnswerBase & {
  choices: (Choice & { rank: string | '' })[];
};

export type NPSAnswer = AnswerBase & {
  npsValue: number;
};

export type SliderAnswer = AnswerBase & {
  sliderValue: number | null;
};

export type Answer =
  | TextAnswer
  | MultipleChoiceSingleSelectAnswer
  | MultipleChoiceMultipleSelectAnswer
  | RankingAnswer
  | NPSAnswer
  | SliderAnswer;
export type QuestionAssignmentAnswerType = 'TextAnswer' | 'MultipleChoiceSingleSelectAnswer';
export type QuestionAssignmentAnswer = {
  type: QuestionAssignmentAnswerType;
  uuid: string;
  assignedToUserIds: number[];
  text: string;
  updatedByUserId?: number;
  isAnonymous: boolean;
};

export const isTextAnswer = (question: Answer): question is TextAnswer =>
  question?.type === CreateAnswerVariants.TextQuestion;

export const isMultipleChoiceSingleSelectAnswer = (question: Answer): question is MultipleChoiceSingleSelectAnswer =>
  question?.type === CreateAnswerVariants.MultipleChoiceSingleSelectQuestion;

export const isMultipleChoiceMultipleSelectAnswer = (
  question: Answer
): question is MultipleChoiceMultipleSelectAnswer =>
  question?.type === CreateAnswerVariants.MultipleChoiceMultipleSelectQuestion;

export const isRankingAnswer = (question: Answer): question is RankingAnswer =>
  question?.type === CreateAnswerVariants.RankingQuestion;

export const isNPSAnswer = (question: Answer): question is NPSAnswer =>
  question?.type === CreateAnswerVariants.NPSQuestion;

export const isSliderAnswer = (question: Answer): question is SliderAnswer =>
  question?.type === CreateAnswerVariants.Slider;
