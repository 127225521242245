import { type Components, type Theme, alpha } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    type: 'button',
    variant: 'contained',
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      height: 44,
      padding: spacing(1.25, 6),
      borderRadius: 100,
      textTransform: 'none',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    }),
    contained: ({ theme: { palette } }) => ({
      '&:disabled': {
        backgroundColor: palette.custom.disabled,
        color: palette.common.white,
      },
    }),
    startIcon: ({ theme: { spacing } }) => ({
      marginRight: spacing(2),
    }),
    endIcon: ({ theme: { spacing } }) => ({
      marginLeft: spacing(0.5),
    }),
    sizeSmall: ({ theme: { spacing } }) => ({
      height: 32,
      padding: spacing(0.5, 3),
      fontSize: 14,
    }),
    sizeLarge: ({ theme: { spacing } }) => ({
      height: 48,
      padding: spacing(0.5, 3),
      fontSize: 14,
    }),
    outlined: ({ theme: { palette } }) => ({
      borderColor: palette.mode === 'light' ? palette.divider : palette.custom.darkGrey,
      '&&.active': {
        backgroundColor: palette.divider,
        borderColor: palette.legacy.redSolid,
      },
    }),
    text: ({ theme: { spacing } }) => ({
      height: 'unset',
      padding: '4px 8px !important',
      margin: 0,
      color: 'inherit',
      fontSize: 12,
      minWidth: 'unset',
      borderRadius: spacing(1),
    }),
  },
  variants: [
    {
      props: { variant: 'link' },
      style: ({ theme: { typography, palette, spacing } }) => ({
        ...typography.caption,
        fontWeight: 'bold',
        fontSize: 14,
        color: palette.primary.main,
        minWidth: 'auto',
        height: 'auto',
        border: 'none',
        padding: spacing(0.5),
        margin: spacing(0, -0.5),
        borderRadius: spacing(0.5),
      }),
    },
    {
      props: { variant: 'underline' },
      style: ({ theme: { palette } }) => ({
        height: 'unset',
        padding: '0 !important',
        margin: 0,
        textDecoration: 'none',
        color: palette.text.primary,
        '&:hover': {
          textDecoration: 'underline',
          backgroundColor: 'unset',
        },
      }),
    },
    {
      props: { variant: 'contained-light' },
      style: ({ theme: { palette, spacing } }) => ({
        fontWeight: 'bold',
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
        backgroundColor: alpha(palette.primary.main, 0.1),
        borderColor: alpha(palette.primary.main, 0.1),
        color: palette.primary.main,
        transition: 'backgroundColor 0.3s ease, color 0.3s ease',

        '&:disabled': {
          backgroundColor: palette.custom.darkGreySolid,
          borderColor: palette.custom.darkGreySolid,
          color: palette.common.white,
        },
        '&:hover': {
          backgroundColor: alpha(palette.primary.main, 0.2),
          borderColor: alpha(palette.primary.main, 0.2),
        },
      }),
    },
    {
      props: { variant: 'outlined-primary' },
      style: ({ theme: { palette } }) => ({
        backgroundColor: 'transparent',
        borderColor: palette.grey[200],
        boxSizing: 'border-box',
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.grey[200],
        },
      }),
    },
    {
      props: { variant: 'outlined-contained' },
      style: ({ theme: { palette } }) => ({
        backgroundColor: palette.mode === 'light' ? palette.background.default : palette.custom.grey,
        border: '1px solid',
        borderColor: palette.divider,
      }),
    },
  ],
};

declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsColorOverrides {
    grey?: true;
    danger?: true;
  }
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    square?: true;
    link?: true;
    underline?: true;
    'outlined-contained'?: true;
    'outlined-primary'?: true;
    'contained-light'?: true;
    grayed?: true;
  }
}
