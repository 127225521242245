import React, { PropsWithChildren, ReactNode } from 'react';

import { Stack } from '@mui/material';
import { Loadable, PagePicker } from 'common/components';
import type { Columns } from 'common/components/_legacy/Table';
import type { SortOrder } from 'domain/Common';
import { GetCommunityMembersParams } from 'store/apis/community';

import { MembersTable } from './components';
import type { MemberInfo } from './types';

const getEmptyTableText = ({ tags, showAdmins, searchPhrase }: GetCommunityMembersParams) => {
  if (tags.length || showAdmins || searchPhrase) {
    return 'Sorry, we couldn’t find any Members matching your selected filters.';
  }

  return 'No Members found';
};

export function Members<T extends MemberInfo>({
  children,
  data,
  totalPages,
  isLoading,
  headerOverlay,
  queryParams,
  rowsPerPage,
  createTableSchema,
  onOrderByChange,
  onOrderChange,
  onCurrentPageChange,
  onRowsPerPageChange,
}: PropsWithChildren<{
  isLoading: boolean;
  data: T[];
  totalPages: number;
  queryParams: GetCommunityMembersParams;
  headerOverlay?: ReactNode;
  rowsPerPage?: number;
  createTableSchema(baseTableSchema: Columns<T>): Columns<T>;
  onOrderByChange(orderBy: string): void;
  onOrderChange(order: SortOrder): void;
  onCurrentPageChange(page: number): void;
  onRowsPerPageChange?(rowsPerPage: number): void;
}>) {
  return (
    <Stack gap={3}>
      {children}
      <Stack gap={3} alignItems="center" pb={6}>
        <Loadable isLoading={isLoading} backdrop>
          <MembersTable
            communityMembers={data}
            order={queryParams.sortOrder}
            orderBy={queryParams.sortBy}
            emptyTableText={getEmptyTableText(queryParams)}
            onOrderByChange={onOrderByChange}
            onOrderChange={onOrderChange}
            headerOverlay={headerOverlay}
            createTableSchema={createTableSchema}
          />
        </Loadable>
        <PagePicker
          currentPage={queryParams.page}
          isLoading={isLoading}
          onChange={onCurrentPageChange}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Stack>
    </Stack>
  );
}
