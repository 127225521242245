import { Box, Stack, styled } from '@mui/material';

export const InactiveIndicator = styled(Box)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.common.white,
  border: `${spacing(1)} solid ${palette.grey[100]}`,
  borderRadius: '50%',
  boxSizing: 'content-box',
  height: spacing(1),
  width: spacing(1),
}));

export const ActiveIndicatorContainer = styled(Box)(({ theme: { palette, spacing } }) => ({
  border: `${spacing(0.5)} solid ${palette.grey[100]}`,
  borderRadius: '50%',
  left: spacing(-0.5),
  position: 'absolute',
  top: spacing(-0.5),
}));

export const ActiveIndicator = styled(InactiveIndicator)(({ theme: { palette } }) => ({
  borderColor: palette.primary.main,
}));

export const CompletedIconContainer = styled(Stack)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.success.main,
  borderRadius: '50%',
  color: palette.common.white,
  height: spacing(3),
  width: spacing(3),
}));
