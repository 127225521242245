import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { GroupsOutlinedIcon } from 'icons';

export type GuestListProps = {
  amount: number;
};

export const GuestsCounter: FC<GuestListProps> = ({ amount }) => (
  <Stack flexDirection="row" alignItems="center" gap={1}>
    <GroupsOutlinedIcon fontSize="small" />
    <Typography>{amount}</Typography>
  </Stack>
);
