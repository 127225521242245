import type { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: '16px',
    },
    shrink: ({ theme: { palette } }) => ({
      fontWeight: 'bold',
      color: palette.text.primary,
    }),
  },
};
