import { format } from 'date-fns';
import { Timestamp } from 'domain/Common';

const secondsInMinute = 60;
const secondsInHour = 60 * secondsInMinute;
const secondsInDay = 24 * secondsInHour;

export const formatDuration = (valueInSeconds: number | undefined): string => {
  if (!valueInSeconds) {
    return '00:00';
  }

  const d = Math.floor(valueInSeconds / secondsInDay);
  const seconds = valueInSeconds - d * secondsInDay;
  const h = Math.floor(seconds / secondsInHour);
  const rest = seconds - h * secondsInHour;
  const m = Math.floor(rest / secondsInMinute);
  const s = Math.ceil(rest - m * secondsInMinute);
  const durationParts: (string | number)[] = [];
  if (valueInSeconds >= secondsInDay) {
    durationParts.push(d < 10 ? `0${d}` : d);
  }
  if (valueInSeconds >= secondsInHour) {
    durationParts.push(h < 10 ? `0${h}` : h);
  }
  durationParts.push(m < 10 ? `0${m}` : m);
  durationParts.push(s < 10 ? `0${s}` : s);
  return durationParts.join(':');
};

export const timeFormat = 'hh:mm a';
export const fullDayFormat = 'EEE dd MMM yyyy';
export const monthFormat = 'MMMM yyyy';
const startTimeFormat = `EEE d MMM, ${timeFormat}`;

export const formatDateTimeRange = (startDate: string | Date, endDate: string | Date): string =>
  `${format(new Date(startDate), startTimeFormat)} - ${format(new Date(endDate), timeFormat)}`;

/**
 * [NAT-3840]
 * Convert timestamp from nano to milliseconds
 * @param  {Timestamp} timestamp
 */
export const toTimestamp = (timestamp: Timestamp) => (Number.isInteger(timestamp) ? timestamp : timestamp * 1000);

export const toUtcDayStart = (date: Date) => {
  const datePart = format(date, 'yyyy-MM-dd');
  return `${datePart}T00:00:00Z`;
};
