import { useCallback, useState } from 'react';

import { useElementSize } from 'common/hooks';

type LegendRef = {
  lastBoundingBox: {
    width: number | undefined;
    height: number | string;
  };
};

type Props = {
  initialHeight: number | string;
};

export const usePieAutoSizing = ({ initialHeight }: Props) => {
  const [legendSize, setLegendSize] = useState<LegendRef['lastBoundingBox']>({
    width: undefined,
    height: initialHeight,
  });
  const [wrapperRef, wrapperSize] = useElementSize();
  const height =
    typeof initialHeight === 'number' && typeof legendSize?.height === 'number'
      ? Math.max(legendSize.height, initialHeight)
      : initialHeight;
  const innerRadius =
    typeof height === 'number' ? (Math.min(wrapperSize.width - (legendSize?.width ?? 0), height) * 0.7) / 2 : '70%';
  const outerRadius =
    typeof height === 'number' ? Math.min(wrapperSize.width - (legendSize?.width ?? 0), height) / 2 : '100%';

  const legendRef = useCallback((legend: unknown) => {
    if (legend === null) return;
    if (!(legend as LegendRef)?.lastBoundingBox) return;

    setLegendSize((legend as LegendRef).lastBoundingBox);
  }, []);

  return {
    legendRef,
    wrapperRef,
    innerRadius,
    outerRadius,
    height,
  };
};
