import {
  ButtonBase,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';
import { grey } from 'common/theme/palette';
import { omitForwardedProps } from 'common/utils';

export const DialogCloseButton = styled(ButtonBase)({
  marginTop: 2,
  marginLeft: 'auto',
});

DialogCloseButton.defaultProps = {
  disableRipple: true,
};

export type StyledDialogProps = {
  variant?: 'default' | 'confirmation';
  centered?: boolean;
  fullScreen?: boolean;
};

export const DialogTitle = styled(MuiDialogTitle, {
  shouldForwardProp: omitForwardedProps<StyledDialogProps>('variant', 'centered', 'fullScreen'),
  skipVariantsResolver: true,
})<StyledDialogProps>(({ centered, fullScreen, theme }) => ({
  justifyContent: centered ? 'center' : undefined,
  textAlign: centered ? 'center' : undefined,
  ...(fullScreen && {
    flex: 0,
    borderBottom: '1px solid',
    borderBottomColor: grey[200],
    marginBottom: theme.spacing(4),
  }),
}));

export const DialogContentText = styled(MuiDialogContentText, {
  shouldForwardProp: omitForwardedProps<StyledDialogProps>('variant', 'centered'),
  skipVariantsResolver: true,
})<StyledDialogProps>(({ centered }) => ({
  ...(centered && {
    textAlign: 'center',
    paddingBottom: 0,
  }),
}));
