import { createAction } from '@reduxjs/toolkit';
import { QuestionsWSMessage, QuestionsWSMessages } from 'common/constants';
import { SocketRoomPrefix } from 'store/features/socket';

type QuestionsWSMessageAction = `${typeof SocketRoomPrefix.Questions}:${QuestionsWSMessage}`;

/**
 * Factory function for creating WebSocket message actions related to **Questions**.
 *
 * @template P - The payload type of the WebSocket message action (default is undefined).
 *
 * @param {QuestionsWSMessage} message - The specific action or event identifier to create a WebSocket message for.
 *
 * @returns {string} A WebSocket message action identifier generated by concatenating the 'Meetings' namespace
 *                 with the provided action string.
 */
export const createQuestionsWSMessage = <P = undefined>(message: QuestionsWSMessage) => {
  if (!Object.values(QuestionsWSMessages).includes(message))
    throw Error(`Message '${message}' is not supported by Questions WS`);

  return createAction<P, QuestionsWSMessageAction>(`${SocketRoomPrefix.Questions}:${message}`);
};
