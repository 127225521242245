/**
 * https://usehooks-ts.com/react-hook/use-intersection-observer
 */
import { RefObject, useEffect, useState } from 'react';

export interface UseIsInViewArgs extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export function useIsInViewport(
  elementRef: RefObject<Element>,
  { threshold, root = null, rootMargin = '0%', freezeOnceVisible = false }: UseIsInViewArgs = {}
): boolean {
  const [isInView, setIsInView] = useState(false);
  const frozen = isInView && freezeOnceVisible;

  const updateEntry =
    (freezeOnceVisibleLocal: boolean) =>
    ([newEntry]: IntersectionObserverEntry[]): void => {
      setIsInView((prevValue) => {
        if (freezeOnceVisibleLocal && prevValue) return prevValue;
        return newEntry.isIntersecting;
      });
    };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry(freezeOnceVisible), observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [root, rootMargin, frozen, elementRef, threshold, freezeOnceVisible]);

  return isInView;
}
