import { makeStyles } from '@mui/styles';

export const useFilterByTagsStyles = makeStyles(
  (theme) => ({
    filterByTagsContainer: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    filterByTagsSelected: {
      color: theme.palette.primary.main,
    },
    badge: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      minWidth: 20,
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
      fontSize: '12px',
    },
  }),
  { name: 'filterByTags' }
);
