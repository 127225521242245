import React, { ComponentProps, forwardRef } from 'react';

import { IconButton } from '@mui/material';
import { ButtonMenu } from 'common/components/Buttons';
import { MoreHorizontalIcon } from 'icons';

type Props = ComponentProps<typeof ButtonMenu>;

export const TableCellMoreActions = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <ButtonMenu {...props} ref={ref}>
    <IconButton color="inherit" aria-label="More actions">
      <MoreHorizontalIcon />
    </IconButton>
  </ButtonMenu>
));
