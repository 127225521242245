import React, { FC } from 'react';

import { Tooltip, Typography, styled } from '@mui/material';

import { CHART_TICK_FONT_SIZE, CHART_TICK_LINE_HEIGHT, CHART_TICK_PADDING } from '../constants';

type TickProps = {
  className: string;
  fill: string;
  height: number;
  index: number;
  orientation: string;
  payload: { coordinate: number; value: string; index: number; offset: number };
  stroke: string;
  textAnchor: string;
  tickFormatter: (text: string, index: number) => string;
  verticalAnchor: string;
  visibleTicksCount: number;
  width: number;
  x: number;
  y: number;
} & {
  axis?: 'X' | 'Y';
  onClick?(index: number): void;
  activeIndex?: number;
};

export const Tick: FC<TickProps> = ({
  x,
  y,
  width: containerWidth,
  height,
  textAnchor,
  payload: { value, offset, index },
  axis = 'Y',
  visibleTicksCount,
  activeIndex,
  onClick,
}) => {
  const isYAxis = axis === 'Y';
  const tickHeight = isYAxis ? height / visibleTicksCount : height;
  const width = isYAxis ? containerWidth : 2 * offset;
  const positionX = (isYAxis ? x - containerWidth + 2 : x - offset) + CHART_TICK_PADDING;
  const positionY = y - tickHeight / 2;

  return (
    <foreignObject
      x={positionX}
      y={positionY}
      width={width - CHART_TICK_PADDING * 2}
      height={tickHeight}
      alignmentBaseline="middle"
    >
      <Tooltip title={isYAxis ? '' : value}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: isYAxis ? 'end' : 'center',
            alignItems: isYAxis ? 'center' : 'start',
          }}
        >
          <TickLabel
            onClick={() => onClick?.(index)}
            active={activeIndex === index}
            style={{
              // inline styles required for exporting to PNG
              textAlign: textAnchor === 'end' ? 'end' : 'center',
              fontSize: `${CHART_TICK_FONT_SIZE}px`,
              lineHeight: `${CHART_TICK_LINE_HEIGHT}px`,
              margin: 0,
              ...(!isYAxis && {
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-word',
              }),
            }}
          >
            {value}
          </TickLabel>
        </div>
      </Tooltip>
    </foreignObject>
  );
};

const TickLabel = styled(Typography)<{
  active: boolean;
}>(({ active, onClick }) => ({
  ...(onClick && {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  ...(active && {
    textDecoration: 'underline',
  }),
}));
