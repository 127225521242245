import React, { FC } from 'react';

import { SxProps, Theme, Typography } from '@mui/material';
import { ErrorFilledIcon, InfoOutlinedIcon } from 'icons';

import { Wrapper } from './FormFieldHint.styled';

type Props = {
  error?: boolean;
  justifyContent?: 'center' | 'start';
  noWrap?: boolean;
  sx?: SxProps<Theme>;
};

export const FormFieldHint: FC<Props> = ({ children, error, justifyContent = 'start', noWrap, sx }) =>
  children ? (
    <Wrapper justifyContent={justifyContent} data-testid="FormFieldHint" sx={sx}>
      {error ? <ErrorFilledIcon fontSize="inherit" color="error" /> : <InfoOutlinedIcon fontSize="inherit" />}
      <Typography component="div" variant="natter-text-xs" color={error ? 'error' : undefined} noWrap={noWrap}>
        {children}
      </Typography>
    </Wrapper>
  ) : null;
