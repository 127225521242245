import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
  InternalAxiosRequestConfig,
} from 'axios';
import { isApiErrors, uuid } from 'common/utils';
import type Keycloak from 'keycloak-js';
import { addAppBackendErrorMessage } from 'store/features/alerts';

const storeImport = import('store');

export type FetchConfig = RequestInit & AxiosRequestConfig;

interface AjaxServiceI {
  axios: AxiosInstance;
  keycloakService: Pick<Keycloak, 'token'>;
}

interface AjaxServiceAttributes {
  keycloakService: Keycloak;
}
class AjaxService implements AjaxServiceI {
  axios: AxiosInstance;
  keycloakService: Keycloak;
  numberOfPendingRequests = 0;

  constructor({ keycloakService }: AjaxServiceAttributes) {
    this.keycloakService = keycloakService;
    this.axios = axios.create({ timeout: 60000 });
    this.axios.interceptors.request.use(this.useToken);
    this.axios.interceptors.request.use(this.useXRequestIdHeader);
    this.axios.interceptors.response.use(this.useRedirectUnauthorized, this.useRedirectUnauthorized);
    this.axios.interceptors.response.use(undefined, this.handleUnknownServerErrors);
  }

  useRedirectUnauthorized = (data: AxiosResponse | AxiosError): AxiosResponse | Promise<never> => {
    if ((data as AxiosError).response?.status === 401 && this.keycloakService.authenticated) {
      this.keycloakService.logout();
    }

    return axios.isAxiosError(data) ? Promise.reject(data) : data;
  };

  useToken: (value: InternalAxiosRequestConfig<unknown>) => Promise<InternalAxiosRequestConfig<unknown>> = async (
    axiosConfig
  ) => {
    if (!this.keycloakService.authenticated) {
      return axiosConfig;
    }

    if (this.keycloakService.isTokenExpired(10)) {
      await this.keycloakService.updateToken(10);
    }

    const authToken = this.keycloakService.token;
    const modifiedConfig = { ...axiosConfig };
    modifiedConfig.headers.Authorization = authToken ? `Bearer ${authToken}` : null;
    return modifiedConfig;
  };

  useXRequestIdHeader = async (
    axiosConfig: InternalAxiosRequestConfig<unknown>
  ): Promise<InternalAxiosRequestConfig<unknown>> => {
    axiosConfig.headers['X-Request-ID'] = uuid();
    return axiosConfig;
  };

  handleUnknownServerErrors = async (error: AxiosError): Promise<void> => {
    if (error.response?.status === 500 && axios.isAxiosError(error)) {
      const { store } = await storeImport;
      const response = error.response.data;

      if (isApiErrors(response)) {
        response.errors.forEach((apiError) => {
          store.dispatch(addAppBackendErrorMessage(apiError));
        });
        return;
      }

      store.dispatch(addAppBackendErrorMessage('Unknown server error'));
    }
    throw error;
  };

  initCancelableRequest = <T = unknown>(): {
    cancelableRequest: (url: string, config?: FetchConfig) => Promise<AxiosResponse<T>>;
    cancelRequest: () => void;
  } => {
    // eslint-disable-next-line functional/no-let
    let source: CancelTokenSource;

    const cancelableRequest = async (url: string, config?: FetchConfig): Promise<AxiosResponse<T>> => {
      source?.cancel();

      source = axios.CancelToken.source();

      return this.axios.request({
        ...config,
        url,
        cancelToken: source.token,
      });
    };

    const cancelRequest = () => source?.cancel();

    return {
      cancelableRequest,
      cancelRequest,
    };
  };
}

export default AjaxService;
