import { makeStyles } from '@mui/styles';

export const useSortableItemStyles = makeStyles(
  () => ({
    dragged: {
      cursor: 'grabbing',
      opacity: 0.5,
    },
  }),
  { name: 'sortableItem' }
);
