import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControlLabel, FormControlLabelProps, SwitchProps as MuiSwitchProps } from '@mui/material';
import { Switch as NatterSwitch, SwitchProps as NatterSwitchProps } from 'common/components/Inputs';

export type SwitchProps = NatterSwitchProps &
  Pick<MuiSwitchProps, 'sx'> & {
    label?: FormControlLabelProps['label'];
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    name: string;
    onChangeEffect?: (value: boolean) => void;
  };

export const Switch: FC<SwitchProps> = ({ name, onChangeEffect, label, labelPlacement, sx, ...switchProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <FormControlLabel
          sx={sx}
          control={
            <NatterSwitch
              {...switchProps}
              {...field}
              checked={field.value || false}
              onChange={(event, checked) => {
                onChange(event, checked);
                onChangeEffect?.(checked);
              }}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
        />
      )}
    />
  );
};
