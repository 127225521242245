import React, { FC, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';

import { FormFieldHint } from '../FormFieldHint';
import { PinInputField } from './PinInputField';
import { PinInputFormValues } from './schema';

export type PinInputProps = {
  isSubmitting?: boolean;
  length?: number;
  isPinInvalid?: boolean;
  onChange?(): void;
};

export const PinInput: FC<PinInputProps> = ({ isSubmitting = false, length = 6, isPinInvalid = false, onChange }) => {
  const methods = useFormContext<PinInputFormValues>();
  const ref = useRef<HTMLDivElement>(null);
  const fields = useMemo(() => Array.from({ length }), [length]);

  const getInputByIndex = (index: number) => ref.current?.querySelectorAll('input')[index];

  const handleChange = (index: number) => () => {
    if (index + 1 === length) {
      return;
    }

    getInputByIndex(index + 1)?.focus();

    onChange?.();
  };

  const handlePaste = (startIndex: number) => (event: React.ClipboardEvent<HTMLDivElement>) => {
    const text = event.clipboardData.getData('text');
    const chars = text.slice(0, length - startIndex).split('');
    chars.forEach((char, charIndex) => {
      methods.setValue(`pin.${startIndex + charIndex}.value`, char, { shouldValidate: true });
    });
    const nextIndex = startIndex + chars.length;
    getInputByIndex(Math.min(nextIndex, length - 1))?.focus();

    onChange?.();
  };

  const handleBackspace = (index: number) => () => {
    if (index === 0) return;
    const previousIndex = index - 1;
    methods.setValue(`pin.${previousIndex}.value`, '', { shouldValidate: true });
    getInputByIndex(previousIndex)?.focus();

    onChange?.();
  };

  const handleIndexChange = (newIndex: number) => {
    const input = getInputByIndex(newIndex);
    if (!input) return;

    input.focus();
    setTimeout(() => (input.selectionStart = input.selectionEnd = 1), 0);
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} ref={ref}>
        {fields.map((_, index) => (
          <PinInputField
            key={index}
            index={index}
            disabled={isSubmitting}
            isError={isPinInvalid}
            onChange={handleChange(index)}
            onPaste={handlePaste(index)}
            onBackspace={handleBackspace(index)}
            onIndexChange={handleIndexChange}
          />
        ))}
      </Stack>
      {isPinInvalid && <FormFieldHint error>This PIN is incorrect</FormFieldHint>}
    </Stack>
  );
};
