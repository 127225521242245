import { uuid } from 'common/utils';
import broadcastMessages from 'translation/broadcast.json';

export const BroadcastNotifications = {
  currentUserJoinedAudience: {
    id: uuid(),
    getMessage: () => broadcastMessages.userJoinedAudience,
  },
  otherUserJoinedStage: {
    id: uuid(),
    getMessage: (name: string, speakersCount: number) =>
      speakersCount === 1 ? `${name} has joined the Stage` : `${name} +${speakersCount - 1} more have joined the Stage`,
  },
  currentUserJoinedStage: {
    id: uuid(),
    getMessage: () => broadcastMessages.userJoinedStage,
  },
  currentUserLeftStage: {
    id: uuid(),
    getMessage: () => 'You have left the Stage',
  },
  otherUserLeftStage: {
    id: uuid(),
    getMessage: (name: string, speakersCount: number) =>
      speakersCount === 1 ? `${name} has left the Stage` : `${name} +${speakersCount - 1} more have left the Stage`,
  },
};
