import { type Components, type Theme } from '@mui/material';

import { grey, red } from '../palette';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: ({ theme: { shape } }) => ({
      borderRadius: shape.borderRadius,
    }),
    colorPrimary: ({ theme: { palette } }) => ({
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,

      '&:hover': {
        backgroundColor: palette.primary.dark,
      },

      '&:focus': {
        backgroundColor: palette.extendedPrimary[800],
      },

      '&:active': {
        backgroundColor: palette.extendedPrimary[900],
      },
    }),
    colorSecondary: ({ theme: { palette } }) => ({
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,

      '&:hover': {
        backgroundColor: palette.secondary.dark,
      },

      '&:focus': {
        backgroundColor: grey[300],
      },

      '&:active': {
        backgroundColor: palette.common.white,
        border: `2px solid ${palette.primary.main}`,
      },
    }),
    colorInfo: ({ theme: { palette } }) => ({
      backgroundColor: palette.info.main,
      color: palette.info.contrastText,

      '&:hover': {
        backgroundColor: palette.info.dark,
      },

      '&:focus': {
        backgroundColor: palette.info.light,
      },

      '&:active': {
        backgroundColor: palette.info.light,
      },
    }),
    colorError: ({ theme: { palette } }) => ({
      backgroundColor: palette.error.main,
      color: palette.error.contrastText,

      '&:hover': {
        backgroundColor: palette.error.dark,
      },

      '&:focus': {
        backgroundColor: red[700],
      },

      '&:active': {
        backgroundColor: red[800],
      },
    }),
    sizeLarge: ({ theme: { spacing } }) => ({
      height: 40,
      width: 40,
      padding: spacing(1),

      '& .MuiSvgIcon-root': {
        fontSize: 24,
      },
    }),
    sizeMedium: ({ theme: { spacing } }) => ({
      height: 32,
      width: 32,
      padding: spacing(0.75),

      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
    }),
    sizeSmall: ({ theme: { spacing, typography } }) => ({
      height: 24,
      width: 24,
      padding: spacing(0.5, 0.75),

      '& .MuiSvgIcon-root': {
        ...typography['natter-text-md'],
      },
    }),
  },
  variants: [
    {
      props: { color: 'light' },
      style: ({ theme: { palette } }) => ({
        backgroundColor: palette.extendedPrimary[25],
        color: palette.primary.main,

        '&:hover': {
          backgroundColor: palette.extendedPrimary[50],
        },

        '&.Mui-focusVisible': {
          backgroundColor: palette.extendedPrimary[100],
        },

        '&:active; &:focus': {
          backgroundColor: palette.extendedPrimary[200],
        },
      }),
    },
    {
      props: { size: 'action' },
      style: () => ({
        height: 48,
        width: 48,
        borderRadius: 24,
      }),
    },
  ],
};

declare module '@mui/material/IconButton' {
  // eslint-disable-next-line no-unused-vars
  interface IconButtonPropsColorOverrides {
    light: true;
  }
  // eslint-disable-next-line no-unused-vars
  interface IconButtonPropsSizeOverrides {
    action: true;
  }
}
