import type { Components, Theme } from '@mui/material';

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { spacing, breakpoints } }) => ({
      maxWidth: '100%',
      width: '100%',
      boxSizing: 'border-box',
      padding: spacing(1, 7, 2),
      [breakpoints.down('sm')]: {
        padding: spacing(1, 2, 2),
      },
    }),
  },
};
