import React, { ComponentType, FC, useEffect } from 'react';

import { useTheme } from '@mui/material';

export const withWhiteBackground =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props) => {
    const { palette } = useTheme();

    useEffect(() => {
      document.getElementsByTagName('body')[0].style.backgroundColor = palette.common.white;

      return () => {
        document.getElementsByTagName('body')[0].style.backgroundColor = palette.background.default;
      };
    }, [palette.background.default, palette.common.white]);

    return <WrappedComponent {...props} />;
  };
