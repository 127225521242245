import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button as MaterialButton, ButtonProps as MaterialButtonProps, SxProps, Theme } from '@mui/material';

export interface ButtonProps extends MaterialButtonProps {
  wrapText?: boolean;
  isLoading?: boolean;
  loadingIndicator?: ReactNode;
  href?: string;
  externalHref?: string;
  sx?: SxProps<Theme>;
}

export const Button: FC<ButtonProps> = ({
  variant = 'contained',
  className,
  startIcon,
  children,
  isLoading = false,
  color,
  loadingIndicator,
  href,
  externalHref,
  sx,
  size,
  title,
  ...props
}) =>
  href ? (
    <MaterialButton
      component={Link}
      to={href}
      variant={variant}
      startIcon={startIcon}
      color={color}
      sx={sx}
      title={title}
      size={size}
      className={className}
      {...props}
    >
      {children}
    </MaterialButton>
  ) : (
    // @ts-expect-error props are incompatible
    <LoadingButton
      loading={isLoading}
      loadingIndicator={loadingIndicator}
      startIcon={startIcon}
      variant={variant}
      href={externalHref}
      target={externalHref ? '_blank' : undefined}
      color={color}
      title={title}
      sx={sx}
      size={size}
      className={className}
      {...props}
    >
      <Box sx={{ opacity: isLoading ? 0 : 1 }}>{children}</Box>
    </LoadingButton>
  );
