import React, { MouseEvent } from 'react';

import { TableSortLabel, Typography } from '@mui/material';
import { ColumnData, isColumnSortable } from 'common/components/_legacy/Table/types';
import { SortOrder } from 'domain/Common';

import { StyledChevronDownIcon } from './TableLabel.styled';

type TableLabelProps<T> = {
  column: ColumnData<T>;
  order?: SortOrder;
  orderBy?: string;
  onSort?: (event: MouseEvent<HTMLSpanElement>) => void;
};

export const TableLabel = <T,>({ column, orderBy, order, onSort }: TableLabelProps<T>) => {
  if (isColumnSortable(column)) {
    return (
      <TableSortLabel
        active={orderBy === column.id}
        direction={orderBy === column.id ? order : 'asc'}
        onClick={onSort}
        IconComponent={StyledChevronDownIcon}
      >
        <Typography variant="body1" fontWeight={500} title={column.label}>
          {column.label}
        </Typography>
      </TableSortLabel>
    );
  }
  if (column.headerFormat) {
    return <>{column.headerFormat()}</>;
  }
  return (
    <Typography variant="body1" fontWeight={500} title={column.label}>
      {column.label}
    </Typography>
  );
};
