import { ERROR_MESSAGES } from 'common/constants';
import { QuestionVariants } from 'domain/question';
import { InferType, TestContext, array, boolean, mixed, number, object, string } from 'yup';

// // TODO
// // - Add validation rules for each question type (dynamic based on format)
// // - Attach question schema to the event wizard (when title message should be changed the schema should concat with the new message)
// // - Move choices validation from `useChoicesWithFormContext` to schema rules with .when().then() and .default()
// export const questionSchema = object({
//   id: number(),
//   title: string().trim().required('Please enter the text for your question. This field cannot be left blank.'),
//   format: object({
//     label: string(),
//     value: mixed<QuestionVariants>().oneOf(Object.values(QuestionVariants)),
//   })
//     .nullable()
//     .transform((_, value) => (value && !Object.keys(value).length ? null : value))
//     .required('You must select a question type (e.g., Free Text, Multiple Choice) to proceed.'),
//   isObligatory: boolean().required('Mandatory is required').default(false),
//   choices: array().of(object({ value: string().trim().required('You need to fill this answer in order to go next') })),
//   randomizeChoices: boolean(),
//   answerLibraryType: object({ label: string(), value: string() }).optional().nullable(),
//   chooseFromAnswersLibrary: boolean(),
//   minimumValue: number(),
//   minimumValueLabel: string(),
//   maximumValue: number(),
//   maximumValueLabel: string(),
//   incrementValue: number(),
// });

// export type QuestionType = InferType<typeof questionSchema>;

const MAX_SLIDER_DISTANCE = 100;

const testMaxSliderDistance = (value: number | undefined, distanceToPath: string, ctx: TestContext) =>
  value !== undefined &&
  ctx.parent[distanceToPath] !== undefined &&
  Math.abs(value - ctx.parent[distanceToPath]) <= MAX_SLIDER_DISTANCE;

const sliderQuestionSchema = object({
  minimumValue: number()
    .label('Minimum value')
    .when('format', (format: QuestionVariants[], schema) =>
      format[0] === QuestionVariants.SliderQuestion
        ? schema
            .typeError(ERROR_MESSAGES.numberTypeError)
            .integer()
            .test(
              'less-than-maximumValue',
              ({ label }) => `${label} must be less than maximum`,
              (value, ctx) =>
                value !== undefined && ctx.parent.maximumValue !== undefined && value < ctx.parent.maximumValue
            )
            .test(
              'max-distance',
              ({ label }) => `${label} must be less than ${MAX_SLIDER_DISTANCE} from maximum value`,
              (value, ctx) => testMaxSliderDistance(value, 'maximumValue', ctx)
            )
        : schema
    ),
  minimumValueLabel: string(),
  maximumValue: number()
    .label('Maximum value')
    .when('format', (format: QuestionVariants[], schema) =>
      format[0] === QuestionVariants.SliderQuestion
        ? schema
            .typeError(ERROR_MESSAGES.numberTypeError)
            .integer()
            .test(
              'greater-than-minimumValue',
              ({ label }) => `${label} must be greater than minimum`,
              (value, ctx) =>
                value !== undefined && ctx.parent.minimumValue !== undefined && value > ctx.parent.minimumValue
            )
            .test(
              'max-distance',
              ({ label }) => `${label} must be less than ${MAX_SLIDER_DISTANCE} from minimum value`,
              (value, ctx) => testMaxSliderDistance(value, 'minimumValue', ctx)
            )
        : schema
    ),
  maximumValueLabel: string(),
  incrementValue: number().integer().positive(),
});

type GetQuestionSchemaFieldsProps = { errorMessages?: { title: string } };

export const getQuestionSchemaFields = ({ errorMessages }: GetQuestionSchemaFieldsProps = {}) => ({
  id: number(),
  assignmentType: string(),
  title: string()
    .trim()
    .required(errorMessages?.title || 'In order to proceed, you must fill in this question.'),
  format: mixed<QuestionVariants>()
    .nullable()
    .oneOf(Object.values(QuestionVariants))
    .test((format, context) => {
      if (!format) {
        return context.createError({ message: 'In order to proceed, you must select and fill in this question.' });
      }

      return true;
    }),
  isObligatory: boolean().required('Mandatory is required').default(false),
  choices: array().of(object({ value: string().trim().required('You need to fill this answer in order to go next') })),
  randomizeChoices: boolean(),
  answerLibraryType: object({
    label: string(),
    value: string(),
  })
    .optional()
    .nullable(),
  chooseFromAnswersLibrary: boolean(),
});

export const createQuestionSchema = (props: GetQuestionSchemaFieldsProps = {}) =>
  object(getQuestionSchemaFields(props)).concat(sliderQuestionSchema);

export type QuestionType = InferType<ReturnType<typeof createQuestionSchema>>;
