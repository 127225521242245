import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import { Rating as MuiRating, RatingProps } from '@mui/material';

type Props = Omit<RatingProps, 'onChange'> & {
  name: string;
};

export const Rating: FC<Props> = ({ name, ...props }) => {
  const { field } = useController({ name });

  return <MuiRating name={name} onChange={(_, value) => field.onChange(value)} data-testid="rating" {...props} />;
};
