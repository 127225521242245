import type { Components, Theme } from '@mui/material';

export const MuiTablePagination: Components<Theme>['MuiTablePagination'] = {
  styleOverrides: {
    root: {
      border: 'none',
    },
    toolbar: ({ theme: { breakpoints } }) => ({
      minHeight: 32,
      [breakpoints.up(600)]: {
        minHeight: 32,
      },
    }),
    selectLabel: {
      display: 'none',
    },
    select: {
      display: 'none',
    },
    input: {
      display: 'none',
    },
    displayedRows: ({ theme: { palette } }) => ({
      color: palette.grey[600],
      fontSize: '12px',
    }),
    actions: ({ theme: { spacing, palette } }) => ({
      marginLeft: spacing(1),
      'button.Mui-disabled': {
        backgroundColor: 'unset',
      },
      'button:not(.Mui-disabled)': {
        color: palette.grey[700],
      },
    }),
  },
};
