import { createApi } from '@reduxjs/toolkit/query/react';
import { pickBy } from 'lodash/fp';

import axiosBaseQuery from '../_axiosBaseQuery';
import { AnswerStatistics, InsightsReportData, TagCategoryDemographics } from '../insightsReport';
import { mapSurveysReportDataToInsightsReportData } from '../surveyReport/mappers';
import { PublicSurveysReportAnswersPayload, PublicSurveysReportPayload } from './types';

export const publicSurveysReportApi = createApi({
  reducerPath: 'publicSurveysReportApi',
  tagTypes: ['REPORTS', 'REPORT_DEMOGRAPHICS', 'REPORT_ANSWERS'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPublicSurveysReportById: builder.query<InsightsReportData, PublicSurveysReportPayload>({
      providesTags: (result) => (result?.id ? [{ type: 'REPORTS', id: result.id }] : []),
      query: ({ reportId, pin }) => ({
        url: `/survey/report/${reportId}/public`,
        headers: { pin },
      }),
      transformResponse: mapSurveysReportDataToInsightsReportData,
    }),
    getPublicSurveysReportKeyMetrics: builder.query<Record<string, number>, PublicSurveysReportPayload>({
      providesTags: (_result, _error, args) => (args.reportId ? [{ type: 'REPORTS', id: args.reportId }] : []),
      query: ({ reportId, pin }) => ({
        url: `/survey/report/${reportId}/public/survey-metrics`,
        headers: { pin },
      }),
    }),
    getPublicSurveysReportDemographics: builder.query<TagCategoryDemographics[], PublicSurveysReportPayload>({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_DEMOGRAPHICS', id: reportId }] : [],
      query: ({ reportId, pin }) => ({
        url: `/survey/report/${reportId}/public/survey-demographics`,
        headers: { pin },
      }),
    }),
    getPublicSurveysReportAnswers: builder.query<AnswerStatistics[], PublicSurveysReportAnswersPayload>({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_ANSWERS', id: reportId }] : [],
      query: ({ reportId, communityTagIds, pin }) => {
        const filteredCommunityTagIds = pickBy('length')(communityTagIds);
        const communityTagIdsParam = Object.keys(filteredCommunityTagIds).length
          ? JSON.stringify(filteredCommunityTagIds)
          : undefined;
        return {
          url: `/survey/report/${reportId}/public/answers`,
          params: {
            communityTagIds: communityTagIdsParam,
          },
          headers: { pin },
        };
      },
    }),
  }),
});

export const {
  useGetPublicSurveysReportAnswersQuery,
  useGetPublicSurveysReportByIdQuery,
  useGetPublicSurveysReportDemographicsQuery,
  useGetPublicSurveysReportKeyMetricsQuery,
} = publicSurveysReportApi;
