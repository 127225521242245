import { Box, Checkbox, MenuItem, styled } from '@mui/material';
import { SearchInput } from 'common/components/Inputs';

export const MultipleSelectFilterCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  '& .MuiSvgIcon-root': {
    width: 24,
    height: 24,
  },
}));

export const MultipleSelectFilterMenuItem = styled(MenuItem)(({ theme: { spacing, typography } }) => ({
  display: 'flex',
  gap: spacing(1),
  height: '36px',
  paddingLeft: spacing(3),
  paddingRight: spacing(2),
  '&.Mui-selected': {
    backgroundColor: 'unset',
  },
  '& .MuiListItemText-primary': {
    ...typography['natter-text-sm'],
  },
}));

export const MultipleSelectFilterSelectAllItem = styled(MultipleSelectFilterMenuItem)(() => ({
  '& .MuiListItemText-primary': {
    fontWeight: 700,
  },
}));

export const MultipleSelectFilterSearchWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 2, 2, 2),
}));

export const MultipleSelectFilterSearchInput = styled(SearchInput)(() => ({
  width: '100%',
}));
