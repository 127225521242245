import type { Account, AccountApi } from 'domain/Account';
import type { Tag, TagsCategory } from 'domain/Common';

export const mapAccountFromApi = (account: AccountApi): Account => ({
  id: account.id,
  username: account.username,
  email: account.email,
  firstName: account.firstName,
  lastName: account.lastName,
  displayName: `${account.firstName} ${account.lastName}`,
  avatarLink: account.avatarLink,
  isOrganizationMember: account.isOrganizationMember,
});

const flatMap = <T>(arrayOfArrays: T[][]): T[] => arrayOfArrays.reduce((r, x) => r.concat(x), []);

export const mapTagsFromCategories = (tagsCategories: TagsCategory[] | undefined): Tag[] =>
  tagsCategories ? flatMap(tagsCategories.map((c) => c.tags)).filter((t) => t) : [];
