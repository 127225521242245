import type { Components, Theme } from '@mui/material';

export const MuiTableHead: Components<Theme>['MuiTableHead'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      backgroundColor: palette.custom.cardBackground,
    }),
  },
};
