import { CSSProperties } from 'react';

import { Palette } from '@mui/material';

import { IconButtonVariantColor } from '../types';

export const mapColorForTextVariant = (palette: Palette, color?: IconButtonVariantColor) => {
  switch (color) {
    case 'primary':
      return palette.primary.main;
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.main;
    default:
      return palette.secondary.contrastText;
  }
};

export const mapHoverColorForTextVariant = (palette: Palette, color?: IconButtonVariantColor) => {
  switch (color) {
    case 'primary':
      return palette.primary.dark;
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    default:
      return palette.secondary.contrastText;
  }
};

export const mapHoverBackgroundForTextVariant = (
  palette: Palette,
  color?: IconButtonVariantColor
): CSSProperties['backgroundColor'] => {
  switch (color) {
    case 'primary':
      return palette.extendedPrimary[25];
    case 'text':
      return palette.grey[200];
    default:
      return 'transparent';
  }
};

export const mapFocusColorForTextVariant = (palette: Palette, color?: IconButtonVariantColor) => {
  switch (color) {
    case 'primary':
      return palette.extendedPrimary[700];
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    case 'text':
      return palette.text.primary;
    default:
      return palette.secondary.contrastText;
  }
};

export const mapActiveColorForTextVariant = (palette: Palette, color?: IconButtonVariantColor) => {
  if (color === 'text') {
    return palette.grey[800];
  }
  return mapColorForTextVariant(palette, color);
};

export const mapActiveBackgroundForTextVariant = (palette: Palette, color?: IconButtonVariantColor) => {
  switch (color) {
    case 'primary':
      return palette.extendedPrimary[100];
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    default:
      return palette.grey[300];
  }
};

export const mapColorForOutlinedVariant = mapColorForTextVariant;

export const mapHoverColorForOutlinedVariant = mapActiveBackgroundForTextVariant;

export const mapFocusColorForOutlinedVariant = (palette: Palette, color?: IconButtonVariantColor) => {
  switch (color) {
    case 'primary':
      return palette.info.light;
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    default:
      return palette.secondary.contrastText;
  }
};
