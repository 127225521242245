import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OptionItem } from 'common/components';
import { MediaDevice } from 'domain/event';

type Props = {
  label: string;
  devices: MediaDevice[];
  name: string;
  isAccessGranted?: boolean;
};

export const useDevicesSelect = ({ label: genericLabel, devices, name, isAccessGranted = true }: Props) => {
  const { t } = useTranslation('devices');
  const selectedDeviceId: string | undefined = useWatch({ name });
  const isDisabled = !isAccessGranted && devices.length === 0;

  const options = useMemo<OptionItem<string>[]>(() => {
    if (devices.length === 0) {
      return [
        {
          label: t('noDevicesFound'),
          disabled: true,
          value: '',
        },
      ];
    }
    return devices.map(({ deviceId, label }, index) => ({
      label: label || `${genericLabel} ${index + 1}`,
      value: deviceId,
    }));
  }, [devices, genericLabel, t]);

  const label = (() => {
    if (isDisabled) return `Default ${genericLabel.toLowerCase()}`;
    return selectedDeviceId ? undefined : `Select ${genericLabel.toLowerCase()}`;
  })();

  return { options, selectedDeviceId, isDisabled, label };
};
