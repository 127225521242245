import { Stack, styled } from '@mui/material';

export const FormWrapper = styled(Stack)(({ theme: { palette, spacing } }) => ({
  borderRadius: 8,
  border: '1px solid',
  borderColor: palette.divider,
  padding: spacing(3),
  gap: spacing(3),
  backgroundColor: palette.common.white,
}));

export const FormSectionWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
}));
