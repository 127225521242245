import { useMemo } from 'react';

import { BasicChartData, chartNonDataKeys } from '../types';

export const useAutoDataKeys = (data: BasicChartData, dataKeys?: string[]) => {
  const autoDataKeys = useMemo(() => {
    if (dataKeys?.length) return dataKeys;

    if (!data.length) return [];

    return Object.keys(data[0]).filter((key) => !chartNonDataKeys.includes(key));
  }, [data, dataKeys]);

  return { dataKeys: autoDataKeys };
};
