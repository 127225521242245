import React, { forwardRef } from 'react';

import { Box, CircularProgress, Fade, Grid, Stack, SxProps, Theme } from '@mui/material';

export const cover: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  top: 0,
  left: 0,
};

interface LoaderProps {
  isTransitionDelay: boolean;
  sx?: SxProps<Theme>;
  size?: string | number;
}

export const Loader = forwardRef<HTMLElement, LoaderProps>(({ sx, isTransitionDelay, size }, ref) => (
  <Box ref={ref} sx={{ position: 'relative', width: '100%', height: '100%', minHeight: 'inherit', ...sx }}>
    <Grid container justifyContent="center" alignItems="center" sx={cover}>
      <Grid item>
        <Fade in style={{ transitionDelay: isTransitionDelay ? '800ms' : '0ms' }} unmountOnExit>
          <Stack justifyContent="center">
            <CircularProgress data-testid="Loadable-loading" size={size} />
          </Stack>
        </Fade>
      </Grid>
    </Grid>
  </Box>
));
