import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateDynamicPath } from 'common/utils/routing';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { websocketUnsubscribe } from '../actions';
import { SocketRoomPrefix, SocketRoomPrefixType } from '../types';

type WSChannels = {
  public: string;
  private: string;
};

/**
 * Creates a Redux Toolkit asynchronous thunk for unsubscribing to WebSocket channels.
 *
 * @template Payload - The payload type for the WebSocket subscription.
 * @template Channels - An object containing public and private channel paths.
 *
 * @param {SocketRoomPrefixType} prefix - The prefix for the WebSocket room.
 * @param {Channels} channels - An object containing public and private channel paths.
 *
 * @returns {AsyncThunk<void, Payload>} - An asynchronous thunk that subscribes to WebSocket channels.
 *
 * @throws {Error} If the `prefix` is not provided or if `channels` is not an object.
 *
 * @example
 * // Example usage:
 * const channels = {
 *   public: 'publicChannel',
 *   private: 'privateChannel',
 * };
 * const unsubscribeAction = createWSUnsubscribeAction('SomePrefix', channels);
 *
 * // Dispatch the action with payload:
 * dispatch(unsubscribeAction({ payload data  }));
 */
export const createWSUnsubscribeAction = <Payload extends Record<string, unknown>, Channels extends WSChannels>(
  prefix: SocketRoomPrefixType,
  channels: Channels
) => {
  if (!Object.values(SocketRoomPrefix).includes(prefix)) throw Error(`Prefix '${prefix}' is not supported by WS`);

  return createAsyncThunk<void, Payload>(
    `${prefix}:unsubscribeFrom${startCase(camelCase(prefix)).replace(/ /g, '')}`,
    (payload, { dispatch }) => {
      dispatch(
        websocketUnsubscribe({
          actionPrefix: prefix,
          publicChannel: generateDynamicPath<Payload>(channels.public, payload),
          privateChannel: generateDynamicPath<Payload>(channels.private, payload),
        })
      );
    }
  );
};
