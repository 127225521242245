import type { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import Axios, { type AxiosRequestConfig } from 'axios';
import { ERROR_MESSAGES } from 'common/constants';
import { ajaxService } from 'modules/api/services';
import { UrlNames } from 'modules/app/constants';
import { configService } from 'modules/app/services';

interface AxiosBaseQueryArgs<
  Meta,
  Response = {
    httpStatus: 200;
    created_at: string;
  },
> {
  meta?: Meta;
  transformResponse?: (response: Response) => unknown;
}

const getRequestConfig = (args: string | AxiosRequestConfig) => {
  if (typeof args === 'string') {
    return { url: args };
  }

  return args;
};

const axiosBaseQuery =
  <Args extends AxiosRequestConfig | string = AxiosRequestConfig, Meta = Record<string, unknown>>({
    transformResponse,
    baseURL = configService.getUrl(UrlNames.Api),
  }: AxiosBaseQueryArgs<Meta> & { baseURL?: string } = {}): BaseQueryFn<
    Args | Promise<Args>,
    unknown,
    FetchBaseQueryError
  > =>
  async (args, api, extraOptions) => {
    try {
      const requestConfig = getRequestConfig(await args);
      const result = await ajaxService.axios({
        baseURL,
        ...requestConfig,
        signal: api.signal,
        ...extraOptions,
      });

      return {
        data: transformResponse ? transformResponse(result.data) : result.data,
      };
    } catch (err: unknown) {
      if (!Axios.isAxiosError(err)) {
        return {
          error: {
            status: 500,
            // @ts-expect-error add type guard
            data: err?.message || ERROR_MESSAGES.unknownError,
          },
        };
      }

      return {
        error: {
          status: err.response?.status ?? 500,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
