import { BroadcastWSActions, BroadcastWSMessages } from 'common/constants';
import { createMeetingWSAsyncAction, createMeetingWSMessage } from 'store/utils';

import {
  BroadcastUserSearchItemAddedPayload,
  BroadcastUserSearchItemRemovedPayload,
  BroadcastUserSearchSubscribedPayload,
  GetEventParticipantsParams,
} from './types';

export const broadcastUserSearchSubscribed_WS = createMeetingWSMessage<BroadcastUserSearchSubscribedPayload>(
  BroadcastWSMessages.BroadcastUserSearchSubscribed
);
export const broadcastUserSearchItemAdded_WS = createMeetingWSMessage<BroadcastUserSearchItemAddedPayload>(
  BroadcastWSMessages.BroadcastUserSearchItemAdded
);
export const broadcastUserSearchItemRemoved_WS = createMeetingWSMessage<BroadcastUserSearchItemRemovedPayload>(
  BroadcastWSMessages.BroadcastUserSearchItemRemoved
);

export const searchBroadcastParticipants_WS = createMeetingWSAsyncAction<GetEventParticipantsParams>(
  BroadcastWSActions.SearchUsers
);
