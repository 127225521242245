import React, { FC } from 'react';

import { Stack, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

export const SliderInputHint: FC<{ showHint: boolean }> = ({ showHint }) => {
  const { palette } = useTheme();

  return showHint ? (
    <Stack
      sx={{
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -4px)',
        pointerEvents: 'none',
      }}
    >
      <motion.div
        transition={{ repeat: Infinity, duration: 1 }}
        style={{
          border: '3px solid',
          borderRadius: '100%',
          borderColor: palette.primary.main,
        }}
        initial={{
          width: 0,
          height: 0,
          opacity: 0,
        }}
        animate={{
          width: 40,
          height: 40,
          opacity: 0.7,
        }}
        exit={{
          width: 40,
          height: 40,
          opacity: 0,
        }}
      />
    </Stack>
  ) : null;
};
