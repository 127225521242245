import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';
import { Tooltip } from 'common/components';
import { HelpOutlinedIcon } from 'icons';

export const GoToHelpCenter: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'topBar.helpCenter' });

  return (
    <Tooltip title={t('title')}>
      <IconButton
        size="medium"
        href={t('url')}
        target="_blank"
        rel="noreferrer"
        sx={{ borderRadius: '50%', '& .MuiSvgIcon-root': { fontSize: 24 } }}
      >
        <HelpOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};
