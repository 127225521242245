import { type Components, type Theme } from '@mui/material';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    label: ({ theme: { typography, spacing } }) => ({
      ...typography['natter-text-sm'],

      padding: spacing(1, 0),
      flex: 1,
    }),
    labelPlacementStart: ({ theme: { spacing } }) => ({
      marginRight: 0,
      gap: spacing(1),
    }),
  },
};
