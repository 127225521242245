import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Community } from 'domain/Community';
import type { RootState } from 'store';

type MasterAdminState = {
  selectedCommunity: Community | null | undefined;
};

const initialState: MasterAdminState = {
  selectedCommunity: null,
};

export const masterAdminSlice = createSlice({
  name: 'masterAdmin',
  initialState,
  reducers: {
    selectCommunity: (state, { payload }: PayloadAction<Community | null | undefined>) => {
      state.selectedCommunity = payload;
    },
  },
});

export const selectSelectedCommunity = (state: RootState) => state.masterAdmin.selectedCommunity;

export const { selectCommunity } = masterAdminSlice.actions;
