import type { StatusIndicatorColor } from 'common/components/StatusIndicator';

import { TaskStepperStatus, TaskStepperStatuses } from './types';

export const TaskStepperStatusColors: Record<TaskStepperStatus, StatusIndicatorColor> = {
  [TaskStepperStatuses.Completed]: 'success',
  [TaskStepperStatuses.InProgress]: 'inprogress',
  [TaskStepperStatuses.Incomplete]: 'moved',
  [TaskStepperStatuses.NotStarted]: 'default',
};
