import React, { DOMAttributes, FC } from 'react';

import { Box, CircularProgress, Fade, Tooltip } from '@mui/material';
import classNames from 'classnames';

import { useActionButtonStyles } from './ActionButton.styles';

export type ActionButtonProps = {
  tooltip: string;
  isActive: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: DOMAttributes<HTMLDivElement>['onClick'];
  classes?: {
    actionIcon?: string;
    actionIconOff?: string;
  };
  className?: string;
  'data-testid'?: string;
};

export const ActionButton: FC<ActionButtonProps> = ({
  tooltip,
  isActive,
  isDisabled = false,
  isLoading = false,
  onClick,
  classes: customClasses,
  children,
  className,
  ...props
}) => {
  const classes = useActionButtonStyles();

  return (
    <Box position="relative">
      <Tooltip title={tooltip} placement="top">
        <div
          className={classNames(
            classes.actionIcon,
            customClasses?.actionIcon,
            {
              [customClasses?.actionIconOff ?? classes.actionIconOff]: !isActive,
              [classes.disabled]: isDisabled,
            },
            className
          )}
          onClick={isLoading || isDisabled ? undefined : onClick}
          data-testid={props['data-testid']}
        >
          {children}
        </div>
      </Tooltip>
      <Fade in={isLoading} mountOnEnter unmountOnExit>
        <CircularProgress size={48} sx={{ position: 'absolute', top: 0 }} />
      </Fade>
    </Box>
  );
};
