import type { Components, Theme } from '@mui/material';

export const MuiAccordionDetails: Components<Theme>['MuiAccordionDetails'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: '16px',
      lineHeight: '24px',
      paddingLeft: 0,
    },
  },
};
