import { DEFAULT_DATE_FORMAT } from 'common/components/Inputs/DatePicker';
import { format } from 'date-fns';

export const ERROR_MESSAGES = {
  noCommunityId: 'Community ID required',
  noEmpty: "Can't be empty",
  unknownError: 'Unknown error',
  unknownServerError: 'Unknown server error',
  invalidEmail: 'Please enter a valid email address',
  numberTypeError: 'Only numerical values are allowed',
  requiredWithLabel: (label: string) => `Please enter the ${label.toLowerCase()} in order to proceed.`,
  minDateWithLabel: (label: string, min: Date | string) =>
    `The ${label.toLowerCase()} must be on or after ${format(new Date(min), DEFAULT_DATE_FORMAT)}.`,
  invalidTime: 'Invalid time',
} as const;
