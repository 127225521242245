import React, { FC } from 'react';

import { TypographyProps } from '@mui/material';

import { BoxWithLabelDivider, BoxWithLabelText, BoxWithLabelWrapper } from './BoxWithLabel.styled';

type BoxWithLabelProps = Pick<TypographyProps, 'height' | 'whiteSpace'> & {
  label: React.ReactNode;
  disabled?: boolean;
};

export const BoxWithLabel: FC<BoxWithLabelProps> = ({
  label,
  height,
  whiteSpace = 'nowrap',
  disabled = false,
  children,
}) => (
  <BoxWithLabelWrapper height={height}>
    <BoxWithLabelText disabled={disabled} whiteSpace={whiteSpace} variant="natter-text-sm">
      {label}
    </BoxWithLabelText>
    <BoxWithLabelDivider orientation="vertical" flexItem variant="middle" />
    {children}
  </BoxWithLabelWrapper>
);
