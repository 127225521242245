import React, { FC } from 'react';

import { CircularProgress, Fade, Stack, Typography } from '@mui/material';

export type LoadingProps = {
  isLoading?: boolean;
  text?: string;
};

export const Loading: FC<LoadingProps> = ({ isLoading = true, text, children }) =>
  isLoading || !(isLoading || children) ? (
    <Fade in unmountOnExit>
      <Stack justifyContent="center" alignItems="center" height={1} gap={2} data-testid="loading-container">
        <CircularProgress />
        {text && <Typography variant="h6">{text}</Typography>}
      </Stack>
    </Fade>
  ) : (
    <>{children}</>
  );
