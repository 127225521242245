import { Components, Theme, alpha } from '@mui/material';

import { darkBlue } from '../palette';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      borderRadius: spacing(0.5),
    }),
    paper: ({ theme: { palette, spacing } }) => ({
      backgroundColor: palette.common.white,
      border: 'none',
      borderRadius: spacing(0.5),
      boxShadow: `
        0px 8px 12px 0px ${alpha(darkBlue, 0.15)},
        0px 0px 1px 0px ${alpha(darkBlue, 0.31)}
      `,
      marginTop: spacing(1),
      minWidth: 240,
    }),
  },
};
