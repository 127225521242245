import { AnswerTypes, QuestionAssignmentDefinition, QuestionDefinition } from '../question';
import { EventQuestionType } from './Common';

export enum EventQuestionAssignmentType {
  PreEvent = 'PRE_EVENT',
  EndOfEvent = 'END_OF_EVENT',
  Room = 'ROOM',
  EndOfRoom = 'POST_NATTERING',
}

export type EventQuestionAssignment = QuestionAssignmentDefinition<EventQuestionAssignmentType>;

export type EventQuestionDefinition = QuestionDefinition<EventQuestionAssignment> & {
  postNatteringQuestions?: PostNatteringQuestion[];
};

export type PostNatteringQuestion = QuestionDefinition<
  QuestionAssignmentDefinition<EventQuestionAssignmentType.EndOfRoom>
>;

// TODO Check if still needed
export type FormEventQuestion = {
  id?: number;
  question: string;
  questionType: EventQuestionType;
  format: AnswerTypes;
  mandatory?: boolean;
  answerLibrary?: boolean;
  answerLibraryType?: string;
  answers?: {
    value: string;
  }[];
  order?: number;
  // should expand question details, true for newly added items
  isOpen?: boolean;
};
