import { assert } from 'common/utils';
import { EventQuestionAssignmentType } from 'domain/event';
import {
  AnswerTypes,
  CreateBasicQuestionRequest,
  CreateMultipleChoiceSingleSelectQuestionRequest,
  CreateQuestionRequest,
  CreateQuestionVariants,
  CreateRankingQuestionRequest,
  CreateSliderQuestionRequest,
  QuestionVariants,
} from 'domain/question';
import { QuestionType } from 'modules/question/schemas';
import { CreateEventQuestionRequest } from 'store/apis/event';

export const AnswerTypesToQuestionVariant: Record<AnswerTypes, CreateQuestionVariants> = {
  [AnswerTypes.Text]: CreateQuestionVariants.TextQuestion,
  [AnswerTypes.SingleChoice]: CreateQuestionVariants.MultipleChoiceSingleSelectQuestion,
  [AnswerTypes.MultipleChoice]: CreateQuestionVariants.MultipleChoiceMultipleSelectQuestion,
  [AnswerTypes.Ranking]: CreateQuestionVariants.RankingQuestion,
  [AnswerTypes.NPS]: CreateQuestionVariants.NPSQuestion,
  [AnswerTypes.Slider]: CreateQuestionVariants.SliderQuestion,
};

export const QuestionVariantToAnswerTypes: Record<QuestionVariants, AnswerTypes> = {
  [QuestionVariants.TextQuestion]: AnswerTypes.Text,
  [QuestionVariants.MultipleChoiceSingleSelectQuestion]: AnswerTypes.SingleChoice,
  [QuestionVariants.MultipleChoiceMultipleSelectQuestion]: AnswerTypes.MultipleChoice,
  [QuestionVariants.RankingQuestion]: AnswerTypes.Ranking,
  [QuestionVariants.NPSQuestion]: AnswerTypes.NPS,
  [QuestionVariants.SliderQuestion]: AnswerTypes.Slider,
};

export const mapTopicQuestionToApi = (question: QuestionType): CreateEventQuestionRequest => ({
  question: {
    title: question.title,
    type: CreateQuestionVariants.TextQuestion,
  },
  isObligatory: question.isObligatory,
  assignmentType: EventQuestionAssignmentType.Room,
  postNatteringQuestions: question.choices?.length
    ? [
        {
          question: mapQuestionToCreateQuestionRequest(question),
          isObligatory: question.isObligatory,
        },
      ]
    : [],
});

export const mapEventWizardQuestionToApi = (
  question: QuestionType,
  assignmentType: EventQuestionAssignmentType
): CreateEventQuestionRequest => ({
  question: mapQuestionToCreateQuestionRequest(question),
  isObligatory: question.isObligatory,
  assignmentType,
});

const mapQuestionToCreateQuestionRequest = (question: QuestionType): CreateQuestionRequest => {
  assert(question.format, 'Question format value missing!');
  const createQuestionType = CreateQuestionVariants[question.format];

  const questionBase: CreateBasicQuestionRequest = {
    title: question.title,
    type: createQuestionType,
  };

  switch (createQuestionType) {
    case CreateQuestionVariants.TextQuestion:
    case CreateQuestionVariants.NPSQuestion:
      return questionBase;
    case CreateQuestionVariants.MultipleChoiceMultipleSelectQuestion:
    case CreateQuestionVariants.MultipleChoiceSingleSelectQuestion: {
      assert(question.choices, 'Property "choices" missing!');
      const multipleChoiceSingleSelectQuestion: CreateMultipleChoiceSingleSelectQuestionRequest = {
        ...questionBase,
        choices: question.choices.map((c) => c.value),
      };
      return multipleChoiceSingleSelectQuestion;
    }
    case CreateQuestionVariants.RankingQuestion: {
      assert(question.choices, 'Property "choices" missing!');
      const rankingQuestion: CreateRankingQuestionRequest = {
        ...questionBase,
        choices: question.choices.map((c) => c.value),
        randomizeChoices: !!question.randomizeChoices,
      };
      return rankingQuestion;
    }
    case CreateQuestionVariants.SliderQuestion: {
      const rankingQuestion: CreateSliderQuestionRequest = {
        ...questionBase,
        incrementValue: question.incrementValue ?? 1,
        maximumValue: question.maximumValue ?? 100,
        maximumValueLabel: question.maximumValueLabel ?? '',
        minimumValue: question.minimumValue ?? 0,
        minimumValueLabel: question.minimumValueLabel ?? '',
      };
      return rankingQuestion;
    }
  }
};
