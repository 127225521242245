import React, { FC, useMemo, useState } from 'react';

import { isDefined } from 'common/utils';

import { FilterBase } from '../FilterBase';
import { MultipleSelectFilterContent } from './MultipleSelectFilterContent';
import { MultipleSelectFilterOption } from './types';

type Props = {
  value: (string | number)[];
  onChange(value: (string | number)[]): void;
  label: string;
  options: MultipleSelectFilterOption[];
  searchLabel?: string;
  fullWidth?: boolean;
};

export const MultipleSelectFilter: FC<Props> = ({
  value,
  onChange,
  label,
  searchLabel = `Search ${label}`,
  options,
  fullWidth = false,
}) => {
  const selectedOptions = useMemo(
    () => value.map((v) => options.find((o) => o.value === v)).filter(isDefined),
    [options, value]
  );
  const buttonLabel = useMemo(() => {
    switch (selectedOptions.length) {
      case 0:
        return label;
      case 1:
        return selectedOptions[0].label;
      default:
        return `${selectedOptions[0].label} +${selectedOptions.length - 1}`;
    }
  }, [selectedOptions, label]);
  const [tmpValue, setTmpValue] = useState<(string | number)[]>(value);

  const resetValue = () => {
    onChange([]);
  };

  const applyChanges = () => {
    onChange(tmpValue);
  };

  const handleOpen = () => {
    setTmpValue(value);
  };

  return (
    <>
      <FilterBase
        buttonLabel={buttonLabel}
        isValueSelected={value.length > 0}
        label={label}
        onApply={applyChanges}
        onReset={resetValue}
        onOpen={handleOpen}
        fullWidth={fullWidth}
      >
        <MultipleSelectFilterContent
          label={label}
          searchLabel={searchLabel}
          options={options}
          value={tmpValue}
          onChange={setTmpValue}
        />
      </FilterBase>
    </>
  );
};
