import { BroadcastWSActions } from 'common/constants';
import { AgoraCloudPlayerInfo } from 'domain/Broadcast';
import { createMeetingWSAction } from 'store/utils';

import { MeetingWSParams } from '../types/meeting.types';

export const playVideoViaUrl_WS = createMeetingWSAction<MeetingWSParams<AgoraCloudPlayerInfo>>(
  BroadcastWSActions.PlayVideoViaUrl
);
export const stopVideoViaUrl_WS = createMeetingWSAction<MeetingWSParams>(BroadcastWSActions.StopVideoViaUrl);
export const pauseVideoViaUrl_WS = createMeetingWSAction<MeetingWSParams<{ timestamp: number }>>(
  BroadcastWSActions.PauseVideoViaUrl
);
export const resumeVideoViaUrl_WS = createMeetingWSAction<MeetingWSParams<{ timestamp: number }>>(
  BroadcastWSActions.ResumeVideoViaUrl
);
