import { SxProps, Theme } from '@mui/material';

/**
 * Merges multiple `SxProps` objects into a single `SxProps` object.
 * @link https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
 */
export const mergeSxProps = (...sx: Array<SxProps<Theme> | undefined>) => {
  if (!sx.length) return undefined;

  return sx.reduce((acc, curr) => {
    if (!Array.isArray(acc) || typeof curr === 'undefined') return acc;

    acc.push(...(Array.isArray(curr) ? curr : [curr]));
    return acc;
  }, [] as SxProps<Theme>);
};
