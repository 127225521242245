import { useCallback, useEffect, useRef, useState } from 'react';

import AgoraRTC, { type ICameraVideoTrack } from 'agora-rtc-react';
import { logger } from 'common/services';
import { DEFAULT_VIDEO_ENCODER_PRESET, LOCAL_VIDEO_CONFIG } from 'devices/constants';
import { disposeLocalTrack } from 'devices/utils';
import type { BlurLevel } from 'domain/event';

import { useVideoBlur } from '../useVideoBlur';

export type UseCameraProps = {
  blurLevel: BlurLevel;
  isCameraEnabled: boolean;
  videoDeviceId: string;
};

export const useCameraTest = ({ blurLevel, isCameraEnabled, videoDeviceId }: UseCameraProps) => {
  const [isVideoOn, setIsVideoOn] = useState(false);
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const videoTrackRef = useRef<ICameraVideoTrack | null>(null);

  const { applyBlurForVideoTrack } = useVideoBlur();

  const cleanupVideoTrack = useCallback(() => {
    if (!videoTrackRef.current) return;

    logger.addBreadcrumb('[useCameraTest] Cleanup video track');

    disposeLocalTrack(videoTrackRef.current);
    videoTrackRef.current = null;

    setIsVideoOn(false);
  }, []);

  const initVideoTrack = useCallback(async (cameraId: string) => {
    logger.addBreadcrumb('[useCameraTest] Init video track', { data: { cameraId } });

    try {
      videoTrackRef.current = await AgoraRTC.createCameraVideoTrack({
        cameraId,
        encoderConfig: DEFAULT_VIDEO_ENCODER_PRESET,
      });
      videoTrackRef.current.play(videoElementRef.current!, LOCAL_VIDEO_CONFIG);

      setIsVideoOn(true);
    } catch (error) {
      logger.error(error);
    }
  }, []);

  useEffect(() => {
    logger.addBreadcrumb('[useCameraTest] Handle initial setup', {
      data: { videoDeviceId, isCameraEnabled, doesVideoTrackExists: !!videoTrackRef.current },
    });

    if (!!videoDeviceId && isCameraEnabled && !videoTrackRef.current) {
      initVideoTrack(videoDeviceId);
    }
  }, [videoDeviceId, isCameraEnabled, initVideoTrack]);

  useEffect(() => {
    logger.addBreadcrumb('[useCameraTest] Handle toggle camera', { data: { isCameraEnabled } });

    if (!isCameraEnabled) {
      cleanupVideoTrack();
    }
  }, [isCameraEnabled, cleanupVideoTrack]);

  useEffect(() => {
    logger.addBreadcrumb('[useCameraTest] Handle video device change', { data: { videoDeviceId } });

    if (videoDeviceId && videoTrackRef.current) {
      initVideoTrack(videoDeviceId);
    }

    return () => {
      cleanupVideoTrack();
    };
  }, [videoDeviceId, cleanupVideoTrack, initVideoTrack]);

  useEffect(() => {
    if (!videoTrackRef.current) return;

    logger.addBreadcrumb('[useCameraTest] Handle blur change', { data: { blurLevel, isVideoOn } });

    if (isVideoOn) {
      applyBlurForVideoTrack(videoTrackRef.current, blurLevel);
    }
  }, [blurLevel, isVideoOn, applyBlurForVideoTrack]);

  useEffect(
    () => () => {
      cleanupVideoTrack();
    },
    [cleanupVideoTrack]
  );

  return {
    videoElementRef,
  };
};
