import { flow, startCase, toLower } from 'lodash/fp';

/**
 * Converts a constant string to a label format.
 *
 * This function takes a constant string (typically in uppercase with underscores)
 * and transforms it into a more human-readable label format. The transformation
 * involves converting the string to lowercase and then capitalizing the first
 * letter of each word.
 *
 * @param constant - The constant string to be converted.
 * @returns The formatted label string.
 *
 * @example
 * constantToLabel('COMMUNITY_ADMIN'); // 'Community Admin'
 */
export const constantToLabel = (constant: string) => flow(toLower, startCase)(constant);
