import React, { FC, useEffect, useRef } from 'react';

import { TableCell, TableRow } from '@mui/material';
import { Loadable } from 'common/components/Loadable';
import { useIsInViewport } from 'common/hooks/useIsInViewport/useIsInViewport';

export const LoadMoreRow: FC<{
  onFetchNextPage(): void;
  colSpan?: number;
}> = ({ onFetchNextPage, colSpan }) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const isOnScreen = useIsInViewport(ref, { freezeOnceVisible: true });

  useEffect(() => {
    if (!isOnScreen) {
      return;
    }
    onFetchNextPage();
  }, [isOnScreen, onFetchNextPage]);

  return (
    <TableRow ref={ref}>
      <TableCell colSpan={colSpan}>
        <Loadable isLoading sx={{ height: 100 }} />
      </TableCell>
    </TableRow>
  );
};
