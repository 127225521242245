import { logger } from 'common/services';
import { ParseLocalConfig, ParseResult, parse } from 'papaparse';

type Props<Result = unknown> = {
  onCompleteUpload: (data: ParseResult<Result>, file: File) => void;
  onError?: (error: unknown, file: File) => void;
  delimiter?: string;
} & Pick<ParseLocalConfig<Result>, 'header'>;

// TODO Apply RTK Query pattern like `return { parseFile, data, isLoading, isError, ... }`
export const useParseCSV = <Result = unknown>({
  onCompleteUpload,
  onError,
  header = true,
  delimiter = ',',
  ...options
}: Props<Result>) => {
  const parseFile = (selectedFile: File) => {
    parse<Result, File>(selectedFile, {
      delimiter,
      skipEmptyLines: true,
      complete: (data, file) => {
        if (data.errors.length) {
          logger.error('Error while parsing CSV file', { error: data });
          onError?.(data.errors, file);
        }

        return onCompleteUpload(data, file);
      },
      header,
      error: (error, file) => {
        logger.error('Error while reading CSV file', { error });

        return onError?.(error, file);
      },
      ...options,
    });
  };

  return { parseFile };
};
