import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { ImageFile } from 'common/components/_legacy/Form';
import { assert } from 'common/utils';
import { mapTagsCategoriesToDefaultValues } from 'community/components';
import { KnownTagsCategoryTypes } from 'domain/Common';
import { Community, CommunityFormValues, CommunityGuest, UpdateCommunityData } from 'domain/Community';
import { useAppDispatch, useAppSelector } from 'store';
import { selectAvailableTagsCategories } from 'store/apis/community';
import { addAppErrorMessage } from 'store/features/alerts';
import { AnyObjectSchema } from 'yup';

interface CommunityWizardProps {
  community?: Community;
  stepsToFormValues: (keyof CommunityFormValues)[][];
  defaultValues?: { [key: string]: unknown };
  schema: AnyObjectSchema;
  onCreateCommunity(value: CommunityFormValues): Promise<{ id: number }>;
  onUpdateCommunity(value: UpdateCommunityData): Promise<unknown>;
  onUploadCommunityLogo(value: { logo: ImageFile; communityId: number }): Promise<unknown>;
  onInviteMembersToCommunity?(value: { guests: CommunityGuest[]; communityId: number | undefined }): Promise<unknown>;
  onSuccess?(): void;
}

export const useCommunityWizard = ({
  stepsToFormValues,
  community,
  defaultValues,
  schema,
  onSuccess,
  onUpdateCommunity,
  onUploadCommunityLogo,
  onInviteMembersToCommunity,
  onCreateCommunity,
}: CommunityWizardProps) => {
  const isEditMode = !!community;
  const dispatch = useAppDispatch();
  const communityTagsCategories = community?.tagsCategories ?? [];
  const defaultTagsCategories = useAppSelector(selectAvailableTagsCategories);
  const tagsCategories = communityTagsCategories.length ? communityTagsCategories : defaultTagsCategories;
  const optionalTagsCategory = tagsCategories.find(({ type }) => type === KnownTagsCategoryTypes.Additional);
  const [step, setStep] = useState(0);
  const { palette } = useTheme();

  const methods = useForm<CommunityFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: community?.name ?? '',
      color: {
        hex: community?.brandingColor ?? palette.primary.main,
      },
      calendarPermissionsRequired: !!community?.calendarPermissionsRequired,
      logo: {
        data: community?.logoImageLink,
        isLoading: false,
      },
      guests: [],
      tagsCategories: mapTagsCategoriesToDefaultValues(tagsCategories),
      ...defaultValues,
    },
    mode: 'onChange',
  });

  const { reset, watch, trigger, setValue } = methods;

  const isImageLoading = watch('logo.isLoading');

  const handleStepChange = useCallback(
    async (currentStep: number, nextStep: number) => {
      if (isImageLoading) return;

      const fields = stepsToFormValues[currentStep];
      const isValid = await trigger(fields);
      if (!isValid) {
        dispatch(
          addAppErrorMessage({
            metadata: 'CommunityWizard.handleStepChange',
            message: 'An error has occurred, check your inputs and try again',
          })
        );
        setStep(currentStep);
        return;
      }
      setStep(nextStep);
    },
    [dispatch, trigger, isImageLoading, stepsToFormValues]
  );

  const updateCommunity = async (data: CommunityFormValues) => {
    assert(community);
    const updatedData: UpdateCommunityData = { ...community, ...data };
    await onUpdateCommunity(updatedData);

    if ('name' in data.logo) {
      await onUploadCommunityLogo({
        communityId: community.id,
        logo: data.logo,
      });
    }

    if (onInviteMembersToCommunity && data.guests.length) {
      await onInviteMembersToCommunity({
        communityId: community.id,
        guests: data.guests,
      });
    }

    reset(undefined, { keepValues: true, keepDirty: false });
    onSuccess?.();
  };

  const submitCreateCommunityForm = async (values: CommunityFormValues) => {
    try {
      const newCommunity = await onCreateCommunity(values);

      if (newCommunity.id) {
        const { logo, guests } = values;

        if ('name' in logo) {
          await onUploadCommunityLogo({
            communityId: newCommunity.id,
            logo,
          });
        }

        if (onInviteMembersToCommunity && guests.length) {
          await onInviteMembersToCommunity({
            communityId: newCommunity.id,
            guests,
          });
        }
      }

      // required so there is no 'Are you sure you want to leave this page?' modal when leaving
      reset(undefined, { keepValues: true });
      onSuccess?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = methods.handleSubmit(
    (data) => (isEditMode ? updateCommunity(data) : submitCreateCommunityForm(data)),
    (errors) => {
      console.error(errors);
    }
  );

  const saveChanges = async () => {
    const submit = methods.handleSubmit(
      (data) => updateCommunity(data),
      (errors) => {
        console.error(errors);
      }
    );
    await submit();
  };

  useEffect(() => {
    setValue(
      'tagsCategories',
      mapTagsCategoriesToDefaultValues(tagsCategories) as unknown as CommunityFormValues['tagsCategories']
    );
  }, [setValue, tagsCategories]);

  return {
    optionalTagsCategory,
    step,
    methods,
    // for some reason methods.formState.isDirty returns true
    isFormDirty: Object.keys(methods.formState.dirtyFields).length > 0,
    handleStepChange,
    handleSubmit,
    saveChanges,
  };
};
