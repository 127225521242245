import React, { FC, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { Box } from '@mui/material';

import { StyledSwitch } from '../StyledSwitch';

export type SwitchInputProps = {
  name: string;
  label: string;
  value?: boolean;
  defaultValue?: boolean;
};

export const SwitchInput: FC<SwitchInputProps> = ({ name, label, defaultValue = false, ...props }) => {
  const {
    field: { value: fieldValue, onChange },
  } = useController({ name, defaultValue });

  const onChangeSwitch = useCallback(
    ({ target }) => {
      onChange(target.checked);
    },
    [onChange]
  );

  return (
    <Box
      width={1}
      display="flex"
      alignItems="center"
      justifyContent="start"
      sx={{ fontSize: 14 }}
      data-testid={`switch-input-${name}`}
    >
      <StyledSwitch
        checked={!!fieldValue}
        onChange={onChangeSwitch}
        {...props}
        inputProps={{ 'aria-label': `switch-input-${name}` }}
      />
      {label}
    </Box>
  );
};
