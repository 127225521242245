import React, { FC } from 'react';

import { Tooltip, TooltipProps } from '../../Tooltip';
import { IconButton, IconButtonProps } from '../IconButton';

type Props = IconButtonProps & Pick<TooltipProps, 'title'>;

export const IconButtonWithTooltip: FC<Props> = ({ title, ...iconButtonProps }) => (
  <Tooltip title={title} placement="bottom-end">
    <IconButton {...iconButtonProps} />
  </Tooltip>
);
