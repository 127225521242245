import React, { CSSProperties, FC } from 'react';
import { useWatch } from 'react-hook-form';

import { useBreakpoints } from 'common/hooks';
import { DevicesDefaultFormValues } from 'devices';
import type { BlurLevel } from 'domain/event';

import { CameraTest } from '../CameraTest';
import { CameraPreviewActions } from './components';

type Props = {
  showMobileMicrophoneControl?: boolean;
  aspectRatio?: CSSProperties['aspectRatio'];
  onChange?(): void;
};

export const CameraPreviewSection: FC<Props> = ({
  showMobileMicrophoneControl = true,
  aspectRatio = '16/9 auto',
  onChange,
}) => {
  const { isSmallScreen } = useBreakpoints();
  const isBlurEnabled = useWatch<DevicesDefaultFormValues, 'isBlurEnabled'>({ name: 'isBlurEnabled' });
  const cameraId = useWatch<DevicesDefaultFormValues, 'cameraId'>({ name: 'cameraId' });
  const isCameraEnabled = useWatch<DevicesDefaultFormValues, 'isCameraEnabled'>({ name: 'isCameraEnabled' });

  return (
    <CameraTest
      videoDeviceId={cameraId}
      isCameraEnabled={isCameraEnabled}
      blurLevel={Number(isBlurEnabled) as BlurLevel}
      sx={{ aspectRatio }}
    >
      {isSmallScreen && (
        <CameraPreviewActions showMicrophoneControl={showMobileMicrophoneControl} onChange={onChange} />
      )}
    </CameraTest>
  );
};
