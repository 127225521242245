import React, { ComponentType, FC, ReactElement } from 'react';

import { UserPrivilegeName } from 'domain/UserProfile';

import { useAuthorization } from '../../hooks';

interface WithPrivilegeDependencyProps {
  privilegeAvailability?: UserPrivilegeName[];
  withNoCommunity?: boolean;
  withCommunity?: boolean;
}

export const withPrivilegeDependency =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P & WithPrivilegeDependencyProps> =>
  ({ privilegeAvailability = [], withNoCommunity, withCommunity, ...props }): ReactElement | null => {
    const { hasPrivilege, isWithoutCommunity } = useAuthorization();

    if (
      hasPrivilege(privilegeAvailability) ||
      (withNoCommunity && isWithoutCommunity) ||
      (withCommunity && !isWithoutCommunity)
    ) {
      return <WrappedComponent {...(props as P)} />;
    }

    return null;
  };
