/* eslint-disable @typescript-eslint/no-empty-interface */

/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material';

const natterBreakpoints = {
  mobile: 0,
  tablet: 1024,
  desktop: 1440,
  small: 400,
  /** **Hug**: 584px */
  hug: 584,
  page: 800,
} as const;

type NatterBreakpoints = keyof typeof natterBreakpoints;

export const { breakpoints } = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1504, // Microsoft surface issue -> NAT-1203, NAT-1608,

      ...natterBreakpoints,
    },
  },
});

declare module '@mui/material/styles' {
  interface BreakpointOverrides extends Record<NatterBreakpoints, true> {}
}
