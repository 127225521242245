import { useCallback, useEffect, useState } from 'react';

import type { IVirtualBackgroundProcessor } from 'agora-extension-virtual-background';
import type { ICameraVideoTrack } from 'agora-rtc-react';
import { logger } from 'common/services';

import { initVideoProcessor } from './utils';

export const useVideoBlur = () => {
  const [isBlurSupported, setIsBlurSupported] = useState(true);
  const [virtualBackgroundProcessor, setVirtualBackgroundProcessor] = useState<IVirtualBackgroundProcessor | undefined>(
    undefined
  );

  useEffect(() => {
    initVideoProcessor({
      onBlurNotSupported: () => setIsBlurSupported(false),
      onSuccess: setVirtualBackgroundProcessor,
    });
  }, []);

  const enableBlur = useCallback(
    (videoTrack: ICameraVideoTrack, blurLevel: number) => {
      if (!virtualBackgroundProcessor) return;

      try {
        virtualBackgroundProcessor.setOptions({ type: 'blur', blurDegree: blurLevel });
        virtualBackgroundProcessor.enable();
        videoTrack.pipe(virtualBackgroundProcessor).pipe(videoTrack.processorDestination);
      } catch (error) {
        logger.error(error);
      }
    },
    [virtualBackgroundProcessor]
  );

  const disableBlur = useCallback(() => {
    virtualBackgroundProcessor?.disable();
  }, [virtualBackgroundProcessor]);

  const applyBlurForVideoTrack = useCallback(
    (videoTrack: ICameraVideoTrack, newBlurLevel: number) => {
      if (isBlurSupported && newBlurLevel) {
        enableBlur(videoTrack, newBlurLevel);
      } else {
        disableBlur();
      }
    },
    [isBlurSupported, enableBlur, disableBlur]
  );

  return {
    isBlurSupported,
    isBlurReady: !isBlurSupported || !!virtualBackgroundProcessor,
    applyBlurForVideoTrack,
  };
};
