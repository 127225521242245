import { useCallback, useState } from 'react';

export const useTooltip = ({
  initialActiveKey,
}: {
  initialActiveKey?: string;
} = {}) => {
  const [activeKey, setActiveKey] = useState<string | undefined>(initialActiveKey);

  const handleMouseEnter = useCallback((key: string) => () => setActiveKey(key), []);
  const hideTooltip = useCallback(() => setActiveKey(undefined), []);

  return {
    handleMouseEnter,
    hideTooltip,
    activeKey,
  };
};
