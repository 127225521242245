export const AlertVariant = {
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  InfoGray: 'infoGray',
  Transparent: 'transparent',
} as const;

export type AlertVariantType = (typeof AlertVariant)[keyof typeof AlertVariant];
