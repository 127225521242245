import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';
import classNames from 'classnames';
import { Tag, TagsCategory } from 'domain/Common';

import { useFilterByTagsStyles } from './FilterByTags.styles';
import { FilterByTagsModal } from './components';

export const FilterByTags: FC<{
  tagsCategories: TagsCategory[];
  selectedTags: Tag[];
  onChange(tags: Tag[]): void;
}> = ({ tagsCategories, selectedTags, onChange }) => {
  const classes = useFilterByTagsStyles();
  const [filtersAnchor, setFiltersAnchor] = useState<Element | null>(null);
  const handleFilterByTagsClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setFiltersAnchor(filtersAnchor ? null : event.currentTarget);
  const handleMembersFilterClose = () => setFiltersAnchor(null);

  return (
    <>
      <div
        onClick={handleFilterByTagsClick}
        className={classNames(classes.filterByTagsContainer, !!filtersAnchor && classes.filterByTagsSelected)}
      >
        <Typography variant="button" fontWeight="bold" marginLeft={1}>
          Filter by tags
        </Typography>
        {selectedTags.length ? <div className={classes.badge}>{selectedTags.length}</div> : null}
      </div>
      <FilterByTagsModal
        tagsCategories={tagsCategories}
        anchor={filtersAnchor}
        onClose={handleMembersFilterClose}
        initialSelectedTags={selectedTags}
        onChange={onChange}
      />
    </>
  );
};
