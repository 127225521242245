import React, { FC } from 'react';

import { TooltipProps } from 'recharts';

import { ChartType, TooltipContent as TooltipContentType } from '../../types';
import { TooltipWrapper } from './TooltipContentWrapper.styled';

type Props = TooltipProps<number, string> & {
  activeDataKey?: string;
  isPercentageValue?: boolean;
  TooltipContent?: TooltipContentType;
  shouldDisplay?(currentDataPointPayload: CurrentDataPointPayload): boolean;
  chartType?: ChartType;
};

type CurrentDataPointPayload = { name: string; fill: string; x0: number; x1: number };
type TooltipPayload = { fill: string; value: number; dataKey: string; payload: CurrentDataPointPayload };

export const TooltipContentWrapper: FC<Props> = ({
  payload,
  activeDataKey = 'value',
  isPercentageValue,
  TooltipContent,
  chartType,
  shouldDisplay,
}) => {
  const {
    fill,
    value,
    dataKey,
    payload: currentDataPointPayload = {} as CurrentDataPointPayload,
  } = (payload?.find((p) => p.dataKey === activeDataKey) ?? {}) as TooltipPayload;
  const { name, fill: currentDataPointFill, x0, x1 } = currentDataPointPayload;
  const isDisplayed = shouldDisplay ? shouldDisplay(currentDataPointPayload) : true;
  const showTooltip = name !== undefined && isDisplayed;

  return (
    <TooltipWrapper backgroundColor={fill ?? currentDataPointFill} visibility={showTooltip ? 'visible' : 'hidden'}>
      {TooltipContent ? (
        <TooltipContent
          value={value}
          name={name}
          dataKey={dataKey}
          range={[x0, x1]}
          isPercentageValue={isPercentageValue}
          chartType={chartType}
          dataPoint={currentDataPointPayload}
        />
      ) : (
        value
      )}
    </TooltipWrapper>
  );
};
