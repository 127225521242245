import React, { FC, useRef } from 'react';

import { Box } from '@mui/material';
import { MutedOnASystemLevelNotification } from 'devices/components/MutedOnASystemLevelNotification';

import { AudioInputVolume } from './components';
import { useAudioTest } from './useAudioTest';

type AudioTestsProps = {
  deviceId: string;
};

export const AudioInputTest: FC<AudioTestsProps> = ({ deviceId }) => {
  const { inputLevel, isMutedOnSystemLevel } = useAudioTest({ audioInputDeviceId: deviceId });
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {isMutedOnSystemLevel && <MutedOnASystemLevelNotification anchorEl={anchorRef.current} />}
      <Box ref={anchorRef}>
        <AudioInputVolume inputLevel={inputLevel} />
      </Box>
    </>
  );
};
