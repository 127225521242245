import { ButtonProps, IconButtonProps } from '@mui/material';

import { LOADER_SIZE } from '../constants';

export const mapButtonSizeToLoaderSize = (size: ButtonProps['size'] | IconButtonProps['size']) => {
  switch (size) {
    case 'large':
      return LOADER_SIZE.large;
    case 'small':
      return LOADER_SIZE.small;
    case 'medium':
    default:
      return LOADER_SIZE.medium;
  }
};
