import { type Components, type Theme } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      color: palette.grey[700],
      borderColor: palette.grey[200],
    }),
    head: ({ theme: { spacing, palette, typography } }) => ({
      padding: spacing(0.5, 1, 0.5, 2),
      borderColor: palette.grey[300],
      ...typography['natter-text-xs'],
      fontWeight: 600,
      '& span': {
        color: palette.text.secondary,
      },
      '&:nth-of-type(1):not(.MuiTableCell-paddingCheckbox)': {
        paddingLeft: spacing(2),
      },
    }),
    body: ({ theme: { spacing, typography }, ownerState: { children } }) => ({
      maxWidth: 0,
      padding: spacing(1, 2),
      height: spacing(5),
      ...typography['natter-text-sm'],

      ...(typeof children === 'string' && {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }),

      '&:nth-of-type(1):not(.MuiTableCell-paddingCheckbox)': {
        paddingLeft: spacing(2),
      },
    }),
    paddingCheckbox: {
      '&&': {
        paddingLeft: 0,
        width: 48,
      },
    },
  },
};
