import { Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      color: palette.mode === 'light' ? palette.divider : palette.custom.darkGrey,
      '& > .MuiSvgIcon-root': {
        width: 29.34,
        height: 29.34,
      },
    }),
  },
};
