import React, { FC } from 'react';

import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Stack } from '@mui/material';
import { DialogTitle } from 'common/components/_legacy/DialogTitle';
import { CommunitySuccessIcon } from 'community/components/icons/communitySuccess';

interface CommunityCreationSuccessProps {
  isOpen: boolean;
}

export const CommunityCreationSuccess: FC<CommunityCreationSuccessProps> = ({ children, isOpen }) => (
  <Dialog open={isOpen} maxWidth="sm">
    <DialogContent>
      <Stack alignItems="center" pt={2}>
        <Box sx={{ width: 112, height: 83 }}>
          <CommunitySuccessIcon />
        </Box>
        <DialogTitle title="Community Creation Successful" />
        <DialogContentText component="div">
          <div>You have successfully created a community.</div>
          <div>Create an Event to get started.</div>
        </DialogContentText>
      </Stack>
    </DialogContent>
    <DialogActions>{children}</DialogActions>
  </Dialog>
);
