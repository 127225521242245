import { QuestionWSChannels, QuestionsWSActions, QuestionsWSMessages } from 'common/constants';
import { CreateAnswerRequest } from 'domain/question';
import { createQuestionsWSAction, createQuestionsWSMessage } from 'store/utils';

import { SocketRoomPrefix } from '../socket';
import { createWSSubscribeAction } from '../socket/utils/createWSSubscribeAction';
import { createWSUnsubscribeAction } from '../socket/utils/createWSUnsubscribeAction';
import type {
  AnonymiseAnswerRequest,
  AnswerAnonymizedPayload,
  AnswerAssignedToUserIdsPayload,
  AnswerCreatedPayload,
  AnswerDeletedPayload,
  AnswerUpdatedPayload,
  AssignAnswerToUserIdsRequest,
  DeleteAnswerRequest,
  DisabledPayload,
  EnabledUntilPayload,
  QuestionAssignmentWSPayload,
  UpdateAnswerRequest,
} from './types';

export const questionAssignment_WS = createQuestionsWSMessage<QuestionAssignmentWSPayload>(
  QuestionsWSMessages.QuestionAssignment
);
export const enabledUntil_WS = createQuestionsWSMessage<EnabledUntilPayload>(QuestionsWSMessages.EnabledUntil);
export const disabled_WS = createQuestionsWSMessage<DisabledPayload>(QuestionsWSMessages.Disabled);
export const answerAssignedToUserIds_WS = createQuestionsWSMessage<AnswerAssignedToUserIdsPayload>(
  QuestionsWSMessages.AnswerAssignedToUserIds
);
export const answerAnonymized_WS = createQuestionsWSMessage<AnswerAnonymizedPayload>(
  QuestionsWSMessages.AnswerAnonymised
);
export const answerCreated_WS = createQuestionsWSMessage<AnswerCreatedPayload>(QuestionsWSMessages.AnswerCreated);
export const answerDeleted_WS = createQuestionsWSMessage<AnswerDeletedPayload>(QuestionsWSMessages.AnswerDeleted);
export const answerUpdated_WS = createQuestionsWSMessage<AnswerUpdatedPayload>(QuestionsWSMessages.AnswerUpdated);

export const createAnswer_WS = createQuestionsWSAction<CreateAnswerRequest>(QuestionsWSActions.AnswerCreate);
export const assignToUserIds_WS = createQuestionsWSAction<AssignAnswerToUserIdsRequest>(
  QuestionsWSActions.AnswerAssignToUserIds
);
export const anonymiseAnswer_WS = createQuestionsWSAction<AnonymiseAnswerRequest>(QuestionsWSActions.AnswerAnonymise);
export const updateAnswer_WS = createQuestionsWSAction<UpdateAnswerRequest>(QuestionsWSActions.AnswerUpdate);
export const deleteAnswer_WS = createQuestionsWSAction<DeleteAnswerRequest>(QuestionsWSActions.AnswerDelete);

export const subscribeToQuestions = createWSSubscribeAction(SocketRoomPrefix.Questions, QuestionWSChannels);
export const unsubscribeFromQuestions = createWSUnsubscribeAction(SocketRoomPrefix.Questions, QuestionWSChannels);
