import { createSelector } from '@reduxjs/toolkit';
import { EventQuestionAssignmentType, EventStatus } from 'domain/event';
import type { RootState } from 'store';
import { selectActiveEventDetails } from 'store/apis/event';

import { EventPhase } from './types';
import { findRoomById } from './utils';

export const selectActiveEventId = (state: RootState) => state.event.activeEventId;
export const selectRooms = (state: RootState) => state.event.rooms;
export const selectRoomsCount = (state: RootState) => state.event.roomsCount;
export const selectActiveRoom = (state: RootState) => state.event.activeRoom;
export const selectActiveRoomId = (state: RootState) => state.event.activeRoom?.id;
export const selectActiveRoomDetails = createSelector([selectRooms, selectActiveRoomId], (connectedRooms, roomId) =>
  findRoomById(connectedRooms, roomId)
);
export const selectEndOfRoomQuestionsAssignment = createSelector(selectActiveRoomDetails, (activeRoom) =>
  activeRoom?.roomQuestionAssignments?.filter(
    ({ assignmentType }) => assignmentType === EventQuestionAssignmentType.EndOfRoom
  )
);
export const selectRoomQuestionAssignment = createSelector(selectActiveRoomDetails, (activeRoom) =>
  activeRoom?.roomQuestionAssignments?.find(({ assignmentType }) => assignmentType === EventQuestionAssignmentType.Room)
);
export const selectRoomsRequestingSupport = (state: RootState) => state.event.roomsRequestingSupport;
export const selectIsRoomSupportRequested = createSelector(
  [selectRoomsRequestingSupport, (_, roomId: string | undefined) => roomId],
  (roomsRequestingSupport, roomId) => (roomId ? roomsRequestingSupport.includes(roomId) : false)
);
export const selectPinValue = (state: RootState) => state.event.pin;
export const selectBroadcast = (state: RootState) => state.event.broadcast;
export const selectEventMetrics = (state: RootState) => state.event.metrics;
export const selectIsBroadcastCountdown = (state: RootState) => !!state.event.activeRoom?.isBroadcastCountdown;
export const selectServerTimeOffset = (state: RootState) => state.event.serverTimeOffset;

export const selectScreensharingDetails = (state: RootState) => state.event.screensharingDetails;
export const selectActiveRoomGuests = (state: RootState) => state.event.lastCallGuests;
export const selectAudience = (state: RootState) => state.event.audience;
export const selectSpeakers = (state: RootState) => state.event.speakers;
export const selectParticipants = createSelector([selectAudience, selectSpeakers], (audience, speakers) =>
  audience.concat(speakers)
);
export const selectParticipantById = createSelector(
  (_state: RootState, participantId?: number) => participantId,
  selectParticipants,
  (participantId, participants) => participants.find((p) => p.id === participantId)
);
export const selectStageInvitation = (state: RootState) => state.event.stageInvitation;
export const selectIsJoiningNattering = (state: RootState) => state.event.isJoiningNattering;
export const selectIsNatteringSkipped = (state: RootState) => state.event.isNatteringSkipped;
export const selectIsWaitingForNattering = (state: RootState) => state.event.isWaitingForNattering;
export const selectIsBroadcastStopped = (state: RootState) => state.event.isBroadcastStopped;
export const selectNatteringRoundEndTime = (state: RootState) => state.event.natteringRoundEndTime;
export const selectIsBigMeetingMode = (state: RootState) => state.event.isBigMeetingMode;
export const selectIsBroadcastBeingRecorded = (state: RootState) => state.event.broadcast?.isRecording;
export const selectAudienceCount = (state: RootState) => state.event.audienceCount;
export const selectCurrentEventPhase = createSelector(
  selectBroadcast,
  selectActiveRoom,
  selectActiveEventDetails,
  selectIsNatteringSkipped,
  (broadcast, activeRoom, event, isNatteringSkipped): EventPhase => {
    switch (true) {
      case activeRoom &&
        !event?.isOwned &&
        // either conversation finished or event was stopped
        (activeRoom.isConversationFinished || event?.status === EventStatus.Finished):
        return EventPhase.Feedback;
      case event?.status === EventStatus.Finished:
        return EventPhase.Ended;
      case !!activeRoom?.id:
        return EventPhase.OneOnOne;
      case Boolean(broadcast):
        return EventPhase.Broadcast;
      case event?.isOwned && !activeRoom:
        return EventPhase.Backstage;
      case isNatteringSkipped:
      default:
        return EventPhase.WaitingRoom;
    }
  }
);

export const selectParticipantsInvitedToStage = (state: RootState) => state.event.participantsInvitedToStage;
export const selectIsParticipantInvitedToStage = createSelector(
  [selectParticipantsInvitedToStage, (state: RootState, participantId: number) => participantId],
  (participantsInvitedToStage, participantId) => participantsInvitedToStage.includes(participantId)
);

export const selectParticipantsWithRaisedHands = (state: RootState) => state.event.participantsWithRaisedHands;
export const selectIsParticipantHandRaised = createSelector(
  [selectParticipantsWithRaisedHands, (state: RootState, participantId: number | undefined) => participantId],
  (participantsWithRaisedHands, participantId) =>
    participantId === undefined ? false : participantsWithRaisedHands.includes(participantId)
);
export const selectRaisedHandsAmount = (state: RootState) => state.event.participantsWithRaisedHands.length;
export const selectIsAnyParticipantHandRaised = (state: RootState) =>
  state.event.participantsWithRaisedHands.length > 0;
export const selectRaisedHandAlertText = createSelector(
  selectParticipantsWithRaisedHands,
  selectParticipants,
  (participantsWithRaisedHands, participants) => {
    const firstParticipantsWithRaisedHand = participantsWithRaisedHands.slice(0, 2);
    const participantNames = firstParticipantsWithRaisedHand.length
      ? participants.filter((p) => firstParticipantsWithRaisedHand.includes(p.id)).map((p) => p.name)
      : [];
    switch (participantsWithRaisedHands.length) {
      case 0:
        return `${participantsWithRaisedHands.length} people have raised hands`;
      case 1:
        return `${participantNames[0]} has raised a hand`;
      case 2:
        return `${participantNames.join(' and ')} have raised hands`;
      default:
        return `${participantNames.join(', ')} and ${participantsWithRaisedHands.length - participantNames.length} more have raised hands`;
    }
  }
);

export const selectParticipantsDisconnected = (state: RootState) => state.event.participantsDisconnected;
export const selectIsParticipantDisconnected = createSelector(
  [selectParticipantsDisconnected, (state: RootState, participantId: number | undefined) => participantId],
  (participantsDisconnected, participantId) =>
    participantId === undefined ? false : participantsDisconnected.includes(participantId)
);

export const selectShowAudienceList = (state: RootState) => state.event.showAudienceList;
export const selectActivePanelTab = (state: RootState) => state.event.activePanelTab;
export const selectShowTranscriptionModal = (state: RootState) => !!state.event.activeRoom?.showTranscriptionModal;
export const selectIsTranscribing = (state: RootState) => state.event.activeRoom?.isTranscribing ?? false;
