import { Dispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { initMergedDataDispatch } from 'store/utils/throttledDispatch';

import { reactionReceived_WS } from './actions';
import { resetReactionsState, setReactionsForUser } from './reactionsSlice';
import { ReceivedReaction } from './types';

type ReactionHandlerType = ReturnType<typeof initMergedDataDispatch<string, { reaction: string; userId: number }>>;

const REACTIONS_THROTTLE_TIMEOUT = 1000;
const reactionsHandlersMap = new Map<number, Map<string, ReactionHandlerType>>();

/**
 * Throttles reactions separately for each user and each reaction type
 * @param dispatch
 * @param receivedReaction
 */
const groupReactions = (dispatch: Dispatch, { reaction, userId, accountId }: ReceivedReaction) => {
  const reactionUserId = userId ?? accountId;
  // eslint-disable-next-line functional/no-let
  let specificUserReactionsHandlersMap = reactionsHandlersMap.get(reactionUserId);
  if (!specificUserReactionsHandlersMap) {
    specificUserReactionsHandlersMap = new Map<string, ReactionHandlerType>();
    reactionsHandlersMap.set(reactionUserId, specificUserReactionsHandlersMap);
  }
  // eslint-disable-next-line functional/no-let
  let handleSpecificReaction = specificUserReactionsHandlersMap.get(reaction);
  if (!handleSpecificReaction) {
    handleSpecificReaction = initMergedDataDispatch<string, { reaction: string; userId: number }>(
      (data, extras) => {
        dispatch(setReactionsForUser({ count: data.length, reaction: extras.reaction, userId: extras.userId }));
      },
      { timeout: REACTIONS_THROTTLE_TIMEOUT }
    );
    specificUserReactionsHandlersMap.set(reaction, handleSpecificReaction);
  }
  handleSpecificReaction([reaction], { dispatch, reaction, userId: reactionUserId });
};

export const reactionsListener = createListenerMiddleware<RootState>();

reactionsListener.startListening({
  actionCreator: reactionReceived_WS,
  effect: ({ payload }, { dispatch }) => {
    groupReactions(dispatch, payload);
  },
});

reactionsListener.startListening({
  actionCreator: resetReactionsState,
  effect: () => {
    reactionsHandlersMap.clear();
  },
});
