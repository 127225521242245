import React, { FC, MouseEventHandler, ReactNode } from 'react';

import { Breakpoint, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitle } from 'common/components/_legacy/DialogTitle';

export const SimpleModal: FC<{
  title: string;
  isOpen: boolean;
  actions?: ReactNode;
  handleClose?: MouseEventHandler<HTMLButtonElement>;
  maxWidth?: false | Breakpoint;
}> = ({ title, children, actions, isOpen, maxWidth = 'xs', handleClose }) => (
  <Dialog open={isOpen} onClose={handleClose} maxWidth={maxWidth} data-testid="shareThisEventDialog">
    <DialogTitle title={title} onClose={handleClose} data-testid={`simpleModal-${title}`} />
    <DialogContent sx={{ textAlign: 'center' }}>{children}</DialogContent>
    {!!actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
);
