import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useGuestsInputStyles = makeStyles(
  (theme) => ({
    inputContainer: {
      padding: theme.spacing(4),
      borderRadius: 4,
      border: `dashed 1px ${theme.palette.primary.main}`,
      backgroundColor: `${lighten(theme.palette.primary.main, 0.95)}`,
      textAlign: 'center',
      position: 'relative',
    },
    input: {
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      cursor: 'pointer',
    },
    sampleFile: {
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: theme.palette.custom.darkGrey,
    },
    guestsContainer: {
      maxHeight: 250,
      overflow: 'auto',
    },
    guestItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 56,
      borderBottom: `solid 1px ${theme.palette.custom.lightGrey}`,
    },
    underline: {
      textDecoration: 'underline',
    },
  }),
  { name: 'guestsInput' }
);
