import { type Components, type Theme } from '@mui/material';

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme: { spacing, palette } }) => ({
      padding: spacing(2, 3, 3),

      // Handle useConfirmationModal with "old" cancel button
      '& .MuiButton-outlined': {
        color: palette.grey[700],
        borderColor: 'transparent',
      },
    }),
  },
};
