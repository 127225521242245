import { InferType, array, boolean, number, object, string } from 'yup';

export const MAX_NUMBER_OF_TAGS = 50;

const generateMaxLengthError = ({ label, max }: { label: string; max: number }) =>
  `${label} must be less than ${max} characters long.`;
const generateMinLengthError = ({ label, min }: { label: string; min: number }) =>
  `${label} must be at least ${min} characters long.`;
const generateMaxTagsError = ({ max }: { max: number }) => `Maximum of ${max} tags allowed.`;
export const tagsRequiredError = 'You must add a minimum of one Tag for this Category.';
const categoryNameRequired = 'Tag Category Name can not be empty.';

export const tagValidation = object({
  name: string()
    .label('Tag')
    .trim()
    .transform((value: string) => value || undefined)
    .required()
    .min(2, generateMinLengthError)
    .max(255, generateMaxLengthError),
});

export const tagsValidation = array(tagValidation).min(1, tagsRequiredError).max(50, generateMaxTagsError);

export const tagCategorySchema = object({
  id: number().nullable(),
  name: string().label('Tag Category Name').trim().max(255, generateMaxLengthError).required(categoryNameRequired),
  tags: tagsValidation,
  isOptional: boolean().required(),
  isSingleSelection: boolean().required(),
  type: string().required(),
});

export const tagsCategoriesSchema = object({
  tagsCategories: array().of(tagCategorySchema).required(),
});

export type TagsCategoriesFormValues = InferType<typeof tagsCategoriesSchema>;
export type TagsCategoryFormValues = InferType<typeof tagCategorySchema>;
