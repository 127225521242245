import {
  EventListItemApiResponse,
  EventOwnerBase,
  EventRecording,
  EventRecordingApiResponse,
  EventRelatedVideo,
  EventTime,
  EventVideoApiResponse,
} from 'domain/event';
import { pick } from 'lodash';

export const mapOwnerBaseFromApi = ({
  ownerId,
  ownerName,
}: Pick<EventListItemApiResponse, 'ownerId' | 'ownerName'>): EventOwnerBase => ({
  id: ownerId,
  fullName: ownerName,
});

export const mapEventTimeFromApi = ({
  startTime,
  durationInMinutes,
}: {
  startTime: string;
  durationInMinutes: number;
}): EventTime => ({
  startTime,
  durationInMinutes,
});

export const mapEventRelatedVideoFromApi = (video: EventVideoApiResponse): EventRelatedVideo => ({
  ...pick(video, ['id', 'occurrenceOrder']),
  name: video.videoName,
  link: video.videoLink,
  createdAt: video.createdDate,
});

export const mapEventRecordingFromApi = (recording: EventRecordingApiResponse): EventRecording => recording;
