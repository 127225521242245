import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, type SxProps, type Theme } from '@mui/material';
import { ChevronLeftIcon } from 'icons';

import { Button } from '../Buttons';

type NavigateBackProps = {
  label?: string;
  sx?: SxProps<Theme>;
  onClick?(): void;
};

// TODO Replace with hierarchical routes and ".."" notation
export const NavigateBack: FC<NavigateBackProps> = ({ label = 'Back', sx, onClick, ...props }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const pathnames = pathname.split('/').filter(Boolean);

  const handleBackButtonClick = () => {
    history.push(`/${pathnames.slice(0, -1).join('/')}`);
  };

  if (pathnames.length <= 1) return null;

  return (
    <Box sx={sx} {...props}>
      <Button
        type="button"
        startIcon={<ChevronLeftIcon />}
        size="small"
        variant="text"
        onClick={onClick || handleBackButtonClick}
        sx={{ marginLeft: -1, padding: '0 12px !important' }}
        data-testid="navigate-back-button"
      >
        {label}
      </Button>
    </Box>
  );
};
