import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { Tooltip } from 'common/components/Tooltip';
import { CommunityMember } from 'domain/Community';

type Props = {
  member: Partial<CommunityMember>;
};

export const MemberTagsTooltip: FC<Props> = ({ member, children }) => (
  <Tooltip
    placement="bottom-start"
    title={
      <Stack p={1}>
        <Typography pb={2}>{member.displayName}</Typography>
        <Typography pb={1}>Tags</Typography>
        {!member.tagsCategories ||
          (member.tagsCategories.length === 0 && <Typography fontWeight={400}>Not available</Typography>)}
        {member.tagsCategories?.map((tagCategory) => (
          <Typography
            key={tagCategory.id}
            fontWeight={400}
          >{`${tagCategory.name}: ${tagCategory.tags.map((t) => t.name).join(', ')}`}</Typography>
        ))}
      </Stack>
    }
  >
    <>{children}</>
  </Tooltip>
);
