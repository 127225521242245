import { type Theme, useMediaQuery } from '@mui/material';

export const useBreakpoints = () => {
  const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'), { noSsr: true });
  const isTablet = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.between('sm', 'lg'), { noSsr: true });
  const isDesktop = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.up('lg'), { noSsr: true });
  const isSmallScreen = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('md'), { noSsr: true });

  return { isMobile, isTablet, isDesktop, isSmallScreen };
};
