import React, { FC } from 'react';
import { isChrome, isDesktop, isEdge } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Backdrop, Fade, Stack, Typography } from '@mui/material';

export interface NoMediaAccessModalProps {
  display: boolean;
  title?: string;
}

export const NoMediaAccessModal: FC<NoMediaAccessModalProps> = ({ display, title }) => {
  const { t } = useTranslation('devices', { keyPrefix: 'noMediaAccessModal' });
  const showHint = isDesktop && (isChrome || isEdge);

  return (
    <Fade in={display} style={{ transitionDelay: '1s' }}>
      <Backdrop
        open
        sx={{
          zIndex: 1301,
          backgroundColor: 'rgba(20, 30, 48, 0.91)',
        }}
      >
        <Stack textAlign="center" maxWidth={566} gap={3}>
          <Typography color="white" variant="natter-display-lg" fontWeight={500}>
            {t('hint')}
          </Typography>
          <Typography color="white" variant="natter-text-lg">
            {title ?? t('defaultTitle')} {showHint && <span>{t('description')}</span>}
          </Typography>
        </Stack>
      </Backdrop>
    </Fade>
  );
};
