import React, { FC, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button } from 'common/components/_legacy/Button';

import { GuestsFooterContainer } from './GuestsFooter.styled';
import { GuestsCounter } from './components';

export type GuestsFooterProps = {
  fieldName: string;
};

export const GuestsFooter: FC<GuestsFooterProps> = ({ fieldName }) => {
  const { control, setValue } = useFormContext();
  const watch = useWatch({ name: fieldName, control });

  const handleButtonClick = useCallback((): void => {
    setValue(fieldName, []);
  }, [fieldName, setValue]);

  return (
    <GuestsFooterContainer>
      <GuestsCounter amount={watch.length} />
      <Button variant="outlined" onClick={handleButtonClick}>
        Remove all
      </Button>
    </GuestsFooterContainer>
  );
};
