import React, { FC } from 'react';

import { Checkbox as NatterCheckbox, type CheckboxProps as NatterCheckboxProps, SxProps, Theme } from '@mui/material';

import { StyledFormControlLabel, StyledFormControlLabelProps } from './Checkbox.styled';

export type CheckboxProps = NatterCheckboxProps & {
  label?: React.ReactNode;
  labelSx?: SxProps<Theme>;
} & StyledFormControlLabelProps;

export const Checkbox: FC<CheckboxProps> = ({ checked, label, labelSx, variant = 'default', ...props }) => (
  <StyledFormControlLabel
    sx={labelSx}
    control={<NatterCheckbox {...props} />}
    label={label}
    variant={variant}
    checked={checked}
  />
);
