import React, { FC } from 'react';

import { Switch, SwitchProps } from '@mui/material';

import { useStyledSwitchStyles } from './StyledSwitch.styles';

export const StyledSwitch: FC<SwitchProps> = ({ classes: customClasses, ...props }) => {
  const { focusVisible, ...classes } = useStyledSwitchStyles();

  return (
    <Switch disableRipple focusVisibleClassName={focusVisible} classes={{ ...classes, ...customClasses }} {...props} />
  );
};
