import { StepItem } from 'common/components';

const createStep = (step: Partial<StepItem> & { title: string }): StepItem => ({
  isCompleted: false,
  isDisabled: true,
  isInProgress: false,
  ...step,
});

export const INITIAL_STEPS: StepItem[] = [
  createStep({
    title: 'Event Details',
    description: 'Add scheduling and media',
    isDisabled: false,
    isInProgress: true,
  }),
  createStep({
    title: 'Entry Survey',
    description: 'Pre-Event Questions',
  }),
  createStep({
    title: 'Natter',
    description: 'Add Conversation Topics',
  }),
  createStep({
    title: 'Exit Survey',
    description: 'Post-Event Questions',
  }),
  createStep({
    title: 'Summary',
    description: 'Review and finalize',
  }),
];

export const INITIAL_STEPS_FOR_REPEAT: StepItem[] = [
  ...INITIAL_STEPS.map((step, i) => {
    const isLastStep = i === INITIAL_STEPS.length - 1;
    return createStep({
      ...step,
      isCompleted: !isLastStep,
      isDisabled: i > 0,
      isInProgress: !!isLastStep,
    } as StepItem);
  }),
];
