import React, { FC, useEffect, useState } from 'react';

import { Button } from 'common/components/_legacy/Button';
import { useNavigationBlocker } from 'common/hooks/useNavigationBlocker';

import { SimpleModal } from './components';

interface UnsavedChangesModalProps {
  isUnsavedChanges: boolean;
  saveChanges?(): Promise<void> | void;
  message?: string;
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({
  isUnsavedChanges,
  saveChanges,
  message = 'Are you sure you want to leave this page without saving? Any unsaved information will be lost.',
}) => {
  // TODO: extract connectiong to navigation, pass as a prop
  const { showPrompt, handleConfirm, handleCancel } = useNavigationBlocker({ when: isUnsavedChanges });
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (!saveChanges) return;

    setIsSaving(true);
    try {
      await saveChanges();
      handleConfirm();
    } catch {
      handleCancel();
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const onUnload = (e: BeforeUnloadEvent) => {
      if (isUnsavedChanges) {
        e.returnValue = message;
      }
    };
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [isUnsavedChanges, message]);

  return (
    <SimpleModal
      title="Are you sure you want to leave this page?"
      isOpen={showPrompt}
      actions={
        saveChanges ? (
          <>
            <Button variant="outlined" onClick={handleConfirm} disabled={isSaving}>
              Discard
            </Button>
            <Button variant="contained" onClick={handleSave} isLoading={isSaving}>
              Save changes
            </Button>
          </>
        ) : (
          <>
            <Button variant="outlined" color="inherit" onClick={handleCancel} disabled={isSaving}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirm} isLoading={isSaving}>
              Leave page
            </Button>
          </>
        )
      }
    >
      Are you sure you want to leave this page without saving? Any unsaved information will be lost.
    </SimpleModal>
  );
};
