import React, { FC } from 'react';

import { Dialog } from 'common/components/Dialog';
import { useAppDispatch } from 'store';
import { DialogAlertData, removeAppMessage } from 'store/features/alerts';

export type AlertDialogProps = DialogAlertData;

export const AlertDialogContainer: FC<AlertDialogProps> = ({
  id,
  options: { actionToDispatchOnClose, actionToDispatchOnConfirm, ...options },
}) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(removeAppMessage(id));
    if (actionToDispatchOnClose) {
      dispatch(actionToDispatchOnClose);
    }
  };

  const handleConfirm = () => {
    dispatch(removeAppMessage(id));
    if (actionToDispatchOnConfirm) {
      dispatch(actionToDispatchOnConfirm);
    }
  };

  return (
    <Dialog
      isOpen
      onClose={handleClose}
      onConfirm={handleConfirm}
      maxWidth="xs"
      showCancelButton={false}
      showCloseButton={false}
      role="alertdialog"
      confirmLabel="Close"
      {...options}
    />
  );
};
