import { BroadcastWSActions, BroadcastWSMessages } from 'common/constants';
import { SendReactionParams } from 'domain/Broadcast';
import { createMeetingWSAsyncAction, createMeetingWSMessage } from 'store/utils';

import { MeetingWSParams } from '../event';
import { ReceivedReaction } from './types';

export const reactionReceived_WS = createMeetingWSMessage<ReceivedReaction>(BroadcastWSMessages.ReactionReceived);

export const sendBroadcastReaction_WS = createMeetingWSAsyncAction<MeetingWSParams<SendReactionParams>>(
  BroadcastWSActions.SendReaction
);
