import type { Components, Theme } from '@mui/material';

export const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  defaultProps: {},
  styleOverrides: {
    outlined: ({ theme: { palette } }) => ({
      border: `1px solid ${palette.mode === 'dark' ? palette.custom.grey : palette.custom.lightGrey}`,
      borderRadius: 4,
      color: palette.text.secondary,
      '&.Mui-selected': {
        color: palette.getContrastText(palette.primary.main),
        backgroundColor: palette.primary.main,
      },
    }),
    ellipsis: {
      border: 'none',
    },
    previousNext: ({ theme: { palette } }) => ({
      color: palette.primary.main,
      fontSize: '1.5rem',
      '&.Mui-disabled': {
        color: palette.custom.lightGrey,
      },
    }),
  },
};
