import { styled } from '@mui/material';

export const NavigationBarToolbarWrapper = styled('div', { name: 'NavigationBarToolbarWrapper' })(
  ({ theme: { spacing, palette } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.grey[50],
    padding: spacing(1, 3),
    borderRadius: spacing(4),
    width: '100%',
    gap: spacing(2),
  })
);
