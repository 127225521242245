import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const getAsNumber = (key: string): number | undefined => {
    const value = searchParams.get(key);
    const isValid = value && !Number.isNaN(Number(value));

    return value && isValid ? Number(value) : undefined;
  };

  return {
    searchParams,
    getAsNumber,
  };
};
