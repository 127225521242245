import { SVGProps, useCallback, useMemo } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { LabelProps } from 'recharts';

const useStyles = makeStyles(
  () => ({
    yAxisLabel: {
      textAnchor: 'middle',
    },
  }),
  {
    name: 'chartAxisStyles',
  }
);

export const useChartAxis = () => {
  const classes = useStyles();
  const theme = useTheme();

  const tickStyles: SVGProps<SVGTextElement> = useMemo(
    () => ({
      fontFamily: theme.typography.fontFamily,
      lineHeight: '16px',
      fontSize: '11px',
      fill: theme.palette.text.primary,
    }),
    [theme]
  );

  const tickLineStyles: SVGProps<SVGTextElement> = useMemo(
    () => ({
      stroke: theme.palette.divider,
    }),
    [theme]
  );

  const axisLineStyles: SVGProps<SVGLineElement> = useMemo(
    () => ({
      stroke: theme.palette.divider,
    }),
    [theme]
  );

  const renderAxisYLabel = useCallback(
    (value?: string): LabelProps => ({
      value,
      angle: -90,
      position: 'insideLeft',
      offset: -40,
      fontSize: '12px',
      fill: theme.palette.grey[600],
      // this is a workaround for https://github.com/recharts/recharts/issues/2105
      className: classes.yAxisLabel,
    }),
    [theme, classes]
  );

  const renderAxisXLabel = useCallback(
    (value?: string): LabelProps => ({
      value,
      position: 'insideBottom',
      offset: -36,
      fontSize: '12px',
      fill: theme.palette.grey[600],
    }),
    [theme]
  );

  return {
    tickStyles,
    tickLineStyles,
    axisLineStyles,
    renderAxisYLabel,
    renderAxisXLabel,
  };
};
