import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Popper, Typography } from '@mui/material';
import { Banner } from 'common/components';

import { NotificationContainer } from './MutedOnASystemLevelNotification.styled';
import { MUTED_SUPPORT_ARTICLE_LINK } from './constants';

type MutedOnASystemLevelNotificationProps = {
  anchorEl?: Element | null;
};

export const MutedOnASystemLevelNotification: FC<MutedOnASystemLevelNotificationProps> = ({ anchorEl }) => {
  const { t } = useTranslation('devices', { keyPrefix: 'mutedOnSystemLevelNotification' });

  return (
    <Popper anchorEl={anchorEl} placement="top" open sx={{ paddingBottom: 1 }}>
      <NotificationContainer>
        <Banner severity="error">
          <Typography>{t('title')}</Typography>
          <Typography>{t('description')}</Typography>
          <Typography>
            <Trans
              t={t}
              i18nKey="hint"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href={MUTED_SUPPORT_ARTICLE_LINK} target="_blank" rel="noreferrer" />,
              }}
            />
          </Typography>
        </Banner>
      </NotificationContainer>
    </Popper>
  );
};
