/* eslint functional/no-let: 0 */
import { hslToRgb, rgbToHex } from '@mui/material';

import { assert } from './assert';

export const hexToHSL = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  assert(result, '[hexToHSL] Please provide color in hex format!');

  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;
  const noDiff = max === min;

  const luminescence = (max + min) / 2;

  const saturation = (() => {
    if (noDiff) {
      return 0;
    }
    if (luminescence > 0.5) {
      return diff / (2 - max - min);
    }
    return diff / (max + min);
  })();

  const hue = (() => {
    let hueValue = 0;

    if (noDiff) {
      return hueValue;
    }

    switch (max) {
      case r:
        hueValue = (g - b) / diff + (g < b ? 6 : 0);
        break;
      case g:
        hueValue = (b - r) / diff + 2;
        break;
      case b:
        hueValue = (r - g) / diff + 4;
        break;
    }
    return hueValue / 6;
  })();

  return initHSLObject({ hue, luminescence, saturation });
};

const initHSLObject = (initialValues: { hue: number; saturation: number; luminescence: number }) => {
  const values = { ...initialValues };

  const toString = () => `hsl(${values.hue * 360}, ${values.saturation * 100}%, ${values.luminescence * 100}%)`;

  const changeHue = (coefficient: number) => {
    values.hue = (values.hue + coefficient) % 1;
  };

  return {
    toString,
    changeHue,
  };
};

export const changeHue = (color: string, hueCoefficient: number) => {
  const hsl = hexToHSL(color);
  hsl.changeHue(hueCoefficient);
  return rgbToHex(hslToRgb(hsl.toString()));
};
