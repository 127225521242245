import React, { ComponentType, FC } from 'react';

import { ThemeProvider } from '@mui/material';
import { theme as createTheme } from 'common/theme';

const theme = createTheme();

export const NatterThemeProvider: FC = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export const withTheme =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props) => (
    <NatterThemeProvider>
      <WrappedComponent {...props} />
    </NatterThemeProvider>
  );
