import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { searchRooms } from './actions';
import { setRoomsSearchIsLoading } from './roomsSearchSlice';

export const roomsSearchListener = createListenerMiddleware<RootState>();

roomsSearchListener.startListening({
  actionCreator: searchRooms.pending,
  effect: (_args, { dispatch }) => {
    dispatch(setRoomsSearchIsLoading(true));
  },
});
