import Stomp, { Client as StompClient } from '@stomp/stompjs';
import { logger } from 'common/services';
import EventEmitter from 'events';
import type Keycloak from 'keycloak-js';
import { UrlNames } from 'modules/app/constants';
import ConfigService from 'modules/app/services/ConfigService';

import { SocketRoomPrefixType, SocketSubscribeParams } from './types';
import { getWebsocket } from './utils';

export const SocketEvents = {
  connected: 'connected',
  disconnected: 'disconnected',
  reconnected: 'reconnected',
  newMessage: 'newMessage',
  channelSubscribed: 'channelSubscribed',
  channelUnsubscribed: 'channelUnsubscribed',
  unauthenticated: 'unauthenticated',
};

interface WebSocketServiceAttributes {
  configService: ConfigService;
  keycloakService: Keycloak;
}

interface WebSocketChannelSubscription {
  id: string;
  unsubscribe: () => void;
}

export interface WebSocketConnectionState {
  isConnected: boolean;
  isReconnecting: boolean;
}

export interface SocketDisconnected {
  closedGracefully: boolean;
}

export interface SocketPayload {
  action: string;
  params: string;
}

export interface SocketMessage {
  path: string;
  payload: SocketPayload | SocketPayload[] | undefined;
}

export class Socket extends EventEmitter {
  private urlBase: string;
  private keycloak: Keycloak;
  private client: StompClient | null;
  private initialized: boolean;
  private subscriptions: Map<
    string,
    {
      subscription: WebSocketChannelSubscription;
      actionPrefix: SocketRoomPrefixType;
    }
  > = new Map();
  private awaitingSubscriptions: {
    actionPrefix: SocketRoomPrefixType;
    channelName: string;
  }[] = [];
  private awaitingMessages: { entrypoint: string; params: Object; plainBody: boolean }[] = [];

  constructor({ keycloakService, configService }: WebSocketServiceAttributes) {
    super();
    this.urlBase = `${configService.getUrl(UrlNames.Base)}community`;
    this.keycloak = keycloakService;
    this.client = null;
    this.initialized = false;

    const client = new StompClient({
      brokerURL: this.urlBase,
      beforeConnect: this.prepareHeaders,
      onConnect: this.connectCallback,
      onDisconnect: this.handleClose,
      onWebSocketClose: this.handleClose,
      reconnectDelay: 2000,
      heartbeatIncoming: 25000,
      heartbeatOutgoing: 25000,
      webSocketFactory: () => getWebsocket(this.urlBase),
    });
    this.client = client;

    this.keycloak.onAuthSuccess = () => {
      client.activate();
    };
  }

  private prepareHeaders = async () => {
    if (!this.client) return;

    try {
      if (this.keycloak.isTokenExpired(10)) {
        await this.keycloak.updateToken(10);
      }
      this.client.connectHeaders = {
        token: `${this.keycloak.token}`,
      };
    } catch (error) {
      logger.addBreadcrumb('[Socket prepareHeaders] Error while preparing connectHeaders', {
        data: { error },
      });
    }
  };

  private handleChannelSubscription = (actionPrefix: SocketRoomPrefixType, channelName: string) => {
    if (this.client?.connected) {
      this.subscribeToChannel(this.client, actionPrefix, channelName);
    } else if (!this.awaitingSubscriptions.some((s) => s.channelName === channelName)) {
      this.awaitingSubscriptions.push({ actionPrefix, channelName });
    }
  };

  private subscribeToChannel = (client: Stomp.Client, actionPrefix: SocketRoomPrefixType, channelName: string) => {
    const subscription = client.subscribe(channelName, this.messageHandler(actionPrefix));
    this.subscriptions.set(channelName, {
      actionPrefix,
      subscription,
    });
    this.emit(SocketEvents.channelSubscribed, channelName);
  };

  private messageHandler =
    (actionPrefix: string): ((messageOutput: { body: string }) => void) =>
    (messageOutput): void => {
      const parsedOutput = JSON.parse(messageOutput.body);
      this.emit(SocketEvents.newMessage, {
        path: `${actionPrefix}:${parsedOutput.action}`,
        payload: parsedOutput.params ?? parsedOutput.content ?? parsedOutput.data,
      } as SocketMessage);
    };

  private sendMessage = (entrypoint: string, params: Object = {}, plainBody = false) => {
    this.client?.publish({
      destination: encodeURI(entrypoint),
      headers: {},
      body: JSON.stringify(
        plainBody
          ? params
          : {
              params,
            }
      ),
    });
  };

  private connectCallback = (frame: Stomp.IFrame) => {
    if (this.initialized) {
      this.emit(SocketEvents.reconnected);
      logger.addBreadcrumb('[Socket connect callback] Reconnected to server', {
        data: { frame },
      });
    } else {
      this.emit(SocketEvents.connected);
    }

    for (let i = this.awaitingSubscriptions.length - 1; i >= 0; i--) {
      if (!this.client) return;
      const { actionPrefix, channelName } = this.awaitingSubscriptions[i];
      this.subscribeToChannel(this.client, actionPrefix, channelName);
      this.awaitingSubscriptions.splice(i, 1);
    }

    for (let i = this.awaitingMessages.length - 1; i >= 0; i--) {
      if (!this.client) return;
      const { entrypoint, params, plainBody } = this.awaitingMessages[i];
      this.sendMessage(entrypoint, params, plainBody);
      this.awaitingMessages.splice(i, 1);
    }

    if (!this.initialized) {
      this.initialized = true;
    }
  };

  send(entrypoint: string, params: Object = {}, plainBody = false): void {
    if (this.client?.connected) {
      this.sendMessage(entrypoint, params, plainBody);
    } else {
      this.awaitingMessages.push({
        entrypoint,
        params,
        plainBody,
      });
    }
  }

  handleClose = (error: unknown): void => {
    logger.error('[Socket] Socket connection closed!', { extra: { error } });

    this.emit(SocketEvents.disconnected, {
      closedGracefully: false,
    } as SocketDisconnected);
    this.subscriptions.forEach(({ actionPrefix }, channelName) => {
      this.awaitingSubscriptions.push({ actionPrefix, channelName });
      this.subscriptions.delete(channelName);
      this.emit(SocketEvents.channelUnsubscribed, channelName);
    });
  };

  subscribe = ({ actionPrefix, privateChannel, publicChannel }: SocketSubscribeParams) => {
    if (!this.subscriptions.has(publicChannel)) {
      this.handleChannelSubscription(actionPrefix, publicChannel);
    }
    if (privateChannel && !this.subscriptions.has(privateChannel)) {
      this.handleChannelSubscription(actionPrefix, privateChannel);
    }
  };

  unsubscribe({ publicChannel, privateChannel }: SocketSubscribeParams): void {
    [publicChannel, privateChannel].forEach((channelName) => {
      if (!channelName) return;

      const subscriptionData = this.subscriptions.get(channelName);
      if (!subscriptionData) {
        return;
      }
      subscriptionData.subscription.unsubscribe();
      this.subscriptions.delete(channelName);
      this.emit(SocketEvents.channelUnsubscribed, channelName);
    });

    const isPublicOrPrivateChannel = (channelName: string) => [publicChannel, privateChannel].includes(channelName);
    if (this.awaitingSubscriptions.some(({ channelName }) => isPublicOrPrivateChannel(channelName))) {
      this.awaitingSubscriptions = this.awaitingSubscriptions.filter(
        ({ channelName }) => !isPublicOrPrivateChannel(channelName)
      );
    }
  }
}
