import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { UrlNames } from 'modules/app/constants';
import ConfigService from 'modules/app/services/ConfigService';

interface KeycloakServiceAttributes {
  configService: ConfigService;
}

const KeycloakService = ({ configService }: KeycloakServiceAttributes) => {
  const keycloakConfig: KeycloakConfig = {
    realm: 'Natter',
    url: configService.getUrl(UrlNames.Auth),
    clientId: 'natter-frontend',
  };
  return new Keycloak(keycloakConfig);
};

export default KeycloakService;
