import { CSSProperties } from 'react';

import { Margin } from 'recharts/types/util/types';

export const CHART_MIN_HEIGHT = 340;
export const CHART_TICK_PADDING = 2;
export const CHART_TICK_FONT_SIZE = 11;
export const CHART_TICK_LINE_HEIGHT = 14;
export const CHART_BAR_MAX_WIDTH = 50;
export const CHART_LABEL_FONT_SIZE = '12px';
export const CHART_LABEL_FONT_WEIGHT = 600;

export const chartMargins: Margin = { right: 20, left: 50, bottom: 40, top: 10 };
export const legendWrapperStyle: CSSProperties = { paddingBottom: 10 };
