export enum ChatState {
  Initial,
  Connecting,
  Connected,
  Ready,
  Error,
}

interface ChatMessageSender {
  id: number;
  name: string | undefined;
  avatarLink: string | undefined;
  role: string | undefined;
  // TODO change tags type to just 'string[]' after new chat release is done
  tags: string[] | undefined;
}

export interface ChatMessageBasic {
  id: number | string;
  timestamp: number;
  sender: ChatMessageSender;
  text: string;
}

export enum ChatMessageStatus {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
}

export interface ChatMessage extends ChatMessageBasic {
  isNew: boolean;
  status: ChatMessageStatus;
}
