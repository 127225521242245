import type { Components, Theme } from '@mui/material';

export const MuiDialogContentText: Components<Theme>['MuiDialogContentText'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { spacing, palette, typography } }) => ({
      textAlign: 'center',
      ...typography.bodyBig,
      color: palette.mode === 'dark' ? palette.common.white : palette.common.black,
      padding: spacing(0, 7, 3),
    }),
  },
};
