import { useCallback, useEffect, useRef, useState } from 'react';

import AgoraRTC, { type IMicrophoneAudioTrack } from 'agora-rtc-react';
import { logger } from 'common/services';

const VOLUME_LEVEL_INTERVAL = 500;

type Props = {
  audioInputDeviceId: string;
};

export const useAudioTest = ({ audioInputDeviceId }: Props) => {
  const audioTrackRef = useRef<IMicrophoneAudioTrack>();

  const [inputLevel, setInputLevel] = useState(0);
  const [isMutedOnSystemLevel, setIsMutedOnSystemLevel] = useState(false);

  const initializeAudioTrack = useCallback(
    async (microphoneId: string) => {
      logger.addBreadcrumb('[useAudioTest] Starting audio test', { data: { deviceId: microphoneId } });

      try {
        audioTrackRef.current = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId });
      } catch (error) {
        logger.error(error, { extra: { deviceId: microphoneId } });
      }
    },
    [audioTrackRef]
  );

  const handleSystemLevelMuteChange = useCallback(async (deviceId: string) => {
    try {
      const audioDevice = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } });

      setIsMutedOnSystemLevel(audioDevice.getAudioTracks()[0].muted);

      audioDevice.getAudioTracks()[0].onmute = () => {
        setIsMutedOnSystemLevel(true);
      };

      audioDevice.getAudioTracks()[0].onunmute = () => {
        setIsMutedOnSystemLevel(false);
      };
    } catch (error: unknown) {
      logger.error(error);
    }
  }, []);

  useEffect(() => {
    if (audioInputDeviceId) {
      handleSystemLevelMuteChange(audioInputDeviceId);
    }
  }, [audioInputDeviceId, handleSystemLevelMuteChange]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!audioTrackRef?.current) return;

      const level = audioTrackRef.current.getVolumeLevel();
      setInputLevel(level * 100);
    }, VOLUME_LEVEL_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    initializeAudioTrack(audioInputDeviceId);
  }, [audioInputDeviceId, initializeAudioTrack]);

  useEffect(
    () => () => {
      if (!audioTrackRef.current) return;
      logger.addBreadcrumb('[useAudioTest] Stopping audio test');

      audioTrackRef.current.setEnabled(false);
      audioTrackRef.current = undefined;
    },
    []
  );

  return {
    inputLevel,
    isMutedOnSystemLevel,
  };
};
