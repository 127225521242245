import React, { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { useBreakpoints } from 'common/hooks';

import {
  DevicesSectionDetails,
  DevicesSectionRoot,
  DevicesSectionSummary,
  DevicesSectionTitleWrapper,
} from './DevicesSection.styled';

type Props = {
  Icon?: ReactNode;
  Action?: ReactNode;
  title: ReactNode;
};

export const DevicesSection: FC<Props> = ({ Icon, Action, title, children }) => {
  const { isSmallScreen } = useBreakpoints();

  if (isSmallScreen && !!children) {
    return <>{children}</>;
  }

  return (
    <DevicesSectionRoot>
      <DevicesSectionSummary>
        <DevicesSectionTitleWrapper>
          {Icon}
          {typeof title === 'string' ? (
            <Typography variant="natter-text-sm" fontWeight={600}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </DevicesSectionTitleWrapper>
        {Action}
      </DevicesSectionSummary>
      {!!children && <DevicesSectionDetails>{children}</DevicesSectionDetails>}
    </DevicesSectionRoot>
  );
};
