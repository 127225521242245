import React, { FC, useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogContentText, Grid } from '@mui/material';
import { Loading } from 'common/components';
import { DialogTitle } from 'common/components/_legacy/DialogTitle';
import { useAppSelector } from 'store';
import { selectIsSocketReconnecting } from 'store/features/socket';

export const ReconnectingModal: FC = () => {
  const isReconnecting = useAppSelector(selectIsSocketReconnecting);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isReconnecting) {
      setIsOpen(true);
    }
    return () => {
      setIsOpen(false);
    };
  }, [isReconnecting]);

  return (
    <Dialog
      open={!!isOpen}
      maxWidth="sm"
      transitionDuration={{
        enter: 1000,
        exit: 200,
      }}
    >
      <DialogTitle title="Reconnecting" />
      <DialogContent>
        <Grid container direction="column" wrap="nowrap" spacing={3} alignItems="center">
          <Grid item>
            <DialogContentText>Connection to server lost. Reconnecting...</DialogContentText>
          </Grid>
          <Grid item sx={{ paddingBottom: 3 }}>
            <Loading />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
