import { type Components, type Theme } from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme: { spacing }, ownerState: { fullScreen } }) => ({
      borderRadius: fullScreen ? 0 : spacing(3),
      paddingLeft: 0,
      paddingRight: 0,
      boxSizing: 'border-box',
      alignItems: 'center',
    }),
    paperWidthXs: {
      maxWidth: 480,
    },
    paperWidthSm: {
      maxWidth: 632,
    },
    paperWidthMd: {
      maxWidth: 798,
    },
  },
};
