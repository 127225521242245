import { createListenerMiddleware } from '@reduxjs/toolkit';

import { addAppConfirmationDialog } from '../alerts';
import { userMuted_WS } from '../event';
import { setAudioEnabled, setDevicesLoaded, setMediaSettings, userActions } from './actions';
import { getDeviceLocalStorageKey, getInitialDeviceId } from './utils';

export const userListener = createListenerMiddleware();

userListener.startListening({
  actionCreator: userMuted_WS,
  effect: ({ payload: { mutedByName } }, { dispatch }) => {
    dispatch(setAudioEnabled(false));
    dispatch(
      addAppConfirmationDialog({
        title: `You have been muted by ${mutedByName}`,
        description: 'You can unmute yourself by clicking the microphone below.',
        variant: 'confirmation',
        confirmLabel: 'Got it',
      })
    );
  },
});

userListener.startListening({
  actionCreator: userActions.mediaSettings.settingsUpdated,
  effect: ({ payload: { blurLevel, audioInputDeviceId, audioOutputDeviceId, videoDeviceId } }) => {
    localStorage.setItem(getDeviceLocalStorageKey('audioinput', 'selected'), audioInputDeviceId);
    localStorage.setItem(getDeviceLocalStorageKey('audiooutput', 'selected'), audioOutputDeviceId);
    localStorage.setItem(getDeviceLocalStorageKey('videoinput', 'selected'), videoDeviceId);
    localStorage.setItem('blurLevel', blurLevel.toString());
  },
});

userListener.startListening({
  actionCreator: userActions.navigator.mediaDevices.devicesFound,
  effect: ({ payload }, { dispatch }) => {
    dispatch(
      setMediaSettings({
        audioInputDeviceId: getInitialDeviceId(payload, 'audioinput'),
        audioOutputDeviceId: getInitialDeviceId(payload, 'audiooutput'),
        videoDeviceId: getInitialDeviceId(payload, 'videoinput'),
      })
    );

    dispatch(setDevicesLoaded(true));
  },
});
