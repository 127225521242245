import React, { FC } from 'react';

import { Avatar } from '@mui/material';

import { ImageDropzone, ImageDropzoneProps } from '../ImageDropzone';
import { UploadPreview, type UploadPreviewProps } from '../UploadPreview';
import { ImageFile } from './types';

export type ImageDropzoneWithPreviewProps = ImageDropzoneProps & {
  image?: ImageFile;
  onRemoveClick: UploadPreviewProps['onRemoveClick'];
};

export const ImageDropzoneWithPreview: FC<ImageDropzoneWithPreviewProps> = ({
  image,
  onRemoveClick: handleRemoveClick,
  ...imageDropzoneProps
}) => {
  if (image?.data) {
    return (
      <UploadPreview name={image.name} size={image.size} onRemoveClick={handleRemoveClick}>
        <Avatar src={image.data} alt={image.name} />
      </UploadPreview>
    );
  }

  if (image?.status === 'compressing') {
    return <UploadPreview name={image.name} size={image.size} status="compressing" progress={image.progress} />;
  }

  return <ImageDropzone {...imageDropzoneProps} />;
};
