import { createApi } from '@reduxjs/toolkit/query/react';
import { WithId } from 'domain/Common';
import { getApiBaseUrl } from 'modules/api/utils';
import axiosBaseQuery from 'store/apis/_axiosBaseQuery';

import { CancelMeetingRequest, RecurringNattersMeetingWithQuestions } from './types';

export const recurringNattersMeetingApi = createApi({
  reducerPath: 'recurringNattersMeetingApi',
  tagTypes: ['RECURRING_NATTERS_MEETING'],
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    getMeeting: builder.query<RecurringNattersMeetingWithQuestions, WithId>({
      // query: ({ id }) => ({
      //   url: `/recurringNatters/meeting/${id}`,
      // }),
      queryFn: ({ id }) =>
        Promise.resolve({
          data: {
            recurringNattersName: `Meeting #${id}`,
          } as RecurringNattersMeetingWithQuestions,
        }),
    }),
    cancelMeeting: builder.mutation<void, CancelMeetingRequest>({
      // query: ({ meetingId, ...data }) => ({
      //   url: `/recurringNatters/meeting/${meetingId}/cancel`,
      //   method: 'POST',
      //   data
      // }),
      queryFn: () => Promise.resolve({ data: undefined }),
    }),
  }),
});

export const { useGetMeetingQuery, useCancelMeetingMutation } = recurringNattersMeetingApi;
