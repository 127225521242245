import { Features } from 'common/constants/features';

import { toBoolean } from '../../toBoolean';
import type { FeatureKey } from '../types';

/**
 * Checks if a feature is enabled based on the provided feature key in the environment.
 *
 * @param {Feature} feature - The key representing the feature to be checked.
 * @returns {boolean} - Returns true if the feature is enabled; otherwise, returns false.
 *
 * @typedef {string} Feature - A string representing the key of the feature to be checked.
 *
 * @example
 * // Assuming window._env_ is { FEATURE_A: 'true', FEATURE_B: 'false' }
 * // which represents .env file
 * // FEATURE_A=true
 * // FEATURE_B=false
 *
 * // Returns true
 * isFeatureEnabled('FeatureA');
 *
 * // Returns false
 * isFeatureEnabled('FeatureB');
 */
export const isFeatureEnabled = (feature: FeatureKey) => toBoolean(window._env_[Features[feature] || feature]);
