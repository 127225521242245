import { styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type WrapperProps = {
  isActive: boolean;
};

export const Wrapper = styled('button', {
  name: 'TaskStep-Wrapper',
  shouldForwardProp: omitForwardedProps<WrapperProps>('isActive'),
})<WrapperProps>(({ theme: { palette, spacing }, isActive, onClick }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: spacing(3),
  padding: spacing(2),
  flexShrink: 0,
  borderRadius: spacing(1),
  border: `1px solid ${palette.grey[300]}`,
  background: palette.common.white,
  transition: 'all 0.2s ease-in-out',

  ...(isActive && {
    border: `1px solid ${palette.primary.main}`,
    background: palette.extendedPrimary[25],
  }),

  ...(typeof onClick === 'function' && {
    cursor: 'pointer',

    '&:hover': {
      border: `1px solid ${palette.primary.main}`,
    },
  }),
}));
