import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { useTagsStyles } from './Tags.styles';

const TAGS_DISPLAYED = 6;

export type TagsProps = {
  tags: string[];
};

export const Tags: FC<TagsProps> = ({ tags }) => {
  const classes = useTagsStyles();

  return (
    <Grid container direction="column" spacing={0.5}>
      <Grid item container gap={0.5}>
        {tags.length === 0 && (
          <Grid item>
            <Typography variant="caption" className={classes.moreTags}>
              No tags to display
            </Typography>
          </Grid>
        )}
        {tags.slice(0, TAGS_DISPLAYED).map((tag, index) => (
          <Grid item key={`${index}${tag}`} className={classes.tag}>{`${tag[0] === '#' ? '' : '#'}${tag}`}</Grid>
        ))}
      </Grid>
      {tags.length - TAGS_DISPLAYED > 0 ? (
        <Grid item>
          <Typography variant="caption" className={classes.moreTags}>
            {`+${tags.length - TAGS_DISPLAYED} more...`}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
