import type { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {},
  styleOverrides: {
    select: {
      fontSize: '14px',
    },
  },
};
