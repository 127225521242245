import React, { forwardRef } from 'react';

import { InputAdornment, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

import { FormFieldHint } from '../FormFieldHint';
import { calculateShrink } from './utils';

export type TextFieldProps = Pick<
  MuiTextFieldProps,
  | 'autoComplete'
  | 'autoFocus'
  | 'defaultValue'
  | 'disabled'
  | 'error'
  | 'fullWidth'
  | 'inputProps'
  | 'InputProps'
  | 'label'
  | 'multiline'
  | 'placeholder'
  | 'required'
  | 'rows'
  | 'size'
  | 'value'
  | 'onBlur'
  | 'onChange'
  | 'onClick'
  | 'onKeyDown'
  | 'type'
  | 'sx'
  | 'variant'
> & {
  hint?: string;
  wrapHint?: boolean;
  prefix?: string | React.ReactElement;
  shrinkLabel?: boolean;
  suffix?: string | React.ReactElement;
};

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ error, disabled, hint, prefix, suffix, shrinkLabel, wrapHint = true, ...restOfProps }, ref) => (
    <MuiTextField
      inputRef={ref}
      variant="outlined"
      disabled={disabled}
      error={error}
      InputLabelProps={{
        shrink: calculateShrink({ shrinkLabel, defaultValue: restOfProps.defaultValue, value: restOfProps.value }),
      }}
      helperText={
        hint && (
          <FormFieldHint error={!disabled && !!error} noWrap={!wrapHint}>
            {hint}
          </FormFieldHint>
        )
      }
      {...restOfProps}
      InputProps={{
        ...restOfProps.InputProps,
        startAdornment: prefix && (
          <InputAdornment position="start" sx={{ paddingLeft: 1 }}>
            {prefix}
          </InputAdornment>
        ),
        endAdornment: suffix ? (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ) : (
          restOfProps.InputProps?.endAdornment
        ),
      }}
    />
  )
);
