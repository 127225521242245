import { Box, ButtonBase, styled } from '@mui/material';

export const Step = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing(1.5),
}));

export const StepIndicatorContainer = styled(Box)(({ theme: { spacing } }) => ({
  flexBasis: spacing(3),
  flexGrow: 0,
  flexShrink: 0,
  position: 'relative',
}));

export const Connector = styled(Box)({
  borderRadius: 2,
  bottom: 4,
  height: 'calc(100% - 32px)',
  left: 11,
  position: 'absolute',
  width: 2,
});

export const StepContentContainer = styled(Box)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(3),
}));

export const ClickableArea = styled(ButtonBase)(({ onClick }) => ({
  alignItems: 'flex-start',
  flexDirection: 'column',

  cursor: typeof onClick === 'function' ? 'pointer' : 'default',
}));
