import React, { FC } from 'react';

import { CopyButton } from '../CopyButton';
import { StyledTextField } from './CopyTextField.styled';

type Props = {
  value: string;
};

export const CopyTextField: FC<Props> = ({ value }) => (
  <StyledTextField value={value} fullWidth InputProps={{ readOnly: true }} suffix={<CopyButton value={value} />} />
);
