import type { Components, Theme } from '@mui/material';

export const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      backgroundColor: palette.mode === 'light' ? palette.grey[50] : palette.common.black,
      borderRadius: '4px',
      '&::before': {
        content: 'none',
      },
      '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled:not(.MuiInputAdornment-hiddenLabel)':
        {
          marginTop: 0,
        },
    }),
  },
};
