import type { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    list: ({ theme: { spacing } }) => ({
      padding: spacing(1),
    }),
    paper: ({ theme: { spacing, palette } }) => ({
      border: '1px solid',
      borderColor: palette.divider,
      marginTop: spacing(0.5),
      '&.MuiPaper-rounded': {
        borderRadius: spacing(0.5),
      },
      '& .MuiMenuItem-root': {
        height: 40,
      },
    }),
  },
};
