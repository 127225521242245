import { CSSProperties, MouseEvent, ReactNode } from 'react';

import { SortOrder } from 'domain/Common';

import { TableSearchBarProps } from './components';

export type ColumnData<ItemType, ColumnIdType = string> = {
  id: ColumnIdType;
  label?: string;
  style?: CSSProperties;
  width?: string | number;
  align?: 'left' | 'center' | 'right';
  headerFormat?: () => CellData;
  format: (value: ItemType) => CellData;
  classNames?: string;
  sortable?: boolean;
  padding?: 'checkbox' | 'none' | 'normal';
};

type CellData = string | number | ReactNode | ReactNode[];

type ColumnDataSortable<ItemType, ColumnIdType> = ColumnData<ItemType, ColumnIdType> & {
  sortable: true;
};

export type Columns<ItemType, ColumnIdType = string> = Array<
  ColumnDataSortable<ItemType, ColumnIdType> | ColumnData<ItemType, ColumnIdType>
>;

export function isColumnSortable<ItemType, ColumnIdType>(
  column: ColumnData<ItemType, ColumnIdType> | ColumnDataSortable<ItemType, ColumnIdType>
): column is ColumnDataSortable<ItemType, ColumnIdType> {
  return (column as ColumnDataSortable<ItemType, ColumnIdType>).sortable;
}

export type TableProps<ItemType, ColumnIdType = string> = {
  columns: Columns<ItemType, ColumnIdType>;
  itemsList: ItemType[];
  onRowClick?: Function;
  emptyTableText?: string;
  getRowKey?(row: ItemType): string | number;
  isLoading?: boolean;
  order?: SortOrder;
  orderBy?: ColumnIdType;
  onSortChange?: (event: MouseEvent<unknown>, orderBy: ColumnIdType) => void;
  fixedTableLayout?: boolean;
  summaryRow?: ItemType;
  classes?: {
    container?: string;
    header?: string;
    headerCell?: string;
  };
  highlightedItem?: ItemType;
  onHighlightedItemChange?(row: ItemType | undefined): void;
  hover?: boolean;
  headerOverlay?: ReactNode;
  hasMoreRows?: boolean;
  onFetchNextPage?(): void;
} & TableSearchBarProps;
