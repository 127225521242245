import type { Components, Theme } from '@mui/material';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      h1: 'div',
      h2: 'div',
      h3: 'div',
      h4: 'div',
      h5: 'div',
      h6: 'div',
      subtitle1: 'div',
      subtitle2: 'div',
      body1: 'div',
      body2: 'div',
      caption: 'p',
    },
  },
  styleOverrides: {},
};
