import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { handleQueryError } from 'store/utils';

import { publicSurveysReportApi } from './publicSurveysReportApi';

// TODO extract effects in next iteration
export const publicSurveysReportApiLister = createListenerMiddleware<RootState>();

publicSurveysReportApiLister.startListening({
  matcher: isAnyOf(
    publicSurveysReportApi.endpoints.getPublicSurveysReportById.matchRejected,
    publicSurveysReportApi.endpoints.getPublicSurveysReportKeyMetrics.matchRejected,
    publicSurveysReportApi.endpoints.getPublicSurveysReportDemographics.matchRejected,
    publicSurveysReportApi.endpoints.getPublicSurveysReportAnswers.matchRejected
  ),
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, "Unable to fetch Surveys Report's data");
  },
});
