import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { grey } from 'common/theme/palette';

import { ClickableArea, Connector, Step, StepContentContainer, StepIndicatorContainer } from './ProgressTracker.styled';
import { StateIndicator } from './components/StateIndicator';
import { StepItem } from './types';

type ProgressTrackerProps = {
  activeStep: number;
  steps: StepItem[];
  onStepClick?(stepIndex: number): void;
};

export const ProgressTracker: FC<ProgressTrackerProps> = ({ activeStep, steps, onStepClick }) => {
  const handleClick = (stepIndex: number) => {
    onStepClick?.(stepIndex);
  };

  return (
    <Box display="flex" flexDirection="column">
      {steps.map((step, i) => {
        const isLastStep = i === steps.length - 1;
        const shouldHavePrimaryColor = activeStep !== i && (step.isCompleted || step.isInProgress);

        return (
          <Step key={`progress-tracker-step-${i}`}>
            <StepIndicatorContainer>
              <StateIndicator isActive={activeStep === i} isCompleted={step.isCompleted} />
              {!isLastStep && <Connector bgcolor={step.isCompleted ? 'primary.main' : grey[100]} />}
            </StepIndicatorContainer>

            <StepContentContainer>
              <ClickableArea
                onClick={typeof onStepClick === 'function' ? () => handleClick(i) : undefined}
                disabled={step.isDisabled}
                disableRipple
              >
                <Typography
                  variant="natter-text-sm"
                  fontWeight={500}
                  color={shouldHavePrimaryColor ? 'primary.main' : undefined}
                >
                  {step.title}
                </Typography>
                {step.description && (
                  <Typography variant="natter-text-sm" color="text.secondary">
                    {step.description}
                  </Typography>
                )}
              </ClickableArea>
            </StepContentContainer>
          </Step>
        );
      })}
    </Box>
  );
};
