import React, { FC } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';

import { Step, TaskStep } from './components';

type Props = {
  steps: Step[];
  activeStep: number;
  onChange?(index: number, step: Step): void;
  sx?: SxProps<Theme>;
};

export const TaskStepper: FC<Props> = ({ steps, activeStep, onChange, sx }) => (
  <Stack component="aside" gap={2} sx={sx} data-testid="TaskStepper">
    {steps.map((step, index) => (
      <TaskStep
        key={index}
        onClick={typeof onChange === 'function' ? () => onChange(index, step) : undefined}
        isActive={activeStep === index}
        {...step}
      />
    ))}
  </Stack>
);
