import { useMemo } from 'react';

import { useTheme } from '@mui/material';

import { CHART_MIN_HEIGHT, CHART_TICK_FONT_SIZE, CHART_TICK_LINE_HEIGHT, CHART_TICK_PADDING } from '../constants';
import { BasicChartData } from '../types';

const Y_AXIS_TICKS_TO_CHART_RATIO = 0.3;

export const useDynamicChartDimensions = ({
  data = [],
  fontSize = `${CHART_TICK_FONT_SIZE}px`,
  chartWidth = 0,
  chartHeight,
}: {
  data?: BasicChartData;
  chartWidth?: number;
  chartHeight?: number | string;
  fontSize?: string;
} = {}) => {
  const { typography } = useTheme();

  const { yAxisHeight, yAxisWidth } = useMemo(() => {
    if (!chartWidth)
      return {
        yAxisWidth: 0,
        yAxisHeight: 0,
      };

    const element = initTemporaryHtmlElement({ fontFamily: typography.fontFamily ?? '', fontSize, chartWidth });
    const dataLabelDimensions = data.map(({ name }) => measureText({ text: name.toString(), element }));
    disposeTemporaryHtmlElement(element);

    // we need to add +1 because element.clientWidth rounds decimal down to integer
    // and we add 2 * TICK_PADDING so text fits nicely in axis ticks
    const maxTextWidth = Math.max(...dataLabelDimensions.map((d) => d.width)) + 1 + 2 * CHART_TICK_PADDING;
    const maxTexHeight = Math.max(...dataLabelDimensions.map((d) => d.height)) + 8;

    return {
      yAxisWidth: maxTextWidth,
      yAxisHeight: (Math.max(maxTexHeight, 30) + 2 * CHART_TICK_PADDING) * data.length,
    };
  }, [data, fontSize, typography, chartWidth]);

  return {
    yAxisWidth,
    chartHeight: chartHeight ?? Math.max(yAxisHeight + 100, CHART_MIN_HEIGHT),
  };
};

const measureText = ({ text, element }: { text: string; element: HTMLDivElement }) => {
  element.innerHTML = text;
  return {
    width: element.clientWidth,
    height: element.clientHeight,
  };
};

const initTemporaryHtmlElement = ({
  fontFamily,
  fontSize,
  chartWidth,
}: {
  fontFamily: string;
  fontSize: string;
  chartWidth: number;
}) => {
  const element = document.createElement('div');
  element.style.maxWidth = `${chartWidth * Y_AXIS_TICKS_TO_CHART_RATIO}px`;
  element.style.visibility = 'hidden';
  element.style.fontFamily = fontFamily;
  element.style.fontSize = fontSize;
  element.style.lineHeight = `${CHART_TICK_LINE_HEIGHT}px`;
  element.style.display = 'inline-block';
  document.body.appendChild(element);
  return element;
};

const disposeTemporaryHtmlElement = (element: HTMLDivElement) => {
  document.body.removeChild(element);
};
