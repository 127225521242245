import { useCallback, useEffect, useRef, useState } from 'react';

export function useTimeout(callback: () => void, delay: number | null) {
  const timeoutRef = useRef<number | null>(null);
  const savedCallback = useRef(callback);
  const [id, setId] = useState(1);

  const reset = useCallback(() => {
    setId((oldId) => oldId + 1);
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => {
        timeoutRef.current && window.clearTimeout(timeoutRef.current);
      };
    }
  }, [delay, id]);

  return { timeoutRef, reset };
}
