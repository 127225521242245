import React from 'react';

import { Stack, Typography } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';

import { NoMembersIcon } from './components';

export interface NoCommunityMembersProps {
  onInviteUsersClick(): void;
}

export const NoCommunityMembers: React.FC<NoCommunityMembersProps> = ({ onInviteUsersClick }) => (
  <Stack alignItems="center" pt={14} gap={2}>
    <NoMembersIcon fontSize="102px" />
    <Typography variant="h3">Build your Community</Typography>
    <Stack alignItems="center" pb={2}>
      <Typography>There are no Members yet.</Typography>
      <Typography>Invite Members to build your Community.</Typography>
    </Stack>
    <Button onClick={onInviteUsersClick}>Invite Members</Button>
  </Stack>
);
