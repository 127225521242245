import React, { FC } from 'react';

import { Avatar, type AvatarProps } from '@mui/material';

import AvatarBackground from './AvatarBackground.svg';

export interface UserAvatarProps extends AvatarProps {
  avatarLink?: string | null;
  name?: string;
  dimensions?: number;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  avatarLink,
  name,
  dimensions = 40,
  variant = 'circular',
  ...props
}) => (
  <Avatar
    src={avatarLink ?? undefined}
    variant={variant}
    sx={({ palette }) => ({
      width: dimensions,
      height: dimensions,
      backgroundColor: avatarLink ? 'transparent' : palette.primary.main,
      color: palette.text.primary,
      fontSize: dimensions / 2,
      backgroundImage: avatarLink ? undefined : `url(${AvatarBackground})`,
      fontWeight: 500,
    })}
    alt="User avatar"
    data-testid={avatarLink ? 'user-avatar' : 'UserAvatar-empty'}
    {...props}
  >
    {name?.[0]?.toUpperCase()}
  </Avatar>
);
