import { MouseEvent, useState } from 'react';

type Props = {
  onApply(): void;
  onOpen(): void;
  onReset(): void;
};

export const useFilterBase = ({ onOpen, onApply, onReset }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = !!anchorEl;

  const openPopper = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    onReset();
    closePopper();
  };

  const handleApplyButton = () => {
    onApply();
    closePopper();
  };

  return {
    anchorEl,
    isOpen,
    openPopper,
    closePopper,
    handleReset,
    handleApplyButton,
  };
};
