import { ElementType } from 'react';

import { Link, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  disableInteraction?: boolean;
  component?: ElementType;
  to?: string;
};

export const LogoWrapper = styled(Link, { shouldForwardProp: omitForwardedProps<Props>('disableInteraction') })<Props>(
  ({ theme: { spacing, palette }, disableInteraction }) => ({
    display: 'flex',
    alignItems: 'center',

    padding: spacing(1, 0.5),
    borderRadius: spacing(0.5),
    pointerEvents: 'none',

    ...(!disableInteraction && {
      pointerEvents: 'auto',

      '&:hover': {
        backgroundColor: palette.grey[200],
      },

      '&:focus': {
        outline: 'none',
        backgroundColor: palette.grey[300],
      },

      ...(palette.mode === 'dark' && {
        '&:hover, &:focus': {
          backgroundColor: palette.common.black,
        },
      }),
    }),
  })
);
