import React, { FC } from 'react';

import { ApiExceptionMessage } from 'common/utils';
import { useAppDispatch } from 'store';
import { addAppErrorMessage, addAppInfoMessage } from 'store/features/alerts';

import { ErrorCode } from './ErrorCode';

type Props = ApiExceptionMessage;

export const ErrorCodeContainer: FC<Props> = ({ ...apiExceptionMessage }) => {
  const dispatch = useAppDispatch();
  const scope = apiExceptionMessage.stackTrace ? 'stack trace' : 'code';

  const handleCopySuccess = () => {
    dispatch(addAppInfoMessage(`Error ${scope} copied to clipboard`));
  };
  const handleCopyError = () => {
    dispatch(addAppErrorMessage(`Could not copy error ${scope} to clipboard. Please try again.`));
  };

  return (
    <ErrorCode scope={scope} onCopySuccess={handleCopySuccess} onCopyError={handleCopyError} {...apiExceptionMessage} />
  );
};
