import { CSSProperties } from 'react';

import { createTheme } from '@mui/material';
import { type TypographyStyleOptions } from '@mui/material/styles/createTypography';

export const { typography } = createTheme({
  typography: {
    fontFamily: 'Inter',
    htmlFontSize: 16,
    allVariants: {
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
    },
    h1: {
      fontSize: 38,
      lineHeight: '3rem',
    },
    h2: {
      fontSize: 24,
      lineHeight: '2rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 18,
      lineHeight: '1.625rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 16,
      lineHeight: '1.625rem',
    },
    h4Bold: {
      fontSize: 16,
      lineHeight: '1.625rem',
      fontWeight: 'bold',
    },
    button: {
      fontSize: 14,
      lineHeight: '1.5rem',
      textTransform: 'none',
    },
    bodyBig: {
      fontSize: 16,
      lineHeight: '1.625rem',
    },
    body1: {
      fontSize: 14,
      lineHeight: '1.5rem',
    },
    bodyBold: {
      fontSize: '14px',
      lineHeight: '1.5rem',
      fontWeight: 'bold',
    },
    bodySmall: {
      fontSize: 12,
      lineHeight: '1.25rem',
    },
    bodySmallBold: {
      fontSize: 12,
      lineHeight: '1.25rem',
      fontWeight: 'bold',
    },
    caption: {
      fontSize: 10,
      lineHeight: '1rem',
    },
  },
});

export interface TypographyVariants {
  bodyBig: TypographyStyleOptions;
  bodySmall: TypographyStyleOptions;
  bodySmallBold: TypographyStyleOptions;
  bodyBold: TypographyStyleOptions;
  h4Bold: TypographyStyleOptions;
}

export interface TypographyVariantsOptions {
  bodyBig?: TypographyStyleOptions;
  bodySmall?: TypographyStyleOptions;
  bodySmallBold?: TypographyStyleOptions;
  bodyBold?: TypographyStyleOptions;
  h4Bold?: TypographyStyleOptions;
}

export interface TypographyPropsVariantOverrides {
  bodyBig: true;
  bodySmall: true;
  bodySmallBold: true;
  bodyBold: true;
  h4Bold: true;
}

export const textEllipsis: CSSProperties = {
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
