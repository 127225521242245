import { Stack, styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'CameraTest' })(({ theme: { breakpoints, palette } }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  flex: 1,
  width: '100%',
  minHeight: 240,
  color: palette.common.white,
  backgroundColor: palette.common.black,

  '& video': {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  [breakpoints.down('md')]: {
    flex: 'auto',
    maxHeight: 240,
    color: palette.text.primary,
    backgroundColor: 'transparent',
  },
}));

export const ActionsWrapper = styled(Stack, { name: 'CameraActions' })(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  gap: spacing(2),
  padding: spacing(2),
  width: '100%',
  position: 'absolute',
  bottom: 0,
}));
