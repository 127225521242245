import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const selectAllAgoraState = (state: RootState) => state.agora;
const selectNetworkQuality = createSelector(selectAllAgoraState, (state) => state.networkQuality);

export const selectDownlinkNetworkQuality = createSelector(
  selectNetworkQuality,
  (networkQuality) => networkQuality.downlinkNetworkQuality
);

export const selectUplinkNetworkQuality = createSelector(
  selectNetworkQuality,
  (networkQuality) => networkQuality.uplinkNetworkQuality
);
