import { MeetingWSPath } from './meeting';

export const BroadcastWSMessages = {
  Started: 'broadcast_started',
  Stopped: 'broadcast_stopped',
  AudienceJoined: 'broadcast_joined',
  AudienceJoinedList: 'broadcast_joined_list',
  ScreensharingStarted: 'screensharing_started',
  ScreensharingStopped: 'screensharing_stopped',
  Promoted: 'broadcast_promoted',
  Demoted: 'broadcast_demoted',
  GuestPromoted: 'guest_promoted',
  GuestPromotedList: 'guest_promoted_list',
  GuestDemoted: 'guest_demoted',
  ReactionReceived: 'reaction_received',
  StageInvitationSent: 'stage_invitation_sent',
  StageInvitationReceived: 'stage_invitation_received',
  StageInvitationAccepted: 'stage_invitation_accepted',
  StageInvitationRejected: 'stage_invitation_rejected',
  ChatPendingMessages: 'pending_messages',
  ChatMessagesApproved: 'messages_approved',
  ChatMessagesRejected: 'messages_rejected',
  ChatModerationToggled: 'chat_moderation_toggled',
  VideoViaUrlPlayed: 'video_via_url_played',
  VideoViaUrlPaused: 'video_via_url_paused',
  VideoViaUrlResumed: 'video_via_url_resumed',
  VideoViaUrlStopped: 'video_via_url_stopped',
  BroadcastUserSearchSubscribed: 'broadcast_user_search_subscribed',
  BroadcastUserSearchItemAdded: 'broadcast_user_search_item_added',
  BroadcastUserSearchItemRemoved: 'broadcast_user_search_item_removed',
  RecordingStarted: 'recording_started',
  RecordingStopped: 'recording_stopped',
} as const;

export type BroadcastWSMessage = (typeof BroadcastWSMessages)[keyof typeof BroadcastWSMessages];

export const BroadcastWSPath = `${MeetingWSPath}/broadcast` as const;

export const BroadcastWSActions = {
  Start: `${BroadcastWSPath}/start`,
  PromoteCo: `${BroadcastWSPath}/promote_co`,
  Demote: `${BroadcastWSPath}/demote`,
  SendStageInvitation: `${BroadcastWSPath}/send_stage_invitation`,
  AcceptStageInvitation: `${BroadcastWSPath}/accept_stage_invitation`,
  RejectStageInvitation: `${BroadcastWSPath}/reject_stage_invitation`,
  StartSharing: `${BroadcastWSPath}/start_sharing`,
  StopSharing: `${BroadcastWSPath}/stop_sharing`,
  SearchUsers: `${BroadcastWSPath}/search_users`,

  PlayVideoViaUrl: `${MeetingWSPath}/play_video_via_url`,
  StopVideoViaUrl: `${MeetingWSPath}/stop_video_via_url`,
  PauseVideoViaUrl: `${MeetingWSPath}/pause_video_via_url`,
  ResumeVideoViaUrl: `${MeetingWSPath}/resume_video_via_url`,
  SendReaction: `${MeetingWSPath}/send_reaction`,
  StartNattering: `${MeetingWSPath}/start_nattering`,
  StartRecording: `${MeetingWSPath}/start_recording`,
  StopRecording: `${MeetingWSPath}/stop_recording`,
} as const;

export type BroadcastWSAction = (typeof BroadcastWSActions)[keyof typeof BroadcastWSActions];
