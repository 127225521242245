import type { Components, Theme } from '@mui/material';

export const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      padding: spacing(3),
    }),
  },
};
