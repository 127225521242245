import { ElementType } from 'react';

import { Paper, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  inset: boolean;
  dense: boolean;
} & { component?: ElementType };

export const Wrapper = styled(Paper, {
  name: 'Section',
  shouldForwardProp: omitForwardedProps<Props>('dense', 'inset'),
})<Props>(({ theme: { spacing, shape }, dense, inset }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: dense ? spacing(2) : spacing(3),
  borderRadius: dense ? shape.borderRadius : shape.borderRadius * 2,

  ...(inset
    ? {
        padding: spacing(3),
      }
    : {
        border: 'none',
      }),
}));
