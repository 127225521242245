import type { Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {},
  styleOverrides: {},
  variants: [
    {
      props: { size: 'action' },
      style: ({ theme: { palette } }) => ({
        border: 'solid 1px',
        height: 48,
        width: 48,
        color: palette.custom.silver,
        borderColor: palette.custom.grey,
      }),
    },
  ],
};

declare module '@mui/material/IconButton' {
  // eslint-disable-next-line no-unused-vars
  interface IconButtonPropsSizeOverrides {
    action: true;
  }
}
