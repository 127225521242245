import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack, { name: 'FormFieldHint' })(({ theme: { spacing, palette, typography } }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: spacing(0.5),
  color: palette.grey[500],

  '&': {
    ...typography['natter-text-xs'],
  },
}));
