import type { Components, Theme } from '@mui/material';

export const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { typography, spacing } }) => ({
      paddingLeft: 0,
      ...typography.h4,
      fontSize: '16px',
      margin: 0,
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
      '&.Mui-expanded': {
        margin: 0,
        paddingTop: spacing(3),
        paddingBottom: spacing(3),
      },
    }),
    content: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
  },
};
