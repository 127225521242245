import { UserRoleName } from 'domain/UserProfile';

type GenericCommunityUser = {
  category?: string;
  communityRole?: UserRoleName;
  role?: UserRoleName | string;
};

// TODO update below logic after BE returns COMMUNITY_ADMIN as role
export const isCommunityAdmin = (user: GenericCommunityUser = {}): boolean =>
  user.role === UserRoleName.CommunityAdmin ||
  user.communityRole === UserRoleName.CommunityAdmin ||
  user.category === 'CO';

export const isCommunityMember = (user: GenericCommunityUser = {}): boolean =>
  user.role === UserRoleName.CommunityMember || user.communityRole === UserRoleName.CommunityMember;
