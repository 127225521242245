import { ApiErrors, isApiErrors } from 'common/utils';
import type { Effect } from 'store';
import { addAppAlertDialog, addAppConfirmationDialog, addAppErrorMessage } from 'store/features/alerts';

import { insightsReportsWizardActions } from '../../../pages/InsightsReportsWizard/state/insightsReportsWizard.actions';

export const createInsightsReportsSucceededEffect: Effect = (_, { dispatch }) => {
  dispatch(insightsReportsWizardActions.exit({ shouldGoBack: false }));
  dispatch(
    addAppAlertDialog({
      title: 'We Are Creating Your Report',
      description: `We’ll notify you via email as soon as it's ready!`,
      confirmLabel: 'Got it',
    })
  );
};

const isReportAlreadyExistsError = ({ errors }: ApiErrors) =>
  errors.some((error) => new RegExp(/report for .* already exists/i).test(error.message));

export const createInsightsReportsRejectedEffect: Effect = ({ payload }, { dispatch }) => {
  if (!isApiErrors(payload.data)) return;

  if (isReportAlreadyExistsError(payload.data)) {
    dispatch(
      addAppConfirmationDialog({
        severity: 'danger',
        title: 'Creation unsuccessful',
        description: `You already have created a Report from the selected sources. Therefore you can’t have a duplicate. If you want to create a new one you are required to delete the existing one.`,
        confirmLabel: 'Got it!',
        cancelLabel: 'Close',
        showCancelButton: true,
      })
    );
    return;
  }

  const { errors } = payload.data as ApiErrors;
  errors.forEach(({ message }) => {
    dispatch(addAppErrorMessage(message));
  });
};
