import { useCallback } from 'react';

type UseCopyToClipboardArgs = {
  value: string;
  onSuccess?(): void;
  onError?(): void;
};

export const useCopyToClipboard = ({ value, onError, onSuccess }: UseCopyToClipboardArgs) => {
  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(value);
      onSuccess?.();
    } catch (error: unknown) {
      onError?.();
    }
  }, [value, onError, onSuccess]);

  return { copyToClipboard };
};
