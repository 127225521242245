import React, { ComponentType, FC, ReactElement } from 'react';

import { DragProvider } from './DragProvider';

export const withDragProvider =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => (
    <DragProvider>
      <WrappedComponent {...props} />
    </DragProvider>
  );
