import React, { FC, useMemo } from 'react';

import { SxProps } from '@mui/material';
import { ERROR_MESSAGES } from 'common/constants';
import { hasData, hasErrors, hasValidationErrors } from 'modules/api/utils';

import { Alert, AlertVariant } from '../Alert';

type ErrorBoxProps = {
  title?: string;
  error?: unknown;
  sx?: SxProps;
};

/**
 * Based on Alert
 * @link https://www.figma.com/file/En2gOztv0xR9rIjYFtdut5/Design-system?type=design&node-id=454-11894
 */
export const ErrorBox: FC<ErrorBoxProps> = ({ title, error, children, sx }) => {
  const errorMessage = useMemo(() => {
    if (!error) return;
    const newErrorMessage = hasData(error) ? error.data : error;
    if (typeof newErrorMessage === 'string') {
      return newErrorMessage;
    }
    if (hasValidationErrors(newErrorMessage)) {
      return Object.values(newErrorMessage.validationErrors).join(' ');
    }
    if (hasErrors(newErrorMessage)) {
      return newErrorMessage.errors.map((e) => e.message).join('\n');
    }
    return ERROR_MESSAGES.unknownError;
  }, [error]);

  if (!(errorMessage || children)) return null;

  return <Alert variant={AlertVariant.Error} title={title} description={errorMessage || children} sx={sx} />;
};
