import { AxiosResponse } from 'axios';
import { ERROR_MESSAGES } from 'common/constants';
import { isArray } from 'lodash/fp';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

export type ApiExceptionMessage = {
  errorId: string;
  message: string;
  stackTrace?: string; // only on local and dev
};

export type ApiErrors = {
  errors: ApiExceptionMessage[];
};

export const getErrorMessagesFromResponse = (
  // TODO: add type guards for errorResponse
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorResponse?: AxiosResponse<any>,
  defaultErrorMessage = ERROR_MESSAGES.unknownError
): string[] => {
  const errors: string[] = [];
  const validationErrors = _isString(errorResponse) ? errorResponse : _get(errorResponse, 'data.validationErrors', {});

  Object.keys(validationErrors).forEach((key) => {
    const errorMessages = validationErrors[key];
    if (Array.isArray(errorMessages) && errorMessages.length) {
      errorMessages.forEach((errorMessage) => {
        errors.push(errorMessage);
      });
    }
  });

  if (errors.length === 0 && errorResponse?.data) {
    errors.push(errorResponse.data);
  }

  if (errors.length === 0) {
    errors.push(defaultErrorMessage);
  }

  return errors;
};

export const isApiResponse = (response: unknown): response is Pick<AxiosResponse, 'data' | 'status'> =>
  !!response && typeof response === 'object' && 'data' in response && 'status' in response;

export const isApiErrorMessage = (error: unknown): error is ApiExceptionMessage =>
  !!error && typeof error === 'object' && 'errorId' in error && 'message' in error;

export const isApiErrors = (error: unknown): error is ApiErrors =>
  !!error &&
  typeof error === 'object' &&
  'errors' in error &&
  isArray(error.errors) &&
  error.errors.every(isApiErrorMessage);
