import { Stack, styled } from '@mui/material';

export const AlertsWrapper = styled(Stack)(({ theme: { zIndex, breakpoints, spacing } }) => ({
  position: 'absolute',
  zIndex: zIndex.snackbar,
  top: spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: spacing(1),
  width: '90%',
  pointerEvents: 'none',
  overflow: 'hidden',

  [breakpoints.up('sm')]: {
    top: 'auto',
    bottom: spacing(4),
    padding: 0,
    width: 'auto',
    flexDirection: 'column',
  },
}));
