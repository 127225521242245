import { Backdrop, alpha, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTableStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
    },
    container: {
      boxSizing: 'border-box',
      border: `solid 1px ${theme.palette.custom.lightGrey}`,
      background: theme.palette.common.white,
      position: 'relative',
      zIndex: 1,
    },
    header: {
      border: `solid 1px ${theme.palette.custom.lightGrey}`,
    },
    title: {
      minWidth: 28,
      height: 20,
    },
    avatar: {
      width: 39,
      height: 39,
      fontWeight: 'bold',
    },
    cell: {
      color: theme.palette.common.black,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    summaryCell: {
      ...theme.typography.h3,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: 0,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    clickableRow: {
      cursor: 'pointer',
    },
    highlightedRow: {
      '& > td': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    },
    summaryRow: {
      background: theme.palette.primary.main,
      pointerEvents: 'none',
    },
    tableBody: {
      position: 'relative',
    },
    checkboxCell: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
  { name: 'table' }
);

export const StyledBackdrop = styled(Backdrop)({
  position: 'absolute',
});
