import { repeatIntervalTimeUnitSchema, userEmailsSchema } from 'common/components/ReactHookForm';
import { ERROR_MESSAGES } from 'common/constants';
import { addDays, addMonths, endOfDay, startOfDay } from 'date-fns';
import { every, identity } from 'lodash/fp';
import { createQuestionSchema } from 'modules/question/schemas';
import { InferType, array, boolean, date, number, object, string } from 'yup';

import { validateReminderInterval } from '../components/SetupSurveyStep/utils';

export const MAX_NAME_LENGTH = 254;
export const MIN_DUE_BY_DAYS = 1;
export const DEFAULT_SURVEY_NAME = 'Untitled Survey';
export const DEFAULT_REMINDER_INTERVAL_MILLIS = 0;
const DEFAULT_WELCOME_MESSAGE = `Thank you for taking the time to participate. Your feedback is incredibly valuable to us and will help in making important decisions. This survey should only take a few minutes to complete. Please answer each question as honestly as possible. Rest assured, all responses will be kept confidential and anonymous.`;

const SurveyFormModes = {
  CREATE: 'create',
  EDIT: 'edit',
} as const;

export type SurveyFormMode = (typeof SurveyFormModes)[keyof typeof SurveyFormModes];

export const name = string()
  .transform((value: string, originalValue: string) => originalValue.trim().slice(0, MAX_NAME_LENGTH))
  .max(MAX_NAME_LENGTH)
  .trim();

export const baseSchema = object({
  name,
});

export const surveyUserSchema = object({ email: string().label('Email').email().required() });

const questionSchema = createQuestionSchema();

export const setupSurveySchema = object({
  name,
  dueBy: date()
    .label('Due Date')
    .required(({ label }) => ERROR_MESSAGES.requiredWithLabel(label))
    .transform((value: Date, originalValue: Date) => (originalValue ? endOfDay(new Date(originalValue)) : value))
    .min(startOfDay(addDays(Date.now(), MIN_DUE_BY_DAYS)), ({ label }) =>
      ERROR_MESSAGES.minDateWithLabel(label, addDays(Date.now(), MIN_DUE_BY_DAYS))
    )
    .default(addMonths(Date.now(), 1)),
  dueTime: date()
    .typeError('Invalid Date')
    .label('Due Time')
    .required(({ label }) => ERROR_MESSAGES.requiredWithLabel(label))
    .default(endOfDay(Date.now())),
  reminderDisabled: boolean().default(false),
  reminderIntervalAmount: number()
    .typeError(ERROR_MESSAGES.numberTypeError)
    .label('Reminder interval')
    .when('reminderDisabled', {
      is: false,
      then: (schema) => schema.min(1).max(365).integer(),
      otherwise: (schema) => schema.transform((value) => value || 1),
    })
    .default(1),
  reminderIntervalTimeUnit: repeatIntervalTimeUnitSchema,
  firstReminderDate: date().when(
    ['reminderDisabled', 'dueBy', 'dueTime', 'reminderIntervalAmount', 'reminderIntervalTimeUnit'],
    {
      is: (reminderDisabled: boolean, ...deps: unknown[]) => !reminderDisabled && every(identity)(deps),
      then: (schema) =>
        schema.test({
          name: 'validateReminderInterval',
          message: 'Reminder interval cannot extend beyond the survey due date.',
          test: (_, ctx) => validateReminderInterval(ctx.parent),
        }),
    }
  ),
  questions: array()
    .of(questionSchema)
    .default([{ title: '', format: null, isObligatory: true, randomizeChoices: true }])
    .min(1, 'You must add at least one Question to the Survey.'),
  welcomeMessage: string().trim().default(DEFAULT_WELCOME_MESSAGE),
  users: userEmailsSchema.when('$mode', {
    is: (mode: SurveyFormMode) => mode === SurveyFormModes.CREATE,
    then: (schema) => schema.min(1, 'Please add at least 1 participant to the Survey to proceed.'),
  }),
});

export type NameFormSchema = InferType<typeof baseSchema>;
export type SurveyFormSchema = InferType<typeof setupSurveySchema>;
