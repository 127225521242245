import { type Components, type Theme } from '@mui/material';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    root: {
      overflow: 'visible',
      padding: 0,
    },
    switchBase: ({ theme: { palette } }) => ({
      transitionDuration: '300ms',

      '&:hover': {
        boxShadow: `0px 0px 0px 4px ${palette.primary.main}14`,
      },

      '&.Mui-checked': {
        color: '#fff',

        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },

      '&.Mui-disabled': {
        color: palette.common.white,

        '&.Mui-checked': {
          color: palette.common.white,
        },

        '& + .MuiSwitch-track': {
          backgroundColor: palette.grey[200],
          opacity: 1,
        },
      },
    }),
    track: ({ theme: { palette, transitions } }) => ({
      backgroundColor: palette.grey[300],
      opacity: 1,
      transition: transitions.create(['background-color'], {
        duration: 500,
      }),
    }),
    thumb: {
      boxSizing: 'border-box',
      boxShadow: 'none',
    },
    sizeMedium: {
      width: 48,
      height: 28,

      '&.Mui-checked': {
        transform: 'translateX(20px)',
      },

      '& .MuiSwitch-switchBase': {
        padding: 4,
      },

      '& .MuiSwitch-track': {
        borderRadius: 14,
      },
    },
    sizeSmall: {
      width: 36,
      height: 20,

      '&.Mui-checked': {
        transform: 'translateX(16px)',
      },

      '& .MuiSwitch-switchBase': {
        padding: 2,
      },

      '& .MuiSwitch-track': {
        borderRadius: 10,
      },
    },
  },
};
