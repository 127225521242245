import { AsyncSurveyListItem, AsyncSurveyListSortField } from '../types';

export const mapSurveySortField = (sortBy: keyof AsyncSurveyListItem): AsyncSurveyListSortField => {
  switch (sortBy) {
    case 'name':
      return 'NAME';
    case 'state':
      return 'STATE';
    case 'createdAt':
    default:
      return 'CREATED_AT';
  }
};
