import React, { FC, MouseEvent, PropsWithChildren, ReactNode, useRef } from 'react';

import { Breakpoint, Dialog, DialogProps } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withTheme } from 'common/hocs';

export interface ModalProps {
  close: () => void;
  title?: ((close: () => void) => JSX.Element) | JSX.Element | string;
  content?: ((close: () => void) => JSX.Element) | JSX.Element | string;
  dialogProps?: ((close: () => void) => DialogProps) | DialogProps;
  actions?: ((close: () => void) => JSX.Element) | JSX.Element;
  id?: string;
  dialogTitleProps?: Object;
  dialogContentProps?: Object;
  dialogActionsProps?: Object;
  maxWidth?: false | Breakpoint;
  isOpen: boolean;
}

export const ConfirmationModalComponent: FC<PropsWithChildren<ModalProps>> = ({
  id,
  title,
  content,
  actions,
  children,
  dialogTitleProps,
  dialogContentProps,
  dialogActionsProps,
  close,
  dialogProps,
  maxWidth,
  isOpen,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  const getDialogTitle = (): ReactNode => {
    if (typeof title === 'function') {
      return title(close);
    }
    return title;
  };

  const getDialogContent = (): ReactNode => {
    if (typeof content === 'function') {
      return content(close);
    }
    return content;
  };

  const getDialogChildren = (): ReactNode => {
    if (typeof children === 'function') {
      return children(close);
    }
    return children;
  };

  const getDialogActions = (): ReactNode => {
    if (typeof actions === 'function') {
      return actions(close);
    }
    return actions;
  };

  const getDialogProps = (): undefined | DialogProps => {
    if (typeof dialogProps === 'function') {
      return dialogProps(close);
    }
    return dialogProps;
  };

  return (
    <Dialog
      ref={dialogRef}
      open={isOpen}
      aria-labelledby={id}
      onClose={close}
      PaperProps={{
        onClick: (e: MouseEvent<HTMLElement>) => e.stopPropagation(),
      }}
      {...getDialogProps()}
      maxWidth={maxWidth}
    >
      {title && (
        <DialogTitle id={id} {...dialogTitleProps}>
          {getDialogTitle()}
        </DialogTitle>
      )}
      {content && (
        <DialogContent {...dialogContentProps} sx={{ textAlign: 'center', color: 'text.secondary' }}>
          {getDialogContent()}
        </DialogContent>
      )}
      {actions && <DialogActions {...dialogActionsProps}>{getDialogActions()}</DialogActions>}
      {children && getDialogChildren()}
    </Dialog>
  );
};

// TODO Keep theme wrapper as the ConfirmationModal is used during live event with the old theme
export const ConfirmationModal = withTheme(ConfirmationModalComponent);
