import React, { FC } from 'react';

import { Button } from 'common/components/_legacy/Button';
import { Routes } from 'router';
import { useAppDispatch, useAppSelector } from 'store';
import {
  selectShowCommunityCreationSuccessModal,
  setShowCommunityCreationSuccessModal,
} from 'store/features/community';

import { CommunityCreationSuccess } from '../../components';

export const CommunityCreationSuccessModal: FC = () => {
  const dispatch = useAppDispatch();
  const showSuccess = useAppSelector(selectShowCommunityCreationSuccessModal);

  const closeModal = () => dispatch(setShowCommunityCreationSuccessModal(false));

  return (
    <CommunityCreationSuccess isOpen={showSuccess}>
      <Button variant="outlined" color="inherit" onClick={closeModal}>
        Continue
      </Button>
      <Button variant="contained" color="primary" href={Routes.EventWizard} onClick={closeModal}>
        Create an Event
      </Button>
    </CommunityCreationSuccess>
  );
};
