import React, { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { BackButton } from 'common/components';
import { SectionHeader } from 'common/layout';

import { Wrapper } from './Section.styled';

type Props = PropsWithChildren<{
  title: string;
  description?: string;
  showBackButton?: boolean;
  inset?: boolean;
  dense?: boolean;
  size?: 'small' | 'medium';
}>;

export const Section: FC<Props> = ({
  title,
  description,
  showBackButton,
  inset = true,
  dense = false,
  size = 'medium',
  children,
}) => (
  <Wrapper component="section" inset={inset} dense={dense}>
    {showBackButton && (
      <Box>
        <BackButton />
      </Box>
    )}

    <SectionHeader
      variant={size === 'small' ? 'natter-text-sm' : 'natter-text-lg'}
      title={title}
      description={description}
    />

    {children}
  </Wrapper>
);
