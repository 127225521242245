import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LinearProgress, Stack, Typography } from '@mui/material';

type AudioInputVolumeProps = {
  inputLevel: number;
};

export const AudioInputVolume: FC<AudioInputVolumeProps> = ({ inputLevel }) => {
  const { t } = useTranslation('devices', { keyPrefix: 'selectMicrophone' });

  return (
    <Stack direction="row" gap={3} alignItems="center">
      <Typography sx={{ whiteSpace: 'nowrap' }} variant="natter-text-sm">
        {t('testHint')}
      </Typography>
      <LinearProgress
        sx={{
          flex: '1',
          borderRadius: '4px',
        }}
        variant="determinate"
        value={inputLevel}
      />
    </Stack>
  );
};
