import { Status } from './types';

type StatusLabelMap = {
  [key in Status]: string;
};

export const STATUS_LABELS: StatusLabelMap = {
  compressing: 'Compressing',
  error: '',
  idle: '',
  uploaded: '',
  uploading: 'Uploading',
};

export const STATUS_PROGRESS_LABELS: StatusLabelMap = {
  compressing: 'compressed',
  error: '',
  idle: '',
  uploaded: '',
  uploading: 'uploaded',
};
