import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { disabled_WS, enabledUntil_WS, questionAssignment_WS } from './actions';
import { QuestionAssignmentState } from './types';

export const questionAssignmentSlice = createSlice({
  name: 'questionAssignment',
  initialState: {} as QuestionAssignmentState,
  reducers: {
    clearQuestionAssignment: (state) => {
      state.questionAssignmentKey = null;
      state.title = null;
    },
    setActiveAnswerId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.activeAnswerId = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(questionAssignment_WS, (state, { payload }) => {
        state.type = payload.type;
        state.questionId = payload.questionId;
        state.assignmentId = payload.assignmentId;
        state.enabledUntil = payload.enabledUntil;
        state.title = payload.title;
        state.questionAssignmentKey = payload.questionAssignmentKey;
      })
      .addCase(enabledUntil_WS, (state, { payload }) => {
        // TODO Handle time limited questions
        // eslint-disable-next-line no-console
        console.log(`Unhandled WS message ${enabledUntil_WS}`, payload);
      })
      .addCase(disabled_WS, (state, { payload }) => {
        // TODO Handle limited questions
        // eslint-disable-next-line no-console
        console.log(`Unhandled WS message ${disabled_WS}`, payload);
      }),
});

export const { clearQuestionAssignment, setActiveAnswerId } = questionAssignmentSlice.actions;
