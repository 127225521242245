import { makeStyles } from '@mui/styles';

export const useRadioInputStyles = makeStyles(
  () => ({
    root: {
      marginRight: 0,
    },
    radio: {
      fontSize: '14px',
    },
  }),
  { name: 'radioInput' }
);
