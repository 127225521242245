import React, { FC, ReactNode } from 'react';

import { Box, Stack, Typography, type TypographyProps } from '@mui/material';
import { BackButton } from 'common/components';

export type PageHeaderProps = {
  action?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  isFixedHeight?: boolean;
  onBackClick?(): void;
  disableBackButton?: boolean;
} & Pick<TypographyProps, 'textAlign'>;

export const PageHeader: FC<PageHeaderProps> = ({
  action,
  title,
  description,
  isFixedHeight = true,
  textAlign,
  children,
  onBackClick: handleBackClick,
  disableBackButton = !handleBackClick,
}) => (
  <Stack component="header" gap={2} paddingY={4} minHeight={isFixedHeight ? 146 : undefined} justifyContent="flex-end">
    {!disableBackButton && (
      <Box height={20}>
        <BackButton onClick={handleBackClick} />
      </Box>
    )}
    <Stack gap={1}>
      <Stack flexDirection="row" gap={2}>
        {title ? (
          <Typography variant="natter-display-sm" component="h1" flexGrow={1} fontWeight={500} textAlign={textAlign}>
            {title}
          </Typography>
        ) : (
          <Box flexGrow={1} />
        )}
        {action}
      </Stack>
      {description && (
        <Typography variant="natter-text-sm" color="text.secondary" textAlign={textAlign}>
          {description}
        </Typography>
      )}
    </Stack>
    {children}
  </Stack>
);
