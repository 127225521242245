import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'common/components/Buttons';

export const ResetFiltersButton: FC = () => {
  const {
    formState: { isDirty },
    reset,
  } = useFormContext();

  if (!isDirty) return null;

  return (
    <Button variant="text" onClick={() => reset()}>
      Reset all
    </Button>
  );
};
