import { TaskStepperStatus } from 'common/components';

type Props = {
  currentStep: number;
  activeStep: number;
  stepsWithErrors: number[];
  visitedSteps: number[];
  formMode?: 'create' | 'edit';
};

export const getStepStatus = ({
  currentStep,
  activeStep,
  stepsWithErrors,
  visitedSteps,
  formMode,
}: Props): TaskStepperStatus => {
  const stepHasErrors = stepsWithErrors.includes(currentStep);
  const stepWasCompleted = visitedSteps.includes(currentStep) || formMode === 'edit';
  const stepIsActive = activeStep === currentStep;

  if (stepIsActive) {
    if (stepHasErrors || !stepWasCompleted) {
      return 'IN_PROGRESS';
    }
    return 'COMPLETED';
  }
  if (stepHasErrors) {
    return 'INCOMPLETE';
  }
  if (stepWasCompleted) {
    return 'COMPLETED';
  }
  return 'NOT_STARTED';
};
