/**
 * Replaces variables in a path with values from an object.
 *
 * @param {string} path - The path containing variables to be replaced.
 * @param {Record<string, any>} variables - An object containing the values to replace the variables with.
 * @returns {string} The path with variables replaced by their corresponding values.
 *
 * @example
 * generateDynamicPath('/event-details/:eventId', { eventId: 123 });
 * // Output: '/event-details/123/1'
 *
 * @example
 * generateDynamicPath('/event-details/:eventId', { eventId: 123 });
 * // Output: '/event-details/123'
 *
 * @example
 * // If variables exists in the path but was not provided, it will be cleared from path
 * generateDynamicPath('/event-wizard/:eventId');
 * // Output: '/event-wizard'
 */
export const generateDynamicPath = <
  T extends Record<string, unknown> = {},
  Q extends Record<string, string | number | boolean> = {},
>(
  path: string,
  variables: T = {} as T,
  queryParams?: Q,
  options?: { isAbsolute: boolean }
): string => {
  // eslint-disable-next-line functional/no-let
  let generatedPath = path;

  if (variables) {
    generatedPath = path.replace(/:(\w+)\??/g, (_, key) => String(variables[key] ?? '')).replace(/\/$/, '');
  }

  if (queryParams) {
    const queryString = queryParams
      ? new URLSearchParams(Object.entries(queryParams).map(([key, value]) => [key, value.toString()])).toString()
      : '';
    generatedPath = queryString.length ? `${generatedPath}?${queryString}` : generatedPath;
  }

  if (options?.isAbsolute) {
    generatedPath = window.location.origin + generatedPath;
  }

  return generatedPath;
};
