import React, { ReactNode, forwardRef } from 'react';

import { Backdrop, Box, CircularProgress, Grid, Stack, SxProps, Theme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

import { cover } from './Loader';

type BackdropLoaderProps = {
  isTransitionDelay: boolean;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  size?: string | number;
  children?: ReactNode;
};

export const BackdropLoader = forwardRef<HTMLElement, BackdropLoaderProps>(
  ({ sx, isLoading, children, isTransitionDelay, size }, ref) => (
    <Box ref={ref} sx={{ position: 'relative', width: '100%', height: '100%', minHeight: 'inherit', ...sx }}>
      {children}
      <AnimatePresence>
        {isLoading && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={cover}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: isTransitionDelay ? 0.8 : 0 } }}
            exit={{ opacity: 0 }}
            data-testid="Loadable-backdrop"
          >
            <Grid item>
              <Stack justifyContent="center">
                <Backdrop open sx={cover} />
                <CircularProgress data-testid="Loadable-loading" size={size} />
              </Stack>
            </Grid>
          </Grid>
        )}
      </AnimatePresence>
    </Box>
  )
);
