import { matchPath } from 'react-router-dom';

import { createSelector } from '@reduxjs/toolkit';
import { AppRoutes } from 'router';
import type { RootState } from 'store';

export const selectPathname = (state: RootState) => state.router.location?.pathname || '/';
export const selectLocationState = (state: RootState) => state.router.location?.state;

export const selectRouteByPathname = createSelector(selectPathname, (pathname) => {
  if (!pathname) return undefined;

  for (const route of Object.values(AppRoutes)) {
    const match = matchPath<Record<string, string>>(pathname, { path: route, exact: true });
    if (match) {
      return match;
    }
  }

  return undefined;
});

export const selectIdFromCurrentRoute = createSelector(selectRouteByPathname, (match) => {
  if (!match) return undefined;

  const { id } = match.params;

  if (!id || isNaN(Number(id))) return undefined;

  return Number(id);
});
