export type BaseQuestion = {
  questionId: number;
  questionsVersion: string;
  title: string;
};

export enum QuestionVariants {
  TextQuestion = 'TextQuestion',
  MultipleChoiceSingleSelectQuestion = 'MultipleChoiceSingleSelectQuestion',
  MultipleChoiceMultipleSelectQuestion = 'MultipleChoiceMultipleSelectQuestion',
  RankingQuestion = 'RankingQuestion',
  NPSQuestion = 'NPSQuestion',
  SliderQuestion = 'SliderQuestion',
}

export const v1SupportedQuestions = [
  QuestionVariants.MultipleChoiceSingleSelectQuestion,
  QuestionVariants.TextQuestion,
];

export enum CreateQuestionVariants {
  TextQuestion = 'CreateTextQuestionRequest',
  MultipleChoiceSingleSelectQuestion = 'CreateMultipleChoiceSingleSelectQuestionRequest',
  MultipleChoiceMultipleSelectQuestion = 'CreateMultipleChoiceMultipleSelectQuestionRequest',
  RankingQuestion = 'CreateRankingQuestionRequest',
  NPSQuestion = 'CreateNPSQuestionRequest',
  SliderQuestion = 'CreateSliderQuestionRequest',
}

export enum CreateAnswerVariants {
  TextQuestion = 'CreateTextAnswerRequest',
  MultipleChoiceSingleSelectQuestion = 'CreateMultipleChoiceSingleSelectAnswerRequest',
  MultipleChoiceMultipleSelectQuestion = 'CreateMultipleChoiceMultipleSelectAnswerRequest',
  RankingQuestion = 'CreateRankingAnswerRequest',
  NPSQuestion = 'CreateNPSAnswerRequest',
  Slider = 'CreateSliderAnswerRequest',
}

export const UpdateAnswerVariants = {
  TextQuestion: 'UpdateTextAnswerRequest',
  MultipleChoiceSingleSelectQuestion: 'UpdateMultipleChoiceSingleSelectAnswerRequest',
  RankingQuestion: 'UpdateRankingQuestionRequest',
} as const;

export type UpdateAnswerVariant = (typeof UpdateAnswerVariants)[keyof typeof UpdateAnswerVariants];
