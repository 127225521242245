import React, { FC, MouseEventHandler } from 'react';

import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, styled } from '@mui/material';
import { CloseIcon } from 'icons';

import { ButtonIcon } from '../ButtonIcon';

const CloseButton = styled(ButtonIcon)(({ theme: { spacing } }) => ({
  position: 'absolute',
  right: spacing(2),
  top: spacing(2),
  width: 37,
  '& > .MuiIconButton-label': {
    width: 20,
    height: 20,
  },
}));

interface DialogTitleProps extends MuiDialogTitleProps {
  title?: string;
  onClose?: MouseEventHandler<HTMLButtonElement>;
}

// TODO: join with dialog content to dialog component
export const DialogTitle: FC<DialogTitleProps> = ({ title, onClose, className, children, ...props }) => (
  <MuiDialogTitle {...props} className={className}>
    {title ?? children}
    {onClose && <CloseButton color="inherit" onClick={onClose} icon={<CloseIcon fontSize="small" />} size="medium" />}
  </MuiDialogTitle>
);
