import { Stack, styled } from '@mui/material';

import { Button } from '../Buttons/Button';

export const ErrorStateContent = styled(Stack, { name: 'ErrorStateContent' })(({ theme: { spacing } }) => ({
  height: '100%',
  maxWidth: spacing(58),
  gap: spacing(3),
  alignItems: 'center',
  justifyContent: 'center',
  transform: `translateY(${spacing(-8)})`,
}));

export const ErrorStateAction = styled(Button, { name: 'ErrorStateAction' })({
  minWidth: '60%',
});
