import { makeStyles } from '@mui/styles';
import { NatterColors, customColors } from 'theme/palette';

export const useActionButtonStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
    },
    actionIcon: {
      cursor: 'pointer',
      color: customColors.silver,
      backgroundColor: theme.palette.common.black,
      borderRadius: '50%',
      border: `solid 1px ${customColors.grey}`,
      width: 48,
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      fontSize: '1.3em',
      WebkitTapHighlightColor: 'transparent',
      [theme.breakpoints.up('sm')]: {
        '&:hover:not(.disabled)': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          border: `solid 1px ${customColors.lightGrey}`,
        },
      },
    },
    actionIconOff: {
      backgroundColor: NatterColors.red,
      color: theme.palette.common.white,
      border: `solid 1px ${NatterColors.red}`,
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
  { name: 'actionButton' }
);
