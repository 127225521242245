import React from 'react';
import { FieldErrors, FieldPath, FieldValues } from 'react-hook-form';

import { isEmpty } from 'lodash';

import { Alert, AlertVariant } from '../../Alert';
import { FormFieldHint } from '../../Inputs';
import { extractErrorMessage } from './utils';

type Props<T extends FieldValues> = {
  name?: FieldPath<T> | FieldPath<T>[];
  errors: FieldErrors<T>;
  variant?: 'alert' | 'hint';
};

// TODO Component WIP to handle multiple types of schema errors. Currently return first error message.
export const FormErrors = <T extends FieldValues>({ errors, name, variant = 'alert' }: Props<T>) => {
  if (isEmpty(errors)) return null;

  const errorMessage = extractErrorMessage(errors, name).join('\n');

  if (!errorMessage) return null;

  if (variant === 'hint') {
    return <FormFieldHint error>{errorMessage}</FormFieldHint>;
  }

  return <Alert variant={AlertVariant.Error} description={errorMessage} />;
};
