import React, { FC } from 'react';

export const ShareIcon: FC<{ width?: string | number; height?: string | number }> = ({ width = 14, height = 19 }) => (
  <svg width={width} height={height} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99998 12.3333C6.76387 12.3333 6.56609 12.2533 6.40665 12.0933C6.24665 11.9339 6.16665 11.7361 6.16665 11.5V3.02083L5.41665 3.77083C5.26387 3.92361 5.07304 4 4.84415 4C4.6147 4 4.41665 3.91667 4.24998 3.75C4.0972 3.58333 4.02081 3.38528 4.02081 3.15583C4.02081 2.92694 4.0972 2.73611 4.24998 2.58333L6.41665 0.416667C6.48609 0.347222 6.57303 0.291667 6.67748 0.25C6.78137 0.208333 6.88887 0.1875 6.99998 0.1875C7.11109 0.1875 7.21887 0.208333 7.32331 0.25C7.4272 0.291667 7.51387 0.347222 7.58331 0.416667L9.74998 2.58333C9.91665 2.75 9.99998 2.95139 9.99998 3.1875C9.99998 3.42361 9.91665 3.61806 9.74998 3.77083C9.58331 3.92361 9.38554 4 9.15665 4C8.9272 4 8.73609 3.92361 8.58331 3.77083L7.83331 3.02083V11.5C7.83331 11.7361 7.75359 11.9339 7.59415 12.0933C7.43415 12.2533 7.23609 12.3333 6.99998 12.3333ZM1.99998 18.1667C1.54165 18.1667 1.14942 18.0036 0.823313 17.6775C0.496646 17.3508 0.333313 16.9583 0.333313 16.5V7.33333C0.333313 6.875 0.496646 6.4825 0.823313 6.15583C1.14942 5.82972 1.54165 5.66667 1.99998 5.66667H3.66665C3.90276 5.66667 4.10081 5.74639 4.26081 5.90583C4.42026 6.06583 4.49998 6.26389 4.49998 6.5C4.49998 6.73611 4.42026 6.93389 4.26081 7.09333C4.10081 7.25333 3.90276 7.33333 3.66665 7.33333H1.99998V16.5H12V7.33333H10.3333C10.0972 7.33333 9.89942 7.25333 9.73998 7.09333C9.57998 6.93389 9.49998 6.73611 9.49998 6.5C9.49998 6.26389 9.57998 6.06583 9.73998 5.90583C9.89942 5.74639 10.0972 5.66667 10.3333 5.66667H12C12.4583 5.66667 12.8508 5.82972 13.1775 6.15583C13.5036 6.4825 13.6666 6.875 13.6666 7.33333V16.5C13.6666 16.9583 13.5036 17.3508 13.1775 17.6775C12.8508 18.0036 12.4583 18.1667 12 18.1667H1.99998Z"
      fill="white"
    />
  </svg>
);
