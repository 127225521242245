import { createSelector } from '@reduxjs/toolkit';
import { includes } from 'lodash/fp';
import type { RootState } from 'store';

const selectRooms = (state: RootState) => state.socket.connectedRooms;
const selectRoomName = (state: RootState, roomName: string) => roomName;

export const selectIsConnectedToRoom = createSelector(selectRoomName, selectRooms, includes);

export const selectIsSocketConnected = (state: RootState) => state.socket.isConnected;
export const selectIsSocketReconnecting = (state: RootState) => state.socket.isReconnecting;
