import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { selectSpeakers } from '../event';

export const selectIsParticipantSpeaker = createSelector(
  [selectSpeakers, (state: RootState, participantId: number) => participantId],
  (speakers, participantId) => speakers.some((s) => s.id === participantId)
);

export const selectParticipantsSearchResults = (state: RootState) => state.participantsSearch.results;
export const selectParticipantsSearchIsLoading = (state: RootState) => state.participantsSearch.isLoading;
export const selectParticipantsSearchParams = (state: RootState) => state.participantsSearch.params;
