import React, { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { Components, LinkProps as MuiLinkProps, Theme } from '@mui/material';

const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  ({ href, ...props }, ref) => <RouterLink ref={ref} to={href} {...props} />
);

export const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    component: LinkBehavior,
    underline: 'hover',
  } as MuiLinkProps,
};
