import { FormControlLabel, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

export type StyledFormControlLabelProps = {
  variant?: 'default' | 'outlined';
};

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: omitForwardedProps<StyledFormControlLabelProps>('variant'),
})<StyledFormControlLabelProps>(({ theme: { palette, shape, spacing, typography }, variant, checked }) => ({
  marginLeft: spacing(-1),
  paddingLeft: spacing(5),
  position: 'relative',

  '& > .MuiCheckbox-root': {
    left: 0,
    position: 'absolute',
  },

  '& > .MuiFormControlLabel-label': {
    ...typography['natter-text-sm'],
  },

  ...(variant === 'outlined' && {
    '&.MuiFormControlLabel-root': {
      margin: 0,
    },

    '& .MuiCheckbox-root': {
      marginLeft: spacing(1),
    },

    padding: spacing(1, 2, 1, 6),
    fontWeight: 500,

    borderRadius: shape.borderRadius,
    border: '1px solid',
    borderColor: palette.divider,

    ...(checked && {
      backgroundColor: palette.extendedPrimary[25],
      borderColor: palette.primary.main,
    }),
  }),
}));
