import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntercom as useIntercomExternal } from 'react-use-intercom';

import { DEFAULT_PRIMARY_COLOR } from 'common/theme/palette';
import { AppRoutes } from 'router';
import { useAppSelector } from 'store';
import { selectUserAccount } from 'store/apis/user';

export const intercomBlacklistedRoutes = [
  AppRoutes.EventRoom,
  AppRoutes.JoinEvent,
  AppRoutes.EventReport,
  AppRoutes.SurveyReport,
  AppRoutes.InsightsReportSurveyById,
];

export const useIntercom = () => {
  const userAccount = useAppSelector(selectUserAccount);
  const isCurrentRouteBlacklisted = useRouteMatch(intercomBlacklistedRoutes);
  const { boot, shutdown } = useIntercomExternal();

  useEffect(() => {
    if (!userAccount) return;

    if (isCurrentRouteBlacklisted) {
      shutdown();
      return;
    }

    boot({ actionColor: DEFAULT_PRIMARY_COLOR, name: userAccount.displayName });
  }, [isCurrentRouteBlacklisted, shutdown, boot, userAccount]);
};
