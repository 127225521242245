import { createListenerMiddleware } from '@reduxjs/toolkit';
import { assert } from 'common/utils';
import type { RootState } from 'store';
import { surveyApi } from 'store/apis/survey';

import { surveysListActions } from './surveysList.actions';

export const surveysListListener = createListenerMiddleware<RootState>();

surveysListListener.startListening({
  actionCreator: surveysListActions.listItemActions.unpublishButton.confirmed,
  effect: async ({ payload: id }, { dispatch }) => {
    assert(id);
    dispatch(surveyApi.endpoints.unpublishSurvey.initiate({ id }));
  },
});

surveysListListener.startListening({
  actionCreator: surveysListActions.listItemActions.deleteButton.confirmed,
  effect: async ({ payload: id }, { dispatch }) => {
    assert(id);
    dispatch(surveyApi.endpoints.deleteSurvey.initiate({ id }));
  },
});
