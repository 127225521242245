import { FieldPath } from 'react-hook-form';

import { SurveyFormSchema } from 'pages/SurveyWizard/constants';

export enum SetupSurveyProgressTrackerStep {
  Questions,
  Scheduling,
  Participants,
}

export const SetupSurveyProgressTrackerStepFields: Record<
  SetupSurveyProgressTrackerStep,
  FieldPath<SurveyFormSchema>[]
> = {
  [SetupSurveyProgressTrackerStep.Questions]: ['questions'],
  [SetupSurveyProgressTrackerStep.Scheduling]: [
    'dueBy',
    'dueTime',
    'reminderIntervalAmount',
    'reminderIntervalTimeUnit',
    'reminderDisabled',
    'firstReminderDate',
  ],
  [SetupSurveyProgressTrackerStep.Participants]: ['users', 'welcomeMessage'],
};
