import { type Components, type Theme, alpha } from '@mui/material';

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      flex: 1,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderColor: theme.palette.primary.main,
      color: theme.palette.grey[700],
      fontWeight: 500,
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
      },
      '&.Mui-selected': {
        touchAction: 'none',
        pointerEvents: 'none',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        '&:hover': {
          // required for proper UX on mobile
          backgroundColor: theme.palette.primary.main,
        },
      },
    }),
  },
};
