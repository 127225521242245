import type {
  ConnectionState,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  UID,
} from 'agora-rtc-react';
import type { MeetingWSParams } from 'store/features/event';

import { TranscriptionMode } from './event';

export enum ClientRole {
  Audience = 'audience',
  Host = 'host',
}

export interface AgoraVolume {
  uid: number;
  level: number;
}

export enum MediaTrackState {
  Enabled,
  Disabled,
  Enabling,
  Disabling,
}

export enum PromotionState {
  IDLE,
  PROMOTING,
  DEMOTING,
}

export interface UseAgoraParams {
  agoraProxyMode?: number;
  screenShareUID?: string | number;
  mediaPlayerUID?: number;
}

export type UseAgoraValue = {
  localAudioTrack?: ILocalAudioTrack;
  doesLocalAudioTrackExists: boolean;
  localVideoTrack?: ILocalVideoTrack;
  doesLocalVideoTrackExists: boolean;
  promoteToSpeaker(token: string): Promise<void>;
  demoteFromSpeaker: Function;
  join(joinOptions: { channel: string; token: string; uid?: UID }): Promise<void>;
  speakersStreams: IAgoraRTCRemoteUser[];
  audioTrackState: MediaTrackState;
  videoTrackState: MediaTrackState;
  connectionState: ConnectionState;
  videoStream?: IAgoraRTCRemoteUser;
  screensharingStream?: IAgoraRTCRemoteUser;
  client: IAgoraRTCClient;
  isBlurSupported: boolean;
  toggleVideo(): void;
  promotionState: PromotionState;
};

export type Reaction = { id: string; value: string; x: number; y: number };
export type SendReactionParams = {
  reaction: string;
  /** @deprecated from meeting/v2 */
  accountId: number;
  /** @new in meeting/v2 */
  userId: number;
};

export type AgoraCloudPlayerInfo = {
  streamUrl: string;
  createdById: number;
  createdByName: string;
  isPlaying?: boolean;
  seekPosition?: number;
  mediaPlayerUID?: number;
};

export type StartNatteringParams = {
  withBroadcast?: boolean;
  topicIds?: Array<number>;
  questionIds?: Array<number>;
  pace?: number;
  transcriptionMode?: TranscriptionMode;
};

interface StageInvitationData {
  invitedUserId: number;
  invitedUserFullName: string;
  invitingUserEmail: string;
  invitingUserId: number;
  invitingUserFullName: string;
  invitationTimeout: string;
}

export interface StageInvitationApiPayload {
  invitedUserId: string;
  invitedUserFullName: string;
  invitingUserEmail: string;
  invitingUserId: string;
  invitingUserFullName: string;
  invitationTimeout: string;
}

export type StageInvitationBasicInfo = Pick<
  StageInvitationData,
  'invitationTimeout' | 'invitingUserFullName' | 'invitingUserEmail'
>;

export type HandleStageInvitationParams = MeetingWSParams<Pick<StageInvitationData, 'invitingUserEmail'>>;

export interface SpeakerMediaPlayer {
  localVideoTrack?: ILocalVideoTrack;
  localAudioTrack?: ILocalAudioTrack;
  videoTrack?: IRemoteVideoTrack;
  audioTrack?: IRemoteAudioTrack;
  uid: string | number;
  isLocal: boolean;
}

export interface BroadcastSpeakerApiPayload {
  accountId: string;
  avatarLink: string;
  channelId: string;
  role: string;
  userId: string;
  userName: string;
}

export type BroadcastDemoteSpeakerApiPayload = Pick<
  BroadcastSpeakerApiPayload,
  'accountId' | 'role' | 'userId' | 'userName'
>;

export interface ScreenshareDetailsApiPayload {
  sharerUID: string;
  sharerName: string;
}

export interface ScreenshareDetails {
  sharerUID: number;
  sharerName: string;
}

export interface ActiveMeetingBroadcast {
  accessToken?: string;
  channelId: string;
  broadcastChatToken: string;
  role: ClientRole;
  isChatModerated: boolean;
  publisherAccessToken: string | null;
  screensharing?: ScreenshareDetails;
  cloudPlayer: AgoraCloudPlayerInfo | null;
  isRecording: boolean;
}

export type BroadcastConnectionInfo = Pick<
  ActiveMeetingBroadcast,
  'accessToken' | 'broadcastChatToken' | 'channelId'
> & {
  isChatModerated: 'true' | 'false';
  isRecording: 'true' | 'false';
};
