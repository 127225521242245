import React, { FC } from 'react';

import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { ErrorBox } from 'common/components/_legacy/ErrorBox';
import { NavigationBar } from 'common/layout';

interface ErrorPageProps {
  title?: string;
  status?: string;
  message?: string;
  error?: unknown;
}

const ErrorPage: FC<ErrorPageProps> = ({ title, status, message, error }) => (
  <>
    <NavigationBar disableUserSection />

    <Box display="flex" width={1} height={1} alignItems="center" justifyContent="center">
      <Container maxWidth="sm">
        <Stack alignItems="center" gap={3}>
          {status && <Typography variant="natter-display-2xl">{status}</Typography>}
          <Typography variant="natter-display-md" textAlign="center">
            {title ?? (
              <>
                Oops!
                <br />
                Something went wrong
              </>
            )}
          </Typography>
          {typeof message === 'string' && <Typography variant="natter-display-xs">{message}</Typography>}
          {error ? <ErrorBox error={error} /> : <Typography>Looks like there was some unexpected error.</Typography>}
          <Typography>
            Refresh the page or contact the Support Team{' '}
            <Link underline="hover" href="https://intercom.help/natter/en/" target="_blank" rel="noreferrer">
              here
            </Link>
            .
          </Typography>
          <Button href="/">Go back to Natter</Button>
        </Stack>
      </Container>
    </Box>
  </>
);

export default ErrorPage;
