const toMinutesNumber = (minutesString: string): number => {
  const numericMinutes = Number(minutesString);
  if (isNaN(numericMinutes)) {
    return 0;
  }
  if (numericMinutes < 10) {
    return numericMinutes * 10;
  }
  return numericMinutes;
};

const toMinutesStringQuarter = (minutes: number) => {
  if (minutes < 10) {
    return '00';
  }
  if (minutes > 45) {
    return '45';
  }
  const roundedMinutes = (Math.round(minutes / 15) * 15) % 60;
  return roundedMinutes.toString();
};

const toValidHourString = (hour: string) => {
  const numericHour = Number(hour);
  if (isNaN(numericHour)) return '00';
  return numericHour < 10 ? `0${numericHour}` : `${numericHour}`;
};

export const getNearestTimeOptionLabel = (timeValue: string): string => {
  const [hour, rest = 'mm: aa'] = timeValue.split(':');
  const validHour = toValidHourString(hour);
  const [minutes, ampm] = rest.split(' ');
  const numericMinutes = toMinutesNumber(minutes);
  const stringMinutes = toMinutesStringQuarter(numericMinutes);
  return `${validHour}:${stringMinutes}${ampm === 'PM' ? 'PM' : 'AM'}`;
};
