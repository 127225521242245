import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useDarkScroll = makeStyles(
  ({ palette }) => ({
    darkScroll: {
      '&::-webkit-scrollbar': {
        width: 9,
        height: 9,
      },
      '&::-webkit-scrollbar-button': {
        width: 0,
        height: 0,
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.mode === 'dark' ? palette.common.black : palette.grey[400],
        border: palette.mode === 'dark' ? '1px solid' : '0 none',
        borderColor: palette.mode === 'dark' ? palette.custom.grey : palette.common.white,
        borderRadius: 50,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: palette.mode === 'dark' ? lighten(palette.common.black, 0.12) : palette.grey[500],
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: palette.grey[600],
      },
      '&::-webkit-scrollbar-track': {
        background: palette.mode === 'dark' ? palette.custom.grey : palette.grey[300],
        border: `0px none ${palette.common.white}`, // maybe change to border: none?
        borderRadius: 50,
      },
      '&::-webkit-scrollbar-track:hover': {
        background: palette.grey[300],
      },
      '&::-webkit-scrollbar-track:active': {
        background: palette.grey[300],
      },
      '&::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
    },
  }),
  { name: 'darkScroll' }
);
