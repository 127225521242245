import { PaginatedApiPayloadV2, PaginationParams, TagsCategory, TagsCategoryV2 } from 'domain/Common';
import { EventGuest, ListedEventGuest } from 'domain/event';

export const mapPaginatedEventGuestsFromApi = (
  payload: PaginatedApiPayloadV2<ListedEventGuest>
): PaginatedApiPayloadV2<EventGuest> => ({
  ...payload,
  data: mapConnectionsFromApi(payload.data),
});

export const mapConnectionsFromApi = (data: ListedEventGuest[]): EventGuest[] => data.map(mapEventGuestsFromApi);

const mapEventGuestsFromApi = (data: ListedEventGuest): EventGuest => ({
  ...data,
  communityTagCategories: data.communityTagCategories.map(mapCommunityTagCategoriesFromApi),
});

const mapCommunityTagCategoriesFromApi = (data: TagsCategoryV2): TagsCategory => ({
  ...data,
  isSingleSelection: data.singleSelection,
});

export type EventGuestsSortBy = 'DISPLAY_NAME' | 'ROLE' | 'EMAIL';

export const mapSortParam = (param: string): EventGuestsSortBy | undefined => {
  switch (param) {
    case 'displayName':
      return 'DISPLAY_NAME';
    case 'role':
      return 'ROLE';
    case 'email':
      return 'EMAIL';
    default:
      return undefined;
  }
};

export const mapGetEventGuestsParams = ({
  tagIds,
  page,
  pageSize,
  sortBy,
  sortOrder,
}: PaginationParams & { tagIds: number[] }) => ({
  page,
  pageSize,
  sortParam: mapSortParam(sortBy),
  sortDir: sortOrder,
  tagIds: tagIds.join(',') || undefined,
});
