import { useEffect, useState } from 'react';

export const useTextInputAutoFocus = () => {
  const [autoFocusInput, setAutoFocusInput] = useState<HTMLInputElement | HTMLTextAreaElement>();

  useEffect(() => {
    if (!autoFocusInput) return;

    autoFocusInput.focus();
    autoFocusInput.selectionStart = autoFocusInput.selectionEnd = autoFocusInput.value.length;
  }, [autoFocusInput]);

  return {
    setAutoFocusInput,
  };
};
