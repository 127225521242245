import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { handleQueryError } from 'store/utils';

import { publicEventsReportApi } from './publicEventsReportApi';

// TODO extract effects in next iteration
export const publicEventsReportApiLister = createListenerMiddleware<RootState>();

publicEventsReportApiLister.startListening({
  matcher: isAnyOf(
    publicEventsReportApi.endpoints.getPublicEventsReportById.matchRejected,
    publicEventsReportApi.endpoints.getPublicEventsReportKeyMetrics.matchRejected,
    publicEventsReportApi.endpoints.getPublicEventsReportDemographics.matchRejected,
    publicEventsReportApi.endpoints.getPublicEventsReportAnswers.matchRejected
  ),
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, "Unable to fetch Events Report's data");
  },
});
