import React, { FC, RefObject } from 'react';
import { useWatch } from 'react-hook-form';

import { Stack, Typography } from '@mui/material';
import { TextField } from 'common/components/ReactHookForm';

import { StyledAccordionDragIcon, Wrapper } from './TagsCategory.styled';
import { TagInput } from './components';
import { RemoveTagCategory } from './components/RemoveTagCategory';

type Props = {
  name: string;
  index?: number;
  shadow?: boolean;
  handlerRef?: RefObject<HTMLDivElement>;
  onRemoveCategory?(): void;
};

export const TagsCategory: FC<Props> = ({ name, index, shadow, handlerRef, onRemoveCategory }) => {
  const isOptional: boolean = useWatch({ name: `${name}.isOptional` });
  const categoryName: string = useWatch({ name: `${name}.name` });
  const label = isOptional ? 'Optional Category' : `Category ${index === undefined ? '' : index + 1}`;
  const isDraggable = !isOptional;

  return (
    <Wrapper isDraggable={isDraggable} sx={({ shadows }) => ({ boxShadow: shadow ? shadows[10] : undefined })}>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={1} justifyContent="center">
          <Typography variant="natter-text-sm" fontWeight={600}>
            {label}
          </Typography>
          {isOptional && (
            <Typography variant="natter-text-sm" color="text.secondary">
              Participants will have the option to select one, multiple or no tags in this Category.
            </Typography>
          )}
        </Stack>
        {isDraggable && (
          <Stack ref={handlerRef} justifyContent="center">
            <StyledAccordionDragIcon />
          </Stack>
        )}
      </Stack>

      <Stack gap={2}>
        <TextField name={`${name}.name`} label="Tag Category Name" placeholder="Add Category Name" fullWidth />
        <TagInput name={`${name}.tags`} />
      </Stack>

      {!!onRemoveCategory && (
        <RemoveTagCategory onRemoveCategory={onRemoveCategory} categoryName={categoryName || label} />
      )}
    </Wrapper>
  );
};
