import type { Components, Theme } from '@mui/material';

export const MuiLinearProgress: Components<Theme>['MuiLinearProgress'] = {
  styleOverrides: {
    root: ({ theme: { shape, palette } }) => ({
      height: 6,
      borderRadius: shape.borderRadius,
      backgroundColor: palette.grey[300],
    }),
    bar1Determinate: ({ theme: { shape } }) => ({
      borderRadius: shape.borderRadius,
    }),
  },
};
