import { createListenerMiddleware } from '@reduxjs/toolkit';
import { logger } from 'common/services';
import { pick } from 'lodash/fp';
import type { RootState } from 'store';
import { meetingRoomConnectionError } from 'store/features/event';

import { eventQueueActions } from './eventQueue.actions';

export const eventQueueListener = createListenerMiddleware<RootState>();

eventQueueListener.startListening({
  actionCreator: eventQueueActions.proceedToVideoCallTimedOut,
  effect: ({ payload: { connectedRoom, attendees, disconnectedAttendees } }) => {
    logger.error('[EventQueue] Unable to proceed to video call', {
      extra: {
        connectedRoom,
        attendees: attendees.map(pick(['id', 'name', 'twilioIdentity'])),
        disconnectedAttendees: disconnectedAttendees.map(pick(['id', 'name', 'twilioIdentity'])),
      },
    });
  },
});

eventQueueListener.startListening({
  actionCreator: meetingRoomConnectionError,
  effect: ({ payload: { eventId, roomId } }) => {
    logger.error('[EventQueue] Meeting room connection error', { extra: { eventId, roomId } });
  },
});
