import React, { useCallback, useMemo, useState } from 'react';

import { Dialog } from 'common/components/Dialog';
import type { DialogProps } from 'common/components/Dialog/Dialog';

type CallbackI<T = unknown> = {
  (data?: T): void;
};

type WithCloseCallback = {
  (close: CallbackI): JSX.Element;
};

type ModalPropsI = Partial<Omit<DialogProps, 'isOpen' | 'onClose' | 'showCancelButton'>> & {
  content?: string | JSX.Element | WithCloseCallback;
  closeOnConfirm?: boolean;
};

export const useConfirmationModal = <T,>(
  modalProps: ModalPropsI | ((data?: T) => ModalPropsI),
  confirmCallback?: CallbackI<T>
): [CallbackI<T>, React.JSX.Element, CallbackI] => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<T>();

  const openCallback = useCallback((callbackData?: T) => {
    setData(callbackData);
    setIsOpen(true);
  }, []);

  const closeCallback = useCallback(() => {
    setIsOpen(false);
  }, []);

  const modalData = useMemo(() => {
    const { closeOnConfirm = true, ...props } = typeof modalProps === 'function' ? modalProps(data) : modalProps;

    return {
      ...props,
      onConfirm: () => {
        confirmCallback?.(data);
        if (closeOnConfirm) {
          closeCallback();
        }
      },
    };
  }, [modalProps, confirmCallback, closeCallback, data]);

  const ModalComponent = useMemo(() => {
    const isConfirmationVariant = modalData.variant === 'confirmation';

    return (
      <Dialog
        {...modalData}
        isOpen={isOpen}
        onClose={closeCallback}
        showCancelButton={!!modalData.cancelLabel}
        showWarningIcon={isConfirmationVariant ? false : modalData.showWarningIcon}
        showCloseButton={isConfirmationVariant ? false : modalData.showCloseButton}
      >
        {modalData.content}
      </Dialog>
    );
  }, [isOpen, modalData, closeCallback]);

  return [openCallback, ModalComponent, closeCallback];
};
