import { makeStyles } from '@mui/styles';

export const useTextInputStyles = makeStyles(
  (theme) => ({
    justifyCenter: {
      textAlign: 'center',
    },
    container: {
      width: 315,
    },
    centered: {
      margin: '0 auto',
    },
    hint: {
      marginBottom: theme.spacing(4),
      maxWidth: '100%',
    },
    autoWidth: {
      width: 'auto',
    },
  }),
  { name: 'textInput' }
);
