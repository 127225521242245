export interface OrganizationInfo {
  organizationId: number;
  organizationName: string;
  organizationSlug: string;
}

export interface OrganizationMemberApiPayload {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  avatarLink: string;
  role: OrganizationMemberRole;
  communityIds?: number[];
}

export interface OrganizationMember {
  userId: number;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  avatarLink: string;
  role: OrganizationMemberRole;
  communityIds: number[];
}

export enum OrganizationMemberRole {
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN',
}

export const OrganizationMemberLabel: Record<OrganizationMemberRole, string> = {
  [OrganizationMemberRole.MEMBER]: 'Member',
  [OrganizationMemberRole.ADMIN]: 'Admin',
};
