import { createAction } from '@reduxjs/toolkit';
import { EventRoomAttendee } from 'domain/event';

export const eventQueueActions = {
  proceedToVideoCallTimedOut: createAction<{
    connectedRoom: { token?: string; roomId?: string };
    attendees: EventRoomAttendee[];
    disconnectedAttendees: EventRoomAttendee[];
  }>('[Event Queue] proceed to video call timed out'),
};
