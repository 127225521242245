// nosemgrep
export const getRandomNumber = (min: number, max: number): number => Math.random() * (max - min) + min;

// nosemgrep
export const getRandomInteger = (min: number, max: number): number => getRandomNumber(Math.ceil(min), Math.floor(max));

const formatter = new Intl.NumberFormat('en', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumSignificantDigits: 3,
});

const percentageFormatter = new Intl.NumberFormat('en', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 1,
});

export const convertNumberToAbbreviation = (value: number, isPercentage?: boolean) =>
  isPercentage ? percentageFormatter.format(value) : formatter.format(value);
