import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  broadcastUserSearchItemAdded_WS,
  broadcastUserSearchItemRemoved_WS,
  broadcastUserSearchSubscribed_WS,
} from './actions';
import { GetEventParticipantsParams, ParticipantsSearchState } from './types';

export const participantsSearchInitialState: ParticipantsSearchState = {
  params: {
    page: 1,
    pageSize: 20,
    searchPhrase: '',
  },
  isLoading: false,
  results: {
    data: [],
    totalCount: 0,
    searchPhrase: '',
  },
};

export const participantsSearchSlice = createSlice({
  name: 'participantsSearch',
  initialState: participantsSearchInitialState,
  reducers: {
    resetParticipantSearch: () => participantsSearchInitialState,
    setParticipantsSearchParams: (state, { payload }: PayloadAction<Partial<GetEventParticipantsParams>>) => {
      state.params = {
        ...state.params,
        ...payload,
      };
    },
    setParticipantsSearchIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(broadcastUserSearchSubscribed_WS, (state, { payload }) => {
        if (state.params.searchPhrase !== payload.searchPhrase) return;

        if (state.results.searchPhrase !== payload.searchPhrase) {
          state.results.data = [];
          state.results.searchPhrase = payload.searchPhrase;
        }
        state.results.data.push(...payload.broadcastUsers.data);
        state.results.totalCount = payload.broadcastUsers.totalCount;
        state.isLoading = false;
      })
      .addCase(broadcastUserSearchItemAdded_WS, (state, { payload }) => {
        if (state.params.searchPhrase !== payload.searchPhrase) return;

        if (payload.index < state.params.page * state.params.pageSize) {
          state.results.data.splice(payload.index, 0, payload.value);
        }
        state.results.totalCount++;
      })
      .addCase(broadcastUserSearchItemRemoved_WS, (state, { payload }) => {
        if (state.params.searchPhrase !== payload.searchPhrase) return;

        if (payload.index < state.params.page * state.params.pageSize) {
          state.results.data.splice(payload.index, 1);
        }
        state.results.totalCount--;
      }),
});

export const { resetParticipantSearch, setParticipantsSearchParams, setParticipantsSearchIsLoading } =
  participantsSearchSlice.actions;
