import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCommunitySuccessStyles = makeStyles(
  (theme) => ({
    svgIcon: {
      minWidth: '100%',
      minHeight: '100%',
    },
    strokeColor: {
      strokeWidth: 1.3,
      stroke: theme.palette.primary.main,
      fill: 'none',
    },
    shadowing: {
      fill: lighten(theme.palette.primary.main, 0.9),
      filter: `drop-shadow(0px 0px 3px ${lighten(theme.palette.primary.main, 0.8)})`,
    },
  }),
  { name: 'p2p' }
);
