import React, { FC, useCallback, useEffect } from 'react';

import { Dialog } from 'common/components';
import { useNavigationBlocker } from 'common/hooks/useNavigationBlocker';

export type UnsavedChangesModalOwnProps = {
  onConfirm?(): void;
};

type UnsavedChangesModalStateProps = {
  message: string;
  isUnsavedChanges: boolean;
};

type UnsavedChangesModalProps = UnsavedChangesModalOwnProps & UnsavedChangesModalStateProps;

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({ isUnsavedChanges, onConfirm, message }) => {
  const { showPrompt, handleConfirm, handleCancel } = useNavigationBlocker({ when: isUnsavedChanges });

  useEffect(() => {
    const onUnload = (e: BeforeUnloadEvent) => {
      if (isUnsavedChanges) {
        e.returnValue = message;
      }
    };
    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [isUnsavedChanges, message]);

  const confirmHandler = useCallback(() => {
    handleConfirm();
    onConfirm?.();
  }, [handleConfirm, onConfirm]);

  return (
    <Dialog
      title="Exit"
      isOpen={showPrompt}
      showCloseButton
      showWarningIcon
      severity="danger"
      onClose={handleCancel}
      onConfirm={confirmHandler}
      confirmLabel="Yes, leave"
      cancelLabel="No, cancel"
      maxWidth="xs"
      description={message}
    />
  );
};
