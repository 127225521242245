import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'common/components/ReactHookForm';
import { useBreakpoints } from 'common/hooks';
import { MediaDevice } from 'domain/event';
import { VolumeUpIcon } from 'icons';

import { useDevicesSelect } from '../../hooks';
import { DevicesSection } from '../DevicesSection';
import { AudioOutputTest } from './components';

export const SelectSpeaker: FC<{
  devices: MediaDevice[];
  isAccessGranted: boolean;
  fieldName?: string;
  onChange?(): void;
}> = ({ fieldName = 'speakerId', devices, isAccessGranted, onChange }) => {
  const { t } = useTranslation('devices', { keyPrefix: 'selectSpeaker' });
  const { isSmallScreen } = useBreakpoints();
  const { options, selectedDeviceId, label, isDisabled } = useDevicesSelect({
    name: fieldName,
    label: t('label'),
    devices,
    isAccessGranted,
  });

  return (
    <DevicesSection Icon={<VolumeUpIcon />} title={t('label')}>
      <Select
        startAdornment={isSmallScreen && <VolumeUpIcon />}
        size="small"
        label={label}
        disabled={isDisabled}
        name={fieldName}
        options={options}
        data-testid="MediaSettings-Speakers"
        shouldShowError={false}
        onChange={onChange}
      />
      <AudioOutputTest deviceId={selectedDeviceId as string} disabled={!isAccessGranted} />
    </DevicesSection>
  );
};
