import React, { ComponentType, FC, ReactElement, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useTimeout } from 'common/hooks';
import { isError } from 'lodash';
import ErrorPage from 'pages/ErrorPage';
import { useKeycloakContext } from 'store/context/KeycloakContext';

const AUTH_CONNECTION_TIMEOUT = 5_000;

export const withUserLoggedIn =
  (WrappedComponent: ComponentType): FC =>
  (): ReactElement | null => {
    const keycloak = useKeycloakContext();
    const [authenticated, setAuthenticated] = useState(keycloak.authenticated);
    const [authenticationError, setAuthenticationError] = useState<string>();

    useTimeout(() => {
      if (!authenticated) {
        setAuthenticationError('Could not connect to auth service');
      }
    }, AUTH_CONNECTION_TIMEOUT);

    useEffect(() => {
      if (keycloak.authenticated) {
        setAuthenticated(true);
        return;
      }

      // Keycloak is already initialized
      if (keycloak.realm && keycloak.clientId) {
        keycloak.login({ redirectUri: window.location.href }).then(() => {
          setAuthenticated(true);
        });
        return;
      }

      keycloak
        .init({ onLoad: 'login-required', redirectUri: window.location.href, checkLoginIframe: false })
        .then((isAuthenticated) => {
          setAuthenticationError(undefined);
          setAuthenticated(isAuthenticated);
        })
        .catch((error: unknown) => {
          console.error(error);

          if (isError(error)) {
            setAuthenticationError(error.message);
          }
        });
    }, [keycloak]);

    if (authenticationError) return <ErrorPage message={authenticationError} />;

    return authenticated ? <WrappedComponent /> : <CircularProgress style={{ margin: 'auto' }} size={50} />;
  };
