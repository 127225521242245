import React, { FC, useCallback } from 'react';

import { type Image, useImageCompression } from 'common/hooks/useImageCompression/useImageCompression';

import { UploadDropzone } from '../UploadDropzone';

export type ImageDropzoneProps = {
  required?: boolean;
  onImageSelect?: (file: File) => void;
  onImageLoaded: (image: Image) => void;
  onImageCompressionError?: (error: unknown) => void;
  onProgressChange?: (progress: number) => void;
};

export const ImageDropzone: FC<ImageDropzoneProps> = ({
  required,
  onImageCompressionError,
  onImageLoaded,
  onImageSelect,
  onProgressChange: onCompressionProgress,
}) => {
  const handleError = useCallback(
    (error: unknown) => {
      onImageCompressionError?.(error);
    },
    [onImageCompressionError]
  );

  const { compressImage } = useImageCompression({
    onImageLoaded,
    onError: handleError,
    onCompressionProgress,
  });

  const handleFileSelect = useCallback(
    (file: File) => {
      compressImage(file);
      onImageSelect?.(file);
    },
    [compressImage, onImageSelect]
  );

  return <UploadDropzone type="image" onFileSelect={handleFileSelect} required={required} />;
};
