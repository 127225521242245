import React, { FC } from 'react';

import { MicrophoneOffIcon, MicrophoneOnIcon } from 'icons';

import { ActionButton } from './ActionButton';
import type { ActionButtonProps } from './ActionButton/ActionButton';

type ToggleMicrophoneProps = Pick<ActionButtonProps, 'onClick' | 'isActive' | 'isDisabled' | 'isLoading'>;

export const ToggleMicrophone: FC<ToggleMicrophoneProps> = (props) => (
  <ActionButton tooltip={props.isActive ? 'Turn off microphone' : 'Turn on microphone'} {...props}>
    {props.isActive ? <MicrophoneOnIcon fontSize="medium" /> : <MicrophoneOffIcon fontSize="medium" />}
  </ActionButton>
);
