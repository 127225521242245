export const AVAILABLE_EMOJIS_MAP = {
  heartEyes: '\u{1F60D}', // smiling face with heart-eyes
  grinningFace: '\u{1F603}', // grinning face with big eyes
  tearsOfJoy: '\u{1F602}', // face with tears of joy
  explodingHead: '\u{1F92F}', // exploding head
  sunglasses: '\u{1F60E}', // smiling face with sunglasses
  heart: '❤️', // red heart
  thumbsUp: '\u{1F44D}', // thumbs up
  raisingHands: '\u{1F64C}', // raising hands
  foldedHands: '\u{1F64F}', // folded hands
  flexedBiceps: '\u{1F4AA}', // flexed biceps
};
