import { Dispatch } from '@reduxjs/toolkit';
import _throttle from 'lodash/throttle';

export const initMergedDataDispatch = <T, K = {}>(
  action: (data: T[], extras: { dispatch: Dispatch } & K) => void,
  { shouldAddDataToFront = false, isLeading = true, timeout = 1000 } = {}
) => {
  const state = {
    tmpData: [] as T[],
    extras: {} as { dispatch: Dispatch } & K,
  };

  const throttledDispatch = _throttle(
    () => {
      action([...state.tmpData], state.extras);
      state.tmpData.length = 0;
    },
    timeout,
    {
      leading: isLeading,
    }
  );

  return (data: T[], extras: { dispatch: Dispatch } & K) => {
    shouldAddDataToFront ? state.tmpData.unshift(...data) : state.tmpData.push(...data);
    state.extras = extras;
    throttledDispatch();
  };
};
