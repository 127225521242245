import { type Components, type Theme } from '@mui/material';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      '&.MuiTableRow-hover:hover': {
        backgroundColor: palette.custom.greyLight,
      },
    }),
  },
};
