import { logger } from 'common/services';

export const checkMediaAccess = async (selectedDevice?: {
  cameraId: string;
  microphoneId: string;
}): Promise<boolean> => {
  try {
    logger.addBreadcrumb('[checkMediaAccess] Checking media access', {
      data: { selectedDevice },
    });
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: selectedDevice ? { deviceId: selectedDevice.microphoneId } : true,
      video: selectedDevice ? { deviceId: selectedDevice.cameraId } : true,
    });
    disposeMediaStream(stream);
    return true;
  } catch (error: unknown) {
    logger.error(error);
    return false;
  }
};

export const disposeMediaStream = (stream: MediaStream) => {
  const tracks: MediaStreamTrack[] = stream.getTracks();
  for (const track of tracks) {
    track.stop();
    stream.removeTrack(track);
  }
};

export const checkAudioAccess = async (): Promise<boolean> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    disposeMediaStream(stream);
    return true;
  } catch (error: unknown) {
    logger.error(error);
    return false;
  }
};

export const checkVideoAccess = async (): Promise<boolean> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    disposeMediaStream(stream);
    return true;
  } catch (error: unknown) {
    logger.error(error);
    return false;
  }
};
