import { type Components, type Theme } from '@mui/material';

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme: { spacing, typography } }) => ({
      ...typography['natter-text-sm'],

      padding: spacing(0, 3, 2),
    }),
  },
};
