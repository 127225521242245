import React, { FC, HTMLInputTypeAttribute, KeyboardEventHandler, memo } from 'react';
import { useController } from 'react-hook-form';

import { type InputBaseProps, type OutlinedInputProps, TextField } from '@mui/material';

import { InputWrapper, type InputWrapperProps } from '../InputWrapper';

export type TextInputProps = InputWrapperProps & {
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  InputProps?: Partial<OutlinedInputProps>;
  inputProps?: InputBaseProps['inputProps'];
  noInputLabel?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  defaultValue?: string;
  autoFocus?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  onBlur?(): void;
  className?: string;
  onKeyDown?: KeyboardEventHandler<unknown>;
  'data-testid'?: string;
  title?: string;
  type?: HTMLInputTypeAttribute;
};

export const TextInput: FC<TextInputProps> = memo(
  ({
    name,
    placeholder,
    hint,
    label,
    multiline = false,
    rows,
    required,
    InputProps,
    inputProps,
    noInputLabel = false,
    disabled = false,
    autoWidth,
    fullWidth = true,
    defaultValue = '',
    autoFocus,
    variant,
    onBlur: onBlurEffect,
    className,
    onKeyDown,
    'data-testid': dataTestId,
    title,
    type,
    ...props
  }) => {
    const {
      field: { onChange, onBlur, ref, value },
      fieldState: { error },
    } = useController({
      name,
      defaultValue,
      rules: {
        required,
        validate: required ? (changedValue: string) => changedValue.trim() : undefined,
      },
    });

    return (
      <InputWrapper label={label} hint={hint} name={name} autoWidth={autoWidth || fullWidth} title={title} {...props}>
        <TextField
          name={name}
          value={value}
          onChange={onChange}
          inputRef={ref}
          onBlur={() => {
            onBlur();
            onBlurEffect?.();
          }}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          disabled={disabled}
          error={!!error}
          InputProps={InputProps}
          inputProps={{
            ...inputProps,
            'data-testid': name,
          }}
          multiline={multiline}
          label={noInputLabel ? '' : label || placeholder}
          placeholder={placeholder}
          rows={rows}
          variant={variant}
          className={className}
          title={title}
          type={type}
          data-testid={dataTestId}
        />
      </InputWrapper>
    );
  }
);
