import { isAxiosError } from 'axios';
import { ApiErrors, generateDynamicPath, getErrorMessagesFromResponse, isApiErrors } from 'common/utils';
import { EventStatus } from 'domain/event';
import over from 'lodash/over';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { Effect } from 'store';
import { communityActions, communityApi, selectHasNoCommunity, selectMyCommunitiesIds } from 'store/apis/community';
import { addAppErrorMessage, addAppSuccessMessage } from 'store/features/alerts';
import { selectRouteByPathname } from 'store/features/router';
import { handleQueryError } from 'store/utils';

import { eventApi } from './eventApi';
import { selectEventCommunityId } from './selectors';

export const deleteEventSuccessEffect: Effect = (_, { dispatch, getState }) => {
  const state = getState();
  const route = selectRouteByPathname(state);
  const isEventDetailsPage = route?.path === AppRoutes.EventDetails;

  if (!isEventDetailsPage) return;

  dispatch(push(AppRoutes.MyEvents));
};

export const startEventFailedEffect: Effect = ({ payload: error }, { dispatch }) => {
  handleQueryError(error, dispatch, 'eventApi.startMeeting', 'Unable to start meeting');
};

export const startEventSuccessEffect: Effect = ({ meta }, { dispatch }) => {
  const { id } = meta.arg.originalArgs;

  // https://natterco.atlassian.net/browse/N2-1532
  dispatch(
    eventApi.util.updateQueryData('getEventDetails', { id }, (data) => {
      data.status = EventStatus.Started;
    })
  );

  dispatch(push(generateDynamicPath(AppRoutes.JoinEvent, { id })));
};

export const getConnectionsFailedEffect: Effect = ({ payload: error }, { dispatch }) => {
  handleQueryError(error, dispatch, 'eventApi.getConnections', 'Unable to fetch user connections');
};

export const stopEventFailedEffect: Effect = ({ payload: error }, { dispatch }) => {
  if (isAxiosError(error)) {
    getErrorMessagesFromResponse(error.response).forEach((errorMessage) => {
      dispatch(
        addAppErrorMessage({
          metadata: 'MeetingService.stopMeeting',
          message: 'Unable to stop meeting',
          error: new Error(errorMessage),
        })
      );
    });
  }
};

const invalidateMyCommunities: Effect = (_, { dispatch, getState }) => {
  const state = getState();

  const hasNoCommunity = selectHasNoCommunity(state);
  if (hasNoCommunity) {
    dispatch(communityApi.util.invalidateTags(['MY_COMMUNITIES']));
  }
};

const updateEventDetails: Effect = ({ meta }, { dispatch }) => {
  const { id } = meta.arg.originalArgs;
  dispatch(
    eventApi.util.updateQueryData('getEventDetails', { id }, (data) => {
      data.isUserRegistered = true;
    })
  );
};

const updateMyCommunities: Effect = (_, { dispatch, getState }) => {
  const state = getState();

  const myCommunitiesIds = selectMyCommunitiesIds(state);
  const communityId = selectEventCommunityId(state);

  if (communityId !== undefined && !myCommunitiesIds?.includes(communityId)) {
    // user was not in event community before, but registering for event means he was added to the community
    // so we want to refresh community related data
    dispatch(communityActions.community.registeredToCommunity({ communityId }));
  }
};

const addRegisterSuccessMessage: Effect = (_, { dispatch, getState }) => {
  const state = getState();

  const myCommunitiesIds = selectMyCommunitiesIds(state);
  const communityId = selectEventCommunityId(state);

  if (communityId && myCommunitiesIds?.includes(communityId)) {
    dispatch(addAppSuccessMessage('You registered successfully to Event'));
  }
};

export const registerForEventSuccessEffect: Effect = ({ meta }, { dispatch, getState }) => {
  over(
    invalidateMyCommunities,
    updateEventDetails,
    updateMyCommunities,
    addRegisterSuccessMessage
  )({ meta }, { dispatch, getState });
};

export const registerForEventFailedEffect: Effect = ({ meta, payload: error }, { dispatch }) => {
  const { id } = meta.arg.originalArgs;

  dispatch(
    eventApi.util.updateQueryData('getEventDetails', { id }, (data) => {
      data.isUserRegistered = false;
    })
  );
  handleQueryError(error, dispatch, 'eventApi.registerForEvent', 'Unable to register to Event');
};

export const refreshEventsList: Effect = (_args, { dispatch }) => {
  dispatch(eventApi.util.invalidateTags(['ALL_EVENTS', 'UPCOMING_EVENT']));
};

export const downloadFailedEffect: Effect = ({ payload }, { dispatch }) => {
  if (isApiErrors(payload.data)) {
    (payload.data as ApiErrors).errors.forEach(({ message }) => {
      dispatch(addAppErrorMessage(message));
    });
  } else {
    dispatch(addAppErrorMessage('Failed to download data'));
  }
};
