import React from 'react';

import { type Components, type Theme } from '@mui/material';
import { CloseIcon } from 'icons';

import { grey } from '../palette';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    deleteIcon: (
      <CloseIcon
        aria-label="Clear"
        sx={{
          height: 16,
          width: 16,
          '&.MuiChip-deleteIcon': {
            color: grey[800],
          },
        }}
      />
    ),
  },
  styleOverrides: {
    root: {
      borderRadius: 3,
      height: 20,

      '&:active': {
        boxShadow: 'none',
      },
    },
    filled: ({ theme: { palette } }) => ({
      '&.MuiChip-colorDefault:not(:hover)': {
        backgroundColor: palette.grey[100],
        color: palette.grey[800],
      },
    }),
    sizeSmall: ({ theme: { typography } }) => ({
      ...typography['natter-text-sm'],
    }),
  },
};
