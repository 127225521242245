import type { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme: { breakpoints, spacing } }) => ({
      '&:not(.MuiContainer-disableGutters)': {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),

        [breakpoints.up('sm')]: {
          paddingLeft: spacing(4),
          paddingRight: spacing(4),
        },
      },
    }),
  },
};
