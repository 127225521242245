import type { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      minHeight: 62,
    },
    scroller: ({ theme: { palette } }) => ({
      borderBottom: palette.mode === 'light' ? 1 : 'none',
      borderColor: palette.custom.lightGrey,
    }),
    centered: {
      '.MuiTab-root': {
        padding: 0,
      },
    },
  },
};
