import { type Components, type Theme } from '@mui/material';

export const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  styleOverrides: {
    fallback: ({ theme: { palette } }) => ({
      color: palette.common.white,
      backgroundColor: palette.grey[400],
    }),
  },
};
