import { type Components, type Theme } from '@mui/material';

export const MuiButtonBase: Components<Theme>['MuiButtonBase'] = {
  defaultProps: {
    centerRipple: true,
    type: 'button',
  },
  styleOverrides: {
    root: {
      fontFamily: 'inherit',
      path: { fill: 'currentColor' },
    },
  },
};
