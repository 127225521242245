import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import { Box } from '@mui/material';
import { Wizard } from 'common/components';
import { Page } from 'common/components/_legacy/Page';
import { UnsavedChangesModal } from 'common/components/_legacy/UnsavedChangesModal';
import { Community, CommunityFormValues } from 'domain/Community';
import {
  useCreateCommunityMutation,
  useInviteGuestsToCommunityMutation,
  useUpdateMyCommunityMutation,
  useUploadLogoMutation,
} from 'store/apis/community';

import { CommunityBasics } from '../CommunityBasics';
import { CommunityTags } from '../CommunityTags';
import { useCommunityWizard } from './hooks';
import { basicCommunityWizardSchema } from './schema';

const WIZARD_STEPS = ['Basics', 'Community Tags'];
const stepsToFormValues: (keyof CommunityFormValues)[][] = [
  ['name', 'color', 'calendarPermissionsRequired', 'logo'],
  ['email', 'guests'],
  ['tagsCategories'],
];

interface CommunityWizardProps {
  title: string;
  community?: Community;
  onSuccess?(): void;
}

export const CommunityWizard: FC<CommunityWizardProps> = ({ title, community, onSuccess }) => {
  const isEditMode = !!community;
  const [updateMyCommunity, { isLoading: isUpdateMyCommunityLoading }] = useUpdateMyCommunityMutation();
  const [createMyCommunity, { isLoading: isCreateCommunityLoading }] = useCreateCommunityMutation();
  const [uploadCommunityLogo, { isLoading: isUploadCommunityLogoLoading }] = useUploadLogoMutation();
  const [inviteMembersToCommunity, { isLoading: isInviteMembersToCommunityLoading }] =
    useInviteGuestsToCommunityMutation();

  const { handleStepChange, handleSubmit, methods, optionalTagsCategory, saveChanges, step, isFormDirty } =
    useCommunityWizard({
      schema: basicCommunityWizardSchema,
      stepsToFormValues,
      community,
      onCreateCommunity: (value: CommunityFormValues) => createMyCommunity(value).unwrap(),
      onInviteMembersToCommunity: inviteMembersToCommunity,
      onUpdateCommunity: updateMyCommunity,
      onUploadCommunityLogo: uploadCommunityLogo,
      onSuccess,
    });

  const isSubmitting =
    methods.formState.isValidating ||
    isCreateCommunityLoading ||
    isUploadCommunityLogoLoading ||
    isInviteMembersToCommunityLoading ||
    isUpdateMyCommunityLoading;
  const isImageLoading = !!methods.watch('logo.isLoading');

  return (
    <Page title={title}>
      <Box mb={6}>
        <FormProvider {...methods}>
          <Box>
            <form onSubmit={handleSubmit}>
              <Wizard stepLabels={WIZARD_STEPS} onStepChange={handleStepChange} step={step}>
                <CommunityBasics isEditMode={isEditMode} isLoading={isSubmitting || isImageLoading} />
                <CommunityTags
                  isEditMode={isEditMode}
                  isSubmitting={isSubmitting}
                  isLastStep
                  optionalCategory={optionalTagsCategory}
                />
              </Wizard>
            </form>
          </Box>
        </FormProvider>
      </Box>
      <UnsavedChangesModal saveChanges={isEditMode ? saveChanges : undefined} isUnsavedChanges={isFormDirty} />
    </Page>
  );
};
