import { createApi } from '@reduxjs/toolkit/query/react';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import {
  MergeThemesPayload,
  RenameThemesPayload,
  UnmergeThemesPayload,
  UpdateThemesVisibilityPayload,
  ValidateReportPinPayload,
} from './types';

export const insightsReportApi = createApi({
  reducerPath: 'insightsReportApi',
  tagTypes: ['REPORTS', 'SURVEY_REPORTS', 'REPORT_DEMOGRAPHICS', 'REPORT_ANSWERS'],
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v3') }),
  endpoints: (builder) => ({
    mergeThemes: builder.mutation<void, MergeThemesPayload>({
      invalidatesTags: (_result, _error, { reportId }) => [{ type: 'REPORT_ANSWERS', id: reportId }],
      query: ({ reportId, themeIds, themeName }) => ({
        url: `/report/${reportId}/merge-themes`,
        method: 'PUT',
        data: { themeName, themeIds },
      }),
    }),
    unmergeThemes: builder.mutation<void, UnmergeThemesPayload>({
      invalidatesTags: (_result, _error, { reportId }) => [{ type: 'REPORT_ANSWERS', id: reportId }],
      query: ({ reportId, themeIds }) => ({
        url: `/report/${reportId}/unmerge-themes`,
        method: 'PUT',
        data: { themeIds },
      }),
    }),
    renameThemes: builder.mutation<void, RenameThemesPayload>({
      invalidatesTags: (_result, _error, { reportId }) => [{ type: 'REPORT_ANSWERS', id: reportId }],
      query: ({ reportId, themeIdToNewName }) => ({
        url: `/report/${reportId}/rename-themes`,
        method: 'PUT',
        data: { themeIdToNewName },
      }),
    }),
    updateThemesVisibility: builder.mutation<void, UpdateThemesVisibilityPayload>({
      invalidatesTags: (_result, _error, { reportId }) => [{ type: 'REPORT_ANSWERS', id: reportId }],
      query: ({ reportId, themeIdsToHideStatus }) => ({
        url: `/report/${reportId}/update-theme-visibility`,
        method: 'PUT',
        data: { themeIdsToHideStatus },
      }),
    }),
    exportReport: builder.mutation<void, { reportId: number }>({
      query: ({ reportId }) => ({
        url: `/report/${reportId}/export`,
        method: 'POST',
      }),
    }),
    validateReportPin: builder.mutation<string | undefined, ValidateReportPinPayload>({
      query: ({ reportId, pin }) => ({
        url: `/report/${reportId}/validate-pin`,
        method: 'POST',
        data: { pin },
      }),
      transformResponse: (response: boolean, error, meta) => (response ? meta.pin : undefined),
    }),
  }),
});

export const {
  useMergeThemesMutation,
  useUnmergeThemesMutation,
  useRenameThemesMutation,
  useUpdateThemesVisibilityMutation,
  useExportReportMutation,
  useValidateReportPinMutation,
} = insightsReportApi;
