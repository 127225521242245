import React, { FC, MouseEventHandler } from 'react';

import { ButtonBase, ClickAwayListener, Popper, PopperPlacementType, Typography } from '@mui/material';
import { Button } from 'common/components/Buttons';
import { motion } from 'framer-motion';
import { CloseIcon } from 'icons';

import { FilterBaseActions, FilterBaseContentContent, FilterBaseHeader, FilterBaseWrapper } from './FilterBase.styled';

type Props = {
  label: string;
  anchorEl: HTMLButtonElement | null;
  placement?: PopperPlacementType;
  onApply(): void;
  onReset(): void;
  onClose(): void;
};

export const FilterBasePopper: FC<Props> = ({ label, anchorEl, children, placement, onApply, onReset, onClose }) => {
  const isOpen = !!anchorEl;

  const handleReset: MouseEventHandler<unknown> = (event) => {
    event.stopPropagation();
    onReset();
  };

  const handleApplyButton = () => {
    onApply();
  };

  return (
    <Popper open={isOpen} anchorEl={anchorEl} placement={placement}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
        <ClickAwayListener onClickAway={onClose}>
          <FilterBaseWrapper>
            <FilterBaseHeader>
              <Typography variant="natter-text-sm" fontWeight={600}>
                {label}
              </Typography>
              <ButtonBase onClick={onClose} data-testid="FilterBasePopper-CloseButton">
                <CloseIcon fontSize="small" />
              </ButtonBase>
            </FilterBaseHeader>

            <FilterBaseContentContent>{children}</FilterBaseContentContent>

            <FilterBaseActions>
              <Button onClick={handleReset} variant="text">
                Reset
              </Button>
              <Button onClick={handleApplyButton}>Apply</Button>
            </FilterBaseActions>
          </FilterBaseWrapper>
        </ClickAwayListener>
      </motion.div>
    </Popper>
  );
};
