import React, { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { Explanation } from 'common/components';

export type DescriptionWithExplanationProps = {
  description: ReactNode;
  explanation?: string;
};

export const DescriptionWithExplanation: FC<DescriptionWithExplanationProps> = ({ description, explanation }) => (
  <Typography variant="natter-text-sm" color="text.secondary">
    {description} {explanation && <Explanation>{explanation}</Explanation>}
  </Typography>
);
