import { type Components, type Theme } from '@mui/material';

export const MuiDialogContentText: Components<Theme>['MuiDialogContentText'] = {
  styleOverrides: {
    root: ({ theme: { spacing, palette } }) => ({
      fontSize: 'inherit',
      lineHeight: 'inherit',

      color: palette.grey[600],

      padding: spacing(0, 0, 2),
    }),
  },
};
