import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { Box } from '@mui/material';
import { CommunityGuest } from 'domain/Community';

import { GuestItem } from '../GuestItem/GuestItem';
import { GuestsFooter } from '../GuestsFooter';

export type GuestListProps = {
  name: string;
};

export const GuestsList: FC<GuestListProps> = ({ name }) => {
  const guests: CommunityGuest[] = useWatch({ name });

  return (
    <>
      <Box>
        {guests.map((user, index) => (
          <GuestItem key={index} fieldName={name} user={user} index={index} />
        ))}
      </Box>
      <GuestsFooter fieldName={name} />
    </>
  );
};
