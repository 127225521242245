import type { Effect } from 'store';
import { communityActions, communityApi } from 'store/apis/community';
import { addAppConfirmationDialog } from 'store/features/alerts';
import { setActiveCommunityId } from 'store/features/community';

export const registerToCommunitySuccessEffect: Effect = ({ payload }, { dispatch }) => {
  const { communityId } = payload as { communityId: number };
  if (!communityId) return;

  dispatch(communityApi.util.invalidateTags(['MY_COMMUNITIES']));
  dispatch(setActiveCommunityId(communityId));
  dispatch(communityActions.communityAccount.memberSetupRequired());
  dispatch(
    addAppConfirmationDialog({
      title: 'Community joined',
      description: `Congratulations! You've now joined the Community. You can now proceed to complete your profile.`,
      confirmLabel: 'Complete your profile',
    })
  );
};
