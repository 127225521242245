import { createSelector } from '@reduxjs/toolkit';
import { ChatState } from 'domain/Chat';
import type { RootState } from 'store';
import { selectIsConnectedToRoom } from 'store/features/socket';

import { selectActiveEventId } from '../event';

export const selectMessages = (state: RootState) => state.chat.messages;
export const selectPendingMessages = (state: RootState) => state.chat.pendingMessages;
export const selectIsUnreadMessages = createSelector(selectMessages, (messages) => messages.some((m) => m.isNew));

const selectSelf = (state: RootState) => state;

const selectIsConnected = createSelector([selectActiveEventId, selectSelf], (eventId, state) =>
  selectIsConnectedToRoom(state, `/meeting/${eventId}`)
);

export const selectChatState = createSelector([selectIsConnected], (isConnected) =>
  isConnected ? ChatState.Ready : ChatState.Error
);
