import { createApi } from '@reduxjs/toolkit/query/react';
import { pickBy } from 'lodash';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import { mapAnswerStatisticsList, mapEventsReportDataToInsightsReportData } from '../eventsReport/mappers';
import { AnswerStatistics, InsightsReportData, TagCategoryDemographics } from '../insightsReport';
import { PublicEventsReportAnswersPayload, PublicEventsReportPayload } from './types';

export const publicEventsReportApi = createApi({
  reducerPath: 'publicEventsReportApi',
  tagTypes: ['REPORTS', 'REPORT_DEMOGRAPHICS', 'REPORT_ANSWERS'],
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v2') }),
  endpoints: (builder) => ({
    getPublicEventsReportById: builder.query<InsightsReportData, PublicEventsReportPayload>({
      providesTags: (result) => (result?.id ? [{ type: 'REPORTS', id: result.id }] : []),
      query: ({ reportId, pin }) => ({
        url: `/event/report/${reportId}/public`,
        headers: { pin },
      }),
      transformResponse: mapEventsReportDataToInsightsReportData,
    }),
    getPublicEventsReportKeyMetrics: builder.query<Record<string, number>, PublicEventsReportPayload>({
      providesTags: (_result, _error, args) => (args.reportId ? [{ type: 'REPORTS', id: args.reportId }] : []),
      query: ({ reportId, pin }) => ({
        url: `/event/report/${reportId}/public/event-metrics`,
        headers: { pin },
      }),
    }),
    getPublicEventsReportDemographics: builder.query<TagCategoryDemographics[], PublicEventsReportPayload>({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_DEMOGRAPHICS', id: reportId }] : [],
      query: ({ reportId, pin }) => ({
        url: `/event/report/${reportId}/public/event-demographics`,
        headers: { pin },
      }),
    }),
    getPublicEventsReportAnswers: builder.query<AnswerStatistics[], PublicEventsReportAnswersPayload>({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_ANSWERS', id: reportId }] : [],
      query: ({ reportId, communityTagIds, assignmentType, pin }) => {
        const filteredCommunityTagIds = pickBy(communityTagIds, (value) => value.length);
        const communityTagIdsParam = Object.keys(filteredCommunityTagIds).length
          ? JSON.stringify(filteredCommunityTagIds)
          : undefined;
        return {
          url: `/event/report/${reportId}/public/answers`,
          params: {
            eventQuestionAssignmentType: assignmentType,
            communityTagIds: communityTagIdsParam,
          },
          headers: { pin },
        };
      },
      transformResponse: mapAnswerStatisticsList,
    }),
  }),
});

export const {
  useGetPublicEventsReportByIdQuery,
  useGetPublicEventsReportDemographicsQuery,
  useGetPublicEventsReportKeyMetricsQuery,
  useGetPublicEventsReportAnswersQuery,
} = publicEventsReportApi;
