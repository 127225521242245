import React, { ComponentType, FC, ReactElement } from 'react';

import { CircularProgress } from '@mui/material';
import ErrorPage from 'pages/ErrorPage';
import { useGetAccountQuery } from 'store/apis/user';

export const withUserAccount =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { isLoading, data, isError, error } = useGetAccountQuery();

    // add type guard
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (isError) return <ErrorPage status={(error as any).status} message={(error as any).data} />;
    if (isLoading) return <CircularProgress style={{ margin: 'auto' }} size={50} />;

    return data ? <WrappedComponent {...props} /> : <></>;
  };
