import { alpha, styled } from '@mui/material';
import { darkBlue } from 'common/theme/palette';

export const TabsWrapper = styled('div')({
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 2,
    backgroundColor: alpha(darkBlue, 0.14),
    borderRadius: 1,
  },
});
