/**
 * The util to omit forwarded props for `styled()` components. Please use with `shouldForwardProp` property.
 *
 * @param props - The list of props to be filtered.
 * @returns A function that filters out forwarded props from the list of props.
 *
 * @example
 * ```ts
 *import { styled } from '@mui/material';
 *
 *type Props = {
 *  centered?: boolean;
 *};
 *
 *export const Wrapper = styled('div', {
 *  shouldForwardProp: omitForwardedProps<Props>('centered'),
 *})<Props>(({ centered }) => ({
 *  justifyContent: centered ? 'center' : undefined,
 *}));
 * ```
 */
export const omitForwardedProps =
  <Props extends Record<string, unknown>>(...props: (keyof Props & string)[]) =>
  (prop: string): prop is keyof Props & string =>
    !props.includes(prop);
