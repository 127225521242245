import type { Dispatch } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import { isApiErrors, isApiResponse } from 'common/utils';
import { addAppAlertDialog, addAppErrorMessage } from 'store/features/alerts';

// TODO refactor args to single object
export function handleQueryError(
  error: unknown,
  dispatch: Dispatch,
  metadata: string,
  message: string,
  showAlertDialog = false
) {
  captureException(error, {
    tags: {
      metadata,
      message,
    },
  });

  if (showAlertDialog) {
    dispatch(
      addAppAlertDialog({
        title: message,
        description: '',
        severity: 'danger',
      })
    );
  } else if (isApiResponse(error) && isApiErrors(error.data)) {
    error.data.errors.forEach(({ message: apiErrorMessage }) => {
      dispatch(addAppErrorMessage(apiErrorMessage));
    });
  } else {
    dispatch(addAppErrorMessage(message));
  }
}
