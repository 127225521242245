import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { contrast } from 'theme';

export const useChipStateStyles = makeStyles(
  (theme) => ({
    container: {
      padding: `1px ${theme.spacing(1)}`,
      lineHeight: '20px',
      height: 20,
      borderRadius: 4,
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    ongoing: {
      color: theme.palette.customGreen.main,
      background: contrast(theme.palette.customGreen.main),
    },
    upcoming: {
      color: theme.palette.primary.main,
      background: contrast(theme.palette.primary.main),
    },
    past: {
      background: theme.palette.custom.lightGrey,
      color: contrast(theme.palette.custom.lightGrey),
    },
    cancelled: {
      background: alpha(theme.palette.error.main, 0.1),
      color: theme.palette.error.main,
    },
  }),
  { name: 'chipState' }
);
