import { createSelector } from '@reduxjs/toolkit';
import { isEqual, prop } from 'lodash/fp';
import type { RootState } from 'store';

const selectInsightsReportsWizardState = (state: RootState) => state.insightsReportsWizard;

export const selectCurrentStep = createSelector(selectInsightsReportsWizardState, prop('step'));
export const selectSelectedReportSource = createSelector(
  selectInsightsReportsWizardState,
  prop('selectedReportSource')
);
export const selectSelectedSimilarReportSourcesIds = createSelector(
  selectInsightsReportsWizardState,
  prop('selectedSimilarReportSourcesIds')
);

export const selectIsSimilarReportSourcesStep = createSelector(selectCurrentStep, isEqual('similar-sources'));

export const selectIsAllSelected = createSelector(selectInsightsReportsWizardState, prop('isAllSelected'));

export const selectCanCreateReport = createSelector(
  selectIsSimilarReportSourcesStep,
  selectSelectedSimilarReportSourcesIds,
  (isSimilarReportSourcesStep, selectedSimilarReportSources) =>
    isSimilarReportSourcesStep && !!selectedSimilarReportSources?.length
);

export const selectSelectedInsightsReportType = createSelector(
  selectInsightsReportsWizardState,
  prop('selectedSurveyType')
);
