import React, { FC, useMemo, useState } from 'react';

import { Box, PopperPlacementType } from '@mui/material';
import { RadioGroup, RadioGroupOption } from 'common/components/Inputs';

import { FilterBase } from '../FilterBase';

type Props = {
  value: string | number | undefined;
  onChange(value?: string | number): void;
  label: string;
  options: RadioGroupOption[];
  fullWidth?: boolean;
  placement?: PopperPlacementType;
};

export const SingleSelectFilter: FC<Props> = ({ value, onChange, label, options, fullWidth = false, placement }) => {
  const buttonLabel = useMemo(
    () => options.find((option) => option.value.toString() === value)?.label ?? label,
    [options, value, label]
  );
  const [tmpValue, setTmpValue] = useState<string | number>();

  const resetValue = () => {
    onChange();
  };

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    setTmpValue(newValue);
  };

  const applyChanges = () => {
    onChange(tmpValue);
  };

  const handleOpen = () => {
    setTmpValue(value);
  };

  return (
    <>
      <FilterBase
        buttonLabel={buttonLabel}
        isValueSelected={!!value}
        label={label}
        onApply={applyChanges}
        onReset={resetValue}
        onOpen={handleOpen}
        fullWidth={fullWidth}
        placement={placement}
      >
        <Box px={3}>
          <RadioGroup onChange={handleChange} options={options} value={tmpValue} />
        </Box>
      </FilterBase>
    </>
  );
};
