import { makeStyles } from '@mui/styles';

export const useStyledSwitchStyles = makeStyles(
  (theme) => ({
    root: {
      width: 39,
      height: 21,
      padding: 0,
      margin: 5,
      marginRight: theme.spacing(2),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(18px)',
        '& + $track': {
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
      },
      '&:not(.Mui-disabled)': {
        '&$checked': {
          color: theme.palette.common.white,
          '& + $track': {
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
          },
        },
        '&$focusVisible $thumb': {
          color: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 19,
      height: 19,
      boxShadow: 'none',
      color: theme.palette.common.white,
    },
    track: {
      boxSizing: 'border-box',
      borderRadius: 26 / 2,
      border: `1px solid`,
      borderColor: theme.palette.mode === 'dark' ? theme.palette.custom.darkestGrey : theme.palette.grey[200],
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.custom.darkestGrey : theme.palette.grey[200],
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  }),
  { name: 'styledSwitch' }
);
