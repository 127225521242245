import { type Components, type Theme } from '@mui/material';

export const MuiTableBody: Components<Theme>['MuiTableBody'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderTop: '1px solid',
      borderColor: theme.palette.grey[300],
    }),
  },
};
