import { type Components, type Theme } from '@mui/material';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    inputRoot: ({ theme: { spacing } }) => ({
      padding: spacing(0.75, 5, 0.75, 2),
    }),
    endAdornment: {
      '.MuiIconButton-root:disabled': {
        backgroundColor: 'transparent',
      },
    },
    tag: {
      borderRadius: '100px',
      padding: 2,
      '& .MuiAvatar-root': {
        width: 16,
        height: 16,
        marginLeft: 0,
      },
    },
  },
};
