import { createSelector } from '@reduxjs/toolkit';
import { filter, first, last } from 'lodash/fp';
import type { RootState } from 'store';

import { alertsAdapter } from './alertsSlice';
import { AlertContext, isAlertMatchingContext, isBackendErrorAlert, isDialogAlert, isSnackBarAlert } from './types';

const alertsSelectors = alertsAdapter.getSelectors<RootState>((state) => state.alerts);

export const selectAlerts = (state: RootState) => alertsSelectors.selectAll(state);

export const selectAlertById = (id: string) => (state: RootState) => alertsSelectors.selectById(state, id);

export const selectBackendErrorAlerts = createSelector(selectAlerts, filter(isBackendErrorAlert));

const selectAlertContext = (_state: RootState, context: AlertContext) => context;

export const selectSnackBarAlertsByContext = createSelector(selectAlertContext, selectAlerts, (context, alerts) =>
  alerts.filter(isSnackBarAlert).filter(isAlertMatchingContext(context))
);

const selectDialogAlerts = createSelector(selectAlerts, filter(isDialogAlert));

export const selectLastDialogAlertByContext = createSelector(
  selectAlertContext,
  selectDialogAlerts,
  (context, alerts) => first(alerts.filter(isAlertMatchingContext(context)))
);

export const selectLastBackendErrorAlert = createSelector(selectBackendErrorAlerts, last);
