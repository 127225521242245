import { alpha } from '@mui/material';

import { darkBlue } from './palette';

/**
 * Overriding Mui shadows is very cumbersome in it's current form so it's much easier to just use this instead.
 * https://github.com/mui/material-ui/issues/28820
 */
export const shadows = {
  extraLight: `0px 1.85px 6.85px 0px ${alpha('#000', 0.04)}`,
  light: `0px 1.85px 6.25px 0px ${alpha('#000', 0.19)}, 0px 0.5px 1.75px 0px ${alpha('#000', 0.02)}`,
  medium: `0px 8px 12px 0px ${alpha(darkBlue, 0.15)}, 0px 0px 1px 0px ${alpha(darkBlue, 0.31)}`,
};
