import { ERROR_MESSAGES } from 'common/constants';
import { CommunityGuest } from 'domain/Community';
import { UserPrivilege, UserPrivilegeName, UserRoleName } from 'domain/UserProfile';
import { AnyObject, ObjectSchema, array, mixed, object, string } from 'yup';

export const userRoleValidation = mixed<UserRoleName>().oneOf(Object.values(UserRoleName)).required();

export const userActionsValidation = array<AnyObject, UserPrivilege>().required();

export const guestValidationSchema: ObjectSchema<Pick<CommunityGuest, 'email'>> = object({
  email: string().email(ERROR_MESSAGES.invalidEmail).required(),
});

export const guestWithRoleValidationSchema: ObjectSchema<CommunityGuest> = object({
  email: string().email(ERROR_MESSAGES.invalidEmail).required(),
  communityRole: userRoleValidation,
  privileges: array(mixed<UserPrivilegeName>().oneOf(Object.values(UserPrivilegeName)).required()).required(),
});
