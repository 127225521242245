import { type XYCoord } from 'react-dnd';

export function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null, width: string) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
    width,
    cursor: 'grabbing',
  };
}
