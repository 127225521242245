type CalculateShrinkArgs = {
  shrinkLabel?: boolean;
  defaultValue?: unknown;
  value?: unknown;
};

export const calculateShrink = ({ shrinkLabel, defaultValue, value }: CalculateShrinkArgs = {}) => {
  if (value) {
    return true;
  }

  if (shrinkLabel !== undefined) {
    return shrinkLabel;
  }

  return defaultValue !== undefined ? true : undefined;
};
