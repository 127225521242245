import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { assert, generateDynamicPath } from 'common/utils';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import { ReportSource } from 'store/apis/insightsReport';
import { AsyncSurveyListItem, surveyApi } from 'store/apis/survey';
import { addAppAlertDialog } from 'store/features/alerts';

export const surveysListActions = {
  listItemActions: {
    publishButton: {
      clicked: createAsyncThunk<void, number>('[Surveys List] publish button clicked', (id, { dispatch }) => {
        assert(id);
        dispatch(surveyApi.endpoints.publishSurvey.initiate({ id }));
      }),
    },
    duplicateButton: {
      clicked: createAsyncThunk<void, number>('[Surveys List] duplicate button clicked', (id) => {
        const duplicateUrl = generateDynamicPath(AppRoutes.SurveyWizardCreate, {}, { surveyId: id });
        window.open(window.location.origin + duplicateUrl, '_blank');
      }),
    },
    createReportButton: {
      clicked: createAsyncThunk<void, AsyncSurveyListItem>(
        '[Surveys List] create report button clicked',
        (survey, { dispatch }) => {
          dispatch(
            push(generateDynamicPath(AppRoutes.InsightsReportsWizardSurveys), {
              reportSource: {
                guestCount: survey.invitedUsers,
                id: survey.id,
                name: survey.name,
              } as ReportSource,
            })
          );
        }
      ),
    },
    unpublishButton: {
      clicked: createAsyncThunk<void, number>('[Surveys List] unpublish button clicked', (id, { dispatch }) => {
        dispatch(
          addAppAlertDialog({
            title: 'Unpublish Survey',
            description: 'By unpublishing this Survey, participants invited will not be able to submit their answers.',
            showCancelButton: true,
            cancelLabel: 'No, cancel',
            confirmLabel: 'Yes, unpublish',
            severity: 'danger',
            actionToDispatchOnConfirm: surveysListActions.listItemActions.unpublishButton.confirmed(id),
          })
        );
      }),
      confirmed: createAction<number>('[Surveys List] unpublish button confirmed'),
    },
    deleteButton: {
      clicked: createAsyncThunk<void, number>('[Surveys List] delete button clicked', (id, { dispatch }) => {
        dispatch(
          addAppAlertDialog({
            title: 'Delete Survey',
            description: 'This Survey and its data will be removed. Are you sure you want to proceed?',
            showCancelButton: true,
            cancelLabel: 'No, cancel',
            confirmLabel: 'Yes, delete',
            severity: 'danger',
            actionToDispatchOnConfirm: surveysListActions.listItemActions.deleteButton.confirmed(id),
          })
        );
      }),
      confirmed: createAction<number>('[Surveys List] delete button confirmed'),
    },
  },
};
