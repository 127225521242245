import React from 'react';
import { useController } from 'react-hook-form';

import {
  AutocompleteOptionItem,
  Autocomplete as NatterAutocomplete,
  type AutocompleteProps as NatterAutocompleteProps,
} from 'common/components/Inputs';
import { omit } from 'lodash';

export type AutocompleteProps<T = string> = NatterAutocompleteProps<T> & {
  name: string;
  onChange?: (selected: AutocompleteOptionItem<T> | null) => void;
};

export const Autocomplete = <T,>({ name, onChange, ...autocompleteProps }: AutocompleteProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });
  const { ref, ...fieldProps } = field;

  const handleChange = (selected: AutocompleteOptionItem<T> | null) => {
    // this is required because Icon is a non-serializable value
    field.onChange(selected ? omit(selected, ['Icon']) : selected);
    field.onBlur();
    onChange?.(selected);
  };

  return (
    <NatterAutocomplete
      {...autocompleteProps}
      {...fieldProps}
      value={field.value?.value ? field.value : null}
      innerRef={ref}
      error={!!error}
      hint={error?.message}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
    />
  );
};
