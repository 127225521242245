import React, { FC, useCallback } from 'react';
import { useController, useWatch } from 'react-hook-form';

import { ToggleCameraButton, ToggleMicrophone } from 'common/components';
import { DevicesDefaultFormValues } from 'devices';

type Props = {
  showMicrophoneControl: boolean;
  onChange?(): void;
};

export const CameraPreviewActions: FC<Props> = ({ showMicrophoneControl, onChange }) => {
  const cameraId = useWatch<DevicesDefaultFormValues, 'cameraId'>({ name: 'cameraId' });
  const microphoneId = useWatch<DevicesDefaultFormValues, 'microphoneId'>({ name: 'microphoneId' });

  const {
    field: { value: isMicrophoneEnabled, onChange: onMicrophoneEnabledChange },
  } = useController<DevicesDefaultFormValues>({
    name: 'isMicrophoneEnabled',
  });
  const {
    field: { value: isCameraEnabled, onChange: onCameraEnabledChange },
  } = useController<DevicesDefaultFormValues>({
    name: 'isCameraEnabled',
  });

  const handleToggleMicrophoneClick = useCallback(() => {
    onMicrophoneEnabledChange({ target: { value: !isMicrophoneEnabled } });
    onChange?.();
  }, [onMicrophoneEnabledChange, isMicrophoneEnabled, onChange]);

  const handleToggleCameraClick = useCallback(() => {
    onCameraEnabledChange({ target: { value: !isCameraEnabled } });
    onChange?.();
  }, [onCameraEnabledChange, isCameraEnabled, onChange]);

  return (
    <>
      {showMicrophoneControl && (
        <ToggleMicrophone
          isActive={!!isMicrophoneEnabled}
          isDisabled={!microphoneId}
          onClick={handleToggleMicrophoneClick}
        />
      )}
      <ToggleCameraButton isActive={!!isCameraEnabled} isDisabled={!cameraId} onClick={handleToggleCameraClick} />
    </>
  );
};
