import React, { ComponentProps, FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { StatusIndicator } from '../../../StatusIndicator';
import { Wrapper } from './TaskStep.styled';
import { TaskStepperStatusColors } from './constants';
import type { Step } from './types';

type Props = Step &
  Pick<ComponentProps<typeof Wrapper>, 'onClick'> & {
    isActive: boolean;
  };

export const TaskStep: FC<Props> = ({ title, description, isActive, status, onClick }) => (
  <Wrapper isActive={isActive} onClick={onClick} data-testid="TaskStep">
    <Stack gap={1} alignItems="start">
      <Typography variant="natter-text-sm" fontWeight={600} textAlign="start" data-testid="TaskStep-title">
        {title}
      </Typography>
      {isActive && description && (
        <Typography
          variant="natter-text-sm"
          color="text.secondary"
          textAlign="start"
          data-testid="TaskStep-description"
        >
          {description}
        </Typography>
      )}
    </Stack>

    <StatusIndicator value={status} colorMapping={TaskStepperStatusColors} variant={isActive ? 'contained' : 'light'}>
      {status.replace(/_/g, ' ')}
    </StatusIndicator>
  </Wrapper>
);
