import React, { FC } from 'react';

import { Toolbar, useTheme } from '@mui/material';
import { useBreakpoints } from 'common/hooks';
import { useAppSelector } from 'store';
import { selectIsDrawerDisplayed, selectMobileMenuOpen } from 'store/features/app';

import { DesktopDrawer, MobileDrawer } from './Sidebar.styled';

export { DRAWER_WIDTH as drawerWidth } from './Sidebar.styled';

export const Sidebar: FC = ({ children }) => {
  const displayDrawer = useAppSelector(selectIsDrawerDisplayed);
  const mobileMenuOpen = useAppSelector(selectMobileMenuOpen);
  const { isMobile } = useBreakpoints();
  const { transitions } = useTheme();

  return isMobile ? (
    <MobileDrawer
      open={mobileMenuOpen && displayDrawer}
      variant="temporary"
      anchor="top"
      ModalProps={{ keepMounted: true }}
    >
      <Toolbar />
      {children}
    </MobileDrawer>
  ) : (
    <DesktopDrawer
      open={displayDrawer}
      variant="persistent"
      transitionDuration={{
        appear: transitions.duration.complex,
        enter: transitions.duration.complex,
        exit: transitions.duration.complex,
      }}
    >
      <Toolbar />
      {children}
    </DesktopDrawer>
  );
};
