import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';

export const useFieldsValidation = <T extends FieldValues>(
  fields: FieldPath<T> | FieldPath<T>[],
  onSuccess: () => void
) => {
  const {
    trigger,
    formState: { errors },
  } = useFormContext<T>();

  const triggerFieldValidation = async () => {
    const isValid = await trigger(fields, { shouldFocus: true });

    if (isValid) {
      onSuccess();
    }
  };

  return {
    triggerFieldValidation,
    errors,
  };
};
