import React, { FC } from 'react';

type Props = { variant: 'dark' | 'light' };

export const Logo: FC<Props> = ({ variant = 'dark' }) => {
  const fill = variant === 'dark' ? '#080808' : '#f7f7f7';

  return (
    <svg width="80" height="16" viewBox="0 0 80 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="Logo">
      <path
        d="M74.2665 2.51879C73.6865 2.5029 73.117 2.63533 72.6085 2.90548C72.2139 3.12001 71.8431 3.37692 71.5014 3.67092C71.1809 3.95431 70.9108 4.28803 70.7016 4.66148H70.6804V2.77305H67.9047V15.7484H70.6804V8.67141C70.6724 8.17348 70.7598 7.6835 70.9399 7.21735C71.0988 6.80682 71.3504 6.42543 71.6656 6.12084C71.9835 5.81626 72.3543 5.58053 72.7648 5.42162C73.2044 5.24946 73.6653 5.16471 74.1394 5.17001H75.8424V2.51879H74.2665Z"
        fill={fill}
      />
      <path
        d="M10.8651 2.51879C10.2215 2.50025 9.58848 2.60619 8.98725 2.83927C8.54229 3.02202 8.11852 3.25509 7.72654 3.54114C7.36368 3.81924 7.05115 4.15295 6.79954 4.53435H6.7757V2.7704H4V15.7457H6.7757V8.75352C6.76775 8.23175 6.85516 7.71793 7.03526 7.22794C7.19682 6.79358 7.44314 6.40159 7.76362 6.06787C8.07879 5.74474 8.44695 5.49048 8.86277 5.31568C9.29979 5.13028 9.75269 5.03758 10.2347 5.04287C11.2173 5.04287 11.9642 5.29979 12.4542 5.80301C12.9442 6.30889 13.1905 7.13259 13.1905 8.25029V15.7457H15.9689V7.99868C15.9689 6.21089 15.5133 4.83364 14.6155 3.90929C13.7176 2.98494 12.4569 2.51614 10.8704 2.51614L10.8651 2.51879Z"
        fill={fill}
      />
      <path
        d="M28.004 4.40722H27.9748C27.6729 4.06025 27.3286 3.75037 26.9472 3.48552C26.5499 3.21801 26.1208 2.99553 25.6732 2.82337C25.1091 2.60884 24.5158 2.50555 23.9119 2.51614C23.0856 2.51349 22.2698 2.66976 21.5044 3.00083C20.7681 3.3213 20.1165 3.77951 19.5683 4.36484C18.9962 4.98196 18.5592 5.69707 18.2705 6.48899C17.9447 7.37891 17.7884 8.31121 17.799 9.25675C17.7858 10.2049 17.9447 11.1346 18.2705 12.0245C18.5592 12.8164 18.9962 13.5315 19.5683 14.1487C20.1165 14.734 20.7681 15.1922 21.5044 15.5127C22.2698 15.8411 23.0856 16.0079 23.9093 15.9974C23.9384 15.9974 23.9676 15.9974 23.9967 15.9974C24.5741 15.9974 25.1356 15.8888 25.6732 15.6769C26.1235 15.4968 26.5526 15.2611 26.9472 14.975C27.3312 14.6916 27.6756 14.3552 27.9722 13.9818H27.9987V15.7457H30.7744V2.7704H27.996V4.40722H28.004ZM27.0028 12.3979C26.338 13.1131 25.3978 13.5051 24.4205 13.4733C23.3028 13.4733 22.3943 13.1104 21.7242 12.3979C21.0515 11.6828 20.7098 10.6261 20.7098 9.25675C20.7098 7.88744 21.0515 6.83066 21.7242 6.11554C22.397 5.40308 23.3028 5.04022 24.4231 5.04022C24.4602 5.04022 24.4999 5.04022 24.537 5.04022C25.4719 5.04022 26.3672 5.42956 27.0055 6.11554C27.6703 6.83066 28.0066 7.88744 28.0066 9.25675C28.0066 10.6261 27.6703 11.6828 27.0055 12.3979H27.0028Z"
        fill={fill}
      />
      <path
        d="M37.2184 5.29449H41.1224V2.7704H37.2184V0H34.4427V2.7704H32.933V5.29449H34.4427V15.7457H41.2495V13.2217H37.2184V5.29449Z"
        fill={fill}
      />
      <path
        d="M63.847 4.46549C63.2379 3.85896 62.5281 3.37957 61.7361 3.04056C60.9151 2.6883 60.0437 2.51084 59.1538 2.51879C58.2586 2.51084 57.3925 2.6883 56.5714 3.04056C55.7795 3.37692 55.0697 3.85631 54.4605 4.46549C53.8487 5.07995 53.3746 5.80036 53.0488 6.60288C52.7045 7.45042 52.5324 8.34564 52.5403 9.25939C52.5324 10.1758 52.7045 11.0684 53.0488 11.9159C53.3746 12.7184 53.8487 13.4388 54.4605 14.0533C55.0697 14.6598 55.7795 15.1392 56.5714 15.4782C57.3792 15.8252 58.2347 16 59.1114 16C59.1458 16 59.1803 16 59.2173 16C59.7735 16 60.335 15.9391 60.8806 15.8199C61.3706 15.714 61.85 15.555 62.3029 15.3485C62.7029 15.1683 63.079 14.9406 63.4259 14.6731C63.7279 14.4373 64.0166 14.1804 64.2894 13.9103C64.8694 13.2958 65.3249 12.5833 65.6375 11.7994L65.6746 11.7093H62.7717L62.7532 11.7385C62.5333 12.0722 62.2605 12.3635 61.9374 12.6045C61.6011 12.8667 61.225 13.0707 60.8224 13.2111C60.2874 13.3965 59.7285 13.4865 59.1564 13.4733H59.1405C58.6929 13.4733 58.2533 13.3991 57.8321 13.2508C57.411 13.1025 57.0243 12.8826 56.6827 12.5966C56.3304 12.2999 56.0391 11.9477 55.8166 11.5451C55.5862 11.1266 55.4246 10.6764 55.3345 10.2076H65.7725V9.25939C65.7805 8.34564 65.6083 7.45042 65.264 6.60288C64.9383 5.80036 64.4642 5.07995 63.8523 4.46549H63.847ZM55.459 7.93246C55.5544 7.51928 55.7133 7.12465 55.9358 6.76179C56.1609 6.39629 56.4469 6.08111 56.7886 5.82155C57.125 5.56729 57.5011 5.3713 57.9037 5.23887C58.3062 5.10644 58.7247 5.04022 59.1485 5.04022H59.1564C59.6649 5.02963 60.1655 5.11703 60.6423 5.29978C61.0502 5.4587 61.4262 5.68383 61.7573 5.96722C62.0646 6.23208 62.3135 6.55256 62.4963 6.91541C62.6605 7.23589 62.7797 7.5749 62.8512 7.92716H55.459V7.93246Z"
        fill={fill}
      />
      <path
        d="M47.1664 5.29449H51.0704V2.7704H47.1664V0H44.3907V2.7704H42.8784V5.29449H44.3907V15.7457H51.1975V13.2217H47.1664V5.29449Z"
        fill={fill}
      />
    </svg>
  );
};
