import { RoomWSActions, RoomWSMessages } from 'common/constants';
import { PaginatedApiPayloadV2 } from 'domain/Common';
import { EventRoomApiPayload } from 'domain/event';
import { createMeetingWSAsyncAction, createMeetingWSMessage } from 'store/utils';

import { GetRoomsParams } from './types';

export const meetingRoomSearchSubscribed_WS = createMeetingWSMessage<{
  rooms: PaginatedApiPayloadV2<EventRoomApiPayload>;
  searchPhrase: string;
}>(RoomWSMessages.RoomSearchSubscribed);

export const searchRooms = createMeetingWSAsyncAction<GetRoomsParams>(RoomWSActions.SearchRoom);
