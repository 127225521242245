import React, { FC } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';
import { TagOutlinedIcon } from 'icons';

import { ButtonIcon } from '../_legacy/ButtonIcon';
import { Tags } from './Tags';
import { useTagsStyles } from './Tags.styles';

export const TagsTooltip: FC<{
  tags: string[] | undefined;
  disabled?: boolean;
  placement?: TooltipProps['placement'];
}> = ({ disabled, tags, placement = 'bottom-end', children }) => {
  const classes = useTagsStyles();

  if (!tags) {
    return <>{children}</>;
  }

  return (
    <Tooltip classes={{ tooltip: classes.tags }} title={<Tags tags={tags} />} placement={placement}>
      <span>
        {children || (
          <ButtonIcon
            icon={<TagOutlinedIcon />}
            disabled={disabled}
            aria-label="Show tags"
            className={classes.tagsButton}
          />
        )}
      </span>
    </Tooltip>
  );
};
