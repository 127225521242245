import { type Components, type Theme } from '@mui/material';

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: ({ theme: { spacing, typography } }) => ({
      ...typography['natter-text-xl'],
      fontWeight: 500,

      flexGrow: 1,
      padding: spacing(3, 3, 2),

      display: 'flex',
      alignItems: 'start',
      gap: spacing(1.25),
    }),
  },
};
