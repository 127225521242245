import { Box, styled } from '@mui/material';

export const PageActionsContainer = styled(Box, { name: 'PageActionsContainer' })(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    // TODO Still needs to figure out to make this container sticky to bottom of a screen
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
    bottom: 0,
    padding: spacing(4, 0),
    backgroundColor: palette.background.default,
    width: '100%',

    [breakpoints.down('md')]: {
      padding: spacing(3, 0),
    },
  })
);
