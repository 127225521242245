import { Dispatch, ListenerEffect } from '@reduxjs/toolkit';
import { ChatMessageBasic, ChatMessageStatus } from 'domain/Chat';
import type { RootState } from 'store';
import { selectUserAccountId } from 'store/apis/user';

import {
  addMessages,
  addPendingMessage,
  addRejectedMessages,
  excludeFromMessages,
  excludeFromPendingMessages,
} from './chatSlice';

type Effect<T> = ListenerEffect<{ type: string; payload: T }, RootState, Dispatch>;

export const newMessagesEffect: Effect<ChatMessageBasic[]> = ({ payload }, { dispatch }) => {
  dispatch(addMessages({ messages: payload, status: ChatMessageStatus.Approved }));
};

export const newPendingMessageEffect: Effect<ChatMessageBasic> = ({ payload }, { dispatch, getState }) => {
  const state = getState();
  const accountId = selectUserAccountId(state);

  if (payload.sender.id === accountId) {
    dispatch(addMessages({ messages: [payload], status: ChatMessageStatus.Pending }));
  } else {
    dispatch(addPendingMessage({ message: payload }));
  }
};

export const newApprovedMessagesEffect: Effect<ChatMessageBasic[]> = ({ payload }, { dispatch, getState }) => {
  const state = getState();
  const accountId = selectUserAccountId(state);

  const ownMessages = payload.filter((m) => m.sender.id === accountId);
  if (ownMessages.length) {
    dispatch(excludeFromMessages({ messages: ownMessages, status: ChatMessageStatus.Pending }));
  }
  dispatch(excludeFromPendingMessages({ messages: payload }));
};

export const newRejectedMessagesEffect: Effect<ChatMessageBasic[]> = ({ payload }, { dispatch, getState }) => {
  const state = getState();
  const accountId = selectUserAccountId(state);

  dispatch(excludeFromPendingMessages({ messages: payload }));
  dispatch(addRejectedMessages({ messages: payload, accountId }));
};
