import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoMediaAccessModal } from 'devices/components';
import { checkVideoAccess } from 'devices/utils';
import { useAppDispatch } from 'store';

export type WithVideoAccessInfo = { videoAccess: boolean | undefined };

export const withVideoAccess =
  <P extends { onCancel: () => void }>(
    WrappedComponent: ComponentType<P & WithVideoAccessInfo>
  ): FC<Omit<P, 'videoAccess'>> =>
  (props) => {
    const { t } = useTranslation('devices');
    const dispatch = useAppDispatch();
    const [hasVideoAccess, setHasVideoAccess] = useState<boolean>();
    const [isCheckingMediaAccess, setIsCheckingMediaAccess] = useState(hasVideoAccess === undefined);

    useEffect(() => {
      if (hasVideoAccess !== undefined) return;

      (async () => {
        setIsCheckingMediaAccess(true);
        const hasAccess = await checkVideoAccess();
        setHasVideoAccess(hasAccess);
        setIsCheckingMediaAccess(false);
      })();
    }, [hasVideoAccess, dispatch]);

    return (
      <>
        <WrappedComponent
          isCheckingMediaAccess={isCheckingMediaAccess}
          videoAccess={hasVideoAccess}
          {...(props as P)}
        />
        <NoMediaAccessModal display={isCheckingMediaAccess} title={t('grantAccessHint')} />
      </>
    );
  };
