import { BasicPaginationParams, DateISOString } from 'domain/Common';

export enum InsightsReportStatus {
  Creating = 'CREATING',
  Created = 'CREATED',
  Error = 'ERROR',
}

export type InsightsReportAssociatedItem = {
  id: number;
  name: string;
  date: string;
  participantsCount: number;
};

export type InsightsReportData = {
  id: number;
  name: string;
  associatedItems: InsightsReportAssociatedItem[];
  status: InsightsReportStatus;
  createdAt: DateISOString;
  pin?: string;
};

export type TagCount = {
  tagId: number;
  tagName: string;
  count: number;
};

export type TagCategoryDemographics = {
  categoryId: number;
  tagCategoryType: string;
  tags: TagCount[];
};

export type MergeThemesPayload = {
  reportId: number;
  themeName: string;
  themeIds: number[];
};

export type UnmergeThemesPayload = {
  reportId: number;
  themeIds: number[];
};

export type RenameThemesPayload = {
  reportId: number;
  themeIdToNewName: Record<number, string>;
};

export type UpdateThemesVisibilityPayload = {
  reportId: number;
  themeIdsToHideStatus: Record<number, boolean>;
};

export type GetReportSourcesParams = BasicPaginationParams & { communityId: number };

export type ReportSource = {
  name: string;
  id: number;
  guestCount: number;
};

export type CreateInsightsReportParams = {
  ids: (number | undefined)[];
  selectAll: boolean;
};

export type ValidateReportPinPayload = {
  reportId: number;
  pin: string;
};
