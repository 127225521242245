import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  disable?: boolean;
};

export const useHover = ({ disable = false }: Props = {}): [
  MutableRefObject<HTMLDivElement | null>,
  boolean,
  null | HTMLElement,
] => {
  const [value, setValue] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const handleMouseOver = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setValue(true);
  }, []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  useEffect(() => {
    const node = ref.current;
    if (!node || disable) {
      return;
    }
    node.addEventListener('mouseenter', handleMouseOver);
    node.addEventListener('mouseleave', handleMouseOut);
    return () => {
      node.removeEventListener('mouseenter', handleMouseOver);
      node.removeEventListener('mouseleave', handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMouseOut, handleMouseOver, disable]);

  return [ref, value, anchorEl];
};
