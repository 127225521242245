import React, { MouseEvent, PropsWithChildren } from 'react';

import {
  CircularProgress,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import classNames from 'classnames';

import { StyledBackdrop, useTableStyles } from './Table.styles';
import { TableLabel } from './components/TableLabel/TableLabel';
import { TableProps, isColumnSortable } from './types';

export function Table<ItemType, ColumnIdType extends string = string>({
  columns,
  itemsList,
  emptyTableText = 'No data',
  onRowClick,
  getRowKey,
  isLoading = false,
  order,
  orderBy,
  onSortChange,
  fixedTableLayout,
  summaryRow,
  classes: customClasses,
  highlightedItem,
  onHighlightedItemChange,
  hover = true,
  headerOverlay,
}: PropsWithChildren<TableProps<ItemType & { id?: string | number | null }, ColumnIdType>>): JSX.Element {
  const classes = useTableStyles();
  const createSortHandler = (selectedOrderBy: ColumnIdType) => (event: MouseEvent<HTMLSpanElement>) => {
    onSortChange?.(event, selectedOrderBy);
  };

  return (
    <TableContainer className={classNames(classes.container, customClasses?.container)}>
      <MaterialTable sx={{ tableLayout: fixedTableLayout ? 'fixed' : 'auto' }}>
        <TableHead className={classNames(classes.header, customClasses?.header)}>
          {headerOverlay || (
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classNames(
                    classes.title,
                    column.classNames,
                    customClasses?.headerCell,
                    column.padding === 'checkbox' ? classes.checkboxCell : undefined
                  )}
                  key={column.id}
                  align={column.align}
                  style={column.style}
                  width={column.width}
                >
                  <TableLabel
                    column={column}
                    orderBy={orderBy}
                    order={order}
                    onSort={isColumnSortable(column) ? createSortHandler(column.id) : undefined}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className={classes.tableBody} sx={{ height: isLoading && !itemsList.length ? 58 : undefined }}>
          {itemsList?.length ? (
            itemsList.map((row, index) => (
              <TableRow
                key={getRowKey ? getRowKey(row) : row.id ?? index}
                hover={hover}
                tabIndex={-1}
                onClick={() => (onRowClick ? onRowClick(row) : null)}
                onMouseEnter={() => onHighlightedItemChange?.(row)}
                onMouseLeave={() => onHighlightedItemChange?.(undefined)}
                classes={{
                  root: classNames(
                    !!onRowClick && classes.clickableRow,
                    highlightedItem === row && classes.highlightedRow
                  ),
                }}
                data-testid={`row-${index}`}
              >
                {columns.map((column) => {
                  const value = column.format(row);
                  return (
                    <TableCell
                      classes={{ root: classes.cell }}
                      key={column.id}
                      align={column.align}
                      style={column.style}
                      width={column.width}
                      title={typeof value === 'string' ? value : ''}
                      padding={column.padding}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow role="checkbox" tabIndex={-1}>
              <TableCell align="center" colSpan={columns.length} title={emptyTableText}>
                {!isLoading && emptyTableText}
              </TableCell>
            </TableRow>
          )}
          {!!summaryRow && (
            <TableRow tabIndex={-1} classes={{ root: classes.summaryRow }} data-testid="summary-row">
              {columns.map((column) => {
                const value = column.format(summaryRow);
                return (
                  <TableCell
                    classes={{ root: classes.summaryCell }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                    width={column.width}
                    title={typeof value === 'string' ? value : ''}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          )}
        </TableBody>
      </MaterialTable>
      <StyledBackdrop open={isLoading} unmountOnExit mountOnEnter>
        <CircularProgress />
      </StyledBackdrop>
    </TableContainer>
  );
}
