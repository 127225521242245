import { createAction } from '@reduxjs/toolkit';
import {
  BroadcastWSMessage,
  BroadcastWSMessages,
  ChatWSMessage,
  ChatWSMessages,
  MeetingWSMessage,
  MeetingWSMessages,
  RoomWSMessage,
  RoomWSMessages,
} from 'common/constants';
import { SocketRoomPrefix } from 'store/features/socket';

type Action = MeetingWSMessage | ChatWSMessage | BroadcastWSMessage | RoomWSMessage;

type MeetingWSMessageAction = `${typeof SocketRoomPrefix.Meetings}:${Action}`;

/**
 * Factory function for creating WebSocket message actions related to **Meeting***.
 *
 * @template P - The payload type of the WebSocket message action (default is undefined).
 *
 * @param {Action} message - The specific action or event identifier to create a WebSocket message for.
 *
 * @returns {string} A WebSocket message action identifier generated by concatenating the 'Meetings' namespace
 *                 with the provided action string.
 */
export const createMeetingWSMessage = <P = undefined>(message: Action) => {
  // TODO Create type guard isMeetingMessage
  if (
    ![
      ...Object.values(MeetingWSMessages),
      ...Object.values(ChatWSMessages),
      ...Object.values(BroadcastWSMessages),
      ...Object.values(RoomWSMessages),
    ].includes(message)
  )
    throw Error(`Message '${message}' is not supported by Meeting WS`);

  return createAction<P, MeetingWSMessageAction>(`${SocketRoomPrefix.Meetings}:${message}`);
};
