import React, { ComponentType, FC, useEffect } from 'react';

import { CLASSNAMES } from './constants';

export const withLayout =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props) => {
    useEffect(() => {
      document.getElementsByTagName('body')[0].classList.add(CLASSNAMES.layoutWithScroll);
      document.getElementById('root')?.classList.add(CLASSNAMES.layoutWithScroll);

      return () => {
        document.getElementsByTagName('body')[0].classList.remove(CLASSNAMES.layoutWithScroll);
        document.getElementById('root')?.classList.remove(CLASSNAMES.layoutWithScroll);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
