import { createTheme } from '@mui/material';

export const { breakpoints } = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1504, // Microsoft surface issue -> NAT-1203, NAT-1608,
      mobile: 0,
      tablet: 1024,
      desktop: 1440,
      small: 400,
      hug: 584,
      page: 800,
    },
  },
});
