import { makeStyles } from '@mui/styles';

export const useUserAvatarStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      width: '100%',
      justifyContent: 'left',
    },
    userNameWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '15px',
      overflow: 'hidden',
    },
    userName: {
      fontWeight: 500,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      letterSpacing: 'normal',
    },
  }),
  { name: 'userAvatar' }
);
