import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CommunityState } from './types';

const initialState: CommunityState = {
  activeCommunityId: null,
  showCommunityCreationSuccessModal: false,
};

export const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    setActiveCommunityId: (state, action: PayloadAction<number | null>) => {
      state.activeCommunityId = action.payload;
    },
    setShowCommunityCreationSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.showCommunityCreationSuccessModal = action.payload;
    },
  },
});

export const { setActiveCommunityId, setShowCommunityCreationSuccessModal } = communitySlice.actions;
