import { Alert, styled } from '@mui/material';

export const StyledAlert = styled(Alert)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  alignContent: 'center',
  borderRadius: 0,
  padding: spacing(1.5, 2),

  backgroundColor: palette.error.dark,
  color: palette.common.white,

  '& .MuiAlert-message': {
    display: 'flex',
    alignContent: 'center',
    gap: spacing(1),
  },

  '&& .MuiIconButton-root': {
    padding: 0,
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
  },
}));

StyledAlert.defaultProps = {
  variant: 'filled',
  severity: 'error',
};
