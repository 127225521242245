import type { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme: { palette } }) => ({
      backgroundColor: palette.mode === 'light' ? palette.grey[50] : palette.custom.darkGrey,
      border: `1px solid ${palette.mode === 'light' ? palette.custom.lightGrey : palette.custom.darkGrey}`,
      color: palette.text.primary,
      fontSize: 12,
      minHeight: 20,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
    }),
    tooltipPlacementBottom: {
      marginTop: '8px !important',
    },
    tooltipPlacementTop: {
      marginBottom: '8px !important',
    },
    tooltipPlacementRight: {
      marginLeft: '8px !important',
    },
    tooltipPlacementLeft: {
      marginRight: '8px !important',
    },
    arrow: ({ theme: { palette } }) => ({
      '&:before': {
        backgroundColor: palette.mode === 'light' ? palette.grey[50] : palette.custom.darkGrey,
        border: `1px solid ${palette.mode === 'light' ? palette.custom.lightGrey : palette.custom.darkGrey}`,
      },
      color: palette.grey[50],
    }),
  },
};
