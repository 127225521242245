import { Box, styled } from '@mui/material';

export const TooltipWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  fontSize: '12px',
  lineHeight: '14px',
  padding: theme.spacing(1),
  borderRadius: '2px',
  boxShadow: theme.shadows[10],
  maxWidth: 320,
  wordBreak: 'break-word',
  backgroundColor,
  color: backgroundColor && theme.palette.getContrastText(backgroundColor),
}));
