import React, { FC } from 'react';

import { CircularProgress, Grid } from '@mui/material';

export const SuspenseFallback: FC = () => (
  <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);
