import { BroadcastWSPath } from './broadcast';
import { MeetingWSPath } from './meeting';

export const ChatWSMessages = {
  ChatMessages: 'chat_messages',
  PendingMessages: 'pending_messages',
  MessagesApproved: 'messages_approved',
  MessagesRejected: 'messages_rejected',
} as const;

export type ChatWSMessage = (typeof ChatWSMessages)[keyof typeof ChatWSMessages];

export const ChatWSActions = {
  SendChatMessage: `${BroadcastWSPath}/send_chat_message`,
  AcceptChatMessages: `${BroadcastWSPath}/accept_chat_messages`,
  RejectChatMessages: `${BroadcastWSPath}/reject_chat_messages`,

  EnableChatModeration: `${MeetingWSPath}/enable_chat_moderation`,
  DisableChatModeration: `${MeetingWSPath}/disable_chat_moderation`,
} as const;

export type ChatWSAction = (typeof ChatWSActions)[keyof typeof ChatWSActions];
