import { PaletteMode, PaletteOptions, createTheme } from '@mui/material';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';

export const theme = (color?: string, mode?: PaletteMode) =>
  createTheme({
    components,
    palette: palette(color, mode) as PaletteOptions,
    typography,
    breakpoints,
  });

export * from './shadows';
export * from './utils';
