import type { Components, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      justifyContent: 'center',
      borderBottom: 'solid 1px',
      borderColor: palette.divider,
      ...(palette.mode === 'dark' ? { backgroundColor: palette.custom.darkestGrey, borderBottom: 'none' } : {}),
      '@media all': {
        minHeight: 65,
      },
      '.logo, .sponsors': {
        filter: palette.mode === 'dark' ? 'grayscale(1) invert(1) brightness(1.6)' : undefined,
      },
    }),
  },
};
