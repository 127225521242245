import React from 'react';
import { useController } from 'react-hook-form';

import {
  TimePicker as NatterTimePicker,
  type TimePickerProps as NatterTimePickerProps,
} from 'common/components/Inputs';

type TimePickerProps = Omit<NatterTimePickerProps, 'value' | 'onChange'> & {
  defaultValue?: Date | null;
  name: string;
  onChange?: (value: Date | null) => void;
};

export const TimePicker = ({ defaultValue = null, name, onChange, ...timePickerProps }: TimePickerProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, defaultValue });

  const handleChange = (date: Date | null) => {
    onChange ? onChange(date) : field.onChange(date);
    field.onBlur();
  };

  return (
    <NatterTimePicker {...timePickerProps} {...field} error={!!error} hint={error?.message} onChange={handleChange} />
  );
};
