import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme: { spacing, palette, shape } }) => ({
  flexDirection: 'row',
  gap: spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: spacing(1.5, 2),

  color: palette.text.primary,
  backgroundColor: palette.common.white,
  border: `1px solid ${palette.divider}`,
  borderRadius: shape.borderRadius,

  userSelect: 'none',
}));
