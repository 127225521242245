import type { Components, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme: { spacing, palette } }) => ({
      textDecoration: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: 16,
      paddingLeft: 0,
      paddingRight: spacing(6),
      color: palette.text.primary,
    }),
  },
};
