import React, { FC } from 'react';

import { AlertProps, Typography } from '@mui/material';

import { StyledAlert } from './Ribbon.styled';

type Props = { onClose(): void } & Pick<AlertProps, 'sx'>;

export const Ribbon: FC<Props> = ({ sx, children, onClose }) => (
  <StyledAlert onClose={onClose} sx={sx}>
    <Typography variant="inherit" component="span">
      {children}
    </Typography>
  </StyledAlert>
);
