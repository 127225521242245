import type { Components, Theme } from '@mui/material';

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { typography, spacing } }) => ({
      ...typography.h2,
      textAlign: 'center',
      marginBottom: 0,
      padding: spacing(5, 7, 3, 7),
    }),
  },
};
