import { type Components, type Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: {
      minHeight: 'auto',
    },
    flexContainer: ({ theme: { spacing } }) => ({
      gap: spacing(2),
    }),
    indicator: {
      borderRadius: 1,
      height: 2,
      zIndex: 1,
    },
  },
};
