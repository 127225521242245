import { type Components, type Theme } from '@mui/material';

export const MuiSlider: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    root: ({ theme: { palette, spacing, shadows } }) => ({
      marginBottom: 0,
      marginTop: spacing(4),
      '& .MuiSlider-valueLabel': {
        borderRadius: '20px !important',
        border: '1.5px solid',
        borderColor: palette.primary.main,
        boxShadow: shadows[4],
      },
    }),
    rail: {
      backgroundColor: '#F0F2F4',
    },
    track: ({ theme: { palette } }) => ({
      backgroundColor: palette.grey[600],
      border: 'none',
    }),
    thumb: ({ theme: { palette } }) => ({
      backgroundColor: palette.primary.main,
      '&:hover': {
        backgroundColor: palette.extendedPrimary[700],
        boxShadow: 'none',
      },
      '&:focus': {},
      '&.Mui-active': {
        boxShadow: 'none',
        backgroundColor: palette.extendedPrimary[800],
      },
      '&.Mui-focusVisible': {
        boxShadow: 'none',
        '&:not(.Mui-active)': {
          backgroundColor: palette.extendedPrimary[700],
          '&::after': {
            content: '""',
            width: 29,
            height: 29,
            border: '2px solid',
            borderColor: palette.extendedPrimary[700],
            borderRadius: '50%',
          },
        },
      },
    }),
    mark: {
      display: 'none',
    },
    valueLabel: ({ theme: { palette, spacing, typography } }) => ({
      ...typography['natter-text-lg'],
      fontWeight: 600,

      backgroundColor: 'white',
      color: palette.text.primary,
      padding: spacing(0.5, 2),
      '&::before': {
        display: 'none',
      },
    }),
    markLabel: ({ theme: { palette, typography } }) => ({
      ...typography['natter-text-sm'],

      top: -30,
      color: palette.grey[500],
      '@media (pointer: coarse)': {
        top: -30,
        ...typography['natter-text-xs'],
        color: palette.grey[500],
      },
    }),
  },
};
