export const Features = {
  SentryEnabled: 'REACT_APP_SENTRY_ENABLED',
  ThemesCSV: 'REACT_APP_ENABLE_THEMES_CSV',
  ChartBreakDown: 'REACT_APP_ENABLE_CHART_BREAK_DOWN',
  Natters: 'REACT_APP_ENABLE_NATTERS',
  EventHosts: 'REACT_APP_ENABLE_EVENT_HOSTS',
  TechCheck: 'REACT_APP_ENABLE_ENABLE_TECH_CHECK',
  SurveysSearch: 'REACT_APP_ENABLE_SURVEYS_SEARCH',
  ReportsSearch: 'REACT_APP_ENABLE_REPORTS_SEARCH',
} as const;
