import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import { Stack } from '@mui/material';
import { Weekday } from 'domain/Common';

import { WeekdayChip } from './WeekdayPicker.styled';

type Props = {
  name: string;
};

export const WeekdayPicker: FC<Props> = ({ name }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
  });

  const handleClick = (day: Weekday) => () => {
    onChange(day);
  };

  return (
    <Stack direction="row" gap={1} width="100%">
      {Object.values(Weekday).map((weekday) => (
        <WeekdayChip
          data-testid={`WeekdayPicker-chip-${weekday}`}
          key={weekday}
          label={weekday[0]}
          onClick={handleClick(weekday)}
          color={value === weekday ? 'primary' : 'default'}
        />
      ))}
    </Stack>
  );
};
