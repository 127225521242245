import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import { Checkbox as CheckboxInput, CheckboxProps as CheckboxInputProps } from '../../Inputs';

export type CheckboxProps = {
  name: string;
  onChange?(checked: boolean): void;
} & CheckboxInputProps;

export const Checkbox: FC<CheckboxProps> = ({ name, onChange, ...props }) => {
  const { field } = useController({ name });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    field.onChange(event, checked);
    onChange?.(checked);
  };

  return <CheckboxInput {...field} {...props} onChange={handleChange} checked={field.value || false} />;
};
