import { Tag } from 'domain/Common';

export const getIsTagSelected = (currentTag: Tag, clickedTag: Tag, isSingleSelection: boolean) => {
  if (currentTag.id === clickedTag.id) {
    return !clickedTag.isSelected;
  }
  if (isSingleSelection) {
    return false;
  }
  return currentTag.isSelected;
};
