import React, { ComponentType, FC, useEffect, useState } from 'react';

import { NoMediaAccessModal } from 'devices/components';
import { checkMediaAccess } from 'devices/utils';
import { useAppDispatch, useAppSelector } from 'store';
import { selectHasMediaAccess, setMediaAccess } from 'store/features/user';

export type WithMediaAccessInfo = { mediaAccess: boolean };

export const withMediaAccess =
  <P extends { onCancel: () => void }>(
    WrappedComponent: ComponentType<P & WithMediaAccessInfo>
  ): FC<Omit<P, 'mediaAccess' | 'isCheckingMediaAccess'>> =>
  (props) => {
    const dispatch = useAppDispatch();
    const hasMediaAccess = useAppSelector(selectHasMediaAccess);
    const [isCheckingMediaAccess, setIsCheckingMediaAccess] = useState(hasMediaAccess === undefined);

    useEffect(() => {
      if (hasMediaAccess !== undefined) return;

      (async () => {
        setIsCheckingMediaAccess(true);
        const hasAccess = await checkMediaAccess();
        dispatch(setMediaAccess(hasAccess));
        setIsCheckingMediaAccess(false);
      })();
    }, [hasMediaAccess, dispatch]);

    return (
      <>
        <WrappedComponent
          isCheckingMediaAccess={isCheckingMediaAccess}
          mediaAccess={!!hasMediaAccess}
          {...(props as P)}
        />
        <NoMediaAccessModal display={isCheckingMediaAccess} />
      </>
    );
  };
