import type { Components, Theme } from '@mui/material';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: '14px',
    },
  },
};
