import { mapObjectWithIdListToIds } from 'common/utils';
import { differenceInMinutes } from 'date-fns';
import { AgoraProxyMode } from 'domain/event';
import { pick } from 'lodash';
import { EventWizardFormSerializedValues } from 'pages/EventWizard/validation/schema';
import { CreateUpdateEventForApi } from 'store/apis/event';

export const mapEventWizardFormValuesToApi = ({
  eventVideos,
  startTime,
  endTime,
  eventRecordingEnabled,
  ...data
}: EventWizardFormSerializedValues & { communityId?: number; relatedImageIds?: number[] }): Omit<
  CreateUpdateEventForApi,
  'communityId'
> & {
  communityId?: number;
} => ({
  ...pick(data, ['name', 'communityId', 'pinEnabled']),
  description: data.description || '',
  startTime,
  eventDuration: differenceInMinutes(new Date(endTime), new Date(startTime)),
  relatedImageIds: data.relatedImageIds || [],
  relatedVideoIds: mapObjectWithIdListToIds(eventVideos),
  agoraProxyMode: AgoraProxyMode.Automatic,
  agoraCloudRecordingEnabled: eventRecordingEnabled,
  hosts: data.hosts.map(({ email }) => email),
});
