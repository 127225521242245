import React, { FC } from 'react';

import { AddIcon } from 'icons';

import { Button, type ButtonProps } from '../Button';

type AddButtonProps = Pick<ButtonProps, 'onClick'>;

export const AddButton: FC<AddButtonProps> = (props) => (
  <Button variant="light" size="large" startIcon={<AddIcon />} sx={{ marginTop: 1 }} fullWidth {...props} />
);
