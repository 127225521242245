import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import qs, { ParseOptions, StringifyOptions } from 'query-string';

export const qsOptions: ParseOptions = {
  arrayFormat: 'comma',
  parseBooleans: true,
  parseNumbers: true,
};

interface UseQueryParamsResult<T> {
  query: T;
  stringify(object: Record<string, unknown>, options?: StringifyOptions): string;
}

export function useQueryParams<T>(): UseQueryParamsResult<T> {
  const { search } = useLocation();

  const query = useMemo(() => qs.parse(search, qsOptions) as T, [search]);

  return {
    query,
    stringify: qs.stringify,
  };
}
