import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { eventDetailsActions } from '../actions';
import { goToEventRepeatEffect } from '../effects';

export const eventDetailsListener = createListenerMiddleware<RootState>();

eventDetailsListener.startListening({
  actionCreator: eventDetailsActions.repeatDialog.confirmButton.clicked,
  effect: goToEventRepeatEffect,
});
