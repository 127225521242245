import { Stack, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { omitForwardedProps } from 'common/utils';
import { DragIndicatorIcon } from 'icons';

type Props = { isDraggable: boolean };

export const Wrapper = styled(Stack, { shouldForwardProp: omitForwardedProps<Props>('isDraggable') })<Props>(
  ({ theme: { spacing, palette }, isDraggable }) => ({
    border: `1px solid ${grey[200]}`,
    borderRadius: 8,
    padding: spacing(3),
    cursor: isDraggable ? 'grab' : undefined,
    background: palette.common.white,

    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
  })
);

export const StyledAccordionDragIcon = styled(DragIndicatorIcon)({
  height: 24,
  width: 24,
  color: grey[400],
});
