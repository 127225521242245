import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material';

import { Logo } from './Logo';
import { LogoWrapper } from './Logo.styled';

const DEFAULT_LOGO_REDIRECT = '/' as const;

type Props = {
  disableInteraction?: boolean;
};

export const LogoContainer: FC<Props> = ({ disableInteraction }) => {
  const { palette } = useTheme();

  return (
    <LogoWrapper
      component={disableInteraction ? 'div' : Link}
      to={disableInteraction ? undefined : DEFAULT_LOGO_REDIRECT}
      disableInteraction={disableInteraction}
      data-testid="logo-container"
    >
      <Logo variant={palette.mode === 'light' ? 'dark' : 'light'} />
    </LogoWrapper>
  );
};
