import React, { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { ErrorStateAction, ErrorStateContent } from './ErrorState.styled';
import { ErrorStateIcon } from './ErrorStateIcon';

type Props = {
  title: string;
  description: string;
  buttonLabel?: string;
  href?: string;
  onClick?(): void;
};

export const ErrorState: FC<Props> = ({ title, description, buttonLabel, href, onClick }) => (
  <Stack height={1} alignItems="center" justifyContent="center">
    <ErrorStateContent>
      <Box p={1.5}>
        <ErrorStateIcon />
      </Box>

      <Stack gap={2} alignItems="center">
        <Typography variant="natter-text-xl">{title}</Typography>
        <Typography variant="natter-text-sm" color="text.secondary">
          {description}
        </Typography>

        {buttonLabel && (href || onClick) && (
          <ErrorStateAction href={href} onClick={onClick}>
            {buttonLabel}
          </ErrorStateAction>
        )}
      </Stack>
    </ErrorStateContent>
  </Stack>
);
