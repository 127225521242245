import React, { FC } from 'react';

import { Link } from '@mui/material';
import { Ribbon } from 'common/components';
import { isApiErrorMessage } from 'common/utils';
import { type BackendErrorAlertData } from 'store/features/alerts';

import { ErrorCodeContainer } from './components';
import { SUPPORT_TEAM_URL } from './constants';

type Props = Pick<BackendErrorAlertData, 'message'> & { onClose(): void };

export const ApiErrorAlert: FC<Props> = ({ message, onClose }) => (
  <Ribbon onClose={onClose}>
    Something went wrong and we are unable to complete that request. Contact the Support Team{' '}
    <Link color="inherit" href={SUPPORT_TEAM_URL} target="_blank" rel="noreferrer">
      here
    </Link>
    . {isApiErrorMessage(message) && <ErrorCodeContainer {...message} />}
  </Ribbon>
);
