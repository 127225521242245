import React, { FC, PropsWithChildren, ReactNode, cloneElement, isValidElement } from 'react';

import { Link, type LinkProps as MuiLinkProps } from '@mui/material';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

type LinkifyTextProps = {
  LinkProps?: MuiLinkProps;
};

export const LinkifyText: FC<LinkifyTextProps> = ({ children, LinkProps }) => {
  const parseString = (string: string) => {
    if (string === '') {
      return string;
    }

    const matches = linkify.match(string);
    if (!matches) {
      return string;
    }

    const { elements, lastIndex } = matches.reduce<{ elements: (ReactNode | string)[]; lastIndex: number }>(
      (result, match, i) => {
        if (match.index > result.lastIndex) {
          result.elements.push(string.substring(result.lastIndex, match.index));
        }

        result.elements.push(
          <Link
            component="a"
            href={match.url}
            key={i}
            underline="hover"
            rel="noreferrer"
            target="_blank"
            {...LinkProps}
          >
            {match.text}
          </Link>
        );

        result.lastIndex = match.lastIndex;
        return result;
      },
      { elements: [], lastIndex: 0 }
    );

    if (string.length > lastIndex) {
      elements.push(string.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  };

  const parse = (childrenToParse: unknown, key = 0): ReactNode => {
    if (typeof childrenToParse === 'string') {
      return parseString(childrenToParse);
    }
    if (isValidElement(childrenToParse) && childrenToParse.type !== 'a' && childrenToParse.type !== 'button') {
      return cloneElement(
        childrenToParse,
        { key },
        parse((childrenToParse.props as PropsWithChildren<unknown>).children)
      );
    }
    if (Array.isArray(childrenToParse)) {
      return childrenToParse.map((child, i) => parse(child, i));
    }

    return childrenToParse;
  };

  return <>{parse(children)}</>;
};
