import { FieldErrors } from 'react-hook-form';

import { pick, uniq } from 'lodash';
import { identity } from 'lodash/fp';

// TODO Add unit tests for displaying schema errors
export const extractErrorMessage = (
  errors: FieldErrors<Record<string, unknown>>,
  name?: string | string[]
): string[] => {
  if ('message' in errors && typeof errors.message === 'string') {
    return [errors.message as string];
  }

  if (Array.isArray(errors)) {
    return uniq(errors.filter(identity).map((error) => extractErrorMessage(error))).flat();
  }

  const filteredErrors = name ? pick(errors, name) : errors;

  // @ts-expect-error TODO the type of field errors is combined from multiple types so needs to set proper type
  return uniq(Object.values(filteredErrors).filter(identity).map(extractErrorMessage).flat());
};
