import React, { FC, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { InputWrapper, type InputWrapperProps } from '../InputWrapper';

export interface OptionItem<T = string | number> {
  label: string;
  value: T;
  disabled?: boolean;
}

interface SelectInputProps extends InputWrapperProps {
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  options: OptionItem[];
  required?: boolean | string;
  InputProps?: unknown;
  defaultValue?: unknown;
  onChange?(value: unknown): void;
  displayEmpty?: boolean;
  'data-testid'?: string;
}

export const SelectInput: FC<SelectInputProps> = ({
  name,
  hint,
  label,
  options,
  required,
  defaultValue,
  placeholder,
  displayEmpty,
  onChange: onChangeEffect,
  'data-testid': dataTestId,
  ...props
}) => {
  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({ name, defaultValue, rules: { required } });

  const selectedLabel = useCallback(
    (selected) => options.find((option) => option.value === selected)?.label,
    [options]
  );

  return (
    <InputWrapper label={label} hint={hint} name={name} {...props}>
      <FormControl error={!!error} fullWidth>
        {!displayEmpty && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
        <Select
          data-testid={dataTestId}
          fullWidth
          displayEmpty={displayEmpty}
          label={displayEmpty ? undefined : label}
          labelId={`${name}-label`}
          ref={ref}
          value={value}
          renderValue={(selected) => (selected?.length === 0 ? placeholder : selectedLabel(selected))}
          onChange={(e) => {
            onChangeEffect?.(e.target.value);
            onChange(e);
          }}
          error={!!error}
        >
          {options.map((option: OptionItem) => (
            <MenuItem
              key={`${option.value}${option.label}`}
              value={option.value}
              disabled={option.value === undefined || option.value === null || option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputWrapper>
  );
};
