import type { Components, Theme } from '@mui/material';

export const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      border: 'none',
      fontWeight: 500,

      '&.Mui-selected': {
        backgroundColor: palette.extendedPrimary[50],
        border: 'none',
      },
    }),
  },
};
