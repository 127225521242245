import { Effect } from 'store';

import { communityActions, selectMyCommunitiesIds } from '../community';
import { AsyncSurvey } from './types';

export const getSurveySuccessEffect: Effect = ({ payload }, { dispatch, getState }) => {
  const { communityId } = payload as AsyncSurvey;
  const state = getState();
  const myCommunitiesIds = selectMyCommunitiesIds(state);
  const isInCommunity = myCommunitiesIds?.includes(communityId);
  if (!isInCommunity) {
    // getSurvey endpoint automatically registers to community if User is not yet registered
    dispatch(communityActions.community.registeredToCommunity({ communityId }));
  }
};
