import { styled } from '@mui/material';

import { TextField } from '../Inputs';

export const StyledTextField = styled(TextField)(({ theme: { palette } }) => ({
  '& .MuiInputBase-readOnly': {
    borderColor: palette.grey[300],
    backgroundColor: palette.grey[25],
  },
}));
