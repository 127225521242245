import { KnownTagsCategoryTypes, TagsCategory } from 'domain/Common';

import { TagsCategoriesFormValues } from '../schema';

export const mapTagsCategoriesToDefaultValues = (
  tagsCategories: TagsCategory[]
): TagsCategoriesFormValues['tagsCategories'] =>
  tagsCategories.reduce(
    (acc, { tags, type, ...rest }) => {
      if (type === KnownTagsCategoryTypes.Additional && tags?.length) {
        acc.push({ ...rest, tags, type, isOptional: true });
        return acc;
      }

      if (type !== KnownTagsCategoryTypes.Additional) {
        acc.push({ ...rest, tags, type, isOptional: false });
      }

      return acc;
    },
    [] as TagsCategoriesFormValues['tagsCategories']
  );
