import type { Components, Theme } from '@mui/material';

export const MuiPaper: Components<Theme>['MuiPaper'] = {
  defaultProps: {},
  styleOverrides: {
    rounded: {
      borderRadius: 15,
    },
    elevation1: {
      boxShadow: 'none',
    },
  },
};
