import React, { FC, PropsWithChildren, ReactElement, isValidElement } from 'react';

import type { FeatureKey } from '../types';
import { isFeatureEnabled } from '../utils';

export type FeatureGuardProps = PropsWithChildren<{
  feature: FeatureKey;
  fallback?: ReactElement;
}>;

/**
 * FeatureGuard is a component that conditionally renders its children or a fallback based on the feature flag status.
 *
 * @component
 * @param {Feature} props.feature - The name of the feature flag to check.
 * @param {ReactNode} props.fallback - The fallback content to render if the feature flag is disabled.
 * @param {ReactNode} props.children - The content to render if the feature flag is enabled.
 * @returns {ReactNode} - The rendered React element.
 *
 * @example
 * // Example usage:
 * // Assuming isFeatureEnabled and isValidElement functions are available.
 *
 * <FeatureGuard feature="exampleFeatureFlag" fallback={<div>Fallback Content</div>}>
 *   <div>Feature Content</div>
 * </FeatureGuard>
 */
export const FeatureGuard: FC<FeatureGuardProps> = ({ feature, fallback, children }) => {
  if (isFeatureEnabled(feature)) {
    return <>{children}</>;
  }

  if (isValidElement(fallback)) {
    return <>{fallback}</>;
  }

  return null;
};
