import { UrlName } from 'modules/app/types';

export interface ConfigServiceI {
  urls: Record<UrlName, string>;
  getUrl(name: UrlName): string;
}

class ConfigService implements ConfigServiceI {
  urls: ConfigServiceI['urls'];

  constructor() {
    this.urls = {
      api: window._env_.REACT_APP_API_URL,
      auth: window._env_.REACT_APP_AUTH_URL,
      base: window._env_.REACT_APP_API_BASE_URL,
    };
  }
  getUrl(name: UrlName): string {
    return this.urls[name];
  }
}

export default ConfigService;
