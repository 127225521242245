import { createApi } from '@reduxjs/toolkit/query/react';
import { ERROR_MESSAGES } from 'common/constants';
import { assert } from 'common/utils';
import { BasicPaginationParams, PaginatedApiParamsV3, PaginatedApiPayloadV2 } from 'domain/Common';
import { pickBy, prop } from 'lodash/fp';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import {
  AnswerStatistics,
  CreateInsightsReportParams,
  GetReportSourcesParams,
  InsightsReportData,
  ReportSource,
  TagCategoryDemographics,
} from '../insightsReport';
import {
  mapSurveysListToReportSources,
  mapSurveysReportDataToInsightsReportData,
  mapSurveysReportListToInsightsReportList,
} from './mappers';
import { SurveysReportData } from './types';

const apiV1 = getApiBaseUrl('v1');
const apiV3 = getApiBaseUrl('v3');

export const surveyReportApi = createApi({
  reducerPath: 'surveyReportApi',
  tagTypes: ['REPORTS', 'REPORT_ANSWERS', 'REPORT_KEY_METRICS', 'REPORT_DEMOGRAPHICS', 'REPORT_SOURCES'],
  baseQuery: axiosBaseQuery({ baseURL: '/' }),
  endpoints: (builder) => ({
    getIsAnySurveyReport: builder.query<boolean, { communityId: number }>({
      query: ({ communityId }) => ({
        url: `${apiV1}/survey/report/reports/${communityId}`,
        page: 1,
        size: 1,
      }),
      transformResponse: (data: PaginatedApiPayloadV2<SurveysReportData>) => data.totalCount > 0,
    }),
    getSurveyReportsList: builder.query<
      PaginatedApiPayloadV2<InsightsReportData>,
      PaginatedApiParamsV3 & { communityId: number | null }
    >({
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'REPORTS' as const, id: id.toString() })),
              { type: 'REPORTS' as const, id: 'LIST' },
            ]
          : ['REPORTS'],
      query: ({ communityId, ...params }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return {
          url: `${apiV1}/survey/report/reports/${communityId}`,
          params,
        };
      },
      transformResponse: mapSurveysReportListToInsightsReportList,
    }),
    getSurveyReportById: builder.query<InsightsReportData, { reportId: number }>({
      providesTags: (result) => (result?.id ? [{ type: 'REPORTS', id: result.id }] : []),
      query: ({ reportId }) => ({
        url: `${apiV1}/survey/report/${reportId}`,
      }),
      transformResponse: mapSurveysReportDataToInsightsReportData,
    }),
    getSurveyReportAnswers: builder.query<
      AnswerStatistics[],
      { reportId: number; communityTagIds?: Record<number, number[]> }
    >({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_ANSWERS', id: reportId }] : [],
      query: ({ reportId, communityTagIds }) => {
        const filteredCommunityTagIds = pickBy('length')(communityTagIds);
        const communityTagIdsParam = Object.keys(filteredCommunityTagIds).length
          ? JSON.stringify(filteredCommunityTagIds)
          : undefined;
        return {
          url: `${apiV1}/survey/report/${reportId}/answers`,
          params: {
            communityTagIds: communityTagIdsParam,
          },
        };
      },
    }),
    getSurveyReportKeyMetrics: builder.query<Record<string, number>, { reportId: number }>({
      providesTags: (_result, _error, args) =>
        args.reportId ? [{ type: 'REPORT_KEY_METRICS', id: args.reportId }] : [],
      query: ({ reportId }) => ({
        url: `${apiV1}/survey/report/${reportId}/survey-metrics`,
      }),
    }),
    getSurveyReportDemographics: builder.query<TagCategoryDemographics[], { reportId: number }>({
      providesTags: (result, _error, { reportId }) =>
        result?.length !== undefined ? [{ type: 'REPORT_DEMOGRAPHICS', id: reportId }] : [],
      query: ({ reportId }) => ({
        url: `${apiV1}/survey/report/${reportId}/survey-demographics`,
      }),
    }),
    getSurveysReportSources: builder.query<PaginatedApiPayloadV2<ReportSource>, GetReportSourcesParams>({
      providesTags: ['REPORT_SOURCES'],
      query: ({ page, pageSize, communityId, sortOrder, sortBy }) => ({
        url: `${apiV1}/survey/surveys/${communityId}`,
        params: {
          page,
          size: pageSize,
          sortDir: sortOrder,
          sortField: sortBy,
        },
      }),
      transformResponse: mapSurveysListToReportSources,
    }),
    getSimilarSurveysReportSources: builder.query<
      PaginatedApiPayloadV2<ReportSource>,
      BasicPaginationParams & { id: number }
    >({
      query: ({ id, page, pageSize }) => ({
        url: `${apiV1}/survey/${id}/similar-surveys`,
        params: { page, size: pageSize },
      }),
      transformResponse: mapSurveysListToReportSources,
      forceRefetch: () => true,
    }),
    updateSurveyReport: builder.mutation<void, Pick<InsightsReportData, 'id' | 'name'>>({
      invalidatesTags: (_result, _error, { id }) => [{ type: 'REPORTS', id }],
      query: ({ id, name }) => ({
        url: `${apiV3}/report/${id}`,
        method: 'PUT',
        data: { name },
      }),
    }),
    createSurveysReport: builder.mutation<void, CreateInsightsReportParams>({
      query: ({ ids, selectAll }) => ({
        url: `${apiV1}/survey/report`,
        method: 'POST',
        data: {
          surveyIds: ids,
          allSurveys: selectAll,
        },
      }),
    }),
    deleteSurveyReport: builder.mutation<void, Pick<InsightsReportData, 'id' | 'name' | 'status'>>({
      query: ({ id }) => ({
        url: `${apiV3}/report/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'REPORTS', id: 'LIST' }],
    }),
    retrySurveyReportGeneration: builder.mutation<void, InsightsReportData>({
      invalidatesTags: (_result, _error, { id }) => [{ type: 'REPORTS', id }],
      query: ({ associatedItems, name }) => ({
        url: `${apiV1}/survey/report`,
        method: 'POST',
        data: { surveyIds: associatedItems.map(prop('id')), name },
      }),
    }),
  }),
});

export const {
  useGetIsAnySurveyReportQuery,
  useGetSurveyReportsListQuery,
  useGetSurveyReportByIdQuery,
  useGetSurveyReportAnswersQuery,
  useGetSurveyReportDemographicsQuery,
  useGetSurveyReportKeyMetricsQuery,
  useLazyGetSurveysReportSourcesQuery,
  useLazyGetSimilarSurveysReportSourcesQuery,
  useCreateSurveysReportMutation,
  useUpdateSurveyReportMutation,
  useDeleteSurveyReportMutation,
  useRetrySurveyReportGenerationMutation,
} = surveyReportApi;
