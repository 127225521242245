import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components';
import { logger } from 'common/services';
import { VolumeUpIcon } from 'icons';

import testSound from './testSound.wav';

type AudioOutputTestProps = {
  deviceId: string;
  disabled: boolean;
};

export const AudioOutputTest: FC<AudioOutputTestProps> = ({ deviceId, disabled }) => {
  const { t } = useTranslation('devices', { keyPrefix: 'selectSpeaker' });
  const testSoundClick = useCallback(async () => {
    try {
      const soundSample = new Audio(testSound);

      if (deviceId && typeof soundSample?.setSinkId === 'function') {
        await soundSample.setSinkId(deviceId);
      }

      soundSample.currentTime = 0;

      await soundSample.play();
    } catch (error) {
      logger.error(error);
    }
  }, [deviceId]);

  return (
    <Button
      variant="light"
      onClick={testSoundClick}
      startIcon={<VolumeUpIcon fontSize="small" />}
      data-testid="AudioOutputTest-Test"
      disabled={disabled}
    >
      {t('testHint')}
    </Button>
  );
};
