import React, { FC, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, Switch } from 'common/components/ReactHookForm';
import { useBreakpoints } from 'common/hooks';
import { MediaDevice } from 'domain/event';
import { MicrophoneOffIcon, MicrophoneOnIcon } from 'icons';

import { useDevicesSelect } from '../../hooks';
import { DevicesSection } from '../DevicesSection';
import { AudioInputTest } from './components';

type Props = {
  devices: MediaDevice[];
  isAccessGranted: boolean;
  fieldName?: string;
  isEnabledFieldName?: string;
  onChange?(): void;
  onMicrophoneEnabledChange?(value: boolean): void;
};

export const SelectMicrophone: FC<Props> = ({
  fieldName = 'microphoneId',
  isEnabledFieldName = 'isMicrophoneEnabled',
  devices,
  isAccessGranted,
  onChange,
  onMicrophoneEnabledChange,
}) => {
  const { t } = useTranslation('devices', { keyPrefix: 'selectMicrophone' });
  const { isSmallScreen } = useBreakpoints();
  const { options, selectedDeviceId, label, isDisabled } = useDevicesSelect({
    name: fieldName,
    label: t('label'),
    devices,
    isAccessGranted,
  });
  const isMicrophoneEnabled: boolean = useWatch({ name: isEnabledFieldName });

  const handleChangeEffect = useCallback(
    (value) => {
      onMicrophoneEnabledChange?.(value);
      onChange?.();
    },
    [onMicrophoneEnabledChange, onChange]
  );

  return (
    <DevicesSection
      Icon={isMicrophoneEnabled ? <MicrophoneOnIcon fontSize="small" /> : <MicrophoneOffIcon fontSize="small" />}
      title={t('label')}
      Action={
        <Switch size="small" name={isEnabledFieldName} disabled={isDisabled} onChangeEffect={handleChangeEffect} />
      }
    >
      <Select
        startAdornment={isSmallScreen && <MicrophoneOnIcon fontSize="small" />}
        disabled={isDisabled}
        size="small"
        label={label}
        name={fieldName}
        options={options}
        data-testid="MediaSettings-Microphone"
        shouldShowError={false}
        onChange={onChange}
      />
      {selectedDeviceId && isMicrophoneEnabled && <AudioInputTest deviceId={selectedDeviceId} />}
    </DevicesSection>
  );
};
